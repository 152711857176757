import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Box, TextField, Typography } from "@mui/material"
import { useState } from "react"
import { InitialsGrouping } from "@erinfo/brand-ui/src/storybook/inputs/initials-grouping"

interface IProps {
  changePhoneNumber: () => void
  sendNewCode: () => void
  cancel: () => void
  phoneNumber: string
  setCode: (string) => void
  code: string
  onSubmit: (string) => Promise<void>
  error: string
  requireTerms: boolean
}

const VerificationEntry = ({
  changePhoneNumber,
  sendNewCode,
  cancel,
  phoneNumber,
  code,
  setCode,
  onSubmit,
  error,
  requireTerms,
}: IProps) => {
  const [terms, setTerms] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const lastFour = phoneNumber?.slice(-4)

  const submit = () => {
    if (requireTerms && !terms) return setErrors([`You must agree to terms`])

    void onSubmit(code)
  }

  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
        Verification Code
      </Typography>
      <Typography sx={{ color: `rgba(0,0,0,0.6)` }}>
        A code was sent to <strong>xxxxxx-{lastFour}</strong>.<br /> Please
        enter that code below:
      </Typography>
      <TextField
        variant="standard"
        fullWidth
        label="Enter verification code"
        value={code}
        onChange={(e) => setCode(e.target.value.trim())}
        sx={{ marginTop: 1 }}
        size="small"
        autoComplete="one-time-code"
      />

      {requireTerms && (
        <>
          <Box sx={{ marginTop: 2 }} />
          <InitialsGrouping
            to="https://erinfo.me/terms-conditions"
            showCheckbox={true}
            inline
            errors={errors}
            value={terms}
            onChange={(e) => {
              setErrors([])
              setTerms(e.target.checked)
            }}
          >
            I agree to ERinfo's
          </InitialsGrouping>
        </>
      )}

      <StyledButton
        variant="contained"
        color="primary"
        fullWidth
        loadingPosition="end"
        style={{ marginTop: 25 }}
        onClick={submit}
      >
        Verify
      </StyledButton>
      {error && (
        <Typography sx={{ marginTop: 2 }} color="error">
          {error}
        </Typography>
      )}
      <Box>
        <StyledButton fullWidth onClick={sendNewCode} sx={{ marginTop: 4 }}>
          Send a new code
        </StyledButton>
        <StyledButton
          fullWidth
          onClick={changePhoneNumber}
          sx={{ marginTop: 2 }}
        >
          Change phone number
        </StyledButton>
        <StyledButton fullWidth onClick={cancel} sx={{ marginTop: 2 }}>
          Cancel
        </StyledButton>
      </Box>
    </>
  )
}

export default VerificationEntry
