import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { DatepickerInput } from "@erinfo/brand-ui/src/storybook/inputs/datepicker"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { dataGender } from "@erinfo/data-schema"
import { hasWhitespace } from "@erinfo/data-schema/src/_helpers/regex/start-or-end-whitespace"
import * as attr from "@erinfo/data-schema/src/attribute"
import { Field } from "formik"

interface IProps {
  setFieldValue: (field: string, val: string) => void
  showEmail?: boolean
  disableEmail?: boolean
  userIsSubscriber?: boolean
}

export const CorePersonalForm = ({
  setFieldValue,
  showEmail,
  disableEmail,
  userIsSubscriber = false,
}: IProps) => {
  return (
    <>
      <Field
        name={attr.nameFirstName}
        label="First Name"
        component={TextInput}
        inputProps={{
          maxlength: `30`,
          "data-clarity-unmask": `true`,
          className: `clarity-unmask`,
          autoComplete: `given-name`,
        }}
        required
        hideAsterisk
      />
      <Field
        name={attr.nameLastName}
        label="Last Name"
        component={TextInput}
        inputProps={{
          maxlength: `30`,
          "data-clarity-unmask": `true`,
          className: `clarity-unmask`,
          autoComplete: `family-name`,
        }}
        required
        hideAsterisk
      />
      {showEmail && (
        <Field
          name={attr.nameEmail}
          label="Email"
          type="email"
          component={TextInput}
          inputProps={{
            maxlength: `50`,
            "data-clarity-unmask": `true`,
            className: `clarity-unmask`,
            autoComplete: `email`,
          }}
          disabled={disableEmail}
          helperText={
            disableEmail &&
            userIsSubscriber &&
            ` To update, open Settings from main menu`
          }
        />
      )}
      <Field
        component={AutocompleteInput}
        label="Gender"
        name={attr.nameGender}
        options={dataGender}
        setFieldValue={setFieldValue}
        inputProps={{
          autoComplete: `sex`,
        }}
        required
        hideAsterisk
      />
      <Field
        component={DatepickerInput}
        name={attr.nameBirthDate}
        label="Date of Birth"
        inputProps={{
          autoComplete: `bday`,
        }}
        disableFuture
        required
        hideAsterisk
      />
    </>
  )
}
