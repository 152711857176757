import { RootState } from "@erinfo/consumer/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Dispatch } from "@erinfo/consumer/src/store"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"

const SsoRedirect = () => {
  const navigate = useNavigate()
  const { handleSignout } = useAuth()
  const user = useSelector((state: RootState) => state.user)
  const { setDialogMessage } = useRematchDispatch((dispatch: Dispatch) => ({
    setDialogMessage: dispatch.notifications.setDialogMessage,
  }))
  console.log(user)

  if (user.id) {
    navigate(`/dashboard`)
  }

  if (user.error?.status === 404) {
    setDialogMessage({
      title: `User Doesn't Exist`,
      msg: `This user is not registered to use ERinfo.`,
      onYes: () => handleSignout(),
    })
  }

  return (
    <div>
      <h1>Redirecting...</h1>
    </div>
  )
}

export default SsoRedirect
