import parsePhoneNumber, { parsePhoneNumberFromString } from "libphonenumber-js"

export const camelCaseToSentenceCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ` $1`)
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const parseNumber = (phoneNumber) => {
  const number = parsePhoneNumber(phoneNumber)
  if (number) {
    return number.formatInternational()
  }
  return phoneNumber
}
