import * as z from "zod"

const cmdToRemoveAttribute = `` // empty string is the cmd to remove an attribute
/**
 * Accept only optional attributes.
 *   - if the attribute is required, there is no reason to set it to "CanBeRemoved"
 */
type attribute = z.ZodOptional<any>
/**
 * Accept empty string ("") as value for PATCH attributes that can be removed
 */
export const setAttrCanBeRemoved = <Z extends attribute>(attribute: Z) => {
  return z.union([attribute, z.literal(cmdToRemoveAttribute)])
}
