import { Amplify } from "@aws-amplify/core"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { envAmplify } from "@erinfo/env"
import { GoogleAnalytics } from "@erinfo/react-utils/src/components/googleAnalytics"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import CssBaseline from "@mui/material/CssBaseline"
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useSelector } from "react-redux"
import { useRoutes } from "react-router-dom"

import { AuthProvider } from "./contexts/auth"
import routes from "./routes"
import theme from "./theme"

Amplify.configure(envAmplify)

const App = () => {
  const user = useSelector((state: RootState) => state.user)
  const signUp = useSelector((state: RootState) => state.signUp)
  const { setDialogMessage, createNewMember } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      createNewMember: dispatch.user.createNewMember,
    }),
  )
  const content = useRoutes(
    routes({ user, setDialogMessage, createNewMember, signUp }),
  )

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CssBaseline />
          <GoogleAnalytics
            gaTrackingId={
              /**
               * The measurement id is here @see https://analytics.google.com/analytics/web/?authuser=1#/a189025142p262783413/admin/streams/table/
               */
              process.env.AWS_STAGE === `production` ? `G-8PXXDZJ5RV` : `G-MFTYF5ND4Y`
            }
            gtmId={process.env.AWS_STAGE === `production` ? `GTM-MWT5PHP` : `GTM-56JK2CD`}
          />
          <AuthProvider>{content}</AuthProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default App
