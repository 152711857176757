import { MedicalSelect } from "./MedicalSelect"

export const MedicalSelectInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  setFieldValue,
  ...props
}) => {
  const { onBlur, name, value } = field
  const handleChange = (value) => {
    setFieldValue(name, value)
  }

  const handleBlur = () => onBlur(name, true)

  return (
    <MedicalSelect
      inputId={name}
      {...field}
      {...props}
      value={value === undefined ? `` : value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
