import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as z from "zod"

export const nameAllergies = `allergies`

export const allergy = z
  .object({
    [attr.nameTitle]: attr.titleOptional,
    [attr.nameAnaphylaxisReaction]: attr.anaphylaxisReactionOptional,
    [attr.nameReaction]: attr.reactionOptional,
    [attr.nameReactionDesc]: attr.reactionDescOptional,
  })
  .strict()
export type allergy = z.infer<typeof allergy>

export const allergies = z.array(allergy)
export const allergiesOptional = allergies.optional()

export const allergiesPut = helpers.setPutItem(allergy)
export const allergiesPutOptional = allergiesPut.optional()
