/** @jsxImportSource @emotion/react */
import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { Box, InputAdornment, Divider, Typography } from "@mui/material"
import * as attr from "@erinfo/data-schema/src/attribute"
import { evacuationStatus } from "@erinfo/data-schema/src/multi-select/evacuation-status"
import { evacueeTypes } from "@erinfo/data-schema/src/multi-select/evacuee-types"
import { Field } from "formik"
import DoneIcon from "@mui/icons-material/Done"
import RecordIcon from "@mui/icons-material/FiberManualRecord"
import { useMemo } from "react"
import { RadioGroupInput } from "@erinfo/brand-ui/src/storybook/inputs/radio-group"

let hospitals: { title: string; value: string }[] | undefined
import("@erinfo/data-schema/src/collection/hospitals").then((attr) => {
  hospitals = attr.hospitals
})

interface IProps {
  setFieldValue: (field: string, val: string) => void
  values: any
  favoriteDestinations?: string[]
}

export const getColor = (triageStatus: string) => {
  if (!triageStatus || typeof triageStatus !== "string") return undefined
  return triageStatus?.includes("Yellow")
    ? "orange"
    : /\(([^)]+)\)/.exec(triageStatus)?.[1].toLowerCase()
}

export const DispositionForm = ({
  setFieldValue,
  values,
  favoriteDestinations,
}: IProps) => {
  const triageColor = getColor(values?.[attr.nameTriageStatus])

  const hospitalList = useMemo(
    () =>
      favoriteDestinations?.length
        ? [
            ...favoriteDestinations.map((value, idx) => ({
              title: value,
              value,
              topDivider: idx === 0,
              bottomDivider: idx === favoriteDestinations.length - 1,
            })),
            ...(hospitals?.filter(
              (hospital) => !favoriteDestinations.includes(hospital.value),
            ) ?? []),
          ]
        : hospitals,
    [favoriteDestinations],
  )

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Field
          component={RadioGroupInput}
          name={attr.nameIncidentType}
          label=""
          options={[
            { value: "massCasualty", label: "Routine & MCI" },
            { value: "evacuation", label: "Evacuation Mode" },
          ]}
          row
          required
        />
      </Box>
      <Field
        name={attr.nameIncidentId}
        label="Incident ID"
        component={TextInput}
        inputProps={{ maxlength: `15` }}
        required
      />
      <Field
        name={attr.nameTransportUnit}
        label="Transport Unit"
        component={TextInput}
        inputProps={{ maxlength: `15` }}
        required
      />
      {values.incidentType === "evacuation" && (
        <>
          <Field
            name={attr.nameInitialLocation}
            label="Initial Location"
            component={TextInput}
            inputProps={{ maxlength: `100` }}
            required
          />
          <Field
            name={attr.nameEvacueeType}
            component={AutocompleteInput}
            label="Evacuee Type"
            options={evacueeTypes}
            setFieldValue={setFieldValue}
            inputProps={{ maxlength: `100` }}
            required
          />
          <Field
            name={attr.nameEvacuationStatus}
            component={AutocompleteInput}
            label="Evacuation Status"
            options={evacuationStatus}
            setFieldValue={setFieldValue}
            inputProps={{ maxlength: `100` }}
            required
          />
        </>
      )}
      <Field
        component={AutocompleteInput}
        label="Patient Destination"
        name={attr.namePatientDestination}
        options={hospitalList}
        inputProps={{ maxlength: `100` }}
        setFieldValue={setFieldValue}
        renderOption={(props, option) => (
          <>
            {option.topDivider && (
              <div css={{ position: "relative", marginBottom: "12px" }}>
                <Divider sx={{ width: "100%" }} />
                <Typography
                  sx={{
                    position: "absolute",
                    left: "calc(50% - 75px)",
                    top: -10,
                    background: "white",
                    padding: "0 5px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  Recent Destinations
                </Typography>
              </div>
            )}
            <li {...props} css={{ display: "flex" }}>
              <Box sx={{ flexGrow: 1, flex: 1 }}>{option.value}</Box>
            </li>
            {option.bottomDivider && (
              <div css={{ position: "relative", marginBottom: "12px" }}>
                <Divider sx={{ width: "100%" }} />
                <Typography
                  sx={{
                    position: "absolute",
                    left: "calc(50% - 75px)",
                    top: -10,
                    background: "white",
                    padding: "0 5px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  All Destinations
                </Typography>
              </div>
            )}
          </>
        )}
        required
      />

      <Field
        required
        component={AutocompleteInput}
        label="Triage Status"
        name={attr.nameTriageStatus}
        options={
          values.incidentType === "evacuation"
            ? [
                "Level 1 - Non-Ambulatory/Continuous Care",
                "Level 2 - Non-Ambulatory/Frequent Care",
                "Level 3 - Ambulatory/Moderate Care",
                "Level 4 - Ambulatory/Self Sufficient",
              ].map((value) => ({
                value,
                title: value,
              }))
            : [
                "Immediate (Red)",
                "Delayed (Yellow)",
                "Minor (Green)",
                "Deceased (Black)",
                "Expectant (Gray)",
              ].map((value) => ({
                value,
                title: value,
                color: getColor(value),
              }))
        }
        inputProps={{ style: { color: triageColor, marginLeft: -10 } }}
        InputProps={{
          startAdornment: triageColor && (
            <>
              <InputAdornment position="start" sx={{ marginLeft: 2 }}>
                <RecordIcon fontSize="small" sx={{ color: triageColor }} />
              </InputAdornment>
            </>
          ),
        }}
        setFieldValue={setFieldValue}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Box
              component={DoneIcon}
              sx={{ width: 17, height: 17, mr: "5px", ml: "-2px" }}
              style={{ visibility: selected ? "visible" : "hidden" }}
            />
            <Box sx={{ flexGrow: 1, color: option.color }}>{option.value}</Box>
          </li>
        )}
      />
    </>
  )
}
