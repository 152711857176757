import { AutocompleteSelect } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { FileDropzone } from "@erinfo/brand-ui/src/storybook/other/FileDropzone"
import { EditDialog } from "@erinfo/brand-ui/src/storybook/other/photo/cropper-dialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { documentTypes } from "@erinfo/data-schema"
import { getPresignedImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { useUnsavedChanges } from "@erinfo/react-utils/src/hooks/use-unsaved-changes"
import { Add, CreditCard, Delete, Image } from "@mui/icons-material"
import { Box, Divider, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Fragment, useEffect, useState } from "react"

const validDocNameRegex = /^\p{L}+(?:[-_ ']\p{L}+)*$/u
const MAX_DOC_TYPE_LENGTH = 40

const emptyDoc = {
  type: ``,
  typeDetails: undefined,
  front: ``,
  frontFinal: false,
  back: ``,
  backFinal: false,
}

const StyledImage = styled(`img`)(({ theme }) => ({
  width: 200,
  marginBottom: 10,
  border: `3px dashed rgba(0,0,0,0.22)`,
  borderRadius: theme.spacing(1),
}))

export const DocumentsDialog = ({ open, handleClose, user, handleSubmit }) => {
  const [loading, setLoading] = useState(false)
  const [documents, setDocuments] = useState([emptyDoc])
  const [editPhoto, setEditPhoto] = useState<{
    idx: number
    side: string
    base64: string
  }>()
  const [errors, setErrors] = useState<{
    [idx: string]: string[]
  }>()
  const { onClose, setDirty } = useUnsavedChanges(handleClose, open)

  useEffect(() => {
    if (
      documents.some(
        (d) => d.front?.startsWith(`data:`) || d.back?.startsWith(`data:`),
      )
    ) {
      setDirty(true)
    }
    if (errors) validate()
  }, [documents])

  useEffect(() => {
    if (!open) {
      setDocuments([emptyDoc])
      setEditPhoto(undefined)
    }
  }, [open])

  useEffect(() => {
    if (open && user?.documents?.length) {
      setDocuments(
        user.documents.map((doc) => ({
          type: doc.type,
          typeDetails: doc.typeDetails,
          front: doc.images[0]
            ? getPresignedImageUrl(doc.images[0], user.otherPictures)
            : ``,
          back: doc.images[1]
            ? getPresignedImageUrl(doc.images[1], user.otherPictures)
            : ``,
          frontFinal: doc.images[0] ? true : false,
          backFinal: doc.images[1] ? true : false,
        })),
      )
    }
  }, [user, open])

  const addField = () => {
    setDocuments((p) => [emptyDoc, ...p])
  }

  const handleChange = (idx) => (update) => {
    setDocuments((docs) =>
      docs.map((doc, i) =>
        idx === i
          ? {
              ...doc,
              ...update,
            }
          : doc,
      ),
    )
    if (update.front && !update.frontFinal) {
      setEditPhoto({ idx, side: `front`, base64: update.front })
    }
    if (update.back && !update.backFinal) {
      setEditPhoto({ idx, side: `back`, base64: update.back })
    }
  }

  const removeField = (idx) => {
    if (documents.length > 1) {
      setDocuments((p) => [...p.slice(0, idx), ...p.slice(idx + 1)])
    } else {
      setDocuments([emptyDoc])
    }
    setDirty(true)
  }

  const validate = () => {
    const isEmptyDialog =
      documents.length === 1 &&
      documents[0].type === `` &&
      documents[0].front === `` &&
      documents[0].back === ``

    const errs = documents.reduce((agg, curr, idx) => {
      const typeValid =
        validDocNameRegex?.test(curr.type) &&
        curr.type?.length <= MAX_DOC_TYPE_LENGTH
      if (!curr.type || !curr.front || !typeValid) {
        agg[idx] = []
        if (!curr.type) {
          agg[idx].push(`Missing document type`)
        } else if (!typeValid) {
          agg[idx].push(`Bad document name`)
        }
        if (!curr.front) agg[idx].push(`Missing front image`)
        if (curr.type?.length > MAX_DOC_TYPE_LENGTH) {
          agg[idx].push(`${MAX_DOC_TYPE_LENGTH} characters max`)
        }
      }
      return agg
    }, {})

    const isValid = isEmptyDialog || !Object.keys(errs).length
    setErrors(isValid ? undefined : errs)

    return isValid
  }

  const onSubmit = async () => {
    if (!validate()) return

    setLoading(true)
    const data = {
      documents: documents
        .filter((d) => !!d.type)
        .map((d) => ({
          type: d.type,
          typeDetails: d.typeDetails,
          images: [d.front, d.back]
            .filter((i) => i)
            .map((i) =>
              i?.startsWith(`data:image/png`) ? i : i?.split(`/`).pop(),
            ),
        })),
    }

    await handleSubmit(data)
    setLoading(false)
    handleClose()
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      handleSubmit={onSubmit}
      Icon={<CreditCard sx={{ color: `documents.main`, marginRight: 2 }} />}
      title="Additional Documents"
      loading={loading}
    >
      {editPhoto && (
        <EditDialog
          aspectRatio={1.6}
          photo={editPhoto.base64}
          setPhoto={(updatedBase64) => {
            handleChange(editPhoto.idx)({
              [editPhoto.side]: updatedBase64,
              [`${editPhoto.side}Final`]: updatedBase64 ? true : false,
            })
            setEditPhoto(undefined)
          }}
        />
      )}
      <Box display="flex" sx={{ justifyContent: `flex-end` }}>
        <StyledButton
          sx={{ float: `right`, marginBottom: 1 }}
          disabled={
            !((documents[0].front || documents[0].back) && documents[0].type)
          }
          onClick={addField}
        >
          <Add /> Add New
        </StyledButton>
      </Box>
      {documents.map((document, idx) => (
        <Fragment key={`${document}-${idx}`}>
          {idx >= 1 && <Divider sx={{ mt: 2, mb: 1 }} />}
          <Box
            display="flex"
            sx={{
              flexDirection: `column`,
              alignItems: `center`,
              pl: 2,
              pr: 2,
              pb: errors?.[idx] ? 1 : 0,
              border: errors?.[idx] ? `1px solid red` : `none`,
              m: 1,
            }}
          >
            {errors?.[idx] && (
              <Typography color="error" sx={{ fontSize: 12 }}>
                {errors[idx].join(`, `)}
              </Typography>
            )}
            <AutocompleteSelect
              freeSolo
              label="Type of document"
              value={document.type}
              options={documentTypes}
              onChange={(type) => handleChange(idx)({ type })}
            />
            {document.frontFinal ? (
              <StyledImage src={document.front} />
            ) : (
              <FileDropzone
                setBase64={(img) => handleChange(idx)({ front: img })}
                sx={{
                  mb: 1,
                  width: `50%`,
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  cursor: `pointer`,
                  "@media (max-width: 600px)": {
                    width: `70%`,
                  },
                }}
              >
                <Image
                  sx={{
                    color: `primary.main`,
                    width: `1.4em`,
                    height: `1.4em`,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ fontSize: `0.8rem` }}
                >
                  Add Front of Card
                </Typography>
              </FileDropzone>
            )}
            {document.backFinal ? (
              <StyledImage src={document.back} />
            ) : (
              <FileDropzone
                setBase64={(img) => handleChange(idx)({ back: img })}
                sx={{
                  width: `50%`,
                  display: `flex`,
                  flexDirection: `column`,
                  alignItems: `center`,
                  cursor: `pointer`,
                  "@media (max-width: 600px)": {
                    width: `70%`,
                  },
                }}
              >
                <CreditCard
                  sx={{
                    color: `primary.main`,
                    width: `1.4em`,
                    height: `1.4em`,
                  }}
                />
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ fontSize: `0.8rem` }}
                >
                  Add Back of Card
                </Typography>
              </FileDropzone>
            )}
            {(document.front || document.back || document.type) && (
              <Box
                display="flex"
                justifyContent="flex-end"
                mt={1}
                sx={{ width: `100%` }}
              >
                <StyledButton onClick={() => removeField(idx)} color="error">
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Document
                </StyledButton>
              </Box>
            )}
          </Box>
        </Fragment>
      ))}
    </BaseDialog>
  )
}
