import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { useEffect, useState } from "react"

export const useUnsavedChanges = (handleClose, open) => {
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (!open) {
      setDirty(false)
    }
  }, [open])

  const onClose = async () => {
    try {
      if (dirty) {
        await confirm(``, {
          title: `Unsaved changes`,
          description: () => (
            <>
              Any changes you may have made will <br />
              not be saved if cancelled, are you
              <br /> sure you want to cancel now?
            </>
          ),
          noAction: `Back`,
          yesAction: `Cancel`,
          noColor: `primary`,
        })
      }
      handleClose()
    } catch {}
  }

  return { dirty, setDirty, onClose }
}
