interface ProfileCompletion {
  score: number
  checklist: { [key: string]: boolean }
}

export const calculateProfileCompletion = (
  profile,
  emailVerified = false,
): null | ProfileCompletion => {
  if (!profile) return null

  const scoreData = [
    [profile.pictures?.length, 5, `Profile Photo`],
    [emailVerified || profile.type === `u/member`, 5, `Email Verified`],
    [profile.profile?.phoneNumbers?.mobile, 5, `Phone Number`],
    [profile.emergencyContacts?.length, 5, `Emergency Contact`],
    [profile.medicalData?.allergies?.length, 10, `Allergies`],
    [profile.medicalData?.medicalConditions?.length, 10, `Medical Conditions`],
    [profile.medicalData?.medications?.length, 10, `Medications`],
    [profile.profile?.firstName, 5, `First Name`],
    [profile.profile?.lastName, 5, `Last Name`],
    [profile.profile?.gender, 10, `Gender`],
    [profile.identifyingInformation?.height, 10, `Height`],
    [profile.identifyingInformation?.weight, 10, `Weight`],
    [profile.profile?.birthDate, 10, `Birth Date`],
  ]

  const result = scoreData.reduce(
    (agg, [data, value, name]) => {
      agg.checklist[name] = !!data
      if (data) {
        agg.score = agg.score + value
      }
      return agg
    },
    {
      score: 0,
      checklist: {},
    },
  )

  return result
}
