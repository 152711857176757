import { Box } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC, useCallback, useRef } from "react"
import { useDropzone } from "react-dropzone"

import { resizeImage } from "./FileDropzone"

interface IProps {
  setBase64: (b64?: string) => void
  sx?: SxProps
}

export const FileDropzone: FC<IProps> = ({ children, setBase64, sx }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log(`file reading was aborted`)
      reader.onerror = () => console.log(`file reading has failed`)
      reader.onload = async (e) => {
        if (typeof e.target?.result !== `string`) return
        const resized = await resizeImage(e.target.result)
        setBase64(resized)
      }
      reader.readAsDataURL(file)
    })
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderRadius: 2,
        border: `3px dashed rgba(0,0,0,0.22)`,
        p: 3,
        ...sx,
      }}
      onClick={() => {
        try {
          inputRef.current?.click()
        } catch (err) {
          console.log(err)
        }
      }}
    >
      <input
        {...getInputProps()}
        ref={inputRef}
        type="file"
        accept="image/png,image/jpg,image/jpeg"
        multiple={false}
      />
      {children || (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </Box>
  )
}
