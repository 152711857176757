import * as z from "zod"

export const setRegexes = (
  regexes: DataSchema.RegExDef[],
  zDefinition?: z.ZodTransformer<z.ZodString, z.ZodString>,
): z.ZodString => {
  let def: any = zDefinition || z.string()
  for (const regex of regexes) {
    def = def.refine((v: string) => regex.exp.test(v), { message: regex.msg })
  }

  return def
}
