import { TextParagraph } from "@erinfo/brand-ui/src/storybook/typography/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { verifyAttribute } from "@erinfo/consumer/src/data"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"

export type ModalState =
  | undefined
  | "prompt"
  | "verify"
  | "resent"
  | "change"
  | "thanks"

interface IProps {
  numLogins: string
  open: ModalState
  email: string
  onClose: () => void
  updateEmail: (email: string, code: number) => Promise<void>
  setSnackbarMessage: (param: {
    msg: string
    severity?: "error" | "success"
  }) => void
}

export const VerifyModal = (props: IProps) => {
  const [state, setState] = useState<ModalState>()
  const [code, setCode] = useState(``)
  const [email, setEmail] = useState(``)
  const [error, setError] = useState(``)
  const { onClose, updateEmail, setSnackbarMessage } = props

  const remainingLogins = 5 - Number(props.numLogins)

  const latestEmail = email || props.email

  const textProps = {
    fullWidth: true,
    inputId: `textfield`,
    disabled: false,
    maxLength: 12,
    style: { margin: `15px 0` },
    InputLabelProps: { style: { padding: `0 15px` } },
    InputProps: { style: { padding: `0 15px` } },
  }

  const initiateEmailChange = async (newEmail: string) => {
    try {
      await verifyAttribute({ email: newEmail })
    } catch (error) {
      if (error) {
        const { status } = error
        if (status === StatusCodes.CONFLICT) {
          setSnackbarMessage({
            severity: `error`,
            msg: `Email is already in use.`,
          })
        } else {
          setSnackbarMessage({
            severity: `error`,
            msg: `Unable to verify this email address.`,
          })
        }
        throw error
      }
    }
  }

  useEffect(() => {
    setCode(``)
    setError(``)
    setEmail(``)
    if (props.open === `verify`) {
      void initiateEmailChange(props.email)
    }
    setState(props.open)
  }, [props.open])

  const onChangeEmailSubmit = async () => {
    try {
      await initiateEmailChange(email)
      setState(`verify`)
    } catch (err) {
      setEmail(``)
    }
  }

  return (
    <StyledDialog
      open={!!props.open}
      onClose={remainingLogins <= 0 ? () => {} : onClose}
    >
      {state === `prompt` && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>Verify Email</StyledDialogTitle>
            <TextParagraph>
              You have {remainingLogins <= 0 ? `no` : remainingLogins} logins
              left.
            </TextParagraph>
          </DialogContent>
          <StyledDialogActions sx={{ justifyContent: `flex-end` }}>
            {remainingLogins > 0 && (
              <StyledButton onClick={onClose}>Close</StyledButton>
            )}
            <StyledButton
              onClick={async () => {
                await initiateEmailChange(props.email)
                setState(`verify`)
              }}
              sx={{ mr: 2 }}
            >
              Verify Email
            </StyledButton>
          </StyledDialogActions>
        </>
      )}
      {[`verify`, `resent`].includes(state) && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>
              {state === `verify` && `Verification Code Sent`}
              {state === `resent` && `Code Re-Sent`}
            </StyledDialogTitle>
            <TextParagraph>
              Please enter the verification code that was just sent to:
              <br />
              <span style={{ fontWeight: `bold` }}>{latestEmail}</span>
            </TextParagraph>
            {state === `resent` && (
              <Typography color="warning">(email may be in spam)</Typography>
            )}
            <TextField
              {...textProps}
              variant="standard"
              value={code}
              placeholder="Verification Code"
              onChange={(e) => setCode(e.target.value.trim())}
              error={props.error || error}
              helperText={props.error || error}
            />
            <br />
          </DialogContent>
          <StyledDialogActions>
            {remainingLogins > 0 && (
              <StyledButton onClick={onClose}>Cancel</StyledButton>
            )}
            <StyledButton onClick={() => setState(`change`)}>
              Change Email
            </StyledButton>
            <StyledButton
              onClick={async () => {
                try {
                  await initiateEmailChange(latestEmail)
                  setSnackbarMessage({ msg: `Code resent` })
                } catch {}
              }}
            >
              Resend Code
            </StyledButton>
            <StyledButton
              onClick={async () => {
                try {
                  await updateEmail(latestEmail, Number(code))
                  setState(`thanks`)
                } catch (err) {
                  if (
                    err.status === StatusCodes.FAILED_DEPENDENCY ||
                    err.status === StatusCodes.NOT_IMPLEMENTED
                  ) {
                    return setError(`Incorrect code`)
                  } else if (err.message) {
                    return setError(err.message)
                  }
                  setError(`Unexpected error`)
                }
              }}
            >
              Submit
            </StyledButton>
          </StyledDialogActions>
        </>
      )}
      {state === `change` && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>Change Email Address</StyledDialogTitle>
            <TextParagraph>
              Please enter a valid email address that you can access.
            </TextParagraph>
            <TextField
              {...textProps}
              variant="standard"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value.trim())}
            />
          </DialogContent>
          <StyledDialogActions>
            <StyledButton onClick={() => setState(`verify`)}>
              Cancel
            </StyledButton>
            <StyledButton onClick={onChangeEmailSubmit}>Submit</StyledButton>
          </StyledDialogActions>
        </>
      )}
      {state === `thanks` && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>Email Verified</StyledDialogTitle>
            <TextParagraph>
              Thank you!
              <br />
              The email was successfully verified
            </TextParagraph>
          </DialogContent>
          <StyledDialogActions sx={{ justifyContent: `flex-end` }}>
            <StyledButton onClick={onClose}>OK</StyledButton>
          </StyledDialogActions>
        </>
      )}
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: 350,
  },
})

const StyledDialogTitle = styled(DialogTitle)({
  padding: `0 0 15px`,
})

const StyledDialogActions = styled(DialogActions)({
  padding: `0 !important`,
  display: `flex`,
  justfiyContent: `flex-end`,
  "& button": {
    marginLeft: `0 !important`,
  },
})
