import { Models } from "@rematch/core"

import { notifications } from "./notifications"
import { signUp } from "./signUp"
import { user } from "./user"

export interface RootModel extends Models<RootModel> {
  user: typeof user
  signUp: typeof signUp
  notifications: typeof notifications
}

export const models: RootModel = { user, signUp, notifications }
