const domain = `erinfo.me`
// const domain = `localhost`

const utmParamNames = [
  `utm_campaign`,
  `utm_content`,
  `utm_term`,
  `utm_medium`,
  `utm_source`,
  `utm_rc`,
  `utm_v`,
  `utm_l`,
]

interface utmParams {
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
  utm_medium?: string
  utm_source?: string
  utm_rc?: string
  utm_v?: string
  utm_l?: string
}

export const setCookie = (
  cname: string,
  cvalue: string | number,
  expiresInDays?: number,
) => {
  const d = new Date()
  let expires = ``
  if (expiresInDays) {
    d.setTime(d.getTime() + expiresInDays * 24 * 60 * 60 * 1000) // eslint-disable-line @typescript-eslint/no-magic-numbers
    expires = `expires=` + d.toUTCString()
  }
  document.cookie = `${cname}=${cvalue};${expires ? `${expires};` : ``}path=/;${
    domain !== `localhost` ? `domain=${domain}` : ``
  }`
}

export const getCookie = (cname: string) => {
  const name = `${cname}=`
  const ca = document.cookie.split(`;`)
  for (let c of ca) {
    while (c.startsWith(` `)) c = c.substring(1)

    if (c.startsWith(name)) return c.substring(name.length, c.length)
  }
  return ``
}

export const eraseCookie = (cname: string) => {
  return setCookie(cname, ``, -1)
}

export const getUtmParams = (): utmParams =>
  utmParamNames.reduce((agg, name) => {
    agg[name] = getCookie(name)
    return agg
  }, {})
