import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { useState } from "react"

const StyledAutocomplete = styled(Autocomplete)({
  marginBottom: 15,
  "& .MuiAutocomplete-endAdornment": {
    top: `unset`,
  },
  "& .MuiAutocomplete-tag": {
    margin: `3px !important`,
  },
  "& .MuiInput-input": {
    paddingLeft: `15px !important`,
  },
  "& .MuiInputLabel-root": {
    paddingLeft: `15px !important`,
  },
})

type Option = { title: string; value: string } | string

interface IProps {
  options: any[]
  label: string
  errors?: string
  value?: string[]
  classes?: object
  inputId?: string
  className?: string
  sx?: SxProps
  dialog?
  multiple?: boolean
  showDone?: boolean
  onChange: (value) => void
  onBlur?: () => void
  disabled?: boolean
  valueSelector?: (value: Option | Option[]) => string
  freeSolo?: boolean
  required?: boolean
  hideAsterisk?: boolean
  inputProps?: any
  InputProps?: any
  renderOption?: (option: Option) => React.ReactNode
}

export const AutocompleteSelect = (props: IProps) => {
  const [inputValue, setInputValue] = useState<string>("")

  const {
    label,
    errors,
    value = "",
    onChange,
    onBlur,
    options,
    classes,
    className,
    sx,
    inputId,
    disabled,
    valueSelector,
    freeSolo,
    required,
    inputProps,
    InputProps,
    renderOption,
  } = props

  return (
    <>
      <Box sx={{ width: `100%`, ...sx }} className={className}>
        <StyledAutocomplete
          fullWidth
          freeSolo={freeSolo === undefined ? true : freeSolo}
          disabled={disabled}
          classes={classes}
          id={inputId}
          options={options}
          getOptionLabel={(option) =>
            option?.title == null ? option : option.title
          }
          isOptionEqualToValue={(option, value) =>
            option?.title === value || option?.value === value
          }
          onChange={(event, value: Option) =>
            Array.isArray(value?.length)
              ? onChange(value?.map((v) => v?.title || v))
              : onChange(
                  valueSelector
                    ? valueSelector(value)
                    : typeof value === "string"
                    ? value
                    : value?.title,
                )
          }
          value={value}
          inputValue={inputValue}
          renderOption={renderOption}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue || ``)
            onChange(newInputValue || ``)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                "& .MuiFormLabel-asterisk": {
                  color: !props.hideAsterisk ? `error.main` : `transparent`,
                },
              }}
              onBlur={onBlur}
              variant="standard"
              required={required}
              inputProps={{
                ...params.inputProps,
                required: false,
                ...inputProps,
              }}
              InputProps={{
                ...params.InputProps,
                ...InputProps,
              }}
              label={label}
              placeholder=""
              error={!!errors}
              helperText={errors}
            />
          )}
        />
      </Box>
    </>
  )
}
