import {
  allergyFormFields,
  medicalConditionsFormFields,
  medicationFormFields,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import { IPhoto } from "@erinfo/brand-ui/src/storybook/other/photo/face-v2"
import { setUserData } from "@erinfo/react-utils/src/helpers/form"
import {
  createEmergencyContactsData,
  createMedicalDataStructure,
} from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useDynamicFields } from "@erinfo/react-utils/src/helpers/form/use-dynamic-fields"
import { phoneNumberMask } from "@erinfo/react-utils/src/helpers/textMasks"
import dayjs from "dayjs"
import deepmerge from "deepmerge"
import { createContext, FC, useReducer } from "react"

export interface FormData {
  [key: string]: string
}

interface AddMemberContextData {
  loading?: boolean
  isError?: boolean
  picture: IPhoto
  setPicture: (picture: IPhoto) => void
  onDelete: () => void
  setMemberData: (FormData) => void
  setMedicalData: (FormData) => void
  addMember: (FormData) => Promise<void>
  fieldCounts: { [key: string]: number }
  addField: (fn: Function) => void
  removeField: (FormData, fn: Function) => Function
  setFieldValueOrRemove: (fn1: Function, fn2: Function) => Function
  initialValues: FormData
  firstName?: string
  lastName?: string
  birthDate?: string
  gender?: string
  emergencyContactName?: string
  emergencyContactPhone?: string
  emergencyContactEmail?: string
  emergencyContactRelationship?: string
  medical?: { [key: string]: string }
  user: any
  returnUrl?: string
  proApp?: boolean
}

const addMemberReducer = (state, action) => {
  switch (action.type) {
    case `LOADING`:
      return { ...state, loading: true }
    case `SET_PICTURE`:
      return { ...state, picture: action.payload }
    case `SET_MEMBER_DATA`:
      return { ...state, ...action.payload }
    case `SET_MEDICAL_DATA`:
      return { ...state, medical: action.payload }
    default:
      throw new Error()
  }
}

const initialPicture: IPhoto = {
  value: ``,
  errors: [],
  dataBeforeEditing: ``,
  filename: ``,
  created: ``,
}

const initialFieldConts = {
  medicalConditions: 1,
  allergies: 1,
  medications: 1,
}

const noop = () => {}

const init: AddMemberContextData = {
  picture: initialPicture,
  fieldCounts: initialFieldConts,
  setPicture: noop,
  onDelete: noop,
  setMemberData: noop,
  setMedicalData: noop,
  addField: noop,
  initialValues: {},
  removeField: () => () => {},
  setFieldValueOrRemove: () => () => {},
  addMember: async () => Promise.resolve(),
  user: {},
}

export const AddMemberContext = createContext<AddMemberContextData>(init)

export const AddMemberProvider: FC<{
  user: any
  setDialogMessage: (options: any) => void
  createNewMember: (data: any) => void
  returnUrl?: string
  proApp?: boolean
}> = ({
  children,
  user,
  setDialogMessage,
  createNewMember,
  returnUrl,
  proApp,
}) => {
  const [state, dispatch] = useReducer(addMemberReducer, init)

  const {
    fieldCounts,
    addField,
    removeField,
    initialValues,
    setFieldValueOrRemove,
  } = useDynamicFields(
    [`medicalConditions`, `allergies`, `medications`],
    () => ({
      medicalCondition_0: ``,
      onset_0: ``,
      allergy_0: ``,
      reactionDesc_0: ``,
      reaction_0: false,
      medication_0: ``,
      dosage_0: ``,
      frequency_0: ``,
    }),
    null,
    true,
    `medicalData`,
    {
      medicalConditions: medicalConditionsFormFields,
      allergies: allergyFormFields,
      medications: medicationFormFields,
    },
  )

  // console.log(`CURR STATE`, state)

  const setPicture = (photo: IPhoto) =>
    dispatch({
      type: `SET_PICTURE`,
      payload: photo,
    })

  const onDelete = () => {
    setDialogMessage({
      title: `Delete Photo?`,
      msg: `Are you sure you want to delete this photo?`,
      altYesLabel: `Yes`,
      altNoLabel: `Cancel`,
      onYes: () =>
        dispatch({
          type: `SET_PICTURE`,
          payload: initialPicture,
        }),
      onNo: () => setDialogMessage({ msg: `` }),
    })
  }

  const setMemberData = (formValues: FormData) => {
    dispatch({
      type: `SET_MEMBER_DATA`,
      payload: formValues,
    })
  }

  const setMedicalData = (formValues: FormData) => {
    dispatch({
      type: `SET_MEDICAL_DATA`,
      payload: formValues,
    })
  }

  const addMember = (medicalVals) => {
    dispatch({ type: `LOADING` })
    const emergencyContacts = createEmergencyContactsData(state).map(
      (contact: { [key: string]: string }) => ({
        ...contact,
        phone: contact.phone?.replace(phoneNumberMask.removeMaskedChar, ``),
        isPrimaryContact: true,
      }),
    )
    const medicalData = createMedicalDataStructure(medicalVals)

    const data: DataSchema.user.post = setUserData(
      deepmerge(
        {
          emergencyContacts,
          initials: `99`,
          profile: {
            firstName: state.firstName,
            lastName: state.lastName,
            ...(state.birthDate && {
              birthDate: dayjs(state.birthDate).format("YYYY-MM-DD"),
            }),
            gender: state.gender,
          },
        },
        medicalData,
      ),
    )
    return createNewMember({
      userId: user.id,
      data,
      created: state.picture.created,
    })
  }

  return (
    <AddMemberContext.Provider
      value={{
        ...state,
        fieldCounts,
        addField,
        removeField,
        setFieldValueOrRemove,
        initialValues,
        setPicture,
        onDelete,
        setMemberData,
        setMedicalData,
        addMember,
        user,
        returnUrl,
        proApp,
      }}
    >
      {children}
    </AddMemberContext.Provider>
  )
}
