import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { MemberListItem } from "@erinfo/brand-ui/src/storybook/other/memberlist-item/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { onUpgrade } from "@erinfo/consumer/src/lib/stripePortalRedirect"
import { Dispatch } from "@erinfo/consumer/src/store"
import { UserState } from "@erinfo/consumer/src/store/models/user"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { calculateProfileCompletion } from "@erinfo/react-utils/src/helpers/profile-completion"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { AddBox, Edit, FamilyRestroom } from "@mui/icons-material"
import { Box, Divider, IconButton, Link, Typography } from "@mui/material"
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import logoU from "@erinfo/consumer/src/assets/logo-u-icon.png"

import * as Common from "./common"
import { PlanDowngradeModal } from "./PlanDowngradeModal"

dayjs.extend(localizedFormat)

interface IProps {
  user: UserState
  setMemberListDialogOpen: (v: boolean) => void
}

export const PlanPanel: FC<IProps> = ({ user, setMemberListDialogOpen }) => {
  const navigate = useNavigate()
  const { isSamlUser } = useAuth()
  const [downgradeModalOpen, setDowngradeModalOpen] = useState(false)
  const { archiveMember, fetchUserData, setDialogMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      archiveMember: dispatch.user.archiveMember,
      fetchUserData: dispatch.user.fetchUserData,
      setDialogMessage: dispatch.notifications.setDialogMessage,
    }),
  )
  const paymentPlan = user.paymentPlan?.active
  const isPaying = Boolean(paymentPlan)
  const plan = paymentPlan?.name?.replace(/ plan/i, ``)
  const activeUntil =
    paymentPlan?.expire && dayjs(paymentPlan.expire * 1000).format(`LL`)
  const unsubscribed =
    paymentPlan?.canceled_at &&
    dayjs(paymentPlan.canceled_at * 1000).format(`LL`)

  const addedMembers =
    user.enrolledPatients?.filter((i) => !i.status?.includes(`archived`)) || []

  const allowedMembers = Number(paymentPlan?.members || 0)
  const hasAddMemberCapacity =
    allowedMembers && allowedMembers > addedMembers.length
  const reduceMembers = allowedMembers && allowedMembers < addedMembers.length
  const isSaml = isSamlUser()

  const archiveAllMembers = async () => {
    try {
      await Promise.all(
        (user?.members ?? []).map(async (m) =>
          archiveMember({ memberId: m.id }),
        ),
      )
      await fetchUserData({ id: user.id })
    } catch (err) {
      console.log(err)
      setDialogMessage({
        title: `Unexpected Error`,
        msg: `An unexpected error occurred while trying to archive members.  Please contact us to resolve this issue.`,
      })
    }
  }

  useEffect(() => {
    if (allowedMembers === 0 && addedMembers.length > 0) {
      void archiveAllMembers()
    } else if (reduceMembers) {
      setDowngradeModalOpen(true)
    }
  }, [allowedMembers, addedMembers.length])

  return (
    <>
      <PlanDowngradeModal
        open={downgradeModalOpen}
        handleClose={() => setDowngradeModalOpen(false)}
      />
      <Common.StyledFormCard
        Icon={<FamilyRestroom sx={{ color: `plan.main` }} />}
        headerText="My Plan"
        sx={{ minHeight: 100 }}
        Action={() =>
          !isSaml ? (
            <IconButton color="primary" onClick={async () => onUpgrade(user)}>
              <Edit />
            </IconButton>
          ) : (
            <></>
          )
        }
      >
        <Box display="flex" alignItems="center">
          {isSaml && user.pk === "university-of-miami" && (
            <img
              src={logoU}
              style={{ width: 25, height: 25, marginLeft: 8, marginRight: 16 }}
            />
          )}
          <Typography variant="h7">{plan} Plan</Typography>
        </Box>
        <Box p={2} display="flex">
          <Box sx={{ flex: 1 }}>
            {unsubscribed && (
              <div className="unsubscribedDate">
                unsubscribed: {unsubscribed}
              </div>
            )}
            {allowedMembers > 0 ? (
              <Typography>
                Subscriber <b>+{allowedMembers}</b> additional members
              </Typography>
            ) : (
              <Typography>Subscriber Only</Typography>
            )}
            {activeUntil && (
              <Typography>
                {unsubscribed ? `active until: ` : `Your plan renews on `}
                {activeUntil}
              </Typography>
            )}
            {!hasAddMemberCapacity && !isSaml && (
              <Link
                href="#"
                sx={{ fontSize: `0.8rem` }}
                onClick={async () => onUpgrade(user)}
              >
                Update plan to add additional members.
              </Link>
            )}
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            {!isSaml && (
              <IconButton
                onClick={() => navigate(`/add-member/step-1`)}
                disabled={!hasAddMemberCapacity}
              >
                <AddBox color={hasAddMemberCapacity ? `primary` : `inherit`} />
              </IconButton>
            )}
          </Box>
        </Box>
        {!!user?.members?.length && <Divider sx={{ mt: 1, mb: 1 }} />}
        <Box pl={2}>
          {user?.members?.slice(0, 3)?.map((member) => (
            <MemberListItem
              key={member.id}
              avatar={member.pictures?.[0] ? member.pictures[0].src : IPerson}
              // disabled={
              //   !paymentPlan?.members ||
              //   paymentPlan?.members < addedMembers.length
              // }
              // added={dayjs(member.created).format(`MM/DD/YYYY`)}
              updated={dayjs(member.updated).format(`MM/DD/YYYY`)}
              onClick={() => navigate(`/dashboard/member/${member.id}`)}
              pctComplete={calculateProfileCompletion(member)?.score}
              // onSelect={
              //   reduceMembers
              //     ? () =>
              //         setSelectedMembers((p) =>
              //           p.includes(member.id)
              //             ? p.filter((i) => i !== member.id)
              //             : [...p, member.id],
              //         )
              //     : null
              // }
              // checked={selectedMembers.includes(member.id)}
              // progressPercent={member.progressPercent}
            >
              {member.profile.lastName ? `${member.profile.lastName}, ` : ``}
              {member.profile.firstName}
            </MemberListItem>
          ))}
          {user?.members?.length > 3 && (
            <StyledButton
              sx={{ mt: 2 }}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => setMemberListDialogOpen(true)}
            >
              View All Members
            </StyledButton>
          )}
        </Box>
      </Common.StyledFormCard>
    </>
  )
}
