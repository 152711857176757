import { newrelic } from "@erinfo/env"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import { getPersistor } from "@rematch/persist"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import { PersistGate } from "redux-persist/es/integration/react"

import App from "./app"
import store from "./store"

void newrelic.browser.helpers.loadNewRelicAgent()

void defineCustomElements(window)

const container = document.getElementById(`app`)
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={getPersistor()}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>,
)
