/** @jsxImportSource @emotion/react */
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import * as env from "@erinfo/env"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos"
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary"
import { Box, IconButton, Typography } from "@mui/material"
import { Camera, CameraType } from "expo-camera"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"

import { FileDropzone } from "../FileDropzone2"

const standardDev = (arr) => {
  const mean =
    arr.reduce((acc, curr) => {
      return acc + curr
    }, 0) / arr.length
  arr = arr.map((k) => {
    return (k - mean) ** 2
  })
  const sum = arr.reduce((acc, curr) => acc + curr, 0)
  return Math.sqrt(sum / arr.length)
}

const checkForImageIssue = async (cameraRef) => {
  try {
    const canvas = document.createElement(`canvas`)
    const ctx = canvas.getContext(`2d`)

    const image = new Image()
    image.onload = () => {
      ctx.drawImage(image, 0, 0)
      const imageData = ctx?.getImageData(0, 0, 100, 100, {
        colorSpace: `srgb`,
      })
      const imgArr = imageData?.data.filter((i) => i !== 255)
      const std = standardDev(imgArr || [])
      if (std < 5) {
        void env.newrelic.browser.helpers.withNewRelic((nr) => {
          nr.noticeError(
            new Error(`Camera image issue, standard deviation: ${std}`),
          )
        })
      }
    }
    setTimeout(async () => {
      const pic = await cameraRef.current?.takePictureAsync()
      if (!pic?.uri) return console.log(`no uri`)
      image.src = pic?.uri
    }, 200)
  } catch (err) {
    await env.newrelic.browser.helpers.withNewRelic((nr) => {
      nr.noticeError(new Error(`Camera error: ${JSON.stringify(err, null, 2)}`))
    })
    console.log(err)
  }
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onCapture: (base64Img: string) => void
  startingCameraType?: CameraType
}

export const CameraCaptureDialog = ({
  isOpen,
  onClose,
  onCapture,
  startingCameraType = CameraType.back,
}: IProps) => {
  const cameraRef = useRef<Camera>(null)
  const [cameraPermission, setCameraPermission] = useState<boolean | null>(null)
  const [cameraTypes, setCameraTypes] = useState<CameraType[]>()
  const [cameraType, setCameraType] = useState<string>(startingCameraType)

  useEffect(() => {
    void navigator.permissions?.query({ name: `camera` }).then((res) => {
      if (res.state !== `granted` && res.state !== `prompt`) {
        setCameraPermission(false)
        console.log(res.state)
      } else {
        setCameraPermission(true)
      }
    })
  })

  const getTypes = async () => {
    if (cameraTypes?.length) return
    const types = await Camera.getAvailableCameraTypesAsync()
    setCameraTypes(types)
    if (types.includes(CameraType.back)) setCameraType(`back`)
    else setCameraType(`front`)
  }

  return (
    <BaseDialog
      showCloseButton
      open={isOpen}
      handleClose={onClose}
      Icon={<CameraAltIcon sx={{ opacity: 0.54, mr: 2 }} />}
      title="Capture Image"
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <IconButton>
            <FileDropzone
              setBase64={onCapture}
              sx={{
                cursor: `pointer`,
                padding: `3px 5px`,
                border: `none`,
              }}
            >
              <PhotoLibraryIcon color="primary" />
            </FileDropzone>
          </IconButton>
          {cameraTypes?.length > 1 && (
            <IconButton
              onClick={() =>
                setCameraType((p) => (p === `back` ? `front` : `back`))
              }
            >
              <FlipCameraIosIcon />
            </IconButton>
          )}
        </Box>
      )}
      actionsSx={{ padding: 0 }}
      contentSx={(theme) => ({
        paddingLeft: 0,
        paddingRight: 0,
        paddingBottom: 0,
        "& >div:first-of-type": {
          [theme.breakpoints.down(`sm`)]: {
            height: `50vh`,
            width: `90vw`,
            minWidth: `100%`,
          },
          [theme.breakpoints.up(`sm`)]: {
            width: `400px`,
            height: `500px `,
            minWidth: `100%`,
          },
        },
      })}
    >
      {cameraPermission === false ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ width: `100%` }}
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            sx={{ maxWidth: `300px`, textAlign: `start` }}
          >
            <Typography variant="h5">We can't find your camera</Typography>
            <Typography>
              Access Settings to make sure that it is enabled
            </Typography>
            <FileDropzone
              setBase64={onCapture}
              sx={{
                marginTop: `20px`,
                padding: 0,
                cursor: `pointer`,
                border: `none`,
              }}
            >
              <Link to="#">Photo Library</Link>
            </FileDropzone>
          </Box>
        </Box>
      ) : (
        <>
          <Camera
            ref={cameraRef}
            onCameraReady={async () => {
              await getTypes()
              void checkForImageIssue(cameraRef)
            }}
            type={cameraType === `front` ? CameraType.front : CameraType.back}
            key={cameraType}
            onMountError={async (e) => {
              await env.newrelic.browser.helpers.withNewRelic((nr) => {
                nr.noticeError(
                  new Error(`Camera error: ${JSON.stringify(e, null, 2)}`),
                )
              })
              console.log(e)
            }}
          />
          <Box
            onClick={async () => {
              const result = await cameraRef.current?.takePictureAsync()
              onCapture(result?.base64)
            }}
            sx={(theme) => ({
              [theme.breakpoints.down(`sm`)]: {
                width: `70px`,
                height: `70px`,
                marginTop: `-35px`,
                marginLeft: `-35px`,
                bottom: `12%`,
              },
              [theme.breakpoints.up(`sm`)]: {
                width: `100px`,
                height: `100px`,
                marginTop: `-50px`,
                marginLeft: `-50px`,
                bottom: `10%`,
              },
              left: `50%`,
              position: `absolute`,
              transition: `all 0.25s`,
              cursor: `pointer`,
              "&:hover": {
                "& .circle": {
                  opacity: 1,
                },
              },
              "&:active": {
                "& .ring": {
                  opactity: 1,
                },
                "& .circle": {
                  opacity: 0.5,
                },
              },
            })}
          >
            <Box
              className="circle"
              sx={{
                position: `absolute`,
                top: `12%`,
                left: `12%`,
                bottom: `12%`,
                right: `12%`,
                borderRadius: `100%`,
                backgroundColor: `#ffffff`,
                opacity: 0,
                transition: `all 0.25s`,
              }}
            />
            <Box
              className="ring"
              sx={{
                position: `absolute`,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,

                borderRadius: `100%`,
                border: `0.5em solid #ffffff`,
                opacity: `0.8`,
                transition: `all 0.25s`,
              }}
            />
          </Box>
        </>
      )}
    </BaseDialog>
  )
}
