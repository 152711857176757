import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { Box } from "@mui/material"

export const AvatarList = ({ user, onClick }) => {
  const { pictures } = user

  return (
    <Box display="flex" onClick={onClick} sx={{ cursor: `pointer` }}>
      {pictures?.length > 1 &&
        pictures
          .slice(0, -1)
          .map((pic) => (
            <Avatar
              url={pic.src}
              containerSx={{ mr: 1 }}
              key={getS3KeyPhotoFace(user.id, pic.created)}
            />
          ))}
      {!pictures?.length ? (
        <IPerson style={{ width: 50, height: 50 }} />
      ) : (
        <Avatar
          url={pictures.slice(-1)?.[0]?.src}
          showPlusIcon
          onClick={onClick}
        />
      )}
    </Box>
  )
}
