export const cognito = {
  develop: {
    userPoolId: `us-east-1_PmFUWn4AH`,
    userPoolWebClientId: `4rl3t7iq3dptm3p3q7js5p11ss`,
    oauth: {
      domain: `erinfo-dev.auth.us-east-1.amazoncognito.com`,
      scope: [`email`, `openid`, `phone`],
      redirectSignIn: `https://dev.erinfo.me/sso`,
      redirectSignOut: `https://dev.erinfo.me`,
      // redirectSignIn: `https://localhost:3000/sso`,
      // redirectSignOut: `https://localhost:3000`,
      redirectUri: `https://localhost:3000`,
      responseType: `code`,
    },
  },
  stagingnext: {
    userPoolId: `us-east-1_ig7Ww5zyX`,
    userPoolWebClientId: `43vp7vcg16d13km95sgui11l4s`,
    oauth: {
      domain: `erinfo-next.auth.us-east-1.amazoncognito.com`,
      scope: [`email`, `openid`, `phone`],
      redirectSignIn: `https://next.erinfo.me/sso`,
      redirectSignOut: `https://next.erinfo.me`,
      responseType: `code`,
    },
  },
  staging: {
    userPoolId: `us-east-1_ujyChTs9H`,
    userPoolWebClientId: `2klpv484eutg8536jio6c968ho`,
    oauth: {
      domain: `erinfo-staging.auth.us-east-1.amazoncognito.com`,
      scope: [`email`, `openid`, `phone`],
      redirectSignIn: `https://staging.erinfo.me/sso`,
      redirectSignOut: `https://staging.erinfo.me`,
      responseType: `code`,
    },
  },
  production: {
    userPoolId: `us-east-1_hL1weRkgc`,
    userPoolWebClientId: `64ahpejlgrom9r10eqhm05lq09`,
  },
}

export const envAmplify = {
  Auth: {
    region: `us-east-1`,
    authenticationFlowType: `USER_SRP_AUTH`,
    ...cognito[process.env.AWS_STAGE],
  },
}
