import { makeStyles } from "@mui/styles"
import { ReactNode } from "react"

const useStyles = makeStyles({
  textH3: {
    font: `500 20px/1.2 Roboto`,
    opacity: 0.6,
  },
  innerText: {
    color: `#00000099`,
    font: `16px / 24px Roboto`,
  },
  info: {
    font: `14px / 16px Roboto`,
    color: `rgba(0, 0, 0, 0.87)`,
    letterSpacing: `0.24px`,
  },
  textLine: {
    color: `rgba(0, 0, 0, 0.87)`,
    font: `400 14px / 28px Roboto`,
    letterSpacing: `0.5px`,
    whiteSpace: `pre-line`,
  },
})

interface IProps {
  children: ReactNode
  className?: string
}

export const TextH3 = (props: IProps) => {
  const classes = useStyles()
  return (
    <h3 className={`${classes.textH3} ${props.className}`}>{props.children}</h3>
  )
}
export const TextInfo = (props: IProps) => {
  const classes = useStyles()
  return (
    <p className={`${classes.info} ${props.className}`}>{props.children}</p>
  )
}
export const TextParagraph = (props: IProps) => {
  const classes = useStyles()
  return (
    <p className={`${classes.innerText} ${props.className}`}>
      {props.children}
    </p>
  )
}
export const TextLine = (props: IProps) => {
  const classes = useStyles()
  return (
    <p className={`${classes.textLine} ${props.className}`}>{props.children}</p>
  )
}
