import { request, responseType } from "./request"

export const checkStripeCoupon = async (coupon: string) => {
  const response = await request({
    path: `/stripe/coupon/${coupon}`,
  })
  console.log(`stripe coupon check response -> `, response)
  return response.data
}

/**
 * Get stripe's public key & general information
 */
export const getStripeSetup = async (
  coupon?: string,
): Promise<DataSchema.stripe.pricing.get> => {
  const response = await request({
    path: `/stripe`,
    signRequest: true,
    ...(coupon && { query: { coupon } }),
  })
  console.log(`​Stripe setup -> `, response)

  return response.data
}

export const createStripeCheckoutSession = async (
  data: DataSchema.stripe.checkout.post,
) => {
  const body: DataSchema.stripe.checkout.post =
    process.env.AWS_STAGE === `develop` ? { ...data, origin: window.location.origin } : data
  const response = await request({
    method: `POST`,
    path: `/stripe/checkout`,
    body,
    signRequest: true,
    type: responseType.text,
  })
  console.log(`stripe create checkout response -> `, response)
  return response.data
}

export const getStripeCustomerPortalUrl = async (
  id: string,
  pathname?: string,
) => {
  const response = await request({
    path: `/stripe/url`,
    query: {
      id,
      ...(pathname && { pathname }),
      ...(process.env.AWS_STAGE === `develop` && { origin: window.location.origin }),
    },
    signRequest: true,
    type: responseType.text,
  })
  console.log(`stripe url response -> `, response)
  return response.data
}
