import PlaceholderPhoto from "@erinfo/brand-ui/src/assets/png/face_smiling_cropped_small.png"
import { isAndroid } from "@erinfo/react-utils/src/helpers/check-browser"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { styled } from "@mui/material/styles"
import { useRef, useState } from "react"

import { IPhoto } from "./index"
import { imageResize } from "./resizeImage"

const setImg = async (event, setPhoto): Promise<void> => {
  const files = event.target.files || event.dataTransfer.files
  if (!files.length) return

  const file = files[0]

  if (!file.type.match(`image.png`) && !file.type.match(`image.jpeg`)) {
    console.log(`wrong image type`)
    return
  }

  const result = await imageResize(file, 1024)
  setPhoto({ dataBeforeEditing: result })
}

interface IProps {
  setPhoto: (photo: IPhoto) => void
  open: boolean
  onClose: () => void
  openCamera: () => void
}

export const CaptureDialog = (props: IProps) => {
  const input = useRef<HTMLInputElement>(null)
  const inputProps = {
    id: `file`,
    type: `file`,
    style: { display: `none` },
    accept: `image/jpg,image/jpeg,image/png`,
    multiple: false,
    onClick: (event) => event.stopPropagation(),
    onChange: async (e) => {
      await setImg(e, props.setPhoto)
      props.onClose()
    },
  }

  return (
    <StyledDialog open={props.open} onClose={props.onClose}>
      <DialogTitle sx={{ paddingTop: 1, paddingBottom: 1 }}>
        For Best Results
      </DialogTitle>
      <StyledDialogContent style={{ display: `flex` }}>
        <ul>
          <li>
            <span>One face only</span>
          </li>
          <li>
            <span>Use a vertical orientated photo</span>
          </li>
          <li>
            <span>JPG and PNG files only</span>
          </li>
          <li>
            <span>No other app is using camera</span>
          </li>
        </ul>

        <img className="add-photo-dialog__img" src={PlaceholderPhoto} />
        <input ref={input} {...inputProps} />
      </StyledDialogContent>
      <DialogActions sx={{ padding: 0 }}>
        <Button
          onClick={async (e) => {
            // if (false && isAndroid) {
            //   props.onClose()
            //   input.current?.click()
            // } else {
            try {
              props.onClose()
              props.openCamera()
            } catch (err) {
              console.log(err)
            }
            // }
          }}
          style={{ marginBottom: 3 }}
        >
          Ok
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)({
  padding: `0 23px`,
  "& li": {
    display: `flex`,
    alignItems: `center`,
    "& span": { position: `relative`, left: `5px` },
    "&::before": { content: `"\\2022"`, color: `red`, fontSize: `20px` },
  },
  "& .add-photo-dialog__img": {
    marginTop: `20px`,
    alignSelf: `center`,
    width: 130,
  },
})

const StyledDialogContent = styled(DialogContent)({
  display: `flex`,
  flexDirection: `column`,
  paddingBottom: `0px !important`,
})
