import { useNavigate } from "react-router-dom"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useSelector } from "react-redux"

export const PurgeModal = () => {
  const navigate = useNavigate()
  const { handleSignout } = useAuth()
  const user = useSelector((state: RootState) => state.user)
  const { archiveMember, setDialogMessage, purgeProfile } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      archiveMember: dispatch.user.archiveMember,
      purgeProfile: dispatch.user.purgeProfile,
      setDialogMessage: dispatch.notifications.setDialogMessage,
    }),
  )

  const onSubmit = async () => {
    try {
      purgeProfile(false)
    } catch (err) {
      console.log(err)
      setDialogMessage({
        title: `Unexpected Error`,
        msg: `An unexpected error occurred.  Please contact us to resolve this issue.`,
      })
    }
  }

  if (!user?.ttl) return null

  return (
    <>
      <BaseDialog
        title="Account Set to Purge"
        open={true}
        handleClose={() => {}}
        renderActions={() => (
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              justifyContent: `flex-end`,
              alignItems: `center`,
            }}
          >
            <StyledButton onClick={onSubmit}>Cancel Purge</StyledButton>
            <StyledButton
              onClick={async () => {
                await handleSignout()
                navigate(`/`)
              }}
            >
              Logout
            </StyledButton>
          </Box>
        )}
        contentSx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-start`,
          p: 2,
        }}
      >
        <Typography sx={{ fontSize: 14, mt: 1, mb: 1 }}>
          Your account will be purged on {dayjs(user.ttl * 1000).format(`lll`)}.
          <br />
          <br />
          If you would like to cancel the purge, click the "Cancel Purge" button
          below.
        </Typography>
      </BaseDialog>
    </>
  )
}
