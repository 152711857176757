import { PhoneInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Typography } from "@mui/material"

interface IProps {
  back: () => void
  setPhoneNumber: (string) => void
  sendCode: () => void
  phoneNumber: string
  prevPhoneNumber: string
}

const VerificationEntry = ({
  back,
  setPhoneNumber,
  phoneNumber,
  prevPhoneNumber,
  sendCode,
}: IProps) => {
  const lastFour = prevPhoneNumber?.slice(-4)
  return (
    <>
      <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
        Confirm Phone Number
      </Typography>
      <Typography sx={{ color: `rgba(0,0,0,0.6)`, fontSize: 14 }}>
        The previous verification code was sent to{` `}
        <strong>xxx-xxx-{lastFour}</strong>.
      </Typography>
      <br />
      <Typography
        sx={{
          fontWeight: `bold`,
          color: `rgba(0,0,0,0.6)`,
          fontSize: 14,
          mb: 1,
        }}
      >
        Please enter a mobile phone number below that has the ability to receive
        text messages.
      </Typography>
      <PhoneInput
        fullWidth
        name="mobile"
        label="Mobile phone number"
        type="tel"
        value={phoneNumber}
        form={{
          errors: { mobile: `` },
          touched: { mobile: true },
          setFieldValue: (_, v) => setPhoneNumber(v),
          setFieldTouched: () => {},
        }}
        onlyCountries={[`US`, `CA`]}
        field={{ name: `mobile` }}
        sx={{ marginTop: 1 }}
      />
      <StyledButton
        variant="contained"
        color="primary"
        fullWidth
        loadingPosition="end"
        sx={{ marginTop: 4 }}
        onClick={sendCode}
      >
        Re-Send Code
      </StyledButton>
      <StyledButton fullWidth onClick={back} sx={{ marginTop: 1 }}>
        Back
      </StyledButton>
    </>
  )
}

export default VerificationEntry
