import { ReactComponent as IMarkHorizontal } from "@erinfo/brand-ui/src/assets/svg/mark-horizontal.svg"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { onUpgrade } from "@erinfo/consumer/src/lib/stripePortalRedirect"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { version } from "@erinfo/provider/package.json"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Close } from "@mui/icons-material"
import Logout from "@mui/icons-material/Logout"
import Password from "@mui/icons-material/Password"
import People from "@mui/icons-material/People"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import MenuList from "@mui/material/MenuList"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import { FC } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const Logo = styled(IMarkHorizontal)({
  width: `121.22px`,
  height: `40px`,
})

export const MobileMainMenu: FC<{
  closeMenu: () => void
  open: boolean
}> = ({ closeMenu, open }) => {
  const navigate = useNavigate()
  const { handleSignout, isSamlUser } = useAuth()
  const user = useSelector((state: RootState) => state.user)
  const { toggleSettings } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleSettings: dispatch.notifications.toggleSettings,
  }))

  const logout = async () => {
    await handleSignout()
    navigate(`/`)
  }

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={open}
      onClose={closeMenu}
      sx={{ width: `100%`, "& .MuiDrawer-paper": { width: `100%` } }}
    >
      <Box display="flex" sx={{ fontSize: `0.75rem`, flexDirection: `column` }}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ borderBottom: `1px solid #E0E0E0`, padding: 1 }}
        >
          <Logo />
          <IconButton onClick={closeMenu}>
            <Close />
          </IconButton>
        </Box>
        <MenuList>
          {!isSamlUser() && (
            <>
              <MenuItem
                onClick={async (e) => {
                  e.preventDefault()
                  return onUpgrade(user)
                }}
              >
                <ListItemIcon>
                  <People fontSize="small" />
                </ListItemIcon>
                <ListItemText>Plan</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  e.preventDefault()
                  navigate(`/change-password`)
                }}
              >
                <ListItemIcon>
                  <Password fontSize="small" />
                </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
              </MenuItem>
            </>
          )}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout color="error" fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ color: `error.main` }}>Sign Out</ListItemText>
          </MenuItem>
          <Divider />
          <Box p={2}>
            <Typography sx={{ opacity: 0.6, fontSize: 14 }}>
              About ERinfo
            </Typography>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/"
                target="_blank"
              >
                About
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/privacy-policy/"
                target="_blank"
              >
                Privacy
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/terms-conditions/"
                target="_blank"
              >
                Terms & Conditions
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/patents/"
                target="_blank"
              >
                Patent Information
              </ListItemButton>
            </MenuItem>
            <Typography mt={2} sx={{ opacity: 0.6, fontSize: 14 }}>
              Help
            </Typography>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/faqs/"
                target="_blank"
              >
                FAQ
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/faqs/"
                target="_blank"
              >
                Using ERinfo
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                component="a"
                href="https://erinfo.me/contact"
                target="_blank"
              >
                Send Feedback
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ListItemButton
                onClick={() => {
                  toggleSettings(true)
                  closeMenu()
                }}
              >
                Settings
              </ListItemButton>
            </MenuItem>
          </Box>
        </MenuList>
      </Box>
      <Box sx={{ position: `absolute`, bottom: 15, right: 30 }}>
        <Typography sx={{ color: `#888`, fontSize: `0.8rem` }}>
          v {version}
        </Typography>
      </Box>
    </Drawer>
  )
}
