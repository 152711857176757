import { ReactComponent as ILogoProVert } from "@erinfo/brand-ui/src/assets/svg/ERinfoMark_pro_firstNet.svg"
import { ReactComponent as ILogo } from "@erinfo/brand-ui/src/assets/svg/logo-primary.svg"
import { ReactComponent as ILogoProHoriz } from "@erinfo/brand-ui/src/assets/svg/logo-pro.svg"
import { ReactComponent as ILogoHoriz } from "@erinfo/brand-ui/src/assets/svg/mark-horizontal.svg"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: { width: `200px`, height: `150px`, cursor: `pointer` },
})

export const Logo = ({ version = `primary`, notClickable = false }) => {
  const classes = useStyles()

  const commonProps = {
    className: classes.root,
    onClick: notClickable ? () => {} : () => window.open(`https://erinfo.me`), // we must use "window.open", instead of "window.location.href" to open in-app browser in a native app
    style: notClickable ? { cursor: `default` } : {},
    title: `logo`,
  }

  if (version === `pro_horizontal`) {
    return <ILogoProHoriz {...commonProps} />
  } else if (version === `pro_vertical`) {
    return <ILogoProVert {...commonProps} />
  } else if (version === `horizontal`) {
    return (
      <ILogoHoriz
        {...commonProps}
        style={{ ...(version === `horizontal` && { height: `100px` }) }}
      />
    )
  }
  return <ILogo {...commonProps} />
}
