export const dataLanguage = [
  { title: ``, value: `` },
  { title: `English`, value: `English`, key: `English` },
  { title: `Arabic`, value: `Arabic`, key: `Arabic` },
  { title: `Armenian`, value: `Armenian`, key: `Armenian` },
  { title: `Bengali`, value: `Bengali`, key: `Bengali` },
  { title: `Chinese`, value: `Chinese`, key: `Chinese` },
  { title: `French`, value: `French`, key: `French` },
  { title: `German`, value: `German`, key: `German` },
  { title: `Greek`, value: `Greek`, key: `Greek` },
  { title: `Gujarati`, value: `Gujarati`, key: `Gujarati` },
  { title: `Hebrew`, value: `Hebrew`, key: `Hebrew` },
  { title: `Hindi`, value: `Hindi`, key: `Hindi` },
  { title: `Hmong`, value: `Hmong`, key: `Hmong` },
  { title: `Italian`, value: `Italian`, key: `Italian` },
  { title: `Japanese`, value: `Japanese`, key: `Japanese` },
  { title: `Korean`, value: `Korean`, key: `Korean` },
  { title: `Panjabi`, value: `Panjabi`, key: `Panjabi` },
  { title: `Persian`, value: `Persian`, key: `Persian` },
  { title: `Polish`, value: `Polish`, key: `Polish` },
  { title: `Portuguese`, value: `Portuguese`, key: `Portuguese` },
  { title: `Russian`, value: `Russian`, key: `Russian` },
  { title: `Spanish`, value: `Spanish`, key: `Spanish` },
  { title: `Tagalog`, value: `Tagalog`, key: `Tagalog` },
  { title: `Telugu`, value: `Telugu`, key: `Telugu` },
  { title: `Urdu`, value: `Urdu`, key: `Urdu` },
  { title: `Vietnamese`, value: `Vietnamese`, key: `Vietnamese` },
]
