const prefixUser = `u/`
const prefixProvider = `p/`
const prefixOrganization = `o/`

export const setTypeUser = <v extends string>(v: v) =>
  `${prefixUser}${v}` as const
const setTypeProvider = <v extends string>(v: v) =>
  `${prefixProvider}${v}` as const
const setTypeOrganization = <v extends string>(v: v) =>
  `${prefixOrganization}${v}` as const

/**
 * FirstNet doesn't have randomness in ids, so if we don't add a prefix, IDs will collide with other providers
 */
export const setFirstNetId = (sub: string) => `fn${sub}` as const

export const checkIsUser = (v: string) => v.startsWith(prefixUser)
export const checkIsProvider = (v: string) => v.startsWith(prefixProvider)
export const checkIsOrganization = (v: string) =>
  v.startsWith(prefixOrganization)

export const typesConsumer = {
  userSubscriber: setTypeUser(`subscriber`),
  userMember: setTypeUser(`member`),
  userDispositionFlow: setTypeUser(`disposition`),
  userArchived: setTypeUser(`archived`),
  userAudit: setTypeUser(`audit`),
  userPurged: setTypeUser(`purged`),
  userOrganizationMember: setTypeUser(`orgMember`),
}

export const checkIsSubscriber = (v: string) =>
  v === typesConsumer.userSubscriber
export const checkIsMember = (v: string) => v === typesConsumer.userMember
export const checkIsDispositionFlow = (v: string) =>
  v === typesConsumer.userDispositionFlow
export const checkIsOrganizationMember = (v: string) =>
  v === typesConsumer.userOrganizationMember

export const typesProvider = {
  providerFirstNet: setTypeProvider(`fn`),
}
export const typesOrganizations = {
  university: setTypeOrganization(`university`),
}

export const types = {
  ...typesConsumer,
  ...typesProvider,
  ...typesOrganizations,
} as const
type types = typeof types
export type userTypesAll = types[keyof types]

export type userTypesConsumer = types[keyof Omit<types, "providerFirstNet">]
