import { createModel } from "@rematch/core"

import type { RootModel } from "./index"

export interface SignUpState {
  version: string
  logo: string
  registerCode: string
  trialPeriod: string
  logoutAfterSignup: boolean
  providerApp: MessageEventSource | null
  utmData?: {
    utm_campaign: string
    utm_content: string
    utm_term: string
    utm_medium: string
    utm_source: string
  }
}

const initialState = {
  version: ``,
  logo: ``,
  registerCode: ``,
  trialPeriod: ``,
  logoutAfterSignup: false,
  // providerApp: null
}

export const signUp = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSignupInfo: (state: SignUpState, payload: object): SignUpState => ({
      ...state,
      ...payload,
    }),
  },
})
