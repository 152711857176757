import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameHairColor = `hairColor`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid Entry`,
    exp: /^\s*(?:\S\s*){0,20}$/u,
  },
]
export const hairColor = helpers.setRegexes(regex)
export const yupHairColorSchema = helpers.getYupSchema(regex)
export const hairColorOptional = hairColor.optional()
