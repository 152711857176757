import { CameraCaptureDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/CameraDialog"
import { isAndroid } from "@erinfo/react-utils/src/helpers/check-browser"
import { Box } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC, useCallback, useRef, useState } from "react"
import { useDropzone } from "react-dropzone"

interface IProps {
  setBase64: (b64?: string) => void
  sx?: SxProps
}

const MAX_WIDTH = 1024

export const resizeImage = async (base64: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.src = base64.toString()

    img.onload = () => {
      const elem = document.createElement(`canvas`)
      const scaleFactor = MAX_WIDTH / img.width
      elem.width = MAX_WIDTH
      elem.height = img.height * scaleFactor

      const ctx = elem.getContext(`2d`)
      ctx?.drawImage(img, 0, 0, MAX_WIDTH, img.height * scaleFactor)

      resolve(ctx?.canvas.toDataURL(`image/jpeg`, 1) || ``)
    }
  })

export const FileDropzone: FC<IProps> = ({ children, setBase64, sx }) => {
  const [cameraOpen, setCameraOpen] = useState(false)
  const inputRef = useState<HTMLInputElement>()

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => console.log(`file reading was aborted`)
      reader.onerror = () => console.log(`file reading has failed`)
      reader.onload = async (e) => {
        if (typeof e.target?.result !== `string`) return
        const resized = await resizeImage(e.target.result)
        setBase64(resized)
      }

      reader.readAsDataURL(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderRadius: 2,
        border: `3px dashed rgba(0,0,0,0.22)`,
        p: 3,
        ...sx,
      }}
      onClick={() => setCameraOpen(true)}
    >
      {cameraOpen && (
        <CameraCaptureDialog
          isOpen={cameraOpen}
          onClose={(e) => {
            e.stopPropagation()
            setCameraOpen(false)
          }}
          onCapture={(base64Uri) => {
            setBase64(base64Uri)
            setCameraOpen(false)
          }}
        />
      )}
      <input
        {...getInputProps()}
        ref={inputRef}
        type="file"
        accept="image/jpg,image/jpeg,image/png"
        multiple={false}
      />
      {children || (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
    </Box>
  )
}
