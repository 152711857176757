import * as helpers from "@erinfo/data-schema/src/_helpers"

export const namePassword = `password`
export const namePasswordNew = `passwordNew`
/**
 * @see https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
 * @notes cognito supports
 *   - uppercase and lowercase letters
 *   - numbers
 *   - the equals sign "="
 *   - the plus sign "+"
 *   - the special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ `
 * @notes cognito does not support:
 *   - space character
 */
/**
 * Rules for password policy are here
 *   - length between 8 and 31 including
 *   - space symbol is not allowed
 *   - allow only
 *      - latin characters
 *      - numbers
 *      - special symbols from the cognito policy (see above)
 */
const regex: DataSchema.RegExDef[] = [
  ...helpers.hasLengths(8, 31),
  helpers.hasNumber,
  helpers.hasLowercase,
  helpers.hasUppercase,
  {
    msg: `Space character is not allowed`,
    exp: /^((?!\s).)*$/,
  },
]

export const regexPassword = {
  regex,
}

export const password = helpers.setRegexes(regex)
export const yupPasswordSchema = helpers.getYupSchema(regex)
export const passwordOptional = password.optional()
