import { ReactComponent as IPhotoDeleteFace } from "@erinfo/brand-ui/src/assets/svg/photo-delete-face.svg"
import {
  IPhoto,
  PhotoFace,
} from "@erinfo/brand-ui/src/storybook/other/photo/face-v2"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { bucketFilesRaw } from "@erinfo/env"
import { AddAPhoto } from "@mui/icons-material"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useEffect, useState } from "react"

const initialPicture: IPhoto = {
  value: ``,
  errors: [],
  dataBeforeEditing: ``,
  filename: ``,
  created: ``,
}

interface IProps {
  value: IPhoto
  onChange: (v: string) => void
  disabled?: boolean
}

export const PictureCapture = ({ value, onChange, disabled }: IProps) => {
  const [picture, setPicture] = useState(initialPicture)

  useEffect(() => {
    if (picture?.value && picture?.created === null) {
      onChange(picture.value)
    }
  }, [picture])

  return (
    <PhotoFace
      photo={picture}
      setPhoto={setPicture}
      faceValidation={async () => Promise.resolve(null)}
    >
      {(openDialog) =>
        value ? (
          <Box
            onClick={() => {
              setPicture(initialPicture)
              onChange(null)
            }}
            sx={{
              position: `relative`,
              borderRadius: `5px`,
              overflow: `hidden`,
              cursor: `pointer`,
              height: `100%`,
              "& img": {
                borderRadius: `5px`,
                maxHeight: 140,
                minHeight: 65,
              },
            }}
          >
            <PhotoDeleteIcon />
            <img src={value} />
          </Box>
        ) : (
          <StyledButton
            color="primary"
            onClick={openDialog}
            disabled={disabled}
          >
            <AddAPhoto sx={{ marginRight: 1, width: 50, height: 50 }} />
            Add Photo
          </StyledButton>
        )
      }
    </PhotoFace>
  )
}

const PhotoDeleteIcon = styled(IPhotoDeleteFace)({
  position: `absolute`,
  bottom: `3px`,
  left: `3px`,
})
