import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameEyeColor = `eyeColor`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid Entry`,
    exp: /^\s*(?:\S\s*){0,20}$/u,
  },
]
export const eyeColor = helpers.setRegexes(regex)
export const yupEyeColorSchema = helpers.getYupSchema(regex)
export const eyeColorOptional = eyeColor.optional()
