import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameEmail = `email`
/**
 * Can check regexes here:
 * @see https://regexr.com/4hq79
 * Inspired by https://emailregex.com/ for the JavaScript case
 */
const regex: DataSchema.RegExDef[] = [
  ...helpers.hasLengths(5, 254),
  helpers.hasChar(`@`),
  {
    msg: `Invalid part after @`,
    exp: /.*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  {
    msg: `Invalid part before @`,
    exp: /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+")))@.+$/,
  },
  {
    msg: `Not a valid email`,
    exp: /^[a-zA-Z0-9.#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  },
]

export const regexEmail = {
  regex,
}

export const email = helpers.setRegexes(regex, helpers.transformerTrimLowercase)
export const yupEmailSchema = helpers.getYupSchema(regex)
export const emailOptional = email.optional()
