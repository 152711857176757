export const medicalConditions = [
  { title: `None`, value: `none`, selected: false },
  { title: `Addison's Disease`, value: `addisonSDisease`, selected: false },
  { title: `Adrenalectomy`, value: `adrenalectomy`, selected: false },
  { title: `Agoraphobia`, value: `agoraphobia`, selected: false },
  { title: `Alzheimer's Disease`, value: `alzheimerSDisease`, selected: false },
  { title: `Anemia`, value: `anemia`, selected: false },
  { title: `Angina`, value: `angina`, selected: false },
  { title: `Aortic Valve`, value: `aorticValve`, selected: false },
  { title: `Aphasia`, value: `aphasia`, selected: false },
  { title: `Arthritis`, value: `arthritis`, selected: false },
  { title: `Asthma`, value: `asthma`, selected: false },
  { title: `Atherosclerosis`, value: `atherosclerosis`, selected: false },
  { title: `Autism`, value: `autism`, selected: false },
  { title: `Blind`, value: `blind`, selected: false },
  { title: `Cancer`, value: `cancer`, selected: false },
  {
    title: `Cardiovascular Disease`,
    value: `cardiovascularDisease`,
    selected: false,
  },
  { title: `Cataracts`, value: `cataracts`, selected: false },
  { title: `Cerebral Palsy`, value: `cerebralPalsy`, selected: false },
  { title: `Chemotherapy`, value: `chemotherapy`, selected: false },
  { title: `COPD`, value: `copd`, selected: false },
  { title: `Coronary Bypass`, value: `coronaryBypass`, selected: false },
  {
    title: `COVID-19 Positive (PCR)`,
    value: `covidPositivePcr`,
    selected: false,
  },
  {
    title: `COVID-19 Positive (Antibodies)`,
    value: `covidPositiveAntibodies`,
    selected: false,
  },
  {
    title: `COVID-19 Negative (PCR)`,
    value: `covidNegativePcr`,
    selected: false,
  },
  {
    title: `COVID-19 Negative (Antibodies)`,
    value: `covidNegativeAntibodies`,
    selected: false,
  },
  { title: `Cystic Fibrosis`, value: `cysticFibrosis`, selected: false },
  { title: `Deaf`, value: `deaf`, selected: false },
  { title: `Deaf mute`, value: `deafMute`, selected: false },
  { title: `Dementia`, value: `dementia`, selected: false },
  { title: `Detached Retina`, value: `detachedRetina`, selected: false },
  {
    title: `Diabetes - Non Insulin Dependent`,
    value: `diabetesNonInsulinDependent`,
    selected: false,
  },
  { title: `Diabetes - Type 1`, value: `diabetesType1`, selected: false },
  { title: `Diabetes - Type 2`, value: `diabetesType2`, selected: false },
  {
    title: `Diabetes - Type 2 Insulin Dependent`,
    value: `diabetesType2InsulinDependent`,
    selected: false,
  },
  { title: `Dialysis Patient`, value: `dialysisPatient`, selected: false },
  { title: `Difficult Airway`, value: `difficultAirway`, selected: false },
  { title: `Down's Syndrome`, value: `downSSyndrome`, selected: false },
  { title: `Emphysema`, value: `emphysema`, selected: false },
  { title: `Epilepsy`, value: `epilepsy`, selected: false },
  { title: `Erythematosus`, value: `erythematosus`, selected: false },
  { title: `Glaucoma`, value: `glaucoma`, selected: false },
  { title: `Gravis`, value: `gravis`, selected: false },
  { title: `Hard of Hearing`, value: `hardOfHearing`, selected: false },
  { title: `Heart Condition`, value: `heartCondition`, selected: false },
  {
    title: `Heart Rhythm Disorder`,
    value: `heartRhythmDisorder`,
    selected: false,
  },
  { title: `Hemodialysis`, value: `hemodialysis`, selected: false },
  { title: `Hemolytic Anemia`, value: `hemolyticAnemia`, selected: false },
  { title: `Hemophilia`, value: `hemophilia`, selected: false },
  { title: `Hiatal Hernia`, value: `hiatalHernia`, selected: false },
  { title: `Hodgkin's Disease`, value: `hodgkinSDisease`, selected: false },
  { title: `Hyperglycemia`, value: `hyperglycemia`, selected: false },
  { title: `Hypertension`, value: `hypertension`, selected: false },
  { title: `Hypoglycemia`, value: `hypoglycemia`, selected: false },
  { title: `Hypothermia Risk`, value: `hypothermiaRisk`, selected: false },
  { title: `Implants-Cosmetic`, value: `implantsCosmetic`, selected: false },
  {
    title: `Implants-Orthopedic`,
    value: `implantsOrthopedic`,
    selected: false,
  },
  {
    title: `Implants-Other (See Notes)`,
    value: `implantsOther`,
    selected: false,
  },
  {
    title: `Kidney Malformations`,
    value: `kidneyMalformations`,
    selected: false,
  },
  { title: `Kidney Transplant`, value: `kidneyTransplant`, selected: false },
  { title: `Laryngectomy`, value: `laryngectomy`, selected: false },
  { title: `Left Kidney Only`, value: `leftKidneyOnly`, selected: false },
  { title: `Leukemia`, value: `leukemia`, selected: false },
  { title: `Lung Disease`, value: `lungDisease`, selected: false },
  { title: `Lupus`, value: `lupus`, selected: false },
  { title: `Mastectomy`, value: `mastectomy`, selected: false },
  { title: `Meniere's Disease`, value: `meniereSDisease`, selected: false },
  { title: `Mental Handicap`, value: `mentalHandicap`, selected: false },
  { title: `Migraine Headaches`, value: `migraineHeadaches`, selected: false },
  { title: `Multiple Sclerosis`, value: `multipleSclerosis`, selected: false },
  { title: `Muscular Dystrophy`, value: `muscularDystrophy`, selected: false },
  { title: `Myasthenia`, value: `myasthenia`, selected: false },
  { title: `Neck Breather`, value: `neckBreather`, selected: false },
  { title: `No Adrenal Glands`, value: `noAdrenalGlands`, selected: false },
  { title: `Open Heart Surgery`, value: `openHeartSurgery`, selected: false },
  { title: `Osteoarthritis`, value: `osteoarthritis`, selected: false },
  { title: `Ostomate`, value: `ostomate`, selected: false },
  { title: `Ostomy`, value: `ostomy`, selected: false },
  {
    title: `Pacemaker/Defibrillator`,
    value: `pacemakerDefibrillator`,
    selected: false,
  },
  { title: `Parkinson's Disease`, value: `parkinsonSDisease`, selected: false },
  { title: `Pulmonary Embolism`, value: `pulmonaryEmbolism`, selected: false },
  { title: `Raynaud's Disease`, value: `raynaudSDisease`, selected: false },
  { title: `Renal Failure`, value: `renalFailure`, selected: false },
  { title: `Renal Transplant`, value: `renalTransplant`, selected: false },
  { title: `Rheumatoid`, value: `rheumatoid`, selected: false },
  { title: `Right Kidney Only`, value: `rightKidneyOnly`, selected: false },
  { title: `Seizure Disorder`, value: `seizureDisorder`, selected: false },
  { title: `Senile`, value: `senile`, selected: false },
  { title: `Sickle Cell`, value: `sickleCell`, selected: false },
  { title: `Special Needs Child`, value: `specialNeedsChild`, selected: false },
  { title: `Stroke`, value: `stroke`, selected: false },
  { title: `Stroke Risk`, value: `strokeRisk`, selected: false },
  { title: `Tetanus`, value: `tetanus`, selected: false },
  { title: `Toxoid`, value: `toxoid`, selected: false },
  {
    title: `Transplant - See Notes`,
    value: `transplantSeeNotes`,
    selected: false,
  },
  { title: `UTI`, value: `uti`, selected: false },
]
