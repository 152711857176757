export const validateObject = (obj: any, schema: any) => {
  if (!obj || !Object.keys(obj)?.length) return null

  return Object.keys(obj).reduce((agg: any, curr: string) => {
    if (!schema[curr]) return { ...agg, [curr]: null }

    if (typeof obj[curr] === `string`) {
      const errors = schema[curr].reduce(
        (agg: any, regexDef: DataSchema.RegExDef) => {
          const { exp, msg } = regexDef
          const result = exp.test(obj[curr])
          if (result) {
            agg.push(msg)
          }

          return agg
        },
        [],
      )
      agg[curr] = errors.length ? errors : null
    }

    return agg
  }, {})
}
