import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import LinearProgress from "@mui/material/LinearProgress"
import Typography from "@mui/material/Typography"
import { IconButton } from "@mui/material"
import { styled } from "@mui/material/styles"
import Popover from "@mui/material/Popover"
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state"
import { getColor } from "@erinfo/brand-ui/src/storybook/other/forms/Disposition"
import * as attr from "@erinfo/data-schema/src/attribute"
import MapIcon from "@mui/icons-material/Place"
import { GOOGLE_MAPS_API_KEY } from "@erinfo/env/src/other"

interface IProps {
  children?: React.ReactNode
  avatar?: string | React.ReactNode
  disabled?: boolean
  unclickable?: boolean
  added?: string
  updated?: string
  matched?: string
  similarity?: string
  progressPercent?: number
  onClick?: () => void
  onSelect?: () => void
  checked?: boolean
  pctComplete?: number
  disposition?: any
}

export const MemberListItem = (props: IProps) => {
  const IIcon = props.avatar

  return (
    <Wrapper>
      {props.onSelect && (
        <Checkbox
          checked={props.checked}
          color="primary"
          onChange={props.onSelect}
          sx={{ marginRight: 2 }}
        />
      )}
      <Container
        onClick={
          !props.disabled && !props.unclickable ? props.onClick : () => {}
        }
        style={{
          ...(props.disabled && {
            filter: `grayscale(1)`,
            opacity: 0.5,
          }),
          ...((props.unclickable || props.disabled) && {
            cursor: `default`,
          }),
        }}
      >
        <AvatarWrapper>
          {typeof props.avatar === `string` ? (
            <AvatarImage
              src={props.avatar}
              style={{ ...(props.unclickable && { borderColor: `black` }) }}
            />
          ) : (
            <IIcon width="56" height="56" />
          )}
        </AvatarWrapper>

        <TextWrapper>
          <Typography
            className="member-list-item__name"
            style={{ ...(props.unclickable && { color: `black` }) }}
          >
            {props.children}
          </Typography>
          <div className="member-list-item__dates">
            <p>
              {props.added ? (
                <>
                  <Typography component="span" sx={{ color: `#4CAF50` }}>
                    Added
                  </Typography>
                  <Typography component="span">: {props.added}</Typography>
                </>
              ) : null}
              {props.matched ? (
                <>
                  <Typography component="span" sx={{ color: `#F44336` }}>
                    Matched
                  </Typography>
                  <Typography component="span">
                    : {props.matched} · {props.similarity}%
                  </Typography>
                </>
              ) : null}
            </p>
            <p>{props.updated ? `Edited: ` + props.updated : ``}</p>
            {props.pctComplete ? (
              <Box display="flex" alignItems="center">
                <LinearProgress
                  variant="determinate"
                  value={props.pctComplete}
                  color={
                    props.pctComplete <= 75
                      ? props.pctComplete <= 50
                        ? `error`
                        : `warning`
                      : `success`
                  }
                  sx={{ flex: 1, mr: 1 }}
                />
                <Typography sx={{ fontSize: 12 }}>
                  {props.pctComplete}%
                </Typography>
              </Box>
            ) : (
              <></>
            )}
          </div>
          {props.disposition && (
            <Box
              display="flex"
              className="member-list-item__dates"
              sx={{ cursor: "default", maxWidth: 300 }}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Typography
                sx={{
                  color: getColor(props.disposition?.[attr.nameTriageStatus]),
                }}
                component="span"
              >
                ●{" "}
                <Typography
                  sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                  component="span"
                >
                  Incident: {props.disposition?.[attr.nameIncidentId]} |
                  Transport: {props.disposition?.[attr.nameTransportUnit]}
                </Typography>
                {props.disposition[attr.nameLat] &&
                props.disposition[attr.nameLong] ? (
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <>
                        <IconButton
                          {...bindTrigger(popupState)}
                          sx={{ padding: 0, ml: 2 }}
                        >
                          <MapIcon color="primary" />
                        </IconButton>

                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                        >
                          <iframe
                            width="350"
                            height="250"
                            style={{ border: 0 }}
                            referrerPolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${
                              props.disposition[attr.nameLat]
                            },${props.disposition[attr.nameLong]}`}
                          ></iframe>
                        </Popover>
                      </>
                    )}
                  </PopupState>
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          )}
        </TextWrapper>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(`div`)({
  display: `flex`,
  alignItems: `center`,
  position: `relative`,
  width: 300,
})

const Container = styled(`div`)({
  boxSizing: `border-box`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `flex-start`,
  width: `100%`,
  // height: `72px`,
  cursor: `pointer`,
  margin: `5px 0`,
})

const AvatarWrapper = styled(`div`)({
  width: `56px`,
  height: `56px`,
  borderRadius: `50%`,
})

const AvatarImage = styled(`img`)({
  display: `block`,
  objectFit: `cover`,
  width: `56px`,
  height: `56px`,
  borderRadius: `50%`,
})

const TextWrapper = styled(`div`)(({ theme }) => ({
  // height: 64,
  "& .member-list-item__name": {
    marginLeft: `15px`,
    letterSpacing: `normal`,
    color: theme.palette.primary.main,
  },
  "& .member-list-item__dates": {
    marginLeft: `15px`,
    marginTop: `2px`,
    color: `rgba(0, 0, 0, 0.54)`,
    height: `25px`,
    font: `normal 12px/1.4 Roboto`,
    letterSpacing: `normal`,
    whiteSpace: `nowrap`,
  },
}))
