import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import {
  CorePersonalForm,
  SingleEmergencyContactForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import StepperPanel from "@erinfo/brand-ui/src/storybook/other/StepperPanel"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import {
  getCompositeId,
  helpers,
  yupEmailSchema,
  yupFirstNameSchema,
  yupLastNameSchema,
  yupNameSchema,
} from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import { FormikStateUpdate } from "@erinfo/react-utils/src/components/FormikStateUpdate"
import { gtagRecordEnrollment } from "@erinfo/react-utils/src/components/googleAnalytics"
import { setUserData } from "@erinfo/react-utils/src/helpers/form"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { AccountCircle } from "@mui/icons-material"
import AddIcCallIcon from "@mui/icons-material/AddIcCall"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"

const { isIntlPhoneNumber } = helpers

import { stepperSteps } from "../constants"

const StyledFormCard = styled(FormCard)({
  width: 295,
  "@media(max-width: 480px)": {
    width: `100%`,
  },
})

const iconStyle = {
  color: `#F44336`,
  filter: `brightness(0.7)`,
}

const FormSchema = Yup.object().shape({
  firstName: yupFirstNameSchema.required(`First name is required`),
  lastName: yupLastNameSchema.required(`Last name is required`),
  gender: Yup.string().required(`Gender is required`),
  birthDate: Yup.string()
    .required(`Birthdate is required`)
    .test(`format`, `Must enter MM/DD/YYYY`, (val) =>
      Boolean(val?.match(/\d\d\d\d-\d\d-\d\d/)),
    )
    .test(
      `past`,
      `Date must be in the past`,
      (val) => !val || dayjs(val, `YYYY-MM-DD`).isBefore(dayjs()),
    ),
  emergencyContactName: yupNameSchema.required(`Name is required`),
  emergencyContactEmail: yupEmailSchema,
  emergencyContactPhone: Yup.string()
    .test(`intl phone`, `Phone number is not valid`, (val) => {
      const number = val?.replace(/\s/g, ``)
      return number?.startsWith(`+1`)
        ? !!/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
        : !!number?.match(isIntlPhoneNumber.exp)?.[0]
    })
    .max(16, `Too many digits for a phone number`)
    .required(`Mobile number is required`),
  emergencyContactRelationship: Yup.string().required(
    `Relationship is required`,
  ),
})

interface FormValues {
  firstName: string
  lastName: string
  birthDate: string
  gender: string
  emergencyContactName: string
  emergencyContactPhone: string
  emergencyContactEmail: string
  emergencyContactRelationship: string
}

const SignUpStep5 = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const auth = useAuth()
  const user = useSelector((state: RootState) => state.user)
  const { updateUser } = useRematchDispatch((dispatch: Dispatch) => ({
    updateUser: dispatch.user.updateUser,
  }))
  const isSamlUser = auth.isSamlUser()

  const avatarFile = user?.pictures?.[0]?.src
  const FORM_ID = `step-5`

  const handleSubmit = async (values: FormValues) => {
    values.firstName = values.firstName?.trim()
    values.lastName = values.lastName?.trim()
    values.emergencyContactName = values.emergencyContactName?.trim()
    values.emergencyContactEmail = values.emergencyContactEmail?.trim()

    setLoading(true)
    if (window.clarity) {
      window.clarity(`set`, `firstName`, values.firstName)
      window.clarity(`set`, `lastName`, values.lastName)
      window.clarity(`set`, `emergencyContactName`, values.emergencyContactName)
    }
    try {
      const data = setUserData({
        profile: {
          [attr.nameFirstName]: values.firstName,
          [attr.nameLastName]: values.lastName,
          [attr.nameBirthDate]: values.birthDate,
          [attr.nameGender]: values.gender,
        },
        emergencyContacts: [
          {
            [attr.nameName]: values.emergencyContactName,
            [attr.namePhone]: values.emergencyContactPhone,
            [attr.nameEmail]: values.emergencyContactEmail || undefined,
            [attr.nameRelationship]: values.emergencyContactRelationship,
          },
        ],
      })
      data.noNotification = true

      const id = isSamlUser
        ? getCompositeId(
            auth.user.identities[0].providerName,
            auth.user.identities[0].userId,
          )
        : auth.user.attributes[`custom:compositeID`]

      const email = isSamlUser ? auth.user.email : auth.user.attributes.email
      if (!id) {
        throw new Error(
          `There is an issue updating your profile. Please contact customer support`,
        )
      }
      await updateUser({ userId: id, email, data })
      gtagRecordEnrollment()
      navigate(`/sign-up/step-6`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepperPanel
      activeStep={1}
      steps={stepperSteps}
      sx={{
        "@media(min-width: 480px)": {
          width: `calc(100vw - 50px)`,
          maxWidth: 960,
          minHeight: 650,
        },
        "@media(max-width: 480px)": {
          width: `100%`,
        },
      }}
      formId={FORM_ID}
      nextLoading={loading}
      nextDisabled={!isValid}
      // onBack={() => navigate(`/sign-up/step-4?return=true`)}
    >
      <Avatar url={avatarFile} />
      <Formik
        initialValues={{
          firstName: user.profile?.firstName || ``,
          lastName: user.profile?.lastName || ``,
          birthDate: user.profile?.birthDate || ``,
          gender: user.profile?.gender || ``,
          emergencyContactName: user.emergencyContacts?.[0]?.name || ``,
          emergencyContactPhone: user.emergencyContacts?.[0]?.phone || ``,
          emergencyContactEmail: user.emergencyContacts?.[0]?.email || ``,
          emergencyContactRelationship:
            user.emergencyContacts?.[0]?.relationship || ``,
        }}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form id={FORM_ID}>
            <FormikStateUpdate setIsValid={setIsValid} requireDirty />
            <Box
              sx={{
                display: `flex`,
                flexWrap: `wrap`,
                marginTop: 2,
                marginBottom: 2,
                justifyContent: `space-between`,
                "@media(min-width: 480px)": {
                  width: 620,
                },
              }}
            >
              <StyledFormCard
                Icon={<AccountCircle sx={iconStyle} />}
                headerText="Basic Information"
              >
                <CorePersonalForm setFieldValue={setFieldValue} />
              </StyledFormCard>
              <StyledFormCard
                Icon={<AddIcCallIcon sx={iconStyle} />}
                headerText="Emergency Contact"
              >
                <SingleEmergencyContactForm setFieldValue={setFieldValue} />
              </StyledFormCard>
            </Box>
          </Form>
        )}
      </Formik>
      <Typography sx={{ opacity: 0.38 }}>
        *More information and contacts can be added later.
      </Typography>
    </StepperPanel>
  )
}

export default SignUpStep5
