import { readAndCompressImage } from "browser-image-resizer"

export const imageResize = async (file: File, maxWidth: number) => {
  return new Promise(async (resolve) => {
    const resizedImage = await readAndCompressImage(file, {
      quality: 0.5,
      maxWidth,
      autoRotate: true,
      mimeType: `image/png`,
    })
    const browserFileReader = new FileReader()
    browserFileReader.onload = (e) => resolve(e?.target?.result)

    browserFileReader.readAsDataURL(resizedImage)
  })
}
