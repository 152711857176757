import { checkStripeCoupon } from "@erinfo/consumer/src/data"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { getAffiliateFromQS } from "@erinfo/data-schema/src/attribute/affiliate"
import { envProviderUrl } from "@erinfo/env"
import {
  getUtmParams,
  setCookie,
} from "@erinfo/react-utils/src/helpers/cookies"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
interface UrlParams {
  v?: string
  l?: string
  rc?: string
  loasu?: string
  version?: string
  registerCode?: string
  logo?: string
}

const paramLookup = {
  l: `logo`,
  v: `version`,
  rc: `registerCode`,
  loasu: `logoutAfterSignup`,
}

export const useUrlParams = (): UrlParams => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { version, registerCode, logo } = useSelector(
    (state: RootState) => state.signUp,
  )
  const { setSignupInfo } = useRematchDispatch((dispatch: Dispatch) => ({
    setSignupInfo: dispatch.signUp.setSignupInfo,
  }))
  const { handleSignout } = useAuth()

  useEffect(() => {
    const utmParams = getUtmParams()
    const { utm_v, utm_rc, utm_l, ...utmData } = utmParams
    setSignupInfo({
      version: utm_v,
      registerCode: utm_rc,
      logo: utm_l,
      utmData,
    })
  }, [])

  useEffect(() => {
    const affiliate = getAffiliateFromQS(searchParams)
    if (affiliate) {
      setCookie(`affiliate`, affiliate)
    }
    const currentParams: UrlParams = Object.fromEntries([...searchParams])
    const signupInfo = Object.entries(currentParams).reduce(
      (agg, [key, val]) => {
        if (paramLookup[key]) {
          agg[paramLookup[key]] = val
        }
        return agg
      },
      {},
    )
    if (currentParams.loasu === `true`) {
      void handleSignout() // provider app initiated signup, ensure app is not authenticated
      window.addEventListener(`message`, (e) => {
        if (e.origin !== envProviderUrl) {
          return console.log(`Bad Origin in message: ${e.origin}`)
        }
        setSignupInfo({ providerApp: e.source })
      })
    }
    setSignupInfo(signupInfo)
    setSearchParams(``)
  }, [searchParams])

  useEffect(() => {
    console.log(`current code: `, registerCode)

    const checkCoupon = async (): Promise<void> => {
      try {
        await checkStripeCoupon(registerCode)
      } catch (error) {
        setSignupInfo({ registerCode: `` })
      }
    }

    if (registerCode) {
      void checkCoupon()
    }
  }, [registerCode])

  return { version, registerCode, logo }
}
