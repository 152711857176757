import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameBirthDate = `birthDate`

export const birthDateRegex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid date format`,
    exp: /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/, // YYYY-MM-DD
  },
]

export const birthDate = helpers.setRegexes(birthDateRegex)
export const birthDateOptional = birthDate.optional()
