import { getNewRelicAgent } from "./get-new-relic-agent"

type newrelic = typeof window.newrelic

export const withNewRelic = async (handler: (newrelic: newrelic) => void) => {
  try {
    const newrelic = await getNewRelicAgent()
    handler(newrelic)
  } catch (error) {
    console.error(error)
  }
}

/**
 * We should avoid reporting empty strings, but not 0 (number)
 */
const setCustomAttribute = (
  newrelic: newrelic,
  key: string,
  value?: newrelic.SimpleType,
) => {
  if (value !== undefined && value !== ``)
    newrelic.setCustomAttribute(key, value)
}

export const addUserDataToSession = async (
  userData: Record<string, newrelic.SimpleType>,
) =>
  withNewRelic((nr) => {
    for (const key of Object.keys(userData)) {
      setCustomAttribute(nr, `user.${key}`, userData[key])
    }
  })
