import { PictureCapture } from "./PictureCapture"

export const PictureCaptureInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const handleChange = (value) => {
    setFieldValue(field.name, value)
  }

  return <PictureCapture {...field} {...props} onChange={handleChange} />
}
