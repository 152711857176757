import { createTheme } from "@mui/material/styles"
import type {} from "@mui/x-date-pickers/themeAugmentation"

declare module "@mui/material/styles" {
  interface Palette {
    personal: Palette["primary"]
    identify: Palette["primary"]
    medical: Palette["primary"]
    plan: Palette["primary"]
    documents: Palette["primary"]
    border: Palette["primary"]
    headerText: Palette["primary"]
    secondaryGrey: Palette["primary"]
    lightBackground: Palette["primary"]
  }
  interface PaletteOptions {
    personal: PaletteOptions["primary"]
    identify: PaletteOptions["primary"]
    medical: PaletteOptions["primary"]
    plan: PaletteOptions["primary"]
    documents: PaletteOptions["primary"]
    border: PaletteOptions["primary"]
    headerText: PaletteOptions["primary"]
    secondaryGrey: PaletteOptions["primary"]
    lightBackground: PaletteOptions["primary"]
  }

  interface PaletteColor {
    darker?: string
  }
  interface SimplePaletteColorOptions {
    darker?: string
  }
  interface TypographyVariants {
    h7: React.CSSProperties
  }
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true
  }
}

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": [`Roboto`, `Arial`, `sans-serif`],
        },
        "html, body, #root": {
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: `100%`,
          font: `inherit`,
          verticalAlign: `baseline`,
          fontFamily: `Roboto, sans-serif`,
        },
        "h1, h2, h3, h4, h5, h6, h7, p, ul": {
          margin: 0,
          padding: 0,
          border: 0,
          fontSize: `100%`,
          font: `inherit`,
          verticalAlign: `baseline`,
        },
        a: {
          textDecoration: `none`,
          color: `#3F51B5`,
        },
        html: {
          height: `100%`,
          fontSize: `100%`,
          WebkitTextSizeAdjust: `100%`,
          fontVariantLigatures: `none`,
          textRendering: `optimizeLegibility`,
          fontSmoothing: `antialiased`,
          textShadow: `rgba(0, 0, 0, 0.01) 0 0 1px`,
        },
        body: {
          height: `100%`,
          lineHeight: 1,
          backgroundColor: `white`,
        },
        table: { borderCollapse: `collapse`, borderSpacing: `0` },
        "#app": {
          display: `flex`,
          flexFlow: `column`,
          height: `100%`,
          "@supports (top: constant(safe-area-inset-top))": {
            "--safe-area-inset-top": `constant(safe-area-inset-top)`,
            "--safe-area-inset-bottom": `constant(safe-area-inset-bottom)`,
            paddingTop: `var(--safe-area-inset-top)`,
          },
          "@supports (top: env(safe-area-inset-top))": {
            "--safe-area-inset-top": `env(safe-area-inset-top)`,
            "--safe-area-inset-bottom": `env(safe-area-inset-bottom)`,
            paddingTop: `var(--safe-area-inset-top)`,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: `none`,
        },
      },
    },
  },
  typography: {
    h6: {
      fontSize: `24px !important`,
      lineHeight: `32px !important`,
      fontWeight: `400`,
    },
    h7: {
      fontSize: `20px !important`,
      lineHeight: `30px !important`,
      fontWeight: `400`,
    },
  },
  palette: {
    primary: {
      main: `#3F51B5`,
    },
    secondary: {
      main: `#FF0000`,
    },
    background: {
      default: `#e2e2e2`,
    },
    lightBackground: {
      main: `#FAFAFA`,
    },
    personal: {
      main: `#991E1D`,
    },
    identify: {
      main: `#E65609`,
    },
    documents: {
      main: `#FDA609`,
    },
    medical: {
      main: `#D9000D`,
    },
    plan: {
      main: `#4CAF50`,
    },
    border: {
      main: `#b1b1b1`,
    },
    headerText: {
      main: `rgba(0, 0, 0, 0.87)`,
    },
    secondaryGrey: {
      main: `#00000067`,
    },
    info: {
      main: `#2196F3`,
      dark: `#0B79D0`,
    },
  },
})

export default theme
