import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameName = `name`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid name`,
    exp: /^[\p{L}'][ \p{L}'-.]*[\p{L}]$/u,
  },
]

export const name = helpers.setRegexes(regex)
export const yupNameSchema = helpers.getYupSchema(regex)
export const nameOptional = name.optional()
