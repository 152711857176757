import {
  bucketFilesRaw,
  getS3KeyMatch,
  getS3KeyPhotoFace,
  getS3KeyPhotoOther,
  getS3KeyTempFace,
} from "@erinfo/env"

type ImageType = `face` | `other` | `match` | `tempFace`

export const getImageUrl = (
  uid: string,
  created: number | string,
  type: ImageType = `face`,
) => {
  const root = `https://s3.amazonaws.com/${bucketFilesRaw}`

  switch (type) {
    case `face`:
      return `${root}/${getS3KeyPhotoFace(uid, created)}`
    case `other`:
      return `${root}/${getS3KeyPhotoOther(uid, created)}`
    case `match`:
      return `${root}/${getS3KeyMatch(uid, created)}`
    case `tempFace`:
      return `${root}/${getS3KeyTempFace(uid, created)}`
  }
}

type OtherPictures = { created: number; src: string }[]

export const getPresignedImageUrl = (
  created: number | string,
  otherPictures: OtherPictures,
) => otherPictures.find((picture) => picture.created == created)?.src
