import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as z from "zod"

export const nameFirstName = `firstName`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid name`,
    exp: /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u,
  },
]

export const firstName = helpers.setRegexes(regex)
export const yupFirstNameSchema = helpers.getYupSchema(regex)
export const firstNameOptional = firstName.optional()

export const firstNameExternal = z.string()
export const firstNameExternalOptional = firstNameExternal.optional()
