import * as z from "zod"

export const nameDirectives = `directives`

const common = {
  organDonor: z.boolean().optional(),
}

const variantDonor = z.object(common).strict()

const variantDirectives = z
  .object({
    ...common,
    hasDirectives: z.boolean().optional(),
    location: z.string().optional(),
    name: z.string().optional(),
    phone: z.string().optional(),
  })
  .strict()

export const directives = z.union([variantDonor, variantDirectives])
export type directives = z.infer<typeof directives>
export const directivesOptional = directives.optional()
