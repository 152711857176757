import { styled } from "@mui/material/styles"

interface IProps {
  title: string | (() => React.ReactNode)
  topLabel?: string
  topLabelStyle?: React.CSSProperties
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  headerStyle?: React.CSSProperties
  headerClass?: string
  onClick?: () => void
}

const Card = ({
  title,
  topLabel,
  topLabelStyle,
  children,
  className,
  wrapperStyle,
  headerStyle,
  headerClass,
  onClick,
  ...rest
}: IProps) => {
  return (
    <Wrapper
      className={className}
      style={wrapperStyle}
      onClick={onClick}
      {...rest}
    >
      {topLabel && <TopLabel style={topLabelStyle}>{topLabel}</TopLabel>}
      <Header
        withTopLabel={!!topLabel}
        style={headerStyle}
        className={headerClass}
      >
        {typeof title === `string` ? title : title()}
      </Header>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(`div`)({
  display: `flex`,
  flexDirection: `column`,
  borderRadius: `5px`,
  backgroundColor: `#eaeaea`,
  height: `300px`,
})

const Header = styled(`div`)(({ withTopLabel }: { withTopLabel: boolean }) => ({
  display: `flex`,
  backgroundColor: `#A2A2A2`,
  color: `white`,
  padding: `5px 0 2px`,
  borderTopLeftRadius: withTopLabel ? `0px` : `4px`,
  borderTopRightRadius: withTopLabel ? `0px` : `4px`,
  justifyContent: `center`,
  fontFamily: `Roboto`,
  fontSize: `11px`,
  fontWeight: 500,
  fontStretch: `normal`,
  fontStyle: `normal`,
  lineHeight: 1.82,
  letterSpacing: `0.14px`,
}))

const TopLabel = styled(`div`)({
  display: `flex`,
  backgroundColor: `#449309`,
  color: `white`,
  padding: `5px 0 2px`,
  borderTopLeftRadius: `5px`,
  borderTopRightRadius: `5px`,
  justifyContent: `center`,
  fontFamily: `Roboto`,
  fontSize: `11px`,
  fontWeight: 500,
  fontStretch: `normal`,
  fontStyle: `normal`,
  lineHeight: 1.82,
  letterSpacing: `0.14px`,
})

export default Card
