import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { MemberListItem } from "@erinfo/brand-ui/src/storybook/other/memberlist-item/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { calculateProfileCompletion } from "@erinfo/react-utils/src/helpers/profile-completion"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { FamilyRestroom } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useState } from "react"
import { useSelector } from "react-redux"

export const PlanDowngradeModal = ({ open, handleClose }) => {
  const [loading, setLoading] = useState(false)
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const user = useSelector((state: RootState) => state.user)
  const { archiveMember, setDialogMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      archiveMember: dispatch.user.archiveMember,
      setDialogMessage: dispatch.notifications.setDialogMessage,
    }),
  )
  const paymentPlan = user.paymentPlan?.active
  const allowedMembers = Number(paymentPlan?.members || 0)

  const onSubmit = async () => {
    setLoading(true)
    try {
      const toBeArchived =
        user.members?.filter((m) => !selectedMembers.includes(m.id)) ?? []
      await Promise.all(
        toBeArchived.map(async (m) => archiveMember({ memberId: m.id })),
      )

      handleClose()
      setLoading(false)
    } catch (err) {
      console.log(err)
      setDialogMessage({
        title: `Unexpected Error`,
        msg: `An unexpected error occurred while trying to archive members.  Please contact us to resolve this issue.`,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BaseDialog
        open={open}
        handleClose={() => {}}
        renderActions={() => (
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              justifyContent: `flex-end`,
              alignItems: `center`,
            }}
          >
            <StyledButton onClick={onSubmit}>Submit</StyledButton>
          </Box>
        )}
        Icon={<FamilyRestroom sx={{ marginRight: 2, color: `success.main` }} />}
        title="Extended Family Members"
        contentSx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-start`,
          p: 2,
          minHeight: 100,
        }}
      >
        <Typography sx={{ fontSize: 14, mt: 1, mb: 1 }}>
          Your new plan allows for {allowedMembers} additional members.
          <br />
          <strong>
            Please select the {allowedMembers} members you wish to keep.
          </strong>
        </Typography>
        {user?.members?.map((member) => (
          <MemberListItem
            key={member.id}
            avatar={member.pictures?.[0] ? member.pictures[0].src : IPerson}
            updated={dayjs(member.updated).format(`MM/DD/YYYY`)}
            pctComplete={calculateProfileCompletion(member)?.score}
            onSelect={() =>
              setSelectedMembers((p) =>
                p.includes(member.id)
                  ? p.filter((i) => i !== member.id)
                  : p.length < allowedMembers
                  ? [...p, member.id]
                  : [...p.slice(1, allowedMembers), member.id],
              )
            }
            checked={selectedMembers.includes(member.id)}
          >
            {member.profile.lastName ? `${member.profile.lastName}, ` : ``}
            {member.profile.firstName}
          </MemberListItem>
        ))}
      </BaseDialog>
    </>
  )
}
