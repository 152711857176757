import { ReactComponent as IPhotoAddFace } from "@erinfo/brand-ui/src/assets/svg/addPhotoIcon.svg"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import {
  IPhoto,
  PhotoFace,
} from "@erinfo/brand-ui/src/storybook/other/photo/face-v2"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { Add, Delete, Face } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { styled } from "@mui/material/styles"
import { Fragment, useEffect, useRef, useState } from "react"
import { CameraType } from "expo-camera"

const initialPicture = {
  value: ``,
  errors: [],
  dataBeforeEditing: ``,
  filename: ``,
  imageId: ``,
}

export const FacePhotosDialog = ({
  open,
  handleClose,
  user,
  storeUserFacePicture,
  deleteUserFacePicture,
  startingCameraType = CameraType.front,
}) => {
  const [picture, setPicture] = useState<IPhoto>(initialPicture)
  const [loading, setLoading] = useState(false)
  const { pictures } = user
  const savingRef = useRef(false)

  console.log(pictures)

  useEffect(() => {
    const savePic = async () => {
      savingRef.current = true
      setLoading(true)
      try {
        await storeUserFacePicture({ userId: user.id, picture })
        setPicture(initialPicture)
      } catch (error) {
        console.log(`TCL: error`, error)
        setPicture({
          dataBeforeEditing: ``,
          value: ``,
          errors: [
            typeof error.message === `function`
              ? error.message()
              : error.message,
          ],
        })
      }
      savingRef.current = false
      setLoading(false)
    }
    if (picture?.value?.startsWith(`data:image/png`) && !savingRef.current) {
      void savePic()
    }
  }, [picture])

  useEffect(() => {
    if (!open) {
      setPicture(initialPicture)
    }
  }, [open])

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
          }}
        >
          <Typography sx={{ opacity: 0.38, fontSize: `0.7rem` }}>
            *Only used by First Responders
          </Typography>
          <StyledButton onClick={handleClose}>Close</StyledButton>
        </Box>
      )}
      Icon={<Face sx={{ marginRight: 2 }} />}
      title="Facial Recognition Photos"
      loading={loading}
      contentSx={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <PhotoFace
        photo={picture}
        setPhoto={(pic) => setPicture(pic)}
        faceValidation={async () => Promise.resolve(null)}
        onDelete={() => {}}
        startingCameraType={startingCameraType}
      >
        {(openDialog) => (
          <>
            <Box
              display="flex"
              sx={{ justifyContent: `flex-end`, width: `100%` }}
            >
              <StyledButton
                sx={{ float: `right`, marginBottom: 1 }}
                disabled={pictures?.length >= 5}
                onClick={openDialog}
              >
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Add /> Add New
                  </>
                )}
              </StyledButton>
            </Box>
            {picture?.errors?.[0] && (
              <>
                <Box
                  sx={{
                    padding: 1,
                    paddingTop: 3,
                    paddingBottom: 3,
                    backgroundColor: `#E0E0E0`,
                    width: `120px`,
                    "& svg": {
                      width: `100px !important`,
                    },
                  }}
                >
                  <IPhotoAddFace />
                </Box>
                <ErrorWrapper>{picture?.errors?.[0]}</ErrorWrapper>
              </>
            )}
            {!!pictures?.length &&
              pictures.reverse().map(({ created, faceId, src }) => (
                <Fragment key={created}>
                  <img src={src} key={created} style={{ width: 120 }} />
                  {pictures.length > 1 && (
                    <StyledButton
                      onClick={async () => {
                        try {
                          await confirm(``, {
                            title: `Delete Photo?`,
                            description: () => (
                              <>
                                Deleted photos cannot be retrieved. <br /> Are
                                you sure you want to delete this <br /> photo?
                              </>
                            ),
                            noAction: `Back`,
                            yesAction: `Delete`,
                            noColor: `primary`,
                            yesColor: `error`,
                          })
                          deleteUserFacePicture({ userId: user.id, faceId })
                        } catch {}
                      }}
                      color="error"
                      sx={{ mb: 2 }}
                    >
                      <Delete sx={{ marginRight: 2 }} />
                      Delete Photo
                    </StyledButton>
                  )}
                </Fragment>
              ))}
          </>
        )}
      </PhotoFace>
    </BaseDialog>
  )
}

const ErrorWrapper = styled(`div`)(({ theme }) => ({
  position: `relative`,
  fontFamily: `Roboto, arial`,
  fontSize: `12px`,
  maxWidth: `250px`,
  marginTop: `8px`,
  marginBottom: 20,
  color: theme.palette.error.main,
  "& li": {
    marginLeft: 30,
  },
}))
