import {
  ModalState,
  VerifyModal,
} from "@erinfo/brand-ui/src/storybook/notifications/dialogs/verify"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { EmergencyContactsList } from "@erinfo/consumer/src/components/dashboard/EmergencyContactsList"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch } from "@erinfo/consumer/src/store"
import { UserState } from "@erinfo/consumer/src/store/models/user"
import { calculateProfileCompletion } from "@erinfo/react-utils/src/helpers/profile-completion"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { AccountCircle, Edit, GppGood, InfoOutlined } from "@mui/icons-material"
import { Box, IconButton, LinearProgress, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import dayjs from "dayjs"
import { FC, useEffect, useState } from "react"

import { ProfileValueDisplay, StyledDivider, StyledFormCard } from "./common"
import { ProgressTooltip } from "./ProgressTooltip"

export const PersonalPanel: FC<{
  user: UserState
  onAdd: () => void
  sx?: SxProps
}> = ({ user, onAdd, sx }) => {
  const {
    firstName,
    lastName,
    preferredName,
    birthDate,
    gender,
    lang,
    phoneNumbers,
    address = {},
  } = user?.profile || {}
  const { id: userId, type, impersonation, ttl } = user
  const [verifyModalOpen, setVerifyModalOpen] = useState<ModalState>()
  const [logins, setLogins] = useState(`1`)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const { user: authUser, refreshAuthData, isSamlUser } = useAuth()
  const email = authUser?.attributes?.email
  const isEmailVerified =
    type === `u/subscriber` && authUser?.attributes?.email_verified
  const { score: profileCompletion, checklist } =
    calculateProfileCompletion(user, isEmailVerified) ?? {}
  const { fetchCustomAttribute } = useAuth()
  const { confirmUserAttribute, setSnackbarMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      confirmUserAttribute: dispatch.user.confirmUserAttribute,
      setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
    }),
  )

  useEffect(() => {
    if (impersonation) return
    if (!isEmailVerified && type === `u/subscriber`) {
      const emailVerifyPrompted = localStorage.getItem(`emailVerifyPrompted`)

      void fetchCustomAttribute(`custom:numOfLogins`).then((numLogins) => {
        const remainingLogins = 5 - Number(numLogins)

        if (emailVerifyPrompted && remainingLogins > 0) {
          const timeSincePrompt = dayjs().diff(
            dayjs(Number(emailVerifyPrompted)),
            `minute`,
          )
          if (timeSincePrompt < 24 * 60) return
        }

        setLogins(numLogins)
        setVerifyModalOpen(`prompt`)
        localStorage.setItem(`emailVerifyPrompted`, new Date().valueOf())
      })
    }
  }, [isEmailVerified, type])

  const handleVerifyClick = () => {
    setVerifyModalOpen(`verify`)
  }

  return (
    <StyledFormCard
      id="personalPanel"
      sx={sx}
      Icon={
        <AccountCircle
          sx={{ color: `personal.main`, filter: `brightness(0.85)` }}
        />
      }
      headerText="Personal"
      Action={() => (
        <IconButton color="primary" onClick={onAdd}>
          <Edit />
        </IconButton>
      )}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <LinearProgress
          color={
            profileCompletion <= 75
              ? profileCompletion <= 50
                ? `error`
                : `warning`
              : `success`
          }
          variant="determinate"
          value={profileCompletion}
          sx={{ flex: 1 }}
        />
        <Typography sx={{ fontSize: 11, marginLeft: 2, opacity: 0.6 }}>
          Your profile is {profileCompletion}% complete
        </Typography>
        <ProgressTooltip open={tooltipOpen} checklist={checklist}>
          <InfoOutlined
            sx={{ color: `info.main`, marginLeft: 1, width: 20, height: 20 }}
            onClick={() => setTooltipOpen(true)}
            onMouseOver={() => {
              setTooltipOpen(true)
            }}
            onMouseOut={() => {
              setTooltipOpen(false)
            }}
          />
        </ProgressTooltip>
      </Box>
      <Typography variant="h6">
        {firstName} {lastName}
      </Typography>

      <Box display="flex" alignItems="center">
        <ProfileValueDisplay
          label="Email:"
          value={user?.email}
          stacked={false}
          sx={{ mr: 2 }}
        />
        {!impersonation ? (
          <>
            {type === `u/subscriber` && !isSamlUser() ? (
              isEmailVerified ? (
                <GppGood sx={{ opacity: 0.54 }} />
              ) : (
                <StyledButton
                  variant="contained"
                  size="small"
                  onClick={handleVerifyClick}
                >
                  <InfoOutlined sx={{ mr: 1 }} /> Verify
                </StyledButton>
              )
            ) : null}
          </>
        ) : null}
      </Box>

      <ProfileValueDisplay
        label="DOB:"
        stacked={false}
        value={
          !!birthDate ? (
            <>
              {dayjs(birthDate, `YYYY-MM-DD`).format(`MM/DD/YYYY`)}
              {` `}({dayjs().diff(dayjs(birthDate, `YYYY-MM-DD`), `year`)})
            </>
          ) : null
        }
      />

      <ProfileValueDisplay
        label="Edited:"
        value={dayjs(user?.updated).format(`MM/DD/YYYY`)}
        stacked={false}
      />

      <StyledDivider />

      <ProfileValueDisplay
        label="Additional/Preferred Name"
        value={preferredName}
      />
      <ProfileValueDisplay label="Gender" value={gender} />
      <ProfileValueDisplay label="Language" value={lang} />

      <StyledDivider />

      <ProfileValueDisplay
        label="MOBILE Phone"
        value={parseNumber(phoneNumbers?.mobile || ``)}
      />
      <ProfileValueDisplay
        label="Address"
        value={
          address.street || address.city || address.state || address.zipCode
            ? () => (
                <>
                  {address.street ? `${address.street}, ` : ``}
                  {address.city ? `${address.city}, ` : ``}
                  {address.state} {address.zipCode}
                </>
              )
            : null
        }
      />

      <StyledDivider />

      <EmergencyContactsList user={user} />

      <StyledDivider />

      <ProfileValueDisplay
        label="Notes"
        value={user?.notesContact}
        sx={{ whiteSpace: `normal` }}
      />
      {!ttl && (
        <VerifyModal
          email={email}
          open={verifyModalOpen}
          numLogins={logins}
          updateEmail={async (email, code) => {
            await confirmUserAttribute({ userId, data: { email, code } })
            setVerifyModalOpen(undefined)
            refreshAuthData()
          }}
          setSnackbarMessage={setSnackbarMessage}
          onClose={() => setVerifyModalOpen(undefined)}
        />
      )}
    </StyledFormCard>
  )
}
