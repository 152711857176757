import { DatepickerInput } from "@erinfo/brand-ui/src/storybook/inputs/datepicker"
import { MedicalSelectInput } from "@erinfo/brand-ui/src/storybook/inputs/medical-select"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { medicalDevices } from "@erinfo/data-schema"
import {
  filterValues,
  useHandleNoneSelection,
} from "@erinfo/react-utils/src/helpers/form/formUtils"
import { Add, Delete } from "@mui/icons-material"
import { Box, Button, Divider } from "@mui/material"
import { Field } from "formik"
import { Fragment } from "react"

export const medicalDevicesFormFields = [
  `medicalDevice`,
  `serialNumber`,
  `implantedDate`,
]

export const MedicalDevicesForm = ({
  addField,
  removeField,
  setFieldValue,
  setFieldValueOrRemove,
  values,
  fieldCounts,
}) => {
  const hideDelete =
    !values?.medicalDevice_0 &&
    !values?.serialNumber_0 &&
    !values?.implantedDate_0

  useHandleNoneSelection({
    formType: `medicalDevices`,
    formFields: medicalDevicesFormFields,
    values,
    fieldCounts,
    removeField,
    setFieldValue,
  })

  return (
    <>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values?.medicalDevice_0}
        onClick={() => addField(`medicalDevices`, `medicalDevice`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.medicalDevices)]
        .map((_, i) => i)
        .reverse()
        .map((i, idx) => (
          <Fragment key={`medicalDevice_${i}`}>
            {i < fieldCounts.medicalDevices - 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
            <Field
              label="Select Medical Device"
              name={`medicalDevice_${i}`}
              component={MedicalSelectInput}
              setFieldValue={setFieldValueOrRemove(setFieldValue, () =>
                removeField(`medicalDevices`, i),
              )}
              dataForMultiSelect={filterValues(
                `medicalDevice`,
                values,
                medicalDevices,
              )}
              style={{ minWidth: 200 }}
            />
            <Field
              label="Serial Number"
              name={`serialNumber_${i}`}
              component={TextInput}
              disabled={
                !values.medicalDevice_0 || values.medicalDevice_0 === `None`
              }
            />
            <Field
              label="Implant Date"
              name={`implantedDate_${i}`}
              component={DatepickerInput}
              InputLabelProps={{ shrink: true }}
              style={{ width: `100%` }}
              validate={(value) => {
                if (value && value.length !== 10) {
                  return `Invalid date`
                }
              }}
              disabled={
                !values.medicalDevice_0 || values.medicalDevice_0 === `None`
              }
            />
            {(idx !== 0 || !hideDelete) && (
              <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
                <Button
                  onClick={() => removeField(`medicalDevices`, i)}
                  color="error"
                  sx={{ mt: 1 }}
                >
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Medical Device
                </Button>
              </Box>
            )}
          </Fragment>
        ))}
    </>
  )
}
