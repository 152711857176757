import { tryCatchWrapper } from "@erinfo/react-utils/src/helpers/try-catch-wrapper"

import { uploadFile } from "../upload-file"
import * as errors from "./errors"

interface data {
  noFace: boolean
  isNotHuman: boolean
  hasBeenAdded: boolean
  hasMultipleFaces: boolean
  hasProtections: { face: boolean; head: boolean }
}

const statusIssue = 200
const setThrowMsg = (message: string | (() => JSX.Element)) => ({
  message,
  status: statusIssue,
})

/**
 * @todo remove httpClient with `fetch` for both consumer & provider
 */
export const validateFaceImg = (
  httpClient,
  noErrorIfAdded = false,
  forMember = false,
) =>
  tryCatchWrapper(async (dataURL: string): Promise<string | undefined> => {
    try {
      const {
        data: { req, created },
      } = await httpClient({
        method: `GET`,
        url: `/img`,
        path: `/img`,
        query: { type: `face` },
        signRequest: true,
      })
      await uploadFile({ dataURL, req })

      let url = `/img/${created}/face/check`
      if (forMember) {
        url = `${url}?member=1`
      }
      const resp = await httpClient({
        method: `GET`,
        url,
        path: url,
        signRequest: true,
      })
      const issues = resp.data as data

      if (issues) {
        if (issues.noFace) throw setThrowMsg(errors.hasNoFace)
        if (issues.isNotHuman) throw setThrowMsg(errors.hasNoFace)
        if (issues.hasBeenAdded && !noErrorIfAdded)
          throw setThrowMsg(errors.duplicateImageErrorText)
        if (issues.hasMultipleFaces) throw setThrowMsg(errors.hasMultipleFaces)
        if (issues.hasProtections) {
          if (issues.hasProtections.face)
            throw setThrowMsg(errors.hasProtectionsFace)
          if (issues.hasProtections.head)
            throw setThrowMsg(errors.hasProtectionsHead)
        }
        if (
          Object.keys(issues)?.length > 0 &&
          !(issues.hasBeenAdded && noErrorIfAdded)
        ) {
          throw setThrowMsg(errors.hasNoFace)
        }
      }

      return created
    } catch (error) {
      console.log(`There was a face validation error.`, error)
      const { message, status } = error
      if (status === statusIssue) {
        throw { message, status }
      }

      if (message && message === `Network Error`) {
        throw { message: errors.badImageErrorText, status: 502 }
      }
      throw setThrowMsg(errors.hasNoFace)
    }
  })
