import { Auth } from "@aws-amplify/auth"

export const getUserSession = async () => {
  const session = await Auth.currentSession()
  const idToken = session.getIdToken()

  return idToken
}

export const getUserCredentials = async () => {
  const user = await Auth.currentCredentials()
  console.log(`TCL: getUserCredentials -> user`, user)

  return {
    accessKeyId: user.accessKeyId,
    secretAccessKey: user.secretAccessKey,
    sessionToken: user.sessionToken,
  }
}
export const authSignIn = async (
  email,
  password,
  skipLoginCounter?: boolean,
) => {
  console.log(`email: `, email)
  console.log(`password: `, password)

  try {
    console.log(`====================================`)
    console.log(`will request cognito`)
    console.log(`====================================`)
    const cognitoUser = await Auth.signIn(email, password)
    console.log(`TCL: authSignIn -> cognitoUser`, cognitoUser)

    const userGroup =
      cognitoUser.signInUserSession.idToken.payload[`cognito:groups`]
    console.log(`​authSignIn -> userGroup`, userGroup)

    if (!skipLoginCounter) {
      try {
        const loginCount = Number(
          cognitoUser.attributes[`custom:numOfLogins`] ?? 0,
        )
        const newLoginCount = (loginCount + 1).toString()
        await Auth.updateUserAttributes(cognitoUser, {
          "custom:numOfLogins": newLoginCount,
        })
        cognitoUser.attributes[`custom:numOfLogins`] = newLoginCount
        console.log(`Login Count: `, newLoginCount)
      } catch (err) {
        console.log(`Error updating number of logins.`)
      }
    }
    return cognitoUser
  } catch (err) {
    console.log(`==============`)
    console.log(`ERROR IN COGNITO\n`, err)
    console.log(`==============`)

    const { message } = err

    throw {
      response: {
        error: true,
        status: `auth`,
        message,
      },
    }
  }
}

export const getUserInfo = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser()

    const { keyPrefix, username } = user
    const userDataPath = `${keyPrefix}.${username}.userData`
    const userData = user.storage[userDataPath]

    const parsedUserData = JSON.parse(userData)

    const userProfileData: any = {}
    const { UserAttributes } = parsedUserData

    for (const userAttribute of UserAttributes) {
      Object.assign(userProfileData, {
        [userAttribute.Name]: userAttribute.Value,
      })
    }

    return userProfileData
  } catch (err) {
    console.log(`==============`)
    console.log(`err`, err)
  }
}

export const authSignOut = async () => {
  try {
    await Auth.signOut()

    // set(props.store.User, "isAuthenticated", false)
  } catch (err) {
    console.log(`==============`)
    console.log(`err`, err)
    const { message } = err

    throw {
      response: {
        error: true,
        status: `auth`,
        message,
      },
    }
  }
}

export const authSignUp = async (email, password, phoneNumber) => {
  console.log(`email: `, email)
  console.log(`password: `, password)
  console.log(`phoneNumber`, phoneNumber)
  const randomString = (() => {
    const timestamp = Date.now()
    const random13Letters = () => {
      return Math.random().toString(36).substring(2, 15)
    }
    return `${timestamp}${random13Letters() + random13Letters()}`
  })()

  try {
    const data = await Auth.signUp({
      username: randomString,
      password,
      attributes: {
        email,
        phone_number: phoneNumber,
        /**
         * @IMPORTANT we cannot set "preferred_username" on SignUp
         *   - we add it upon user changes own email
         */
        // preferred_username: "DO NOT UNCOMMENT",
      },
    })
    return data
  } catch (err) {
    console.log(`==============`)
    console.log(`err`, err)
    const { message } = err
    throw { error: true, message }
  }
}

export const updateUserData = async (updatedValues) => {
  try {
    const userData = await Auth.currentAuthenticatedUser()
    console.log(`TCL: updateUserData -> userData`, userData)

    const updateUserData = await Auth.updateUserAttributes(
      userData,
      updatedValues,
    )
    console.log(`==============`)
    console.log(`updateUserData`, updateUserData)
    const updatedUserData = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    console.log(`==============`)
    console.log(`updatedUserData`, updatedUserData)
  } catch (err) {
    console.log(`==============`)
    console.log(`err`, err)
    const { message } = err
    throw { error: true, message }
  }
}

/**
 * Change user password
 * @param oldPwd old password
 * @param newPwd new password
 */
export const changePwd = async (oldPwd: string, newPwd: string) => {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const data = await Auth.changePassword(user, oldPwd, newPwd)
    console.log(`==============`)
    console.log(`data`, data)
  } catch (err) {
    console.log(`==============`)
    console.log(`err`, err)
    throw err
  }
}

export const verifyUserAttribute = async (
  attribute: "phone_number" | "email",
) => {
  try {
    const data = await Auth.verifyCurrentUserAttribute(attribute)

    return data
  } catch (err) {
    const { message } = err

    throw { message }
  }
}

export const verifyUserAttributeWithCode = async (
  attribute: string,
  code: string,
) => {
  try {
    const data = await Auth.verifyCurrentUserAttributeSubmit(attribute, code)

    return data
  } catch (err) {
    console.log(err)
    return err
  }
}

export const forgotPassword = async (username: string) => {
  console.log(`TCL: resetPassword -> username`, username)
  return Auth.forgotPassword(username)
}

export const forgotPasswordSubmit = async (
  username: string,
  code: string,
  newPassword: string,
  options?: any,
) => {
  return Auth.forgotPasswordSubmit(username, code, newPassword, options)
}
