import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { SxProps } from "@mui/system"
import { forwardRef } from "react"

interface IProps {
  label?: string
  options: { label: string; value: any; disabled: boolean }[]
  defaultValue?: any
  onChange: (val: any) => void
  value?: any
  errors?: string[]
  row?: boolean
  disabled?: boolean
  sx?: SxProps
}

export const RadioButtonGroup = forwardRef(
  ({
    row,
    label,
    options = [],
    defaultValue,
    onChange,
    value,
    errors,
    disabled,
    sx,
  }: IProps) => {
    const errorColor = { color: errors ? `error.main` : `default.main` }

    return (
      <FormControl component="div" sx={{ mt: 2, ...sx }} error={!!errors}>
        <FormLabel component="legend" color="primary">
          {label}
        </FormLabel>
        <RadioGroup
          row={row}
          aria-label={label}
          name="radio-buttons-group"
          defaultValue={defaultValue}
          onChange={onChange}
          value={value}
        >
          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio sx={errorColor} />}
              label={option.label}
              disabled={option.disabled || disabled}
              sx={errorColor}
            />
          ))}
        </RadioGroup>
        {!!errors && <FormHelperText>Required</FormHelperText>}
      </FormControl>
    )
  },
)
