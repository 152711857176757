import { Footer } from "@erinfo/brand-ui/src/storybook/other/footer"
import { HeaderWithLeftLogo } from "@erinfo/brand-ui/src/storybook/other/header/with-left-logo"
import Notifications from "@erinfo/consumer/src/components/Notifications"
import { Box, CssBaseline, Hidden } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FC } from "react"
import { Outlet } from "react-router-dom"

const Root = styled(`div`)({
  margin: `0 auto`,
  display: `block`,
  overflowY: `auto`,
  minWidth: `100vw`,
  position: `relative`,
  "@media (min-width: 480px)": {
    minHeight: `100vh`,
    footer: {
      position: `absolute`,
      bottom: `0`,
      padding: `8px 0`,
    },
  },
  "@media (max-width: 480px)": {
    justifyContent: `flex-start`,
    overflowY: `auto`,
    height: `100%`,
  },
})

const Header = styled(HeaderWithLeftLogo)({
  "&&": {
    marginBottom: 10,
  },
})

const Wrapper = styled(`div`)((props) => ({
  background: `white`,
  padding: props.theme.spacing(3),
  display: `flex`,
  "@media (max-width: 480px)": {
    height: `100%`,
  },
  "@media (min-width: 480px)": {
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 120,
  },
  flex: 1,
}))

const DynamicFooter = styled(Footer)({
  "&&&": {
    position: `unset`,
    bottom: `unset`,
    marginTop: 14,
  },
})

const Auth: FC = ({ children }) => {
  return (
    <Root sx={{ backgroundColor: `#E2E2E2` }}>
      <CssBaseline />
      <Header />
      <Wrapper
        style={{
          marginBottom: 0,
          padding: 0,
          background: `transparent`,
          width: `100%`,
          overflowY: `auto`,
          flexDirection: `column`,
          minHeight: `calc(100vh - 67px)`,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: `flex`,
            width: `100%`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
        >
          {children}
          <Outlet />
        </Box>
        <Hidden smDown>
          <DynamicFooter />
        </Hidden>
      </Wrapper>
      <Notifications />
    </Root>
  )
}

export default Auth
