import * as env from "@erinfo/env"

export const tryCatchWrapper =
  (func, errorHandler?, contextData?) =>
  async (...args) => {
    try {
      const result = await func(...args)
      return result
    } catch (error) {
      try {
        void env.newrelic.browser.helpers.withNewRelic((nr) => {
          nr.noticeError(error, contextData)
        })
      } catch {}

      if (errorHandler) await errorHandler(error)
      throw error
    }
  }
