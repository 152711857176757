import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import { Breakpoint } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { FC, ReactNode } from "react"

export const BaseDialog: FC<{
  Icon?: ReactNode
  title?: string
  open: boolean
  handleClose: () => void
  handleSubmit?: () => Promise<void> | void
  renderActions?: () => ReactNode
  maxWidth?: Breakpoint
  formId?: string
  loading?: boolean
  dialogSx?: SxProps
  titleSx?: SxProps
  contentSx?: SxProps | ((theme: any) => SxProps)
  actionsSx?: SxProps
  showCloseButton?: boolean
}> = ({
  Icon,
  title,
  open,
  handleClose,
  handleSubmit,
  renderActions,
  maxWidth = `xs`,
  formId,
  children,
  dialogSx,
  titleSx,
  contentSx,
  actionsSx,
  loading,
  showCloseButton = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      sx={{
        zIndex: 999,
        "& .MuiDialog-paper": {
          margin: 0,
          width: `calc(100% - 32px)`,
        },
        ...dialogSx,
      }}
    >
      <DialogTitle sx={{ borderBottom: `1px solid #e2e2e2`, ...titleSx }}>
        <Box display="flex" alignItems="center">
          {Icon} {title}
        </Box>
        {showCloseButton && (
          <IconButton
            sx={{ position: `absolute`, top: 5, right: 5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent
        {...(typeof contentSx === `function`
          ? { sx: contentSx }
          : { sx: { paddingLeft: 0, paddingRight: 0, ...contentSx } })}
      >
        {children}
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid #e2e2e2`, ...actionsSx }}>
        {renderActions ? (
          renderActions()
        ) : (
          <>
            <StyledButton onClick={handleClose}>Cancel</StyledButton>
            <StyledButton
              type="submit"
              form={formId}
              loading={loading}
              {...(handleSubmit && { onClick: handleSubmit })}
            >
              Save Changes
            </StyledButton>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
