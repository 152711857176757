import TextField from "@mui/material/TextField"
import { forwardRef, useCallback } from "react"
import MaskedInput from "react-text-mask"

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const setRef = useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null

      if (typeof ref === `function`) {
        ref(value)
      } else if (ref) {
        ref.current = value
      }
    },
    [ref],
  )

  return (
    <MaskedInput
      {...props}
      ref={setRef}
      mask={props.mask}
      placeholderChar={`\u2000`}
      showMask
    />
  )
})

interface IProps {
  value?: string
  label?: string
  mask: any[]
  className?: string
  inputId?: string
  type?: string
  errors?: string
  disabled?: boolean
  required?: boolean
  hideAsterisk?: boolean
  maxLength?: number
  onChange?: () => void
  onBlur?: () => void
  name: string
  removeMaskedChar: string
  setFieldValue: (name: string, value: string) => void
}

export const TextfieldMasked = (props: IProps) => {
  const className = ((className = ``) => {
    if (props.className) className += ` ${props.className}`
    return className
  })()

  const { removeMaskedChar, setFieldValue, inputId, ...textFieldProps } = props

  return (
    <TextField
      {...textFieldProps}
      sx={{
        marginBottom: 1,
        "& .MuiFormLabel-asterisk": {
          color: !props.hideAsterisk ? `error.main` : `transparent`,
        },
        "& .MuiFormHelperText-root": {
          fontSize: "0.9rem",
        },
      }}
      fullWidth
      id={props.inputId}
      className={className}
      variant="standard"
      label={props.label}
      value={props.value}
      onChange={(e) =>
        props.setFieldValue(
          props.name,
          e.target.value?.replace(props.removeMaskedChar || ``, ``),
        )
      }
      InputLabelProps={{ shrink: true, style: { padding: `0 15px` } }}
      InputProps={{
        inputComponent: TextMaskCustom,
        style: { padding: `0 15px` },
      }}
      inputProps={{ mask: props.mask }}
      error={!!props.errors}
      helperText={props.errors}
    />
  )
}
