export const dataRace = [
  { title: ``, value: `` },
  {
    title: `American Indian or Alaska Native`,
    value: `American Indian or Alaska Native`,
  },
  { title: `Asian`, value: `Asian` },
  { title: `Black or African American`, value: `Black or African American` },
  {
    title: `Native Hawaiian or Other Pacific Islander`,
    value: `Native Hawaiian or Other Pacific Islander`,
  },
  { title: `White`, value: `White` },
  { title: `Two or More Races`, value: `Two or More Races` },
]
