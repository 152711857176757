import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as z from "zod"

export const nameMedications = `medications`

export const medication = z
  .object({
    [attr.nameTitle]: attr.titleOptional,
    [attr.nameDosage]: attr.dosageOptional,
    [attr.nameFrequency]: attr.frequencyOptional,
  })
  .strict()
export type medication = z.infer<typeof medication>

export const medications = z.array(medication)
export const medicationsOptional = medications.optional()

export const medicationsPut = helpers.setPutItem(medication)
export const medicationsPutOptional = medicationsPut.optional()
