import * as api from "@erinfo/consumer/src/data"
import * as schema from "@erinfo/data-schema"
import { sourceTypeConsumer } from "@erinfo/data-schema"
import * as env from "@erinfo/env"
import { authSignIn } from "@erinfo/react-utils/src/helpers/amplify"
import { createUserData } from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { tryCatchWrapper } from "@erinfo/react-utils/src/helpers/try-catch-wrapper"
import { createModel } from "@rematch/core"
import deepmerge from "deepmerge"
import { StatusCodes } from "http-status-codes"

import type { RootModel } from "./index"

declare global {
  interface Window {
    clarity: any
  }
}

const initialState: UserState = {
  fetching: true,
  error: null,
  isFirstTimeLogin: true,
  id: ``,
  email: ``,
  emergencyContacts: {},
  identifyingInformation: {},
  identifyingMarks: [],
  medicalData: {},
  enrolledPatients: [],
  pictures: [],
  otherPictures: [],
  documents: [],
  paymentPlan: {},
  profile: {},
  impersonation: {
    adminEmail: ``,
  },
  updated: 0,
  members: [],
  notesContact: ``,
}

interface Picture {
  [schema.nameFaceId]: string
  created: string
  src: string
}

interface AddPicPayload {
  userId: string
  picture: Picture
}
interface RemovePicPayload {
  userId: string
  faceId: string
}
interface AddPicPayload {
  userId: string
  picture: Picture
}
interface RemovePicPayload {
  userId: string
  faceId: string
}

interface Impersonation {
  adminEmail: string
}

interface PlanDetails {
  expire: number
  name: string
  description: string
  members: string
  canceled_at?: number
}
interface PaymentPlan {
  active?: PlanDetails
}

interface EmergencyContacts {
  [id: string]: schema.collection.emergencyContact
}

interface MedicalDataSubmission {
  userId: string
  userData: schema.collection.medicalData
}

export interface UserState {
  error?: { status: number } | null
  id: string
  idCustomer?: string
  email: string
  fetching?: boolean
  isFirstTimeLogin?: boolean
  termsAccepted?: string
  profile: schema.collection.profile
  emergencyContacts: EmergencyContacts
  identifyingInformation?: schema.collection.identifyingInformation
  identifyingMarks?: schema.collection.identifyingMarks
  medicalData?: schema.collection.medicalData
  enrolledPatients?: schema.collection.enrolledPatient[]
  documents?: schema.collection.documents
  directives?: schema.collection.directives
  pictures: Picture[]
  otherPictures: { created: number; src: string }[]
  impersonation?: Impersonation
  paymentPlan?: PaymentPlan
  updated: number
  members?: DataSchema.user.post[]
  notesContact?: string
  notes?: string
  notesMedical?: string
  type?: schema.userTypesAll
  pk?: string
  sk?: string
}

export const user = createModel<RootModel>()({
  state: initialState,
  reducers: {
    clear: (): UserState => initialState,
    update: (state: UserState, payload: DataSchema.user.post): UserState => ({
      members: state.members,
      ...payload,
      fetching: false,
    }),
    merge: (state: UserState, payload: DataSchema.user.post): UserState => ({
      ...state,
      ...payload,
      fetching: false,
    }),
    addPicture: (state: UserState, payload: AddPicPayload): UserState => {
      const { userId, picture } = payload
      if (userId === state.id) {
        return {
          ...state,
          pictures: [...state.pictures, picture],
        }
      }
      const member = state?.members?.find((m) => m.id === userId)
      if (member) {
        return {
          ...state,
          members: state.members?.map((m) =>
            m.id === userId
              ? { ...m, pictures: [...(m?.pictures || []), picture] }
              : m,
          ),
        }
      }
      return state
    },
    removePicture: (state: UserState, payload: RemovePicPayload): UserState => {
      const { userId, faceId } = payload
      if (userId === state.id) {
        return {
          ...state,
          pictures: state.pictures.filter((p) => p.faceId !== faceId),
        }
      }
      const member = state?.members?.find((m) => m.id === userId)
      if (member) {
        return {
          ...state,
          members: state.members?.map((m) =>
            m.id === userId
              ? {
                  ...m,
                  pictures: m?.pictures?.filter((p) => p.faceId !== faceId),
                }
              : m,
          ),
        }
      }
      return state
    },
    addUserEmergencyContact: (
      state: UserState,
      payload: object,
    ): UserState => ({
      ...state,
      emergencyContacts: {
        ...state.emergencyContacts,
        ...payload,
      },
    }),
    addMedicalDataItems: (
      state: UserState,
      payload: MedicalDataSubmission,
    ): UserState => {
      const { userId } = payload
      if (userId === state.id) {
        return deepmerge(state, payload)
      }
      const member = state?.members?.find((m) => m.id === userId)
      if (member) {
        return {
          ...state,
          members: state.members?.map((m) =>
            m.id === userId ? deepmerge(m, payload) : m,
          ),
        }
      }
      return state
    },
    addUserEnrolledMember: (
      state: UserState,
      payload: schema.collection.enrolledPatient,
    ): UserState => ({
      ...state,
      enrolledPatients: [...(state.enrolledPatients || []), payload],
    }),
    addUserMember: (
      state: UserState,
      payload: DataSchema.user.post,
    ): UserState => ({
      ...state,
      members: [...(state.members || []), payload],
    }),
    updateUserMember: (
      state: UserState,
      payload: DataSchema.user.id.put,
    ): UserState => ({
      ...state,
      members: state.members?.map((member) =>
        member.id === payload.id ? payload : member,
      ),
    }),
    mergeUserMember: (
      state: UserState,
      payload: DataSchema.user.id.put,
    ): UserState => ({
      ...state,
      members: state.members?.map((member) =>
        member.id === payload.id ? { ...member, ...payload } : member,
      ),
    }),
    removeUserEnrolledMembers: (
      state: UserState,
      payload: [string],
    ): UserState => ({
      ...state,
      members: state.members?.filter((p) => !payload.includes(p.id)),
      enrolledPatients: state.enrolledPatients?.filter(
        (p) => !payload.includes(p.userID),
      ),
    }),
    setFetching: (state: UserState): UserState => ({
      ...state,
      fetching: true,
    }),
  },
  effects: (dispatch) => ({
    create: tryCatchWrapper(async (values, state) => {
      values.email = values.email.toLowerCase().trim()
      values.coupon = state.signUp.registerCode
      values.utmData = state.signUp.utmData

      if (window.clarity) {
        window.clarity(`set`, `email`, values.email)
        window.clarity(`set`, `mobile`, values.mobile)
      }

      const data = createUserData(values)
      const { password, ...safeData } = data

      return tryCatchWrapper(
        async () => {
          await api.createUser(data)
          await authSignIn(values.email, values.password)
          dispatch.user.update(data)
        },
        async (err) => {
          const text = err.text
            ? await err.text()
            : err.response?.message ?? `An unexpected error occurred`
          if (err.status === StatusCodes.CONFLICT) {
            throw new Error(
              `This email is already in use.  Please try to sign in instead.`,
            )
          }
          throw new Error(text)
        },
        safeData,
      )()
    }),

    fetchUserData: tryCatchWrapper(
      async ({
        email,
        id,
        impersonatingAdmin,
      }: {
        email?: string
        id?: string
        impersonatingAdmin?: string
      }) => {
        dispatch.user.setFetching()
        let user: DataSchema.user.post

        if (email) {
          user = await api.getUserByEmail(email)
        } else if (id) {
          user = await api.getUserById(id)
        } else {
          throw new Error(`Need either email or ID to fetch user`)
        }

        if (user.isProvider) {
          dispatch.notifications.setDialogMessage({
            msg: `Please use ERinfoPRO. This app is for members`,
          })
        }
        const memberPromises: Promise<{}>[] = []
        for (const member of user?.enrolledPatients || []) {
          if (!member.status || !member.status.includes(`archived`)) {
            memberPromises.push(api.getUserById(member.userID))
          }
        }
        user.members = await Promise.all(memberPromises)

        await env.newrelic.browser.helpers.addUserDataToSession({
          id: user.id,
          email: user.email,
          phone: user.profile?.phoneNumbers?.mobile,
        })
        if (impersonatingAdmin) {
          user.impersonation = {
            adminEmail: impersonatingAdmin,
          }
        }
        dispatch.user.update(user)
        return user
      },
    ),

    storeUserFacePicture: tryCatchWrapper(async ({ userId, picture }) => {
      const result = await api.createUserFace({
        userId,
        created: picture.created,
        dataUrl: picture.value,
      })

      if (result?.[schema.nameFaceId]) {
        dispatch.user.addPicture({
          userId,
          picture: {
            [schema.nameFaceId]: result[schema.nameFaceId],
            created: result.created,
            src: result.src,
          },
        })
      }
    }),

    deleteUserFacePicture: tryCatchWrapper(async ({ userId, faceId }) => {
      await api.deleteFacePicture(userId, faceId)
      dispatch.user.removePicture({ userId, faceId })
    }),

    updateUser: tryCatchWrapper(async ({ userId, data }, state) => {
      try {
        const resp = await api.updateUser(userId, data)
        console.log(`TCL: onFormSubmit -> resp`, resp)
        if (userId) {
          const user = await api.getUserById(userId)
          if (state.user.id === userId) {
            dispatch.user.update(user)
          } else {
            dispatch.user.updateUserMember(user)
          }
        }
      } catch (error) {
        if (error.message) {
          dispatch.notifications.setDialogMessage({
            msg: error.message,
          })
        }
        throw error
      }
    }),

    applyCoupon: tryCatchWrapper(async ({ coupon }) => {
      try {
        const resp = await api.applyCoupon(coupon)
        dispatch.user.update(resp.user)
        dispatch.notifications.setSnackbarMessage(`Coupon applied successfully`)
      } catch (error) {
        if (
          [StatusCodes.NOT_FOUND, StatusCodes.BAD_REQUEST].includes(
            error.statusCode,
          )
        ) {
          dispatch.notifications.setSnackbarMessage({
            msg: `Invalid Coupon`,
            severity: `error`,
          })
        } else {
          dispatch.notifications.setSnackbarMessage({
            msg: `Unable to apply coupon code`,
            severity: `error`,
          })
        }
        throw new Error(`Applying coupon failed`)
      }
    }),

    confirmUserAttribute: tryCatchWrapper(async ({ userId, data }, state) => {
      if (state.user.id !== userId)
        throw new Error(`Only subscriber attributes can be confirmed.`)
      await api.confirmAttribute(userId, data)
      const user = await api.getUserById(userId)
      dispatch.user.update(user)
    }),

    addMedicalData: tryCatchWrapper(
      async ({ userId, userData }: MedicalDataSubmission) => {
        const data: DataSchema.user.id.put = {
          medicalData: userData.medicalData,
        }

        console.log(`data`, data)

        const resp = await api.updateUser(userId, data)
        console.log(`TCL: submit -> resp`, resp)
        dispatch.user.addMedicalDataItems({ userId, ...data })
      },
    ),

    storeDocuments: tryCatchWrapper(async ({ userId, data }, state) => {
      data.documents = await Promise.all(
        data.documents.map(async (document) => {
          document.images = await Promise.all(
            document.images.map((image) =>
              image.startsWith(`data:image/png`)
                ? api.addOtherPicture(userId, image)
                : image.split(`?`)[0],
            ),
          )
          return document
        }),
      )
      await api.updateUser(userId, data)
      if (state.user.id === userId) {
        dispatch.user.merge(data)
        void dispatch.user.fetchUserData({ id: userId })
      } else {
        dispatch.user.mergeUserMember({ id: userId, ...data })
        const updatedMember = await api.getUserById(userId)
        dispatch.user.updateUserMember(updatedMember)
      }
      dispatch.notifications.setSnackbarMessage(`Changes saved`)
    }),

    createNewMember: tryCatchWrapper(
      async (params: {
        userId: string
        data: DataSchema.user.post
        created: string
      }) => {
        const { userId, data, created } = params
        const newUserId = await api.createUser({
          ...data,
          addedBy: {
            userID: userId,
            source_type: sourceTypeConsumer,
          },
        })
        await api.createUserFace({ userId: newUserId, created })

        const val = { userID: newUserId, date: Date.now() }
        dispatch.user.addUserEnrolledMember(val)

        const memberDetail = await api.getUserById(newUserId)
        dispatch.user.addUserMember(memberDetail)
        dispatch.notifications.setSnackbarMessage(`You have added a new member`)
      },
    ),

    archiveMember: tryCatchWrapper(async ({ memberId }) => {
      await api.archiveMember(memberId)
      dispatch.user.removeUserEnrolledMembers([memberId])
    }),

    deleteUser: tryCatchWrapper(async () => {
      console.log(`deleting user account`)
      await api.deleteUser()
      dispatch.notifications.setSnackbarMessage(`Account successfully deleted.`)
      console.log(`user account deleted`)
    }),

    purgeProfile: tryCatchWrapper(async (purge: boolean, state) => {
      console.log(`purging user profile`)
      await api.purgeProfile(purge)
      if (purge) {
        dispatch.notifications.setSnackbarMessage(
          `Profile successfully purged.`,
        )
      } else {
        dispatch.notifications.setSnackbarMessage(
          `Profile successfully restored.`,
        )
      }
      console.log(`user profile purged`)
      void dispatch.user.fetchUserData({ id: state.user.id })
    }),
  }),
})
