import * as z from "zod"

export const setPutItem = <I extends z.ZodTypeAny>(item: I) => {
  const putItem1 = z
    .object({ idx: z.number(), val: z.union([item, z.literal(``)]) })
    .strict()
  const putItem2 = z
    .object({ idx: z.literal(``), val: z.union([z.array(item), item]) })
    .strict()

  return z.union([
    z.array(z.union([putItem1, putItem2])), // to support lists' in-place updates
    z.array(item), // to replace an array
  ])
}
