import * as React from "react"

export const badImageErrorText = () => (
  <>
    <p>Image did not work. Please try again.</p>
    <br />
    <p>
      Select an image with:
      <ul>
        <li>Good contrast</li>
        <li>Your face clearly visible</li>
        <li>No other faces present</li>
      </ul>
    </p>
    <p>Please use the crop tool if necessary.</p>
  </>
)

export const duplicateImageErrorText = () => (
  <div style={{ textAlign: `center` }}>
    <p>Possible duplicate photo.</p>
    <br />
    <p style={{ color: `rgba(0, 0, 0, 0.6)` }}>
      Please{` `}
      <a href="https://erinfo.me/contact" target="_blank">
        contact us
      </a>
      {` `}
      to resolve this issue.{` `}
    </p>
  </div>
)

export const hasMultipleFaces = () => (
  <>
    <p>Image did not work. Please try again.</p>
    <p>We detected multiple faces in the Image.</p>
    <br />
    <p>
      Select an image with:
      <ul>
        <li>Good contrast</li>
        <li>Your face clearly visible</li>
        <li>No other faces present</li>
        <li>Please use the crop tool if necessary.</li>
      </ul>
    </p>
  </>
)

export const hasProtectionsFace = () => (
  <p>Should not have Face protective elements</p>
)

export const hasProtectionsHead = () => (
  <p>Should not have Head protective elements</p>
)

export const hasNoFace = () => (
  <>
    <p>Image did not work. Please try again.</p>
    <p>We could not detect a face in the Image.</p>
    <br />
    <p>
      Select an image with:
      <ul>
        <li>Good contrast</li>
        <li>Your face clearly visible</li>
        <li>No other faces present</li>
        <li>Please use the crop tool if necessary.</li>
      </ul>
    </p>
  </>
)
