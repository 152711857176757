import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { helpers } from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import { Box } from "@mui/material"
import { Field } from "formik"

const { formikOptionalPhoneValidation } = helpers

export const PreferredTreatmentForm = () => {
  return (
    <Box p={3}>
      <Field
        name={attr.namePreferredTreatmentHospital}
        label="Preferred Treatment Hospital"
        component={TextInput}
      />
      <Field
        name={attr.namePrimaryCarePhysician}
        label="Primary Care Physician"
        component={TextInput}
        style={{ marginRight: 10 }}
      />

      <Field
        component={PhoneInput}
        fullWidth
        name={attr.namePrimaryCarePhysicianPhone}
        label="Phone"
        type="tel"
        validate={formikOptionalPhoneValidation}
      />
    </Box>
  )
}
