import { loadNewRelicAgent } from "./load-new-relic-agent"

/**
 * The way we access New Relic is designed to fail
 *   @see loadNewRelicAgent & the way this helper is used for more info
 *   - in short, even if New Relic is unavailable, the outer scope (app) would continue to operate
 */
export const getNewRelicAgent = async () => {
  if (!window.newrelic) await loadNewRelicAgent()
  if (!window.newrelic) throw new Error(`NewRelic agent is unavailable`)
  return window.newrelic
}
