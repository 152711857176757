import { createModel } from "@rematch/core"
import { ReactNode } from "react"

import type { RootModel } from "./index"

export interface DialogNotificationState {
  title?: string
  msg?: string
  onYes?: () => void
  onNo?: () => void
  altYesLabel?: string
  altNoLabel?: string
  altYesColor?: string
  altNoColor?: string
}

export interface SnackNotificationState {
  msg?: string | (() => ReactNode)
  severity?: "success" | "error"
}

export interface NotificationState {
  dialog: DialogNotificationState
  snackbar: SnackNotificationState
  settingsOpen: boolean
}

const initialStateDialog = {
  title: ``,
  msg: ``,
  onYes: undefined,
  onNo: undefined,
  altYesLabel: `OK`,
  altNoLabel: ``,
}

const initialState: NotificationState = {
  dialog: initialStateDialog,
  snackbar: {
    msg: ``,
  },
  settingsOpen: false,
}

export const notifications = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSnackbarMessage: (
      state: NotificationState,
      payload: string | SnackNotificationState,
    ): NotificationState => ({
      ...state,
      snackbar: typeof payload === `string` ? { msg: payload } : { ...payload },
    }),
    setDialogMessage: (
      state: NotificationState,
      payload: DialogNotificationState,
    ): NotificationState => ({
      ...state,
      dialog: {
        title: payload.title,
        msg: payload.msg,
        onYes: payload.onYes,
        onNo: payload.onNo,
        altYesLabel: payload.altYesLabel,
        altNoLabel: payload.altNoLabel,
        altYesColor: payload.altYesColor,
        altNoColor: payload.altNoColor,
      },
    }),
    toggleSettings: (
      state: NotificationState,
      payload: boolean,
    ): NotificationState => ({
      ...state,
      settingsOpen: payload,
    }),
  },
})
