import { getStripeCustomerPortalUrl } from "@erinfo/consumer/src/data"

export const onUpgrade = async (user) => {
  try {
    if (!user?.idCustomer) {
      return console.log(`Stripe customer ID not present`)
    }
    const url = await getStripeCustomerPortalUrl(user.idCustomer)
    window.location.href = url
  } catch (error) {}
}
