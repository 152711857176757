import { UserState } from "@erinfo/consumer/src/store/models/user"
import { parseNumber } from "@erinfo/react-utils/src/helpers/transform-text"
import { Star } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { orgEmergencyContacts } from "@erinfo/env/src/orgEmergencyContacts"

export const EmergencyContactsList: React.FC<{ user: UserState }> = ({
  user,
}) => {
  const contactArray = Object.values(user?.emergencyContacts || {})
  const showOrgEmergencyContact =
    user?.type === "u/orgMember" && user?.pk === "university-of-miami"
  const orgEmergencyContact = orgEmergencyContacts[user?.pk ?? ``] || {}

  return (
    <Box>
      <Typography variant="h7">Emergency Contacts</Typography>
      <Box pl={2}>
        {showOrgEmergencyContact && (
          <Box sx={{ position: `relative` }}>
            <Typography sx={{ opacity: 0.87, fontWeight: `500` }}>
              {orgEmergencyContact.name}
            </Typography>
            <Typography sx={{ opacity: 0.7 }}>
              Phone: {orgEmergencyContact.phoneDisplay}
            </Typography>
            <Typography sx={{ opacity: 0.7, mb: 1 }}>
              Email: {orgEmergencyContact.email}
            </Typography>
          </Box>
        )}
        {contactArray.map((contact) => (
          <Box sx={{ position: `relative` }} key={contact.email}>
            <Typography sx={{ opacity: 0.87, fontWeight: `500` }}>
              {contact.name}
            </Typography>
            <Typography sx={{ opacity: 0.7 }}>
              Phone: {parseNumber(contact?.phone ?? ``)}
            </Typography>
            <Typography sx={{ opacity: 0.7, mb: 1 }}>
              Email: {contact.email}
            </Typography>
            {contact.isPrimaryContact && (
              <Star
                color="warning"
                sx={{ position: `absolute`, right: 4, top: `calc(50% - 15px)` }}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
