import {
  ChangePhoneEmailModal,
  ModalState,
} from "@erinfo/brand-ui/src/storybook/notifications/dialogs/changePhoneEmail"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import DeleteIcon from "@mui/icons-material/DeleteOutline"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import SettingsIcon from "@mui/icons-material/Settings"
import { Box } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { DeleteAccountConfirmation } from "./DeleteAccountConf"

export const SettingsDialog = ({ open, handleClose }) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [deleteConfOpen, setDeleteConfOpen] = useState<
    "purge" | "delete" | false
  >(false)
  const [verifyModalOpen, setVerifyModalOpen] = useState<
    "email" | "phone" | undefined
  >()
  const { handleSignout, user: authUser, isSamlUser } = useAuth()
  const user = useSelector((state: RootState) => state.user)
  const email = user?.email
  const phone = user?.profile?.phoneNumbers?.mobile
  const { deleteUser, purgeProfile, confirmUserAttribute, setDialogMessage } =
    useRematchDispatch((dispatch: Dispatch) => ({
      deleteUser: dispatch.user.deleteUser,
      purgeProfile: dispatch.user.purgeProfile,
      confirmUserAttribute: dispatch.user.confirmUserAttribute,
      setDialogMessage: dispatch.notifications.setDialogMessage,
    }))

  const onDelete = async () => {
    setLoading(true)
    try {
      if (deleteConfOpen === `purge`) {
        await purgeProfile(true)
      } else if (deleteConfOpen === `delete`) {
        await deleteUser()
        await handleSignout()
        navigate(`/sign-up/step-1`)
      }
      setDeleteConfOpen(false)
      handleClose()
      setLoading(false)
    } catch (err) {
      console.log(err)
      setDialogMessage({
        title: `Unexpected Error`,
        msg: `An unexpected error occurred while trying to ${
          deleteConfOpen === "purge" ? "purge" : "delete"
        } the account.  Please contact us to resolve this issue.`,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        renderActions={() => (
          <Box
            sx={{
              width: `100%`,
              display: `flex`,
              justifyContent: `flex-end`,
              alignItems: `center`,
            }}
          >
            <StyledButton onClick={handleClose}>Close</StyledButton>
          </Box>
        )}
        Icon={<SettingsIcon sx={{ marginRight: 2 }} />}
        title="Settings"
        contentSx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-start`,
          pl: 2,
          minHeight: 100,
        }}
      >
        <StyledButton
          color="primary"
          onClick={() => setVerifyModalOpen(`phone`)}
          sx={{ mt: 1, mb: 1 }}
        >
          <PhoneIcon sx={{ mr: 1 }} /> Edit Mobile Phone Number
        </StyledButton>
        <StyledButton
          color="primary"
          onClick={() => setVerifyModalOpen(`email`)}
          sx={{ mb: 1 }}
          disabled={isSamlUser()}
        >
          <EmailIcon sx={{ mr: 1 }} /> Edit Email Address
          {isSamlUser() && ` (Contact SSO admin to modify)`}
        </StyledButton>
        <StyledButton
          color="error"
          onClick={() => setDeleteConfOpen(`purge`)}
          sx={{ mb: 1 }}
        >
          <DeleteIcon sx={{ mr: 1 }} /> Purge My Profile
        </StyledButton>
        {process.env.AWS_STAGE !== `production` && (
          <StyledButton
            color="error"
            onClick={() => setDeleteConfOpen(`delete`)}
            sx={{ mb: 1 }}
          >
            <DeleteIcon sx={{ mr: 1 }} /> Delete Account
          </StyledButton>
        )}
      </BaseDialog>
      <DeleteAccountConfirmation
        open={deleteConfOpen}
        handleClose={() => setDeleteConfOpen(false)}
        user={user}
        onDelete={onDelete}
        loading={loading}
      />
      <ChangePhoneEmailModal
        email={verifyModalOpen === `email` ? email : ``}
        phone={verifyModalOpen === `phone` ? phone : ``}
        open={!!verifyModalOpen}
        updateEmail={async (email, code) =>
          confirmUserAttribute({
            userId: user.id,
            data: { email, code },
          })
        }
        updatePhone={async (phone, code) =>
          confirmUserAttribute({
            userId: user.id,
            data: { phone, code },
          })
        }
        onClose={() => setVerifyModalOpen(undefined)}
      />
    </>
  )
}
