import * as consts from "../../consts"

/**
 * We load the New Relic agent directly, as the NPM package is marked as Experimental
 * @see https://www.npmjs.com/package/@newrelic/browser-agent
 */
const agentScriptUrl = `https://js-agent.newrelic.com/nr-loader-spa-current.min.js`

const scriptId = `erinfo-newrelic`

const getCodeSnippet = async () => {
  const response = await fetch(agentScriptUrl, { cache: `no-store` })
  if (response.ok) {
    const baseCode = await response.text()
    return `${baseCode};NREUM.info={applicationID:"${consts.applicationId}",licenseKey:"${consts.publicKeys.browser}"}`
  }
}

let isFailedToLoad = false
export const loadNewRelicAgent = async () => {
  if (process.env.NODE_ENV !== `production`) return

  try {
    const isCreated = document.head.querySelector(`script[id="${scriptId}"]`)
    if (!isCreated) {
      const scriptTag = document.createElement(`script`)

      const code = await getCodeSnippet()
      if (!code) {
        console.error(`NewRelic agent was not able to be retrieved`)
        isFailedToLoad = true
        return
      }

      scriptTag.type = `text/javascript`
      scriptTag.id = scriptId
      scriptTag.innerHTML = code

      document.head.appendChild(scriptTag)
      console.info(`NewRelic is active`)
      return
    }

    /**
     * In race conditions, the script tag may already be created, but the agent may not be loaded yet
     */
    if (!window.newrelic && !isFailedToLoad) {
      const intervalInMs = 10
      const secondsToWait = 30
      const maxAttempts = secondsToWait * (1000 / intervalInMs)
      let currentAttempt = 0
      const interval = setInterval(() => {
        const newrelic = window.newrelic
        if (newrelic) {
          clearInterval(interval)
          return
        }

        currentAttempt++
        if (currentAttempt >= maxAttempts) {
          clearInterval(interval)
          console.error(
            `NewRelic failed to load within ${secondsToWait} seconds`,
          )
        }
      }, intervalInMs)
    }
  } catch (error) {
    console.error(`NewRelic failed to load`)
  }
}
