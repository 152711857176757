import * as React from "react"
import { useLocation } from "react-router-dom"

import { loadJsConditionally } from "../helpers/load-js-conditionally"

declare global {
  interface Window {
    dataLayer: any[]
  }
}

const createPageTitle = ({ pathname, search }) => {
  const path =
    pathname === `/`
      ? `Login`
      : pathname
          .split(`/`)
          .filter((i) => i)
          .join(` / `)
  const params = search?.replace(`?`, ``).split(`&`).join(` and `)
  return search ? `${path} (with ${params})` : path
}

export const gtag: Gtag.Gtag = function () {
  if (!window.dataLayer) {
    window.dataLayer = [] /** @important GA script might not be loaded when for example "page_view" is trying to be recorded */
  }

  window.dataLayer.push(arguments)
}

export const gtagRecordPageView = (to: string, pageTitle?: string) => {
  /** @see https://developers.google.com/gtagjs/reference/event#page_view */
  gtag(`event`, `page_view`, { page_location: to, page_title: pageTitle })
}

export const gtagRecordSignUp = () =>
  gtag(`event`, `sign_up`, { method: `email` })

export const gtagRecordPayment = (params) => gtag(`event`, `purchase`, params)

export const gtagRecordEnrollment = () => gtag(`event`, `enrollment_complete`)

interface GoogleAnalytics {
  gaTrackingId: string
  gtmId: string
}
export const GoogleAnalytics = React.memo((props: GoogleAnalytics) => {
  const refLoaded = React.useRef(false)
  const location = useLocation()
  const pageTitle = createPageTitle(location)

  React.useEffect(() => {
    loadJsConditionally(
      `https://www.googletagmanager.com/gtag/js?id=${props.gaTrackingId}`,
      () => {
        window.dataLayer = window.dataLayer || []
        gtag(`js`, new Date())
        gtag(`config`, props.gaTrackingId)
        gtagRecordPageView(`${location.pathname}${location.search}`, pageTitle)
      },
      true,
    )
    loadJsConditionally(
      `https://www.googletagmanager.com/gtm.js?id=${props.gtmId}`,
      () => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: `gtm.js`,
          "gtm.start": new Date().getTime(),
          pageTitle,
        })
      },
      true,
    )
  }, [])

  React.useEffect(() => {
    if (!refLoaded.current) refLoaded.current = true
    else {
      const pageTitle = createPageTitle(location)

      gtagRecordPageView(`${location.pathname}${location.search}`, pageTitle)
      window.dataLayer.push({
        event: `virtualPageview`,
        pageUrl: `${location.pathname}${location.search}`,
        pageTitle,
      })
    }
  }, [location.pathname, location.search])

  return null
})
