import { AutocompleteSelect } from "./Autocomplete"

export const AutocompleteInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  setFieldValue,
  ...props
}) => {
  const { onBlur, name } = field
  const handleChange = (value) => {
    setFieldValue(name, value)
  }

  const handleBlur = () => {
    onBlur(name, true)
    setFieldTouched(field.name)
  }

  return (
    <AutocompleteSelect
      errors={(touched[field.name] || props.autoTouched) && errors[field.name]}
      inputId={name}
      {...field}
      {...props}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  )
}
