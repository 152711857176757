import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as z from "zod"

export const nameId = `id`
export const nameIdCustomer = `idCustomer`
export const nameIdUser = `userID`
export const nameIdProvider = `providerID`
export const nameIdU = `uid`

export const id = z.string()
export const idOptional = id.optional()

export const idCustomer = z.string()
export const idCustomerOptional = idCustomer.optional()

/**
 * Convert any unknown value to "id" value our services support
 *   - use it to convert an unknown value from a 3rd party
 *
 * @example AWS Rekognition supports only A-Za-z-_. and :
 *          but since we use a colon as a complex value separator, we cannot use a colon again in the id
 */
export const unknownToAWSSupportedId = (v: string | number) =>
  `${v}`.replace(/[^\w\-.]/g, ``)

/**
 * Join PK (organization identifier) and SK (identifier of a member within organization) into a composite id
 */
export const getCompositeId = (pk: string, sk: string) =>
  `${pk}:${unknownToAWSSupportedId(sk)}`

/**
 * Split a composite id into its parts
 */
export const getKeyFromCompositeId = (id: string) => {
  const [pk, sk] = id.split(`:`)
  return { pk, sk }
}

export const parseId = (id: string) => {
  const { pk, sk } = getKeyFromCompositeId(id)
  return {
    pk,
    sk,
    /**
     * If `pk` and `sk` are not the same, the user is added by someone else (provider, subscriber, organization)
     */
    isAdded: pk !== sk,
  }
}
