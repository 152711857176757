import { ReactComponent as IMarkHorizontalPro } from "@erinfo/brand-ui/src/assets/svg/logo-pro.svg"
import { ReactComponent as IMarkHorizontal } from "@erinfo/brand-ui/src/assets/svg/mark-horizontal.svg"
import { Typography, Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { UserState } from "@erinfo/consumer/src/store/models/user"
import logoU from "@erinfo/consumer/src/assets/logo-u.png"

const Logo = styled(IMarkHorizontal)({
  width: `121.22px`,
  height: `40px`,
})

const LogoPro = styled(IMarkHorizontalPro)({
  width: `150px`,
  height: `40px`,
})

const Link = styled(`a`)({
  paddingTop: `5px`,
  paddingLeft: `15px`,
})

interface WrapperProps {
  showHeaderInMobile?: boolean
}
const Wrapper = styled(`div`, {
  shouldForwardProp: (prop) => prop !== `showHeaderInMobile`,
})<WrapperProps>(({ showHeaderInMobile }) => ({
  boxSizing: `border-box`,
  display: `flex`,
  alignItems: `center`,
  "@media (min-width: 480px)": {
    justifyContent: `space-between`,
    position: `sticky`,
    top: 0,
    height: `56px`,
    zIndex: 1,
  },
  "@media (max-width: 480px)": {
    flexDirection: `column`,
    display: showHeaderInMobile ? `flex` : `none`,
  },
  width: `100%`,
  background: `#fff`,
  color: `rgba(0, 0, 0, 0.87)`,
  font: `500 20px/24px Roboto`,
  letterSpacing: `0.3px`,
  flexShrink: 0,
  boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
  paddingRight: 15,
}))

interface IProps {
  className?: string
  sx?: SxProps
  link?: string
  titleText?: string
  renderRightSlot?: () => React.ReactNode
  showHeaderInMobile?: boolean
  proLogo?: boolean
  inactive?: boolean
  user: UserState
}

export const HeaderWithLeftLogo = (props: IProps) => {
  const {
    titleText,
    renderRightSlot,
    showHeaderInMobile,
    link,
    className,
    proLogo,
    sx,
    user,
    inactive,
  } = props

  return (
    <Wrapper
      className={className}
      sx={sx}
      showHeaderInMobile={showHeaderInMobile}
    >
      <Box alignItems="center">
        <Link
          href={inactive ? `#` : link || `/`}
          sx={{ cursor: inactive ? `default` : `pointer` }}
        >
          {proLogo ? <LogoPro /> : <Logo />}
        </Link>
        {user?.pk === "university-of-miami" && (
          <img
            src={logoU}
            alt="University of Miami"
            style={{ width: 50, height: 50, marginLeft: 25 }}
          />
        )}
      </Box>
      {!!titleText && <Typography>{titleText}</Typography>}
      {!!renderRightSlot &&
        typeof renderRightSlot === `function` &&
        renderRightSlot()}
    </Wrapper>
  )
}
