import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { CheckboxInput } from "@erinfo/brand-ui/src/storybook/inputs/checkbox"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import {
  dataEyeColor,
  dataHairColor,
  dataHeightFeet,
  dataHeightInches,
  dataRace,
} from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import { Box } from "@mui/material"
import { Field } from "formik"

export const IdentifyingBaseForm = ({ setFieldValue }) => {
  return (
    <>
      <Box display="flex" alignItems="center">
        <Field
          autoTouched
          component={AutocompleteInput}
          name="heightFeet"
          label="Height (ft)"
          options={dataHeightFeet}
          setFieldValue={setFieldValue}
          sx={{ marginRight: 1 }}
          validate={(v) => (!v || v.match(/^\d?\d'.?$/) ? null : `Invalid`)}
        />
        <Field
          component={AutocompleteInput}
          name="heightInches"
          label="Height (in)"
          options={dataHeightInches}
          setFieldValue={setFieldValue}
          validate={(v) => (!v || v.match(/^(\d|1[01])"$/) ? null : `Invalid`)}
        />
      </Box>
      <Field name="weight" label="Weight (lb)" component={TextInput} />
      <Field
        component={AutocompleteInput}
        name={attr.nameRace}
        label="Race"
        options={dataRace}
        setFieldValue={setFieldValue}
      />
      <Field
        component={AutocompleteInput}
        name={attr.nameHairColor}
        label="Hair Color"
        options={dataHairColor}
        setFieldValue={setFieldValue}
      />
      <Field
        component={AutocompleteInput}
        name={attr.nameEyeColor}
        label="Eye Color"
        options={dataEyeColor}
        setFieldValue={setFieldValue}
      />
      <Field
        component={CheckboxInput}
        label="Military Veteran"
        name="militaryVeteran"
      />
    </>
  )
}
