import * as z from "zod"

export const nameSourceType = `source_type`

export const sourceTypeConsumer = `consumer`
export const sourceTypeProvider = `provider`

export const sourceType = z.union([
  z.literal(sourceTypeConsumer),
  z.literal(sourceTypeProvider),
])
export const sourceTypeOptional = sourceType.optional()
