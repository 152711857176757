import * as helpers from "@erinfo/data-schema/src/_helpers"
export const nameRace = `race`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid Entry`,
    exp: /^\s*(?:\S\s*){0,50}$/u,
  },
]

export const race = helpers.setRegexes(regex)
export const yupRaceSchema = helpers.getYupSchema(regex)
export const raceOptional = race.optional()
