export const get = (name: string, url: string = window.location.href) => {
  name = name.replace(/[[\]]/g, `\\$&`)
  const regex = new RegExp(`[?&]` + name + `(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ``
  return decodeURIComponent(results[2].replace(/\+/g, ` `))
}

export const format = (parameters: { [key: string]: string }) => {
  const esc = encodeURIComponent
  return Object.keys(parameters)
    .map((k) => esc(k) + `=` + esc(parameters[k]))
    .join(`&`)
}
