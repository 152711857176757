import { Textarea } from "./Textarea"

export const TextareaInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Textarea
    inputId={field.name}
    errors={touched[field.name] && errors[field.name]}
    onBlur={() => setFieldTouched(field.name)}
    {...field}
    {...props}
  />
)
