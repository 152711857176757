import { bucketFilesRaw } from "@erinfo/env"
import { AddCircle } from "@mui/icons-material"
import { Box, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"

const AvatarImage = styled(`img`)({
  display: `block`,
  objectFit: `cover`,
  width: `56px`,
  height: `56px`,
  borderRadius: `50%`,
  border: `none`,
})

interface IProps {
  filePath?: string
  url?: string
  sx?: SxProps
  containerSx?: SxProps
  onClick?: () => void
  showPlusIcon?: boolean
}

export const Avatar = ({
  url,
  filePath,
  containerSx,
  sx,
  onClick,
  showPlusIcon,
}: IProps) =>
  filePath || url ? (
    <Box
      position="relative"
      sx={{ ...(!!onClick && { cursor: `pointer` }), ...containerSx }}
    >
      <AvatarImage
        sx={sx}
        src={url || `https://s3.amazonaws.com/${bucketFilesRaw}/${filePath}`}
        onClick={onClick}
      />
      {showPlusIcon && (
        <AddCircle
          color="primary"
          sx={{
            position: `absolute`,
            bottom: -5,
            right: 0,
            background: `white`,
            borderRadius: 3,
            width: `20px`,
            height: `20px`,
          }}
        />
      )}
    </Box>
  ) : (
    <CircularProgress />
  )
