import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import { useEffect, useMemo, useState } from "react"

const StyledAutocomplete = styled(Autocomplete)({
  marginBottom: 15,
  "& .MuiAutocomplete-endAdornment": {
    top: `unset`,
  },
  "& .MuiAutocomplete-tag": {
    margin: `3px !important`,
  },
  "& .MuiInput-input": {
    paddingLeft: `15px !important`,
  },
  "& .MuiInputLabel-root": {
    paddingLeft: `15px !important`,
  },
})

interface IProps {
  dataForMultiSelect?: any[]
  label: string
  value?: string[]
  classes: object
  inputId?: string
  className?: string // add CSS class(es)
  dialog?
  multiple?: boolean
  showDone?: boolean
  onChange(value)
}

export const MedicalSelect = (props: IProps) => {
  const [inputValue, setInputValue] = useState(``)
  const className = useMemo(() => {
    let className = `medical-select`
    if (props.className) className += ` ${props.className}`
    return className
  }, [props.className])

  useEffect(() => {
    const option = dataForMultiSelect?.find(
      (i) => i.value === props.value || i.title === props.value,
    )
    setInputValue(option?.title || props.value)
  }, [props.value])

  const {
    label,
    multiple,
    value,
    onChange,
    dataForMultiSelect,
    classes,
    inputId,
  } = props

  return (
    <>
      <div className={className}>
        <StyledAutocomplete
          fullWidth
          freeSolo
          multiple={multiple}
          disableCloseOnSelect={multiple}
          classes={classes}
          id={inputId}
          options={dataForMultiSelect}
          isOptionEqualToValue={(option, value) =>
            option.title === value || option.value === value
          }
          getOptionLabel={(option) => option?.title || option}
          onChange={(event, value) => {
            console.log(value)
            if (event?.type === "change") {
              return
            }
            return value?.length
              ? onChange(value?.map((v) => v?.title || v))
              : onChange(value?.title)
          }}
          value={value}
          onInputChange={(event, newInputValue) => {
            if (event?.type === "click") {
              return
            }
            setInputValue(newInputValue || ``)
            onChange(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{ ...params.inputProps, value: inputValue }}
              variant="standard"
              label={label}
              value={inputValue}
              placeholder=""
            />
          )}
        />
      </div>
    </>
  )
}
