import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { onUpgrade } from "@erinfo/consumer/src/lib/stripePortalRedirect"
import { RootState } from "@erinfo/consumer/src/store"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const MainMenu = () => {
  const navigate = useNavigate()
  const { handleSignout, isSamlUser } = useAuth()
  const user = useSelector((state: RootState) => state.user)

  const logout = async () => {
    await handleSignout()
    navigate(`/`)
  }

  return (
    <Box display="flex" sx={{ fontSize: `0.75rem` }}>
      {!isSamlUser() && (
        <>
          <Link
            sx={{ mr: 1 }}
            href="#"
            onClick={async (e) => {
              e.preventDefault()
              return onUpgrade(user)
            }}
          >
            Plan
          </Link>
          |
          <Link
            href="#"
            sx={{ mr: 1, ml: 1 }}
            onClick={() => navigate(`/change-password`)}
          >
            Change Password
          </Link>
          |
        </>
      )}
      <Link href="#" color="error" sx={{ ml: 1 }} onClick={logout}>
        Sign Out
      </Link>
    </Box>
  )
}
