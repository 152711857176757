import * as Dialogs from "@erinfo/brand-ui/src/storybook/other/dialogs"
import { AvatarList } from "@erinfo/consumer/src/components/AvatarList"
import * as Dash from "@erinfo/consumer/src/components/dashboard/index"
import { MobileMainMenu } from "@erinfo/consumer/src/components/MobileMainMenu"
import * as api from "@erinfo/consumer/src/data"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Hidden, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { CameraType } from "expo-camera"

import Loader from "../components/Loader"

const MemberDashboard = () => {
  const { memberId } = useParams()
  const navigate = useNavigate()
  const [medInfoDialogOpen, setMedInfoDialogOpen] = useState(false)
  const [identifyingDialogOpen, setIdentifyingDialogOpen] = useState(false)
  const [personalDialogOpen, setPersonalDialogOpen] = useState(false)
  const [facePhotoDialogOpen, setFacePhotoDialogOpen] = useState(false)
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const user = useSelector((state: RootState) => state.user)
  const member = user?.members?.find((m) => m.id === memberId)
  const {
    archiveMember,
    updateUser,
    storeUserFacePicture,
    deleteUserFacePicture,
    storeDocuments,
    setSnackbarMessage,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    archiveMember: dispatch.user.archiveMember,
    updateUser: dispatch.user.updateUser,
    storeUserFacePicture: dispatch.user.storeUserFacePicture,
    deleteUserFacePicture: dispatch.user.deleteUserFacePicture,
    storeDocuments: dispatch.user.storeDocuments,
    setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
  }))

  useEffect(() => {
    document?.getElementById(`root`)?.scrollTo(0, 0)
  }, [])

  const handleSubmit = async (data) =>
    updateUser({ userId: memberId, email: user.email, data }).then(() =>
      setSnackbarMessage(`Changes saved`),
    )

  const handleDocuments = async (data) =>
    storeDocuments({ userId: memberId, data })

  const onArchive = () => {
    confirm(``, {
      title: `Archive Member?`,
      description: () => (
        <Box sx={{ paddingRight: 4 }}>
          Archived members cannot be
          <br />
          unarchived. Archive anyway?
        </Box>
      ),
      noAction: `No`,
      yesAction: `Yes`,
    }).then(async () => {
      navigate(`/dashboard`)
      await archiveMember({ memberId })
    })
  }
  const onCloseMenu = () => setMobileMenuOpen(false)

  if (!member)
    return (
      <Loader>
        <Typography variant="h6">Loading...</Typography>
      </Loader>
    )

  return (
    <Box
      sx={{
        "@media (min-width: 480px)": {
          padding: 2,
          paddingTop: 9,
        },
        paddingTop: 9,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <Dash.MemberHeader
        user={user}
        onBack={() => navigate(`/dashboard`)}
        archiveMember={onArchive}
      />
      <AvatarList user={member} onClick={() => setFacePhotoDialogOpen(true)} />
      <Box
        sx={{
          display: `flex`,
          flexWrap: `wrap`,
          marginTop: 2,
          marginBottom: 2,
          justifyContent: `space-between`,
          alignItems: `flex-start`,
          "@media (min-width: 480px)": {
            width: `calc(100vw - 50px)`,
          },
          maxWidth: 1280,
        }}
      >
        <Dash.PersonalPanel
          user={member}
          onAdd={() => setPersonalDialogOpen(true)}
        />
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            "@media (max-width: 480px)": { width: `100%` },
          }}
        >
          <Dash.IdentifyingPanel
            user={member}
            onAdd={() => setIdentifyingDialogOpen(true)}
            sx={{ "@media (min-width: 480px)": { mb: 3 } }}
          />
          <Dash.DocumentsPanel
            user={member}
            onAdd={() => setDocumentsDialogOpen(true)}
          />
        </Box>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            "@media (max-width: 480px)": { width: `100%` },
          }}
        >
          <Dash.MedicalPanel
            user={member}
            onAdd={() => setMedInfoDialogOpen(true)}
            sx={{ mb: 3 }}
          />
        </Box>
      </Box>

      <Dialogs.PersonalDialog
        open={personalDialogOpen}
        handleClose={() => setPersonalDialogOpen(false)}
        handleSubmit={handleSubmit}
        user={member}
        appType="consumer"
      />
      <Dialogs.IdentifyingDialog
        open={identifyingDialogOpen}
        handleClose={() => setIdentifyingDialogOpen(false)}
        addOtherPicture={api.addOtherPicture}
        handleSubmit={handleSubmit}
        user={member}
      />

      <Dialogs.MedicalInfoDialog
        open={medInfoDialogOpen}
        handleClose={() => setMedInfoDialogOpen(false)}
        handleSubmit={handleSubmit}
        user={member}
      />
      <Dialogs.DocumentsDialog
        open={documentsDialogOpen}
        handleClose={() => setDocumentsDialogOpen(false)}
        user={member}
        handleSubmit={handleDocuments}
      />
      <Dialogs.FacePhotosDialog
        open={facePhotoDialogOpen}
        handleClose={() => setFacePhotoDialogOpen(false)}
        storeUserFacePicture={storeUserFacePicture}
        deleteUserFacePicture={deleteUserFacePicture}
        user={member}
        startingCameraType={CameraType.back}
      />
      <Hidden smUp>
        <MobileMainMenu open={mobileMenuOpen} closeMenu={onCloseMenu} />
        <Dash.MobileBottomMenu
          onAddMember={() => navigate(`/add-member/step-1`)}
          openMenu={() => setMobileMenuOpen(true)}
        />
      </Hidden>
    </Box>
  )
}

export default MemberDashboard
