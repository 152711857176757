import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as z from "zod"

export const nameMedicalDevices = `medicalDevices`

export const medicalDevice = z
  .object({
    [attr.nameTitle]: attr.titleOptional,
    [attr.nameSerialNumber]: attr.serialNumberOptional,
    [attr.nameImplantedDate]: attr.implantedDateOptional,
  })
  .strict()
export type medicalDevice = z.infer<typeof medicalDevice>

export const medicalDevices = z.array(medicalDevice)
export const medicalDevicesOptional = medicalDevices.optional()

export const medicalDevicesPut = helpers.setPutItem(medicalDevice)
export const medicalDevicesPutOptional = medicalDevicesPut.optional()
