import { InitialsGroupingInput } from "@erinfo/brand-ui/src/storybook/inputs/initials-grouping"
import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { useRedirects } from "@erinfo/consumer/src/hooks/use-redirects"
import { useUrlParams } from "@erinfo/consumer/src/hooks/use-url-params"
import { Dispatch } from "@erinfo/consumer/src/store"
import { helpers, yupEmailSchema, yupPasswordSchema } from "@erinfo/data-schema"
import { envTurnstileSiteKey, isProduction } from "@erinfo/env"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Turnstile from "react-turnstile"
import * as Yup from "yup"

// const { isIntlPhoneNumber } = helpers

const StyledTextInfo = styled(TextInfo)({
  gridColumn: `span 12`,
  textAlign: `center`,
  margin: `20px 0`,
})

const FormSchema = Yup.object().shape({
  signupEmail: yupEmailSchema.required(`Email is required`),
  mobile: Yup.string()
    .test(`intl phone`, `Phone number is not valid`, (val) => {
      const number = val?.replace(/\s/g, ``)

      return (
        number?.slice(0, 2) === `+1` && !!/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
      )

      // return number?.slice(0, 2) === `+1`
      //   ? !!/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
      //   : !!number?.match(isIntlPhoneNumber.exp)?.[0]
    })
    .max(16, `Too many digits for a phone number`)
    .required(`Mobile number is required`),
  password: yupPasswordSchema.required(`Password is required`),
  initials: Yup.bool().required(`Required`).oneOf([true], `Required`),
})

const RequireCaptchaSchema = Yup.object({
  captchaToken: Yup.string().required(`Required`),
})

const SignUpStep1 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const skipCaptchaRef = useRef(false)
  if (!isProduction && location.search.includes(`noCaptcha=1`)) {
    skipCaptchaRef.current = true
  }

  const ValidationSchema = skipCaptchaRef.current
    ? FormSchema
    : FormSchema.concat(RequireCaptchaSchema)

  const { setDialogMessage, createUser } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      createUser: dispatch.user.create,
    }),
  )
  useUrlParams()
  useRedirects({ protectedRoute: false })

  const handleSubmit = async (values) => {
    const submission = {
      ...values,
      email: values.signupEmail.trim(),
    }
    delete submission.signupEmail
    try {
      await createUser(submission)
      navigate(`/sign-up/step-2?initialSignup=1`)
    } catch (error) {
      console.log(error)
      if (error.message) {
        setDialogMessage({ msg: error.message })
      }
    } finally {
      turnstile.reset()
    }
  }

  return (
    <BorderBox sx={{ "@media (min-width: 470px)": { maxWidth: 350 } }}>
      <Typography variant="h6" sx={{ marginBottom: 2, alignSelf: `start` }}>
        Sign Up
      </Typography>

      <Formik
        initialValues={{
          signupEmail: ``,
          mobile: ``,
          password: ``,
          initials: ``,
          captchaToken: ``,
        }}
        validationSchema={ValidationSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, errors, values }) => (
          <Form autoComplete="off">
            <Field
              component={TextInput}
              name="signupEmail"
              label="Email"
              type="search"
              inputProps={{
                autoComplete: `off`,
                role: `presentation`,
                "data-clarity-unmask": `true`,
                className: `clarity-unmask`,
              }}
            />
            <Field
              component={PhoneInput}
              fullWidth
              name="mobile"
              label="MOBILE Phone"
              type="tel"
              inputProps={{
                role: `presentation`,
                autoComplete: `off`,
                "data-clarity-unmask": `true`,
                className: `clarity-unmask`,
              }}
            />
            <Field
              component={TextInput}
              name="password"
              label="Password"
              type="password"
              inputProps={{
                role: `presentation`,
                autoComplete: `new-password`,
              }}
            />
            <Box
              sx={{
                marginTop: 2,
                "& .turnstile": { "& iframe": { width: `100% !important` } },
              }}
            >
              <Field
                name="initials"
                component={InitialsGroupingInput}
                to="https://erinfo.me/terms-conditions"
                showCheckbox={true}
                inline
              >
                I agree to ERinfo's
              </Field>
              {skipCaptchaRef.current ? null : (
                <Turnstile
                  className="turnstile"
                  appearance="interaction-only"
                  sitekey={envTurnstileSiteKey}
                  onVerify={(token) => setFieldValue(`captchaToken`, token)}
                />
              )}
            </Box>
            <StyledButton
              variant="contained"
              fullWidth
              type="submit"
              sx={{ margin: `20px 0` }}
              loading={isSubmitting}
              disabled={isSubmitting}
              loadingPosition="end"
            >
              Sign Up
            </StyledButton>
            {errors.captchaToken && values.signupEmail && values.mobile && (
              <Typography color="error">Could not verify Captcha</Typography>
            )}
            <StyledTextInfo>
              Already a member? <TextAnchor to="/">SIGN IN.</TextAnchor>
            </StyledTextInfo>
          </Form>
        )}
      </Formik>
    </BorderBox>
  )
}

export default SignUpStep1
