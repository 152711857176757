import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { RootState } from "@erinfo/consumer/src/store"
import { verifyUserAttribute } from "@erinfo/react-utils/src/helpers/amplify"
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const StyledTextInfo = styled(TextInfo)({
  gridColumn: `span 12`,
  textAlign: `center`,
  margin: `20px 0`,
})

export const ChangePassword = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(``)
  const user = useSelector((state: RootState) => state.user)

  const onClick = (attribute) => async () => {
    setError(``)
    verifyUserAttribute(attribute)
      .then(() =>
        navigate(`/forgot-password/reset`, {
          state: {
            mode: `change`,
            attribute,
          },
        }),
      )
      .catch((err) => setError(err.message))
  }

  return (
    <BorderBox maxWidth={350} sx={{}}>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        Change Password
      </Typography>
      <StyledButton
        variant="contained"
        sx={{ mb: 2, minWidth: 300 }}
        onClick={onClick(`email`)}
      >
        Send Verification Code To Email
      </StyledButton>
      <StyledButton
        variant="contained"
        sx={{ mb: 2, minWidth: 300 }}
        onClick={onClick(`phone_number`)}
      >
        Send Verification Code To Phone
      </StyledButton>
      <Typography color="error" sx={{ fontSize: `0.8rem` }}>
        {error}
      </Typography>
      <StyledTextInfo>
        Back to{` `}
        <TextAnchor to={user?.id ? `/dashboard` : `/`}>
          {user?.id ? `DASHBOARD` : `SIGN IN`}
        </TextAnchor>
      </StyledTextInfo>
    </BorderBox>
  )
}
