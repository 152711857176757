import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import {
  IdentifyingBaseForm,
  IdentifyingMarksForm,
  identifyingMarksFormFields,
  Notes,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import {
  yupEyeColorSchema,
  yupHairColorSchema,
  yupRaceSchema,
} from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import {
  createIdentifyingDataSubmission,
  createIdentifyingFormData,
} from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useDynamicFields } from "@erinfo/react-utils/src/hooks/use-dynamic-fields"
import { useUnsavedChanges } from "@erinfo/react-utils/src/hooks/use-unsaved-changes"
import { Visibility } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"

const FormSchema = Yup.object().shape({
  weight: Yup.number()
    .typeError(`Only numbers are accepted.`)
    .min(0, `Min value is 0.`)
    .max(999, `Max value is 999.`)
    .nullable()
    .transform((value: string, originalValue: string) =>
      originalValue.trim() === `` ? null : value,
    )
    .test({
      name: `fractions`,
      exclusive: false,
      message: `Please enter whole numbers only`,
      test: function (value) {
        return !value || value % 1 === 0
      },
    }),
  heightFeet: Yup.string().test({
    name: `required`,
    exclusive: false,
    message: `Must be present if inches are specified`,
    test: function (value) {
      return value || !this.parent.heightInches
    },
  }),
  [attr.nameRace]: yupRaceSchema.optional(),
  [attr.nameHairColor]: yupHairColorSchema.optional(),
  [attr.nameEyeColor]: yupEyeColorSchema.optional(),
})

export const IdentifyingDialog = ({
  open,
  handleClose,
  user,
  addOtherPicture,
  handleSubmit,
}) => {
  const [loading, setLoading] = useState(false)
  const { fieldCounts, initialValues, addField, removeField } =
    useDynamicFields(
      [`identifyingMarks`],
      createIdentifyingFormData,
      user,
      open,
      null,
      { identifyingMarks: identifyingMarksFormFields },
    )
  const { onClose, setDirty, dirty } = useUnsavedChanges(handleClose, open)

  const onSubmit = async (values) => {
    setLoading(true)

    const data = createIdentifyingDataSubmission(values)
    if (data.identifyingMarks?.length) {
      data.identifyingMarks = await Promise.all(
        data.identifyingMarks.map(async (mark) => {
          mark.img = mark.img?.startsWith(`data:image/png`)
            ? await addOtherPicture(user.id, mark.img)
            : mark.img?.split(`/`).pop()
          return mark
        }),
      )
    }
    await handleSubmit(data)
    handleClose()
    setLoading(false)
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      Icon={<Visibility sx={{ color: `#ED6C02`, marginRight: 2 }} />}
      title="Identifying Information"
      formId="identifying"
      loading={loading}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, setValues, dirty: formDirty }) => {
          if (formDirty && !dirty && open) {
            setDirty(true)
          }
          const formProps = {
            addField: addField(values, setValues),
            removeField: removeField(values, setValues),
            setFieldValue,
            values,
            fieldCounts,
          }
          return (
            <Form id="identifying">
              <Box p={2}>
                <IdentifyingBaseForm setFieldValue={setFieldValue} />
              </Box>
              <Box
                p={2}
                sx={{
                  backgroundColor: `#2196F320`,
                }}
              >
                <Typography variant="h5">Identifying Marks</Typography>
                <IdentifyingMarksForm {...formProps} />
              </Box>
              <Notes name={attr.nameNotes} value={values?.[attr.nameNotes]} />
            </Form>
          )
        }}
      </Formik>
    </BaseDialog>
  )
}
