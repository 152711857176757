import * as schema from "@erinfo/data-schema"
import { uploadFile } from "@erinfo/react-utils/src/helpers/upload-file"
import { duplicateImageErrorText } from "@erinfo/react-utils/src/helpers/validate-face-img/errors"
import { StatusCodes } from "http-status-codes"

import { request, responseType } from "./request"

export const createUser = async (userData: DataSchema.user.post) => {
  const { data } = await request({
    method: `POST`,
    path: `/users`,
    body: userData,
    type: responseType.json,
  })
  return data.id
}

export const getUserByEmail = async (
  email: string,
): Promise<DataSchema.user.post> => {
  console.log(`TCL: getUserByEmail...`)
  const {
    data: { user },
  } = await request({
    path: `/users/email/${encodeURIComponent(email)}?type=${encodeURIComponent(
      schema.types.userSubscriber,
    )}`,
    signRequest: true,
  })
  return user
}

export const getUserById = async (
  id: DataSchema.id,
): Promise<DataSchema.user.post> => {
  const {
    data: { user },
  } = await request({
    path: `/users/${id}`,
    signRequest: true,
  })
  console.log(`getUserById -> user`, user)

  return user
}

export const updateUser = async (
  id: DataSchema.id,
  userData: DataSchema.user.id.put,
  isProvider?: boolean,
) => {
  try {
    const body = isProvider ? { isProvider: true, ...userData } : userData
    const { data } = await request({
      method: `PUT`,
      path: `/users/${id}`,
      body,
      signRequest: true,
    })

    return data
  } catch (error) {
    if (error?.status) {
      const msg = await error.text()
      const { status } = error

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`There was a conflict updating this user.`)
      } else if (msg) {
        throw new Error(msg)
      } else {
        throw new Error(`User could not be updated.`)
      }
    }
  }
}

export const applyCoupon = async (coupon) => {
  const { data } = await request({
    method: `POST`,
    path: `/users/coupon/${coupon}`,
    signRequest: true,
  })
  return data
}

export const verifyAttribute = async (attribute: {
  [type: string]: string
}) => {
  const { data: id } = await request({
    method: `POST`,
    path: `/verify`,
    body: attribute,
    signRequest: true,
    type: responseType.text,
  })
  return id
}

export const confirmAttribute = async (
  userId: string,
  payload: DataSchema.user.postConfirm,
) => {
  const { data } = await request({
    method: `POST`,
    path: `/users/${userId}/confirm`,
    body: payload,
    signRequest: true,
    type: responseType.json,
  })
  return data
}

export const triggerAffiliateEvent = async (
  body: DataSchema.user.affiliate.post,
) => {
  try {
    await request({
      method: `POST`,
      path: `/users/affiliate`,
      body,
      signRequest: true,
    })
  } catch {}
}

interface IUserFaceParams {
  userId: string
  dataUrl?: string
  created?: string
}

export const createUserFace = async (params: IUserFaceParams) => {
  let { userId, dataUrl, created } = params
  if (!dataUrl && !created)
    throw new Error(`Must provide at least a data URL or created time`)

  try {
    if (dataUrl && !created) {
      const { data } = await request({
        path: `/img`,
        signRequest: true,
        query: { type: `face` },
      })
      await uploadFile({ dataURL: dataUrl, req: data.req })
      created = data.created
    }

    const { data } = await request({
      method: `POST`,
      path: `/img/${created}/face/add`,
      body: { uid: userId },
      signRequest: true,
    })
    data.created = created

    return data
  } catch (error) {
    let message = ``
    let messageData
    try {
      message = await error.text()
    } catch {
      console.log(`no error message`)
    }
    if (error) {
      const { status } = error
      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Status 409`)
      } else if (status === StatusCodes.NOT_FOUND) {
        throw new Error(`Face image does not match this person`)
      } else if (status === StatusCodes.EXPECTATION_FAILED) {
        throw new Error(`Face image does not match this person`)
      } else if (message) {
        try {
          messageData = JSON.parse(message)
        } catch {
          throw new Error(message)
        }
        if (messageData?.noFace || messageData?.faceConfidenceLow) {
          throw new Error(`No face was detected.`)
        }
        if (messageData?.hasMultipleFaces) {
          throw new Error(`There were multiple faces in the image.`)
        }
        if (messageData?.hasBeenAdded) {
          throw { message: duplicateImageErrorText, error: `hasBeenAdded` }
        }

        throw new Error(message)
      } else {
        throw new Error(
          `There was an issue with this image.  Please retry or use a different photo.`,
        )
      }
    }
  }
}

export const deleteFacePicture = async (userId: string, faceId: string) => {
  try {
    const body: DataSchema.img.face.Delete = {
      [schema.nameIdU]: userId,
      [schema.nameFaceId]: faceId,
    }
    return request({
      method: `DELETE`,
      path: `/img/face`,
      body,
      signRequest: true,
    })
  } catch (error) {
    if (error.response) {
      const { status } = error
      let message = ``
      try {
        message = await error.text()
      } catch {
        console.log(`no error message`)
      }
      if (status === 409) {
        throw new Error(`Email already exists.`)
      } else if (message) {
        throw new Error(message)
      } else {
        throw new Error(error)
      }
    }
  }
}

export const archiveMember = async (memberId: DataSchema.id) => {
  try {
    await request({
      method: `POST`,
      path: `/users/archive/${memberId}`,
      signRequest: true,
    })
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (data?.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    }
  }
}

/**
 * Add new other pic
 * @return  {string} id new pic
 */
export const addOtherPicture = async (id: DataSchema.id, img: string) => {
  try {
    const {
      data: { req, created },
    } = await request({
      method: `GET`,
      path: `/img`,
      query: { type: `other` },
      signRequest: true,
    })
    await uploadFile({ dataURL: img, req })

    await request({
      method: `PUT`,
      path: `/img/${created}/other`,
      body: { uid: id },
      signRequest: true,
    })

    return created?.toString()
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Email already exists.`)
      } else if (data && data.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    }
  }
}

/**
 * Delete user account
 * @return  {string} id of user
 */
export const deleteUser = async () => {
  try {
    await request({
      method: `DELETE`,
      path: `/users`,
      signRequest: true,
    })
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Unauthorized.`)
      } else if (data && data.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    } else {
      throw new Error(`Unexpected error`)
    }
  }
}

/**
 * Purge user account
 * @return  {string} id of user
 */
export const purgeProfile = async (purge: boolean) => {
  try {
    await request({
      method: `POST`,
      path: `/users/purge`,
      body: { ttl: purge },
      signRequest: true,
    })
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response

      if (status === StatusCodes.CONFLICT) {
        throw new Error(`Unauthorized.`)
      } else if (data && data.message) {
        throw new Error(data.message)
      } else {
        throw new Error(error)
      }
    } else {
      throw new Error(`Unexpected error`)
    }
  }
}

/**
 * Delete unverified user account
 * @return  {string} id of user
 */
export const deleteUnverifiedUser = async (email) =>
  request({
    method: `DELETE`,
    path: `/users/email/${email}`,
  })

/**
 * Recover user account based on face image
 * @return  {string} id of user
 */
export const recoverUser = async (created: string) =>
  request({
    method: `POST`,
    path: `/img/${created}/face/recover`,
    signRequest: true,
  })
