import * as z from "zod"

export const transformerTrim = z.transformer(z.string(), z.string(), (v) =>
  v.trim(),
)
export const transformerTrimLowercase = z.transformer(
  z.string(),
  z.string(),
  (v) => v.trim().toLowerCase(),
)
export const transformerTrimUppercase = z.transformer(
  z.string(),
  z.string(),
  (v) => v.trim().toUpperCase(),
)
