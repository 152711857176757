import { Auth } from "@aws-amplify/auth"
import { Hub } from "@aws-amplify/core"
import { Dispatch } from "@erinfo/consumer/src/store"
import { getCompositeId } from "@erinfo/data-schema"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import deepmerge from "deepmerge"
import { createContext, FC, useEffect, useReducer, useState } from "react"

interface AuthContextData {
  isLoading?: boolean
  isError?: boolean
  user?: any
  handleSignout: () => Promise<void>
  handleUpdatePhone: (newPhone: string) => void
  handleSetPhoneVerified: () => void
  impersonate: (
    email: string,
    password: string,
    userId: string,
  ) => Promise<void>
  initiateEmailVerification: () => Promise<void>
  initiatePhoneVerification: () => Promise<void>
  updateCustomAttribute: () => Promise<void>
  fetchCustomAttribute: (name: string) => Promise<string>
  verifyEmail: () => Promise<void>
  verifyPhone: () => Promise<void>
  refreshAuthData: () => void
  isSamlUser: () => boolean
}

const amplifyAuthReducer = (state, action) => {
  switch (action.type) {
    case `FETCH_USER_DATA_INIT`:
      return { ...state, isLoading: true, isError: false }

    case `FETCH_USER_DATA_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        isError: false,
        user: action.payload.user,
      }

    case `FETCH_USER_DATA_FAILURE`:
      return { ...state, isLoading: false, isError: true }
    case `RESET_USER_DATA`:
      return { ...state, user: null }
    case `UPDATE_PHONE`:
      const updatedPhone = deepmerge(state, {
        user: { attributes: { phone_number: action.payload } },
      })
      console.log(`TCL: amplifyAuthReducer -> updatedUser`, updatedPhone)
      return updatedPhone
    case `SET_PHONE_VERIFIED`:
      const updatedVerified = deepmerge(state, {
        phoneNumberVerified: true,
        user: { attributes: { phone_number_verified: true } },
      })
      console.log(`TCL: amplifyAuthReducer -> updatedUser`, updatedVerified)
      return updatedVerified
    default:
      throw new Error()
  }
}

const init: AuthContextData = {
  handleSignout: async () => Promise.resolve(),
  handleSetPhoneVerified: () => {},
  handleUpdatePhone: () => {},
  impersonate: async () => Promise.resolve(),
  initiateEmailVerification: async () => Promise.resolve(),
  initiatePhoneVerification: async () => Promise.resolve(),
  updateCustomAttribute: async () => Promise.resolve(),
  fetchCustomAttribute: async () => Promise.resolve(``),
  verifyEmail: async () => Promise.resolve(),
  verifyPhone: async () => Promise.resolve(),
  refreshAuthData: () => {},
}

export const AuthContext = createContext<AuthContextData>(init)

export const AuthProvider: FC = ({ children }) => {
  const initialState = { isLoading: true, isError: false, user: null }
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState)
  const [triggerFetch, setTriggerFetch] = useState(false)
  const [impersonateId, setImpersonateId] = useState<string>()
  const { clearUserData, fetchUserData, mergeUser, setFetching } =
    useRematchDispatch((dispatch: Dispatch) => ({
      clearUserData: dispatch.user.clear,
      fetchUserData: dispatch.user.fetchUserData,
      mergeUser: dispatch.user.merge,
      setFetching: dispatch.user.setFetching,
    }))

  useEffect(() => {
    let isMounted = true
    const retrieveUserData = async () => {
      if (isMounted) {
        dispatch({ type: `FETCH_USER_DATA_INIT` })
      }
      try {
        if (isMounted) {
          setFetching()
          const data = await Auth.currentAuthenticatedUser({
            bypassCache: true,
          })
          if (data) {
            dispatch({
              type: `FETCH_USER_DATA_SUCCESS`,
              payload: { user: data },
            })

            const payload = data.signInUserSession.idToken.payload
            if (payload?.identities?.[0]?.providerType === `SAML`) {
              const userId = getCompositeId(
                payload.identities[0].providerName,
                payload.identities[0].userId,
              )

              dispatch({
                type: `FETCH_USER_DATA_SUCCESS`,
                payload: { user: payload },
              })

              if (window.clarity) {
                window.clarity(`identify`, userId)
              }

              void fetchUserData({ id: userId })
            } else {
              if (window.clarity) {
                window.clarity(
                  `identify`,
                  data.attributes[`custom:compositeID`],
                )
              }
              void fetchUserData({
                id: impersonateId || data.attributes[`custom:compositeID`],
                impersonatingAdmin: !!impersonateId
                  ? data.attributes.email
                  : undefined,
              })
            }
          }
        }
      } catch (error) {
        if (isMounted) {
          dispatch({ type: `FETCH_USER_DATA_FAILURE` })
          mergeUser({ fetching: false })
        }
      }
    }
    const HubListener = () => {
      Hub.listen(`auth`, (data) => {
        const { payload } = data
        onAuthEvent(payload)
      })
    }
    const onAuthEvent = async (payload) => {
      console.log(`TCL: onAuthEvent -> payload.event`, payload.event)
      switch (payload.event) {
        case `signIn`:
          if (isMounted) {
            setTriggerFetch(true)
            console.log(`signed in`)
          }
          break
        case `cognitoHostedUI`:
          console.log(payload)
          const data = payload.data.signInUserSession.idToken.payload
          const userId = getCompositeId(
            data.identities?.[0]?.providerName,
            data.identities?.[0]?.userId,
          )

          dispatch({
            type: `FETCH_USER_DATA_SUCCESS`,
            payload: { user: data },
          })

          if (window.clarity) {
            window.clarity(`identify`, userId)
          }

          try {
            await fetchUserData({ id: userId })
          } catch (err) {
            dispatch({ type: `FETCH_USER_DATA_FAILURE` })
            mergeUser({ fetching: false, error: err })
          }

          break
        case `signOut`:
          dispatch({ type: `RESET_USER_DATA` })
          setTriggerFetch(false)
          break
        default:
          return
      }
    }
    HubListener()
    void retrieveUserData()
    return () => {
      Hub.remove(`auth`, (event) => {
        console.log(`TCL: useAmplifyAuth -> event`, event)
      })
      isMounted = false
    }
  }, [triggerFetch])

  const handleSignout = async () => {
    console.log(`signed out`)
    localStorage.removeItem(`emailVerifyPrompted`)
    await Auth.signOut()
    setImpersonateId()
    clearUserData()
  }

  const handleUpdatePhone = (newPhone: string) => {
    dispatch({ type: `UPDATE_PHONE`, payload: newPhone })
  }

  const handleSetPhoneVerified = () => {
    dispatch({ type: `SET_PHONE_VERIFIED` })
  }

  const initiateEmailVerification = async () =>
    Auth.verifyCurrentUserAttribute(`email`)

  const initiatePhoneVerification = async () =>
    Auth.verifyCurrentUserAttribute(`phone_number`)

  const verifyEmail = async (code) => {
    await Auth.verifyCurrentUserAttributeSubmit(`email`, code)
    const data = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    if (data) {
      dispatch({
        type: `FETCH_USER_DATA_SUCCESS`,
        payload: { user: data },
      })
    }
  }

  const verifyPhone = async (code) => {
    await Auth.verifyCurrentUserAttributeSubmit(`phone_number`, code)
    const data = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    if (data) {
      dispatch({
        type: `FETCH_USER_DATA_SUCCESS`,
        payload: { user: data },
      })
    }
  }

  const updateCustomAttribute = async (fieldName, value) => {
    await Auth.updateUserAttributes(state.user, {
      [fieldName]: value,
    })
  }

  const fetchCustomAttribute = async (fieldName) => {
    const user = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
    return user.attributes[fieldName]
  }

  const impersonate = async (
    email: string,
    password: string,
    userId: string,
  ) => {
    await handleSignout()
    dispatch({ type: `FETCH_USER_DATA_INIT` })
    setImpersonateId(userId)
    const cognitoUser = await Auth.signIn(email, password, {
      idImpersonated: userId,
    })
    dispatch({
      type: `FETCH_USER_DATA_SUCCESS`,
      payload: { user: cognitoUser },
    })
    return cognitoUser
  }

  const isSamlUser = () => state.user?.identities?.[0]?.providerType === `SAML`

  return (
    <AuthContext.Provider
      value={{
        ...state,
        handleSignout,
        handleUpdatePhone,
        handleSetPhoneVerified,
        impersonate,
        initiateEmailVerification,
        initiatePhoneVerification,
        updateCustomAttribute,
        fetchCustomAttribute,
        verifyEmail,
        isSamlUser,
        verifyPhone,
        refreshAuthData: () => setTriggerFetch((p) => !p),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
