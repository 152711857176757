import { awsProductionAccountID } from "./helpers/consts"

export const domain = `erinfo.me` as const

export const apiSubdomain = {
  develop: `api-dev`,
  stagingnext: `api-next`,
  staging: `test`,
  production: `api`,
} as const

export const stageSubdomain = {
  develop: `dev`,
  staging: `staging`,
  stagingnext: `next`,
  production: `app`,
} as const

export const isProduction = process.env.AWS_STAGE === `production`

export const consumerSubdomain = ``
export const providerSubdomain = `pro`
export const adminSubdomain = `admin`
export type websiteSubdomain =
  | typeof consumerSubdomain
  | typeof providerSubdomain
  | typeof adminSubdomain

export const envTurnstileSiteKey =
  process.env.AWS_STAGE === `production`
    ? `0x4AAAAAAADjy4ftaw371zNq` // in David's CloudFlare account
    : `0x4AAAAAAADkpth6s13QrqWz` // in Arman's CloudFlare account

export const GOOGLE_MAPS_API_KEY = "AIzaSyArQqFGNER9G_D13S5croNiKemM6F8v3OE"

export const envConsumerUrl = `https://${
  stageSubdomain[process.env.AWS_STAGE]
}.${domain}` as const
export const envProviderUrl = `https://${
  stageSubdomain[process.env.AWS_STAGE]
}-${providerSubdomain}.${domain}` as const
export const envAdminUrl = `https://${
  stageSubdomain[process.env.AWS_STAGE]
}-${adminSubdomain}.${domain}` as const
export const envAPIEndpoint = `https://${
  apiSubdomain[process.env.AWS_STAGE]
}.${domain}` as const
export const envWebsocketEndpoint =
  process.env.AWS_STAGE === `production`
    ? `ynwmzqv4fd.execute-api.us-east-1.amazonaws.com/production`
    : `6l54k0x5f5.execute-api.us-east-1.amazonaws.com/production`
export const envS3Endpoint = `https://s3.amazonaws.com/erinfo${process.env.AWS_STAGE}-face`

export const bucketFilesRaw = `files-raw-${process.env.AWS_ACCOUNT_ID}` as const

export const bucket3rdPartyOrganizations = `${awsProductionAccountID}-organizations`
