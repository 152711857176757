import { ReactComponent as IPhotoAddFace } from "@erinfo/brand-ui/src/assets/svg/addPhotoIcon.svg"
import { ReactComponent as IPhotoDeleteFace } from "@erinfo/brand-ui/src/assets/svg/photo-delete-face.svg"
import { CameraCaptureDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/CameraDialog"
import { Box, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactNode, useEffect, useState } from "react"
import { CameraType } from "expo-camera"

import { CaptureDialog } from "./CaptureDialog"
import { EditDialog } from "./EditDialog"

export interface IPhoto {
  value?: string
  errors?: string[]
  created?: string | null
  dataBeforeEditing?: string
  filename?: string
}

interface IProps {
  photo: IPhoto
  setPhoto: (photo: IPhoto) => void
  faceValidation: (imgBase64: string) => Promise<string | undefined | null>
  onDelete?: () => void
  children?: (openDialog: () => void) => ReactNode
  startingCameraType?: CameraType
}

export const PhotoFace = ({
  photo,
  setPhoto,
  faceValidation,
  onDelete = () => {},
  children,
  startingCameraType,
}: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [cameraOpen, setCameraOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const { value, created } = photo

  const validateImage = async () => {
    try {
      if (!value) return
      setLoading(true)
      const created = await faceValidation(value)
      setPhoto({
        ...photo,
        errors: [``],
        created,
      })
      setLoading(false)
    } catch (error) {
      console.log(`TCL: error`, error)
      setPhoto({
        dataBeforeEditing: ``,
        value: ``,
        errors: [
          typeof error.message === `function` ? error.message() : error.message,
        ],
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (value && !created) {
      void validateImage()
    }
  }, [value])

  return (
    <>
      <CaptureDialog
        setPhoto={setPhoto}
        openCamera={() => setCameraOpen(true)}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <CameraCaptureDialog
        isOpen={cameraOpen}
        onClose={() => setCameraOpen(false)}
        onCapture={(base64Uri) => {
          setCameraOpen(false)
          setPhoto({ dataBeforeEditing: base64Uri })
        }}
        startingCameraType={startingCameraType}
      />
      {photo.dataBeforeEditing && !photo.value && (
        <EditDialog photo={photo} setPhoto={setPhoto} />
      )}
      {(!!children && children(() => setDialogOpen(true))) ||
        (!photo.created ? (
          <>
            <Box
              sx={{
                padding: 3,
                paddingTop: 6,
                paddingBottom: 6,
                backgroundColor: `#E0E0E0`,
                cursor: `pointer`,
              }}
              onClick={() => setDialogOpen(true)}
            >
              {loading ? <CircularProgress /> : <IPhotoAddFace />}
            </Box>
            {photo?.errors?.[0] && (
              <ErrorWrapper>{photo?.errors?.[0]}</ErrorWrapper>
            )}
          </>
        ) : (
          <ImageWrapper onClick={onDelete} data-test-id="delete-img">
            <PhotoDeleteIcon />
            <img src={photo.value} />
          </ImageWrapper>
        ))}
    </>
  )
}

const ImageWrapper = styled(`div`)({
  position: `relative`,
  margin: `auto`,
  borderRadius: `5px`,
  overflow: `hidden`,
  cursor: `pointer`,
  height: `100%`,
  "& img": {
    borderRadius: `5px`,
    maxHeight: 200,
  },
})

const PhotoDeleteIcon = styled(IPhotoDeleteFace)({
  position: `absolute`,
  bottom: `10px`,
  left: `10px`,
})

const ErrorWrapper = styled(`div`)(({ theme }) => ({
  position: `relative`,
  fontFamily: `Roboto, arial`,
  fontSize: `12px`,
  maxWidth: `250px`,
  marginTop: `8px`,
  color: theme.palette.error.main,
  "& li": {
    marginLeft: 30,
  },
}))
