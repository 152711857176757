export const dataGender = [
  { title: ``, value: `` },
  { title: `Female`, value: `Female`, key: `Female` },
  { title: `Male`, value: `Male`, key: `Male` },
  {
    title: `Male born female`,
    value: `Male born Female`,
    key: `Male born female`,
  },
  {
    title: `Female born Male`,
    value: `Female born Male`,
    key: `Female born Male`,
  },
  { title: `Other`, value: `Other`, key: `Other` },
  {
    title: `Prefer not to answer`,
    value: `Prefer not to answer`,
    key: `Prefer not to answer`,
  },
]
