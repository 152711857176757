export const JdrfInstructions = () => (
  <>
    <h3 style={{ fontSize: `1.17em` }}>Signing up is simple!</h3>
    <ol>
      <li>Enter your user information here.</li>
      <li>
        Select your ERinfo plan.
        <br />
        <b>(JDRF discount will be applied automatically)</b>
      </li>
      <li>Upload your photo and enter your emergency contact information.</li>
      <li>Add additional emergency information and family members.</li>
    </ol>
    <br />
    <h3 style={{ fontSize: `1.17em` }}>After signing up</h3>
    <p>
      <b>During an emergency, first responders can:</b>
      <br />
      <ul>
        <li style={{ marginLeft: 20 }}>Match you with your photo.</li>
        <li style={{ marginLeft: 20 }}>Access your emergency information.</li>
        <li style={{ marginLeft: 20 }}>
          Notify your emergency contacts INSTANTLY!
        </li>
      </ul>
    </p>
  </>
)
