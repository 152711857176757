import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import { SxProps } from "@mui/system"
import { forwardRef } from "react"

interface IProps {
  label?: string
  defaultValue?: any
  onChange: (val: any) => void
  value?: any
  errors?: string[]
  disabled?: boolean
  sx?: SxProps
  checked?: boolean
}

export const RadioButton = forwardRef(
  ({ label, checked, onChange, value, errors, disabled, sx }: IProps) => {
    const errorColor = { color: errors ? `error.main` : `default.main` }

    return (
      <FormControl component="div" sx={sx} error={!!errors}>
        <FormControlLabel
          value={value}
          control={<Radio checked={checked} sx={errorColor} />}
          label={label}
          disabled={disabled}
          sx={errorColor}
          onChange={onChange}
        />
        {!!errors && <FormHelperText>Required</FormHelperText>}
      </FormControl>
    )
  },
)
