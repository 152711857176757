import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as z from "zod"

export const nameLastName = `lastName`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid name`,
    exp: /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u,
  },
]

export const lastName = helpers.setRegexes(regex)
export const yupLastNameSchema = helpers.getYupSchema(regex)
export const lastNameOptional = lastName.optional()

export const lastNameExternal = z.string()
export const lastNameExternalOptional = lastNameExternal.optional()
