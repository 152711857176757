import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import StepperPanel from "@erinfo/brand-ui/src/storybook/other/StepperPanel"
import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { FormData } from "../add-member-context"
import useAddMember from "../use-add-member"
import { AddMemberHeader } from "./AddMemberHeader"
import { stepperSteps } from "./constants"

export const AddMemberWrapper: FC<{
  onSubmit?: (values?: FormData) => void
  nextDisabled?: boolean
  nextLabel?: string
  currStep: number
  formId?: string
  onBack?: () => void
  showAvatar?: boolean
  user: any
  returnUrl?: string
  proApp?: boolean
}> = ({
  children,
  user,
  showAvatar = true,
  currStep,
  onSubmit,
  formId,
  nextDisabled,
  nextLabel,
  onBack,
  returnUrl,
  proApp,
}) => {
  const navigate = useNavigate()
  const { loading, picture } = useAddMember()
  const avatarFile = picture?.value

  const handleBack = () => {
    confirm(``, {
      title: `Cancel?`,
      description: `Are you sure you want to cancel adding a new member?`,
      noAction: `Continue Adding`,
      yesAction: `Cancel`,
      noColor: `primary`,
      yesColor: `error`,
    })
      .then(() => navigate(returnUrl ?? `/dashboard`))
      .catch(() => {})
  }

  return (
    <StepperPanel
      activeStep={currStep}
      steps={stepperSteps}
      sx={{
        "@media(min-width: 480px)": {
          width: `calc(100vw - 50px)`,
          maxWidth: 960,
          minHeight: 650,
        },
        "@media(max-width: 480px)": {
          width: `100%`,
        },
      }}
      onNext={onSubmit}
      nextDisabled={nextDisabled}
      nextLoading={loading}
      nextLabel={nextLabel}
      formId={formId}
      onBack={onBack}
      subHeader={
        <AddMemberHeader user={user} onBack={handleBack} proApp={proApp} />
      }
    >
      {avatarFile && showAvatar && (
        <Avatar url={avatarFile} sx={{ marginTop: 2 }} />
      )}
      {children}
    </StepperPanel>
  )
}
