import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import {
  CorePersonalForm,
  SingleEmergencyContactForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import {
  helpers,
  yupEmailSchema,
  yupFirstNameSchema,
  yupLastNameSchema,
  yupNameSchema,
} from "@erinfo/data-schema"
import { FormikStateUpdate } from "@erinfo/react-utils/src/components/FormikStateUpdate"
import { AccountCircle } from "@mui/icons-material"
import AddIcCallIcon from "@mui/icons-material/AddIcCall"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

import { AddMemberWrapper } from "../components/AddMemberWrapper"
import useAddMember from "../use-add-member"

const { isIntlPhoneNumber } = helpers

const StyledFormCard = styled(FormCard)({
  width: 295,
  "@media(max-width: 480px)": {
    width: `100%`,
  },
})

const iconStyle = {
  color: `#F44336`,
  filter: `brightness(0.7)`,
}

const FormSchema = Yup.object().shape({
  firstName: yupFirstNameSchema.required(`First name is required`),
  lastName: yupLastNameSchema.required(`Last name is required`),
  gender: Yup.string().required(`Required field`),
  birthDate: Yup.string()
    .required(`Required field`)
    .test(`format`, `Must enter MM/DD/YYYY`, (val) =>
      Boolean(val?.match(/\d\d\d\d-\d\d-\d\d/)),
    )
    .test(
      `past`,
      `Date must be in the past`,
      (val) => !val || dayjs(val, `YYYY-MM-DD`).isBefore(dayjs()),
    ),
  emergencyContactName: yupNameSchema.required(`Required field`),
  emergencyContactPhone: Yup.string()
    .test(`intl phone`, `Phone number is not valid`, (val) => {
      const number = val?.replace(/\s/g, ``)
      return number?.slice(0, 2) === `+1`
        ? !!/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
        : !!number?.match(isIntlPhoneNumber.exp)?.[0]
    })
    .max(16, `Too many digits for a phone number`)
    .required(`Mobile number is required`),
  emergencyContactEmail: yupEmailSchema.optional(),
  emergencyContactRelationship: Yup.string().required(`Required field`),
})

export interface FormValues {
  firstName: string
  lastName: string
  birthDate: string
  gender: string
  emergencyContactName: string
  emergencyContactPhone: string
  emergencyContactEmail: string
  emergencyContactRelationship: string
}

const AddMemberStep2 = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isValid, setIsValid] = useState(false)
  const valueRef = useRef<FormValues>()
  const {
    user,
    setMemberData,
    firstName,
    lastName,
    birthDate,
    gender,
    emergencyContactName,
    emergencyContactPhone,
    emergencyContactEmail,
    emergencyContactRelationship,
    proApp,
  } = useAddMember()

  const onSubmit = (values: FormValues) => {
    values.firstName = values.firstName?.trim()
    values.lastName = values.lastName?.trim()
    values.emergencyContactName = values.emergencyContactName?.trim()
    values.emergencyContactEmail = values.emergencyContactEmail?.trim()
    setMemberData(values)
    navigate(`/add-member/step-3`)
  }

  const onBack = () => {
    setMemberData(valueRef.current)
    navigate(`/add-member/step-1`)
  }

  return (
    <AddMemberWrapper
      currStep={1}
      formId="step-1"
      onBack={onBack}
      user={user}
      nextDisabled={!isValid}
      proApp={proApp}
    >
      <Formik
        initialValues={{
          firstName: firstName || ``,
          lastName: lastName || ``,
          birthDate: birthDate || ``,
          gender: gender || ``,
          emergencyContactName: emergencyContactName || ``,
          emergencyContactPhone: emergencyContactPhone || ``,
          emergencyContactEmail: emergencyContactEmail || ``,
          emergencyContactRelationship: emergencyContactRelationship || ``,
        }}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isValid }) => {
          valueRef.current = values

          return (
            <Form id="step-1">
              <FormikStateUpdate
                setIsValid={setIsValid}
                requireDirty={
                  location.state !== `RETURN_FROM_STEP_3` && !values.firstName
                }
              />
              <Box
                sx={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  marginTop: 2,
                  marginBottom: 2,
                  justifyContent: `space-between`,
                  "@media(min-width: 480px)": {
                    width: 620,
                  },
                }}
              >
                <StyledFormCard
                  Icon={<AccountCircle sx={iconStyle} />}
                  headerText="Basic Information"
                >
                  <CorePersonalForm setFieldValue={setFieldValue} />
                </StyledFormCard>
                <StyledFormCard
                  Icon={<AddIcCallIcon sx={iconStyle} />}
                  headerText="Emergency Contact"
                >
                  <SingleEmergencyContactForm setFieldValue={setFieldValue} />
                </StyledFormCard>
              </Box>
            </Form>
          )
        }}
      </Formik>
      <Typography sx={{ opacity: 0.38, mb: 1 }}>
        *Edits and changes can be made later.
      </Typography>
    </AddMemberWrapper>
  )
}

export default AddMemberStep2
