import {
  AccountCircle,
  MedicalServices,
  Menu,
  PersonAdd,
  Visibility,
} from "@mui/icons-material"
import AppBar from "@mui/material/AppBar"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"

export const MobileBottomMenu = ({ onAddMember, openMenu }) => {
  const handleClick = (name) => (event) => {
    event.preventDefault()
    const el = document.getElementById(name)
    el?.scrollIntoView({ behavior: `smooth`, block: `start` })
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        top: `auto`,
        bottom: 0,
        bgcolor: `background.default`,
        zIndex: 900,
      }}
    >
      <Toolbar sx={{ justifyContent: `space-between` }}>
        <IconButton href="#personal" onClick={handleClick(`personalPanel`)}>
          <AccountCircle sx={{ color: `personal.main` }} />
        </IconButton>
        <IconButton
          href="#identifying"
          onClick={handleClick(`identifyingPanel`)}
        >
          <Visibility sx={{ color: `identify.main` }} />
        </IconButton>
        <IconButton href="#medical" onClick={handleClick(`medicalPanel`)}>
          <MedicalServices sx={{ color: `medical.main` }} />
        </IconButton>
        <IconButton onClick={onAddMember}>
          <PersonAdd sx={{ color: `plan.main` }} />
        </IconButton>
        <IconButton aria-label="open drawer" onClick={openMenu}>
          <Menu sx={{ color: `rgba(0,0,0,.54)` }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}
