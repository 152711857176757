import "./cropper.scss"

import { ReactComponent as IApply } from "@erinfo/brand-ui/src/assets/svg/apply.svg"
import { ReactComponent as ICancel } from "@erinfo/brand-ui/src/assets/svg/cancel.svg"
import { ReactComponent as IClose } from "@erinfo/brand-ui/src/assets/svg/close.svg"
import { ReactComponent as IPhotoRotate } from "@erinfo/brand-ui/src/assets/svg/photo-rotate.svg"
import { ButtonWithIcon } from "@erinfo/brand-ui/src/storybook/buttons/with-icon/_"
import { Slider } from "@erinfo/brand-ui/src/storybook/other/slider/_"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import { styled } from "@mui/material/styles"
import { useRef, useState } from "react"
import Cropper from "react-cropper"

const MAX_WIDTH = 500
const MAX_HEIGHT = 600
const NINETY_DEG_CCW = -90
const THREE_SIXTY = 360
const DEFAULT_ASPECT = 11 / 16

export const EditDialog = ({
  photo,
  setPhoto,
  aspectRatio = DEFAULT_ASPECT,
}) => {
  const [photoScale, setPhotoScale] = useState(1)
  const [photoRotationDegree, setPhotoRotationDegree] = useState(0)
  const cropperRef = useRef<HTMLImageElement>(null)
  const { innerWidth: width, innerHeight: height } = window

  const rotate = (amount = NINETY_DEG_CCW) => {
    const newPhotoRotationDegree = (() => {
      const degree = photoRotationDegree + amount
      return Math.abs(degree) === THREE_SIXTY ? 0 : degree
    })()
    setPhotoRotationDegree(newPhotoRotationDegree)
    const imgElement: any = cropperRef?.current
    const cropper: any = imgElement?.cropper
    if (cropper) cropper.rotate(amount)
  }

  return (
    <Dialog open={true}>
      <DialogTitle style={{ padding: 0 }}>
        <HeaderWrapper>
          <ButtonWithIcon icon={IClose} onClick={() => setPhoto(``)} />
          <Title>Edit Photo</Title>
        </HeaderWrapper>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Cropper
          ref={cropperRef}
          src={photo}
          style={{
            height: Math.min(height - 50, MAX_HEIGHT),
            width: Math.min(width - 50, MAX_WIDTH),
          }}
          aspectRatio={aspectRatio}
          guides={false}
          minContainerWidth={315}
          minContainerHeight={452}
        />
      </DialogContent>
      <DialogActions style={{ padding: 0 }}>
        <FooterContainer>
          <CropperControlsWrapper>
            <StyledSlider
              step={0.1}
              min={0.25}
              max={2}
              value={photoScale}
              onChange={(event) => {
                const { value: scale } = event.srcElement || event.target
                const imgElement: any = cropperRef?.current
                const cropper: any = imgElement?.cropper
                if (cropper) {
                  const containerData = cropper.getContainerData()
                  setPhotoScale(scale)
                  cropper.zoomTo(scale, {
                    x: containerData.width / 2,
                    y: containerData.height / 2,
                  })
                }
              }}
            />
            <IPhotoRotate onClick={() => rotate()} />
          </CropperControlsWrapper>

          <ButtonWrapper>
            <SubWrapper>
              <CancelButton onClick={() => setPhoto(``)} />
              <ButtonText>Cancel</ButtonText>
            </SubWrapper>
            <SubWrapper>
              <ApplyButton
                onClick={() => {
                  const imgElement: any = cropperRef?.current
                  const cropper: any = imgElement?.cropper
                  const result = cropper
                    .getCroppedCanvas({ maxHeight: 600 })
                    .toDataURL()
                  setPhoto(result)
                }}
              />
              <ButtonText>Apply</ButtonText>
            </SubWrapper>
          </ButtonWrapper>
        </FooterContainer>
      </DialogActions>
    </Dialog>
  )
}

const HeaderWrapper = styled(`div`)({
  position: `absolute`,
  top: `0`,
  zIndex: 1,
  display: `flex`,
  alignItems: `center`,
  width: `100%`,
  height: `40px`,
  background: `rgba(0, 0, 0, 0.2)`,
  padding: `0 7px`,
  boxSizing: `border-box`,
})

const Title = styled(`h3`)({
  fontWeight: 500,
  fontSize: `20px`,
  lineHeight: `23px`,
  color: `white`,
  margin: `0 auto 0 30px`,
})

const FooterContainer = styled(`div`)({
  position: `absolute`,
  bottom: `0`,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  width: `100%`,
  height: `121px`,
  background: `rgba(0, 0, 0, 0.2)`,
})

const CropperControlsWrapper = styled(`div`)({
  display: `flex`,
  width: `261px`,
  margin: `0 0 9px 0`,
  svg: { width: `36px`, height: `36px` },
  "*": { cursor: `pointer` },
})

const StyledSlider = styled(Slider)({
  background: `none`,
  margin: `0 25px 0 0`,
})

const ButtonWrapper = styled(`div`)({
  display: `flex`,
})

const SubWrapper = styled(`div`)({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  margin: `0 25.5px 0`,
  cursor: `pointer`,
})

const CancelButton = styled(ICancel)({ width: `47.5px`, height: `47.5px` })

const ApplyButton = styled(IApply)({ width: `47.5px`, height: `47.5px` })

const ButtonText = styled(`span`)({
  font: `400 10px/12px Roboto, sans-serif`,
  margin: `4px 0 0`,
  color: `#fff`,
  WebkitTextFillColor: `white`,
})
