import { styled } from "@mui/material/styles"
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip"
import { ReactNode } from "react"

interface IProps {
  children: ReactNode
  open: boolean
  checklist: { [key: string]: boolean }
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `#0B79D0`,
    color: `white`,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    borderRadius: `5px`,
    lineHeight: `1rem`,
  },
}))

export const ProgressTooltip = ({ children, open, checklist = {} }: IProps) => {
  return (
    <HtmlTooltip
      open={open}
      title={
        <>
          {Object.entries(checklist).map(([key, value]) => (
            <>
              {value ? `✓` : `🟡`} {key}
              <br />
            </>
          ))}
        </>
      }
    >
      {children}
    </HtmlTooltip>
  )
}
