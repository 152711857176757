import { MedicalSelectInput } from "@erinfo/brand-ui/src/storybook/inputs/medical-select"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { medications } from "@erinfo/data-schema"
import {
  filterValues,
  useHandleNoneSelection,
} from "@erinfo/react-utils/src/helpers/form/formUtils"
import { Add, Delete } from "@mui/icons-material"
import { Box, Button, Divider } from "@mui/material"
import { Field } from "formik"
import { Fragment } from "react"

export const medicationFormFields = [`medication`, `dosage`, `frequency`]

export const MedicationsForm = ({
  addField,
  removeField,
  setFieldValue,
  setFieldValueOrRemove,
  values,
  fieldCounts,
}) => {
  const hideDelete =
    !values?.medication_0 && !values?.dosage_0 && !values?.frequency_0

  useHandleNoneSelection({
    formType: `medications`,
    formFields: medicationFormFields,
    values,
    fieldCounts,
    removeField,
    setFieldValue,
  })

  return (
    <>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values?.medication_0}
        onClick={() => addField(`medications`, `medication`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.medications)]
        .map((_, i) => i)
        .reverse()
        .map((i, idx) => (
          <Fragment key={`medication_${i}`}>
            {i < fieldCounts.medications - 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
            <Field
              label="Medications"
              name={`medication_${i}`}
              component={MedicalSelectInput}
              setFieldValue={setFieldValueOrRemove(setFieldValue, () =>
                removeField(`medications`, i),
              )}
              dataForMultiSelect={filterValues(
                `medication`,
                values,
                medications,
              )}
              style={{ minWidth: 200 }}
            />
            <Field
              label="Dosage"
              name={`dosage_${i}`}
              component={TextInput}
              disabled={!values.medication_0 || values.medication_0 === `None`}
            />
            <Field
              label="Frequency"
              name={`frequency_${i}`}
              component={TextInput}
              disabled={!values.medication_0 || values.medication_0 === `None`}
            />
            {(idx !== 0 || !hideDelete) && (
              <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
                <Button
                  onClick={() => removeField(`medications`, i)}
                  color="error"
                  sx={{ mt: 1 }}
                >
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Medication
                </Button>
              </Box>
            )}
          </Fragment>
        ))}
    </>
  )
}
