import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { RootState } from "@erinfo/consumer/src/store"
import { Archive, ArrowBack, Info } from "@mui/icons-material"
import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC, useState } from "react"

export const MemberHeader: FC<{
  user: RootState["user"]
  onBack: () => void
  archiveMember: () => void
  sx?: SxProps
}> = ({ user, onBack, archiveMember, sx }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const avatarFile = user?.pictures?.[0]?.src

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 2,
        paddingLeft: 2,
        position: `absolute`,
        top: 0,
        width: `100%`,
        background: `#E0E0E0`,
        ...sx,
      }}
    >
      <Box display="flex" sx={{ flex: 1, alignItems: `center` }}>
        <IconButton color="primary" sx={{ marginRight: 1 }} onClick={onBack}>
          <ArrowBack sx={{ width: 32, height: 32 }} />
        </IconButton>
        <Avatar url={avatarFile} sx={{ width: 45, height: 45 }} />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        onClick={archiveMember}
        sx={{ cursor: `pointer`, mr: 1 }}
      >
        <Archive color="error" />
        <Typography
          sx={{
            color: `error.main`,
            paddingRight: 1,
            marginLeft: 1,
            fontWeight: 500,
          }}
        >
          Archive this Member
        </Typography>
      </Box>
      <Tooltip
        open={showTooltip}
        enterTouchDelay={100}
        onClick={() => setShowTooltip((p) => !p)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        title="Remove from your account, but stay archived in ERinfo's database."
      >
        <Info color="primary" />
      </Tooltip>
    </Box>
  )
}
