export const medications = [
  { title: `None`, value: `none`, selected: false },
  { title: `Accupril`, value: `accupril`, selected: false },
  { title: `Acetaminophen`, value: `acetaminophen`, selected: false },
  {
    title: `Acetaminophen; Hydrocodone Bitartrate`,
    value: `acetaminophenHydrocodoneBitartrate`,
    selected: false,
  },
  { title: `Aciphex`, value: `aciphex`, selected: false },
  { title: `Actos`, value: `actos`, selected: false },
  { title: `Acyclovir`, value: `acyclovir`, selected: false },
  { title: `Adalat CC`, value: `adalatCc`, selected: false },
  { title: `Adalimumab`, value: `adalimumab`, selected: false },
  { title: `Adderall`, value: `adderall`, selected: false },
  { title: `Albuterol`, value: `albuterol`, selected: false },
  {
    title: `Albuterol Sulfate; Ipratropium Bromide`,
    value: `albuterolSulfateIpratropiumBromide`,
    selected: false,
  },
  { title: `Alendronate Sodium`, value: `alendronateSodium`, selected: false },
  { title: `Alesse-28`, value: `alesse`, selected: false },
  { title: `Allegra`, value: `allegra`, selected: false },
  { title: `Allegra-D`, value: `allegraD`, selected: false },
  { title: `Allopurinol`, value: `allopurinol`, selected: false },
  { title: `Alphagan`, value: `alphagan`, selected: false },
  { title: `Alprazolam`, value: `alprazolam`, selected: false },
  { title: `Altace`, value: `altace`, selected: false },
  { title: `Amaryl`, value: `amaryl`, selected: false },
  { title: `Ambien`, value: `ambien`, selected: false },
  {
    title: `Amiodarone Hydrochloride`,
    value: `amiodaroneHydrochloride`,
    selected: false,
  },
  { title: `Amitriptyline`, value: `amitriptyline`, selected: false },
  { title: `Amitriptyline HCL`, value: `amitriptylineHcl`, selected: false },
  {
    title: `Amlodipine Besylate`,
    value: `amlodipineBesylate`,
    selected: false,
  },
  {
    title: `Amlodipine Besylate; Benazepril Hydrochloride`,
    value: `amlodipineBesylateBenazeprilHydrochloride`,
    selected: false,
  },
  { title: `Amoxicillin`, value: `amoxicillin`, selected: false },
  {
    title: `Amoxicillin Trihydrate`,
    value: `amoxicillinTrihydrate`,
    selected: false,
  },
  {
    title: `Amoxicillin; Clavulanate Potassium`,
    value: `amoxicillinClavulanatePotassium`,
    selected: false,
  },
  { title: `Amoxil`, value: `amoxil`, selected: false },
  { title: `Amphetamine`, value: `amphetamine`, selected: false },
  { title: `Amyl nitrite`, value: `amylNitrite`, selected: false },
  { title: `Anabolic Steroids`, value: `anabolicSteroids`, selected: false },
  { title: `Apixaban`, value: `apixaban`, selected: false },
  { title: `Aricept`, value: `aricept`, selected: false },
  { title: `Aripiprazole`, value: `aripiprazole`, selected: false },
  { title: `Aspirin`, value: `aspirin`, selected: false },
  { title: `Atenolol`, value: `atenolol`, selected: false },
  { title: `Atenolol (Mylan)`, value: `atenololMylan`, selected: false },
  { title: `Atorvastatin`, value: `atorvastatin`, selected: false },
  { title: `Atrovent`, value: `atrovent`, selected: false },
  { title: `Augmentin`, value: `augmentin`, selected: false },
  { title: `Avandia`, value: `avandia`, selected: false },
  { title: `Avapro`, value: `avapro`, selected: false },
  { title: `Azithromycin`, value: `azithromycin`, selected: false },
  { title: `Azmacort`, value: `azmacort`, selected: false },
  {
    title: `Bacitracin; Neomycin; Polymyxin B`,
    value: `bacitracinNeomycinPolymyxinB`,
    selected: false,
  },
  { title: `Baclofen`, value: `baclofen`, selected: false },
  { title: `Bactroban`, value: `bactroban`, selected: false },
  { title: `Baycol`, value: `baycol`, selected: false },
  { title: `Beclomethasone`, value: `beclomethasone`, selected: false },
  {
    title: `Benazepril Hydrochloride`,
    value: `benazeprilHydrochloride`,
    selected: false,
  },
  { title: `Benzonatate`, value: `benzonatate`, selected: false },
  { title: `Biaxin`, value: `biaxin`, selected: false },
  {
    title: `Brimonidine Tartrate`,
    value: `brimonidineTartrate`,
    selected: false,
  },
  {
    title: `Budesonide; Formoterol`,
    value: `budesonideFormoterol`,
    selected: false,
  },
  { title: `Bupropion`, value: `bupropion`, selected: false },
  { title: `Buspar`, value: `buspar`, selected: false },
  {
    title: `Buspirone Hydrochloride`,
    value: `buspironeHydrochloride`,
    selected: false,
  },
  {
    title: `Calcium; Cholecalciferol`,
    value: `calciumCholecalciferol`,
    selected: false,
  },
  { title: `Canagliflozin`, value: `canagliflozin`, selected: false },
  { title: `Carbamazepine`, value: `carbamazepine`, selected: false },
  { title: `Carbidopa; Levodopa`, value: `carbidopaLevodopa`, selected: false },
  { title: `Cardizem CD`, value: `cardizemCd`, selected: false },
  { title: `Cardura`, value: `cardura`, selected: false },
  { title: `Carisoprodol`, value: `carisoprodol`, selected: false },
  { title: `Carvedilol`, value: `carvedilol`, selected: false },
  { title: `Ceftin`, value: `ceftin`, selected: false },
  { title: `Cefzil`, value: `cefzil`, selected: false },
  { title: `Celebrex`, value: `celebrex`, selected: false },
  { title: `Celecoxib`, value: `celecoxib`, selected: false },
  { title: `Celexa`, value: `celexa`, selected: false },
  { title: `Cephalexin`, value: `cephalexin`, selected: false },
  {
    title: `Cetirizine Hydrochloride`,
    value: `cetirizineHydrochloride`,
    selected: false,
  },
  { title: `Chlorthalidone`, value: `chlorthalidone`, selected: false },
  {
    title: `Cholecalciferol; .Alpha.-Tocopherol`,
    value: `cholecalciferolAlphaTocopherol`,
    selected: false,
  },
  { title: `Cipro`, value: `cipro`, selected: false },
  { title: `Ciprofloxacin`, value: `ciprofloxacin`, selected: false },
  { title: `Citalopram`, value: `citalopram`, selected: false },
  { title: `Claritin`, value: `claritin`, selected: false },
  { title: `Claritin Reditabs`, value: `claritinReditabs`, selected: false },
  { title: `Claritin-D 12HR`, value: `claritinDHr`, selected: false },
  { title: `Claritin-D 24HR`, value: `claritinDHr`, selected: false },
  { title: `Climara`, value: `climara`, selected: false },
  { title: `Clindamycin`, value: `clindamycin`, selected: false },
  {
    title: `Clobetasol Propionate`,
    value: `clobetasolPropionate`,
    selected: false,
  },
  { title: `Clonazepam`, value: `clonazepam`, selected: false },
  { title: `Clonidine`, value: `clonidine`, selected: false },
  { title: `Clonidine HCL`, value: `clonidineHcl`, selected: false },
  {
    title: `Clopidogrel Bisulfate`,
    value: `clopidogrelBisulfate`,
    selected: false,
  },
  { title: `Cocaine`, value: `cocaine`, selected: false },
  { title: `Combivent`, value: `combivent`, selected: false },
  { title: `Coumadin`, value: `coumadin`, selected: false },
  { title: `Cozaar`, value: `cozaar`, selected: false },
  { title: `Cyanocobalamin`, value: `cyanocobalamin`, selected: false },
  { title: `Cyclobenzaprine`, value: `cyclobenzaprine`, selected: false },
  { title: `Depakote`, value: `depakote`, selected: false },
  {
    title: `Desogestrel; Ethinyl Estradiol`,
    value: `desogestrelEthinylEstradiol`,
    selected: false,
  },
  { title: `Detrol`, value: `detrol`, selected: false },
  { title: `Dexlansoprazole`, value: `dexlansoprazole`, selected: false },
  {
    title: `Dextroamphetamine; Dextroamphetamine Saccharate; Amphetamine; Amphetamine Aspartate`,
    value: `dextroamphetamineDextroamphetamineSaccharateAmphetamineAmphetamineAspartate`,
    selected: false,
  },
  { title: `Diazepam`, value: `diazepam`, selected: false },
  { title: `Diclofenac`, value: `diclofenac`, selected: false },
  {
    title: `Dicyclomine Hydrochloride`,
    value: `dicyclomineHydrochloride`,
    selected: false,
  },
  { title: `Diflucan`, value: `diflucan`, selected: false },
  { title: `Digoxin`, value: `digoxin`, selected: false },
  { title: `Dilantin`, value: `dilantin`, selected: false },
  {
    title: `Diltiazem Hydrochloride`,
    value: `diltiazemHydrochloride`,
    selected: false,
  },
  { title: `Diovan`, value: `diovan`, selected: false },
  { title: `Diovan HCT`, value: `diovanHct`, selected: false },
  { title: `Divalproex Sodium`, value: `divalproexSodium`, selected: false },
  { title: `Docusate`, value: `docusate`, selected: false },
  {
    title: `Donepezil Hydrochloride`,
    value: `donepezilHydrochloride`,
    selected: false,
  },
  {
    title: `Dorzolamide Hydrochloride; Timolol Maleate`,
    value: `dorzolamideHydrochlorideTimololMaleate`,
    selected: false,
  },
  { title: `Doxazosin Mesylate`, value: `doxazosinMesylate`, selected: false },
  { title: `Doxycycline`, value: `doxycycline`, selected: false },
  {
    title: `Drospirenone; Ethinyl Estradiol`,
    value: `drospirenoneEthinylEstradiol`,
    selected: false,
  },
  { title: `Duloxetine`, value: `duloxetine`, selected: false },
  { title: `Effexor XR`, value: `effexorXr`, selected: false },
  { title: `Elocon`, value: `elocon`, selected: false },
  { title: `Enalapril Maleate`, value: `enalaprilMaleate`, selected: false },
  { title: `Ergocalciferol`, value: `ergocalciferol`, selected: false },
  { title: `Ery-Tab`, value: `eryTab`, selected: false },
  {
    title: `Escitalopram Oxalate`,
    value: `escitalopramOxalate`,
    selected: false,
  },
  { title: `Esomeprazole`, value: `esomeprazole`, selected: false },
  { title: `Estradiol`, value: `estradiol`, selected: false },
  {
    title: `Estrogens, Conjugated`,
    value: `estrogensConjugated`,
    selected: false,
  },
  {
    title: `Ethinyl Estradiol; Levonorgestrel`,
    value: `ethinylEstradiolLevonorgestrel`,
    selected: false,
  },
  {
    title: `Ethinyl Estradiol; Norethindrone`,
    value: `ethinylEstradiolNorethindrone`,
    selected: false,
  },
  {
    title: `Ethinyl Estradiol; Norgestimate`,
    value: `ethinylEstradiolNorgestimate`,
    selected: false,
  },
  { title: `Evista`, value: `evista`, selected: false },
  { title: `Ezetimibe`, value: `ezetimibe`, selected: false },
  { title: `Famotidine`, value: `famotidine`, selected: false },
  { title: `Fenofibrate`, value: `fenofibrate`, selected: false },
  { title: `Ferrous Sulfate`, value: `ferrousSulfate`, selected: false },
  { title: `Finasteride`, value: `finasteride`, selected: false },
  { title: `Flomax`, value: `flomax`, selected: false },
  { title: `Flonase`, value: `flonase`, selected: false },
  { title: `Flovent`, value: `flovent`, selected: false },
  { title: `Fluconazole`, value: `fluconazole`, selected: false },
  {
    title: `Fluoxetine Hydrochloride`,
    value: `fluoxetineHydrochloride`,
    selected: false,
  },
  { title: `Fluticasone`, value: `fluticasone`, selected: false },
  {
    title: `Fluticasone Propionate; Salmeterol Xinafoate`,
    value: `fluticasonePropionateSalmeterolXinafoate`,
    selected: false,
  },
  { title: `Folic Acid`, value: `folicAcid`, selected: false },
  { title: `Fosamax`, value: `fosamax`, selected: false },
  { title: `Furosemide`, value: `furosemide`, selected: false },
  { title: `Gabapentin`, value: `gabapentin`, selected: false },
  { title: `Gemfibrozil`, value: `gemfibrozil`, selected: false },
  { title: `Glimepiride`, value: `glimepiride`, selected: false },
  { title: `Glipizide`, value: `glipizide`, selected: false },
  { title: `Glucophage`, value: `glucophage`, selected: false },
  { title: `Glucotrol XL`, value: `glucotrolXl`, selected: false },
  { title: `Glyburide`, value: `glyburide`, selected: false },
  { title: `Guanfacine`, value: `guanfacine`, selected: false },
  {
    title: `Hydralazine Hydrochloride`,
    value: `hydralazineHydrochloride`,
    selected: false,
  },
  {
    title: `Hydrochlorothiazide`,
    value: `hydrochlorothiazide`,
    selected: false,
  },
  {
    title: `Hydrochlorothiazide; Lisinopril`,
    value: `hydrochlorothiazideLisinopril`,
    selected: false,
  },
  {
    title: `Hydrochlorothiazide; Losartan Potassium`,
    value: `hydrochlorothiazideLosartanPotassium`,
    selected: false,
  },
  {
    title: `Hydrochlorothiazide; Triamterene`,
    value: `hydrochlorothiazideTriamterene`,
    selected: false,
  },
  {
    title: `Hydrochlorothiazide; Valsartan`,
    value: `hydrochlorothiazideValsartan`,
    selected: false,
  },
  { title: `Hydrocodone`, value: `hydrocodone`, selected: false },
  {
    title: `Hydrocodone Bitartrate`,
    value: `hydrocodoneBitartrate`,
    selected: false,
  },
  { title: `Hydrocortisone`, value: `hydrocortisone`, selected: false },
  {
    title: `Hydroxychloroquine Sulfate`,
    value: `hydroxychloroquineSulfate`,
    selected: false,
  },
  { title: `Hydroxyzine`, value: `hydroxyzine`, selected: false },
  { title: `Hyzaar`, value: `hyzaar`, selected: false },
  { title: `Ibuprofen`, value: `ibuprofen`, selected: false },
  { title: `Imitrex`, value: `imitrex`, selected: false },
  { title: `Insulin Aspart`, value: `insulinAspart`, selected: false },
  { title: `Insulin Detemir`, value: `insulinDetemir`, selected: false },
  { title: `Insulin Glargine`, value: `insulinGlargine`, selected: false },
  { title: `Insulin Humalog`, value: `InsulinHumalog`, selected: false },
  { title: `Insulin Human`, value: `insulinHuman`, selected: false },
  { title: `Insulin Levemir`, value: `insulinLevemir`, selected: false },
  { title: `Insulin Lispro`, value: `insulinLispro`, selected: false },
  { title: `Ipratropium`, value: `ipratropium`, selected: false },
  { title: `Irbesartan`, value: `irbesartan`, selected: false },
  {
    title: `Isosorbide Mononitrate`,
    value: `isosorbideMononitrate`,
    selected: false,
  },
  { title: `K-Dur`, value: `kDur`, selected: false },
  { title: `Ketamine`, value: `ketamine`, selected: false },
  { title: `Klor-Con 10`, value: `klorCon`, selected: false },
  { title: `Lamotrigine`, value: `lamotrigine`, selected: false },
  { title: `Lanoxin`, value: `lanoxin`, selected: false },
  { title: `Lansoprazole`, value: `lansoprazole`, selected: false },
  { title: `Latanoprost`, value: `latanoprost`, selected: false },
  { title: `Lescol`, value: `lescol`, selected: false },
  { title: `Levaquin`, value: `levaquin`, selected: false },
  { title: `Levetiracetam`, value: `levetiracetam`, selected: false },
  {
    title: `Levocetirizine Dihydrochloride`,
    value: `levocetirizineDihydrochloride`,
    selected: false,
  },
  { title: `Levofloxacin`, value: `levofloxacin`, selected: false },
  { title: `Levothroid`, value: `levothroid`, selected: false },
  { title: `Levothyroxine`, value: `levothyroxine`, selected: false },
  { title: `Levoxyl`, value: `levoxyl`, selected: false },
  { title: `Linagliptin`, value: `linagliptin`, selected: false },
  { title: `Lipitor`, value: `lipitor`, selected: false },
  { title: `Liraglutide`, value: `liraglutide`, selected: false },
  {
    title: `Lisdexamfetamine Dimesylate`,
    value: `lisdexamfetamineDimesylate`,
    selected: false,
  },
  { title: `Lisinopril`, value: `lisinopril`, selected: false },
  { title: `Lo Ovral`, value: `loOvral`, selected: false },
  { title: `Loestrin FE`, value: `loestrinFe`, selected: false },
  { title: `Loratadine`, value: `loratadine`, selected: false },
  { title: `Lorazepam`, value: `lorazepam`, selected: false },
  { title: `Losartan Potassium`, value: `losartanPotassium`, selected: false },
  { title: `Lotensin`, value: `lotensin`, selected: false },
  { title: `Lotrel`, value: `lotrel`, selected: false },
  { title: `Lotrisone`, value: `lotrisone`, selected: false },
  { title: `Lovastatin`, value: `lovastatin`, selected: false },
  {
    title: `Lysergic Acid Diethylamide`,
    value: `lysergicAcidDiethylamide`,
    selected: false,
  },
  { title: `Macrobid`, value: `macrobid`, selected: false },
  { title: `Magnesium`, value: `magnesium`, selected: false },
  { title: `Marijuana`, value: `marijuana`, selected: false },
  { title: `MDMA`, value: `mdma`, selected: false },
  {
    title: `Meclizine Hydrochloride`,
    value: `meclizineHydrochloride`,
    selected: false,
  },
  {
    title: `Medroxyprogesterone`,
    value: `medroxyprogesterone`,
    selected: false,
  },
  { title: `Meloxicam`, value: `meloxicam`, selected: false },
  {
    title: `Memantine Hydrochloride`,
    value: `memantineHydrochloride`,
    selected: false,
  },
  { title: `Mescaline`, value: `mescaline`, selected: false },
  {
    title: `Metformin Hydrochloride`,
    value: `metforminHydrochloride`,
    selected: false,
  },
  {
    title: `Metformin Hydrochloride; Sitagliptin Phosphate`,
    value: `metforminHydrochlorideSitagliptinPhosphate`,
    selected: false,
  },
  { title: `Methamphetamine`, value: `methamphetamine`, selected: false },
  { title: `Methocarbamol`, value: `methocarbamol`, selected: false },
  { title: `Methotrexate`, value: `methotrexate`, selected: false },
  {
    title: `Methylcellulose (4000 Mpa.S)`,
    value: `methylcelluloseMpaS`,
    selected: false,
  },
  { title: `Methylphenidate`, value: `methylphenidate`, selected: false },
  { title: `Methylprednisolone`, value: `methylprednisolone`, selected: false },
  { title: `Metoprolol`, value: `metoprolol`, selected: false },
  {
    title: `Metoprolol Tartrate`,
    value: `metoprololTartrate`,
    selected: false,
  },
  { title: `Metronidazole`, value: `metronidazole`, selected: false },
  { title: `Miacalcin`, value: `miacalcin`, selected: false },
  { title: `Mirtazapine`, value: `mirtazapine`, selected: false },
  { title: `Mometasone`, value: `mometasone`, selected: false },
  { title: `Monopril`, value: `monopril`, selected: false },
  { title: `Montelukast`, value: `montelukast`, selected: false },
  { title: `Morphine`, value: `morphine`, selected: false },
  { title: `Mupirocin`, value: `mupirocin`, selected: false },
  { title: `Naproxen`, value: `naproxen`, selected: false },
  { title: `Naproxen Sodium`, value: `naproxenSodium`, selected: false },
  { title: `Nasonex`, value: `nasonex`, selected: false },
  {
    title: `Nebivolol Hydrochloride`,
    value: `nebivololHydrochloride`,
    selected: false,
  },
  { title: `Neurontin`, value: `neurontin`, selected: false },
  { title: `Nicotine`, value: `nicotine`, selected: false },
  { title: `Nifedipine`, value: `nifedipine`, selected: false },
  { title: `Nitrofurantoin`, value: `nitrofurantoin`, selected: false },
  { title: `Nitroglycerin`, value: `nitroglycerin`, selected: false },
  {
    title: `Nortriptyline Hydrochloride`,
    value: `nortriptylineHydrochloride`,
    selected: false,
  },
  { title: `Norvasc`, value: `norvasc`, selected: false },
  {
    title: `Olmesartan Medoxomil`,
    value: `olmesartanMedoxomil`,
    selected: false,
  },
  {
    title: `Omega-3-acid Ethyl Esters`,
    value: `omegaAcidEthylEsters`,
    selected: false,
  },
  { title: `Omeprazole`, value: `omeprazole`, selected: false },
  { title: `Ondansetron`, value: `ondansetron`, selected: false },
  { title: `Ortho Cyclen`, value: `orthoCyclen`, selected: false },
  { title: `Ortho Tri-Cyclen`, value: `orthoTriCyclen`, selected: false },
  {
    title: `Other Medications - See Notes`,
    value: `otherMedications`,
    selected: false,
  },
  { title: `Oxybutynin`, value: `oxybutynin`, selected: false },
  { title: `Oxycodone`, value: `oxycodone`, selected: false },
  { title: `OxyContin`, value: `oxycontin`, selected: false },
  {
    title: `Pantoprazole Sodium`,
    value: `pantoprazoleSodium`,
    selected: false,
  },
  { title: `Paroxetine`, value: `paroxetine`, selected: false },
  { title: `Paxil`, value: `paxil`, selected: false },
  { title: `Penicillin VK`, value: `penicillinVk`, selected: false },
  { title: `Pepcid`, value: `pepcid`, selected: false },
  { title: `Phenergan`, value: `phenergan`, selected: false },
  { title: `Pioglitazone`, value: `pioglitazone`, selected: false },
  { title: `Plavix`, value: `plavix`, selected: false },
  { title: `Plendil`, value: `plendil`, selected: false },
  {
    title: `Polyethylene Glycol 3350`,
    value: `polyethyleneGlycol`,
    selected: false,
  },
  { title: `Potassium`, value: `potassium`, selected: false },
  { title: `Potassium Chloride`, value: `potassiumChloride`, selected: false },
  { title: `Pravachol`, value: `pravachol`, selected: false },
  { title: `Pravastatin Sodium`, value: `pravastatinSodium`, selected: false },
  { title: `Prednisolone`, value: `prednisolone`, selected: false },
  { title: `Prednisone`, value: `prednisone`, selected: false },
  { title: `Pregabalin`, value: `pregabalin`, selected: false },
  { title: `Premarin`, value: `premarin`, selected: false },
  { title: `Prempro`, value: `prempro`, selected: false },
  { title: `Prevacid`, value: `prevacid`, selected: false },
  { title: `Prilosec`, value: `prilosec`, selected: false },
  { title: `Prinivil`, value: `prinivil`, selected: false },
  { title: `Procardia XL`, value: `procardiaXl`, selected: false },
  { title: `Progesterone`, value: `progesterone`, selected: false },
  { title: `Promethazine`, value: `promethazine`, selected: false },
  {
    title: `Promethazine Hydrochloride`,
    value: `promethazineHydrochloride`,
    selected: false,
  },
  { title: `Propoxyphene`, value: `propoxyphene`, selected: false },
  {
    title: `Propranolol Hydrochloride`,
    value: `propranololHydrochloride`,
    selected: false,
  },
  { title: `Proventil HFA`, value: `proventilHfa`, selected: false },
  { title: `Prozac`, value: `prozac`, selected: false },
  { title: `Psilocybin`, value: `psilocybin`, selected: false },
  {
    title: `Quetiapine Fumarate`,
    value: `quetiapineFumarate`,
    selected: false,
  },
  { title: `Ramipril`, value: `ramipril`, selected: false },
  { title: `Ranitidine`, value: `ranitidine`, selected: false },
  { title: `Relafen`, value: `relafen`, selected: false },
  { title: `Remeron`, value: `remeron`, selected: false },
  { title: `Risperdal`, value: `risperdal`, selected: false },
  { title: `Risperidone`, value: `risperidone`, selected: false },
  { title: `Ritalin`, value: `ritalin`, selected: false },
  { title: `Rivaroxaban`, value: `rivaroxaban`, selected: false },
  { title: `Rohypnol`, value: `rohypnol`, selected: false },
  {
    title: `Ropinirole Hydrochloride`,
    value: `ropiniroleHydrochloride`,
    selected: false,
  },
  {
    title: `Rosuvastatin Calcium`,
    value: `rosuvastatinCalcium`,
    selected: false,
  },
  { title: `Roxicet`, value: `roxicet`, selected: false },
  { title: `Serevent`, value: `serevent`, selected: false },
  {
    title: `Sertraline Hydrochloride`,
    value: `sertralineHydrochloride`,
    selected: false,
  },
  { title: `Serzone`, value: `serzone`, selected: false },
  { title: `Simvastatin`, value: `simvastatin`, selected: false },
  { title: `Singulair`, value: `singulair`, selected: false },
  {
    title: `Sitagliptin Phosphate`,
    value: `sitagliptinPhosphate`,
    selected: false,
  },
  {
    title: `Solifenacin Succinate`,
    value: `solifenacinSuccinate`,
    selected: false,
  },
  { title: `Spironolactone`, value: `spironolactone`, selected: false },
  {
    title: `Sulfamethoxazole; Trimethoprim`,
    value: `sulfamethoxazoleTrimethoprim`,
    selected: false,
  },
  { title: `Sumatriptan`, value: `sumatriptan`, selected: false },
  { title: `Synthroid`, value: `synthroid`, selected: false },
  { title: `Tamoxifen Citrate`, value: `tamoxifenCitrate`, selected: false },
  {
    title: `Tamsulosin Hydrochloride`,
    value: `tamsulosinHydrochloride`,
    selected: false,
  },
  { title: `Temazepam`, value: `temazepam`, selected: false },
  { title: `Terazosin`, value: `terazosin`, selected: false },
  { title: `Testosterone`, value: `testosterone`, selected: false },
  { title: `Thyroid`, value: `thyroid`, selected: false },
  { title: `Tiazac`, value: `tiazac`, selected: false },
  { title: `Timolol`, value: `timolol`, selected: false },
  { title: `Tiotropium`, value: `tiotropium`, selected: false },
  { title: `Tizanidine`, value: `tizanidine`, selected: false },
  { title: `Tobradex`, value: `tobradex`, selected: false },
  { title: `Topiramate`, value: `topiramate`, selected: false },
  { title: `Toprol-XL`, value: `toprolXl`, selected: false },
  {
    title: `Tramadol Hydrochloride`,
    value: `tramadolHydrochloride`,
    selected: false,
  },
  { title: `Trazodone`, value: `trazodone`, selected: false },
  {
    title: `Trazodone Hydrochloride`,
    value: `trazodoneHydrochloride`,
    selected: false,
  },
  { title: `Triamcinolone`, value: `triamcinolone`, selected: false },
  { title: `Triamterene`, value: `triamterene`, selected: false },
  { title: `Trimox`, value: `trimox`, selected: false },
  { title: `Triphasil`, value: `triphasil`, selected: false },
  { title: `Ultram`, value: `ultram`, selected: false },
  { title: `Valacyclovir`, value: `valacyclovir`, selected: false },
  { title: `Valsartan`, value: `valsartan`, selected: false },
  { title: `Valtrex`, value: `valtrex`, selected: false },
  { title: `Vasotec`, value: `vasotec`, selected: false },
  { title: `Veetids`, value: `veetids`, selected: false },
  {
    title: `Venlafaxine Hydrochloride`,
    value: `venlafaxineHydrochloride`,
    selected: false,
  },
  { title: `Verapamil`, value: `verapamil`, selected: false },
  {
    title: `Verapamil Hydrochloride`,
    value: `verapamilHydrochloride`,
    selected: false,
  },
  { title: `Viagra`, value: `viagra`, selected: false },
  { title: `Vicoprofen`, value: `vicoprofen`, selected: false },
  { title: `Vioxx`, value: `vioxx`, selected: false },
  { title: `Warfarin`, value: `warfarin`, selected: false },
  { title: `Warfarin Sodium`, value: `warfarinSodium`, selected: false },
  { title: `Wellbutrin SR`, value: `wellbutrinSr`, selected: false },
  { title: `Xalatan`, value: `xalatan`, selected: false },
  { title: `Xenical`, value: `xenical`, selected: false },
  { title: `Zestoretic`, value: `zestoretic`, selected: false },
  { title: `Zestril`, value: `zestril`, selected: false },
  { title: `Ziac`, value: `ziac`, selected: false },
  { title: `Zithromax`, value: `zithromax`, selected: false },
  { title: `Zithromax Z-PAK`, value: `zithromaxZPak`, selected: false },
  { title: `Zocor`, value: `zocor`, selected: false },
  { title: `Zoloft`, value: `zoloft`, selected: false },
  { title: `Zolpidem Tartrate`, value: `zolpidemTartrate`, selected: false },
  { title: `Zyprexa`, value: `zyprexa`, selected: false },
  { title: `Zyrtec`, value: `zyrtec`, selected: false },
]
