import { CheckboxInput } from "@erinfo/brand-ui/src/storybook/inputs/checkbox"
import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { helpers } from "@erinfo/data-schema"
import { Box } from "@mui/material"
import { Field } from "formik"

const { formikOptionalPhoneValidation } = helpers

export const AdvancedDirectivesForm = ({ values }) => {
  return (
    <Box pl={3}>
      <Field
        label="I am an Organ Donor"
        name={`advancedDirectivesOrganDonor`}
        component={CheckboxInput}
      />
      <Field
        label="I have Advanced Directives"
        name={`advancedDirectivesHasDirectives`}
        component={CheckboxInput}
      />
      <Box pl={3}>
        <Field
          label="Where can directives be found?"
          name={`advancedDirectivesLocation`}
          component={TextInput}
          disabled={!values.advancedDirectivesHasDirectives}
        />
        <Field
          label="Name of person with access"
          name={`advancedDirectivesName`}
          component={TextInput}
          disabled={!values.advancedDirectivesHasDirectives}
        />
        <Field
          component={PhoneInput}
          fullWidth
          disabled={!values.advancedDirectivesHasDirectives}
          name={`advancedDirectivesPhone`}
          label="Phone of person with access"
          type="tel"
          validate={formikOptionalPhoneValidation}
        />
      </Box>
    </Box>
  )
}
