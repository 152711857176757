import { styled } from "@mui/material/styles"

export const WrapperView = (props) => (
  <Container onSubmit={props.onSubmit} className={props.className}>
    {props.children}
  </Container>
)

const Container = styled(`div`)(({ theme }) => ({
  display: `flex`,
  gridColumn: `span 12`,
  flexFlow: `column`,
  alignItems: `center`,
  maxWidth: `900px`,
  "@media (min-width: 600px)": { margin: `20px auto 52px` },
  "@media (max-width: 600px)": { margin: `0` },
  width: `100%`,
  boxSizing: `border-box`,
  border: `solid 1px ${theme.palette.border.default}`,
  background: `white`,
  overflow: `hidden`,
  flexGrow: 1,
  flexShrink: 0,
}))
