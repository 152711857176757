import { FormData } from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useEffect, useState } from "react"

export const useDynamicFields = (
  names: string[],
  initializer: (user) => FormData,
  user,
  open: boolean,
  path?: string, // path under user object where collections are mounted
  formFieldMap?: { [key: string]: string[] },
) => {
  const initialFieldCounts = names.reduce((agg, curr) => {
    agg[curr] =
      (path ? user?.[path]?.[curr]?.length : user?.[curr]?.length) || 1
    return agg
  }, {})

  const [initialValues, setInitialValues] = useState<FormData>()
  const [fieldCounts, setFieldCounts] = useState(initialFieldCounts)

  useEffect(() => {
    if (user && open) {
      setFieldCounts(initialFieldCounts)
      setInitialValues(initializer(user))
    }
  }, [open, user])

  const addField = (type: string) => {
    setFieldCounts((p) => ({ ...p, [type]: p[type] + 1 }))
  }

  const removeField = (values, setValues) => (type: string, idx: number) => {
    setValues(
      Object.entries(values).reduce((agg, [key, value]) => {
        const [field, fieldIdx] = key.split(`_`)
        const fieldIdxNo = Number(fieldIdx)
        if (!formFieldMap || formFieldMap[type].includes(field)) {
          if (fieldIdxNo < idx) {
            agg[key] = value
          } else if (fieldIdxNo > idx) {
            agg[`${field}_${fieldIdxNo - 1}`] = value
          } else if (fieldIdxNo === idx && fieldCounts[type] === 1) {
            agg[key] = ``
          }
        } else {
          agg[key] = value
        }
        return agg
      }, {}),
    )
    if (fieldCounts[type] > 1) {
      setFieldCounts((p) => ({ ...p, [type]: p[type] - 1 }))
    }
  }

  const setFieldValueOrRemove =
    (setFieldValue, removeField) => (name: string, value: string) => {
      if (value === undefined || value === null || value === ``) {
        return removeField() // output of above curry function two layers down
      }
      setFieldValue(name, value)
    }

  return {
    initialValues,
    fieldCounts,
    addField,
    removeField,
    setFieldValueOrRemove,
  }
}
