import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import { styled } from "@mui/material/styles"
import { FC } from "react"

const Root = styled(`div`)({
  justifyContent: `center`,
  alignItems: `center`,
  display: `flex`,
  minHeight: `100%`,
})

const Loader: FC = ({ children }) => {
  return (
    <Root>
      <Box
        sx={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}
      >
        <CircularProgress color="secondary" sx={{ mb: 4 }} />
        {children}
      </Box>
    </Root>
  )
}

export default Loader
