import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { RadioInput } from "@erinfo/brand-ui/src/storybook/inputs/radio"
import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { dataRelationship, helpers, yupNameSchema } from "@erinfo/data-schema"
import { Add, Delete } from "@mui/icons-material"
import { Box, Button, Divider, Typography } from "@mui/material"
import { Field } from "formik"
import { Fragment } from "react"

const { formikRequiredPhoneValidation } = helpers

export const emergencyContactsFormFields = [
  `emergencyContactName`,
  `emergencyContactPhone`,
  `emergencyContactEmail`,
  `emergencyContactRelationship`,
]

export const EmergencyContactsForm = ({
  addField,
  removeField,
  setFieldValue,
  values,
  fieldCounts,
}) => {
  return (
    <>
      <Typography variant="h5">Emergency Contacts</Typography>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values?.emergencyContactName_0}
        onClick={() => addField(`emergencyContacts`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.emergencyContacts)]
        .map((_, i) => i)
        .reverse()
        .map((i) => (
          <Fragment key={`emergencyContact_${i}`}>
            {i < fieldCounts.emergencyContacts - 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
            <Box pl={2}>
              <Field
                name={`emergencyContactName_${i}`}
                label="Contact Name"
                component={TextInput}
                inputProps={{
                  "data-clarity-unmask": `true`,
                  className: `clarity-unmask`,
                }}
                validate={(v) => {
                  if (!v || typeof v !== `string`) {
                    return `Required field`
                  }
                  if (yupNameSchema.isValidSync(v)) {
                    return null
                  } else {
                    return `Invalid Name`
                  }
                }}
              />
              <Field
                component={PhoneInput}
                fullWidth
                name={`emergencyContactPhone_${i}`}
                label="Contact MOBILE Phone"
                type="tel"
                required
                validate={formikRequiredPhoneValidation}
              />
              <Field
                name={`emergencyContactEmail_${i}`}
                label="Contact Email"
                component={TextInput}
                inputProps={{ "data-clarity-unmask": `true` }}
                type="email"
              />
              <Field
                name={`emergencyContactRelationship_${i}`}
                label="Relationship"
                component={AutocompleteInput}
                setFieldValue={setFieldValue}
                options={dataRelationship}
                style={{ minWidth: 200 }}
                autoTouched
                validate={(v) => (!v ? `Required` : null)}
              />
              <Box display="flex" justifyContent="space-between">
                <Field
                  component={RadioInput}
                  name={`isPrimaryContact`}
                  value={i}
                  label="Primary"
                />
                <Button
                  onClick={() => {
                    removeField(`emergencyContacts`, i)
                    const primaryContactIdx = Number(values.isPrimaryContact)
                    if (i <= primaryContactIdx) {
                      const newIdx = primaryContactIdx - 1
                      if (newIdx < 0) {
                        return setFieldValue(`isPrimaryContact`, undefined)
                      }
                      setFieldValue(`isPrimaryContact`, newIdx.toString())
                    }
                  }}
                  color="error"
                >
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Contact
                </Button>
              </Box>
            </Box>
          </Fragment>
        ))}
    </>
  )
}
