export const dataHairColor = [
  { title: ``, value: `` },
  { title: `Black`, value: `Black`, key: `Black` },
  { title: `Blonde`, value: `Blonde`, key: `Blonde` },
  { title: `Brown`, value: `Brown`, key: `Brown` },
  { title: `Gray`, value: `Gray`, key: `Gray` },
  { title: `Red`, value: `Red`, key: `Red` },
  { title: `Bald`, value: `Bald`, key: `Bald` },
  { title: `Other`, value: `Other`, key: `Other` },
]
