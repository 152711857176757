import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import { PictureCaptureInput } from "@erinfo/brand-ui/src/storybook/inputs/picture-capture"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { identifyingMarks, markLocations } from "@erinfo/data-schema"
import { Add, Delete } from "@mui/icons-material"
import { Box, Divider } from "@mui/material"
import { Field } from "formik"
import { Fragment, useEffect, useMemo } from "react"

export const identifyingMarksFormFields = [
  `identifyingMark`,
  `identifyingMarkType`,
  `identifyingMarkLocation`,
  `identifyingMarkDesc`,
  `identifyingMarkImg`,
]

const MAX_LENGTH = 40

export const IdentifyingMarksForm = ({
  addField,
  removeField,
  setFieldValue,
  values = {},
  fieldCounts,
}) => {
  const requiredValidation = (idx) => (v) => {
    if (
      values[`identifyingMarkType_${idx}`] ||
      values[`identifyingMarkLocation_${idx}`]
    ) {
      if (!v) {
        return `Required`
      }
      if (v.length > MAX_LENGTH) {
        return `Too long`
      }
      return null
    }
  }

  useEffect(() => {
    const keys = Object.keys(values)
    Object.entries(values).forEach(([field, value]) => {
      const [name, idx] = field.split(`_`)
      if (
        (name === `identifyingMarkLocation` || name === `identifyingMarkImg`) &&
        (!keys.includes(`identifyingMarkType_${idx}`) ||
          !values[`identifyingMarkType_${idx}`])
      ) {
        setFieldValue(`identifyingMarkLocation_${idx}`, ``)
        setFieldValue(`identifyingMarkDesc_${idx}`, ``)
        setFieldValue(`identifyingMarkImg_${idx}`, ``)
      }
    })
  }, [values])

  const showDelete = useMemo(
    () =>
      Object.entries(values).some(([field, value]) => {
        const [name, idx] = field.split(`_`)
        if (name === `identifyingMarkType` && !!value) {
          return true
        }
      }),
    [values],
  )

  return (
    <>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values.identifyingMarkType_0}
        onClick={() => addField(`identifyingMarks`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.identifyingMarks)]
        .map((_, i) => i)
        .reverse()
        .map((i) => (
          <Fragment key={`identifyingMark_${i}`}>
            {i < fieldCounts.identifyingMarks - 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
            <Field
              label="Type of Mark"
              name={`identifyingMarkType_${i}`}
              component={AutocompleteInput}
              freeSolo
              setFieldValue={setFieldValue}
              options={identifyingMarks}
              validate={requiredValidation(i)}
              inputProps={{ maxLength: MAX_LENGTH + 1 }}
            />
            {values[`identifyingMarkType_${i}`] && (
              <Field
                label="Where is it?"
                name={`identifyingMarkLocation_${i}`}
                component={AutocompleteInput}
                freeSolo
                setFieldValue={setFieldValue}
                disabled={!values[`identifyingMarkType_${i}`]}
                options={markLocations}
                validate={requiredValidation(i)}
                autoTouched
                inputProps={{ maxLength: MAX_LENGTH + 1 }}
              />
            )}
            <Field
              name={`identifyingMarkDesc_${i}`}
              label="Additional Information"
              component={TextInput}
              disabled={!values[`identifyingMarkType_${i}`]}
              validate={(v) => (v?.length > MAX_LENGTH ? `Too long` : null)}
              inputProps={{ maxLength: MAX_LENGTH + 1 }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Field
                name={`identifyingMarkImg_${i}`}
                component={PictureCaptureInput}
                disabled={!values[`identifyingMarkType_${i}`]}
              />
              {showDelete && (
                <StyledButton
                  onClick={() => removeField(`identifyingMarks`, i)}
                  color="error"
                  sx={{ mt: 1 }}
                >
                  <Delete sx={{ marginRight: 1 }} />
                  Delete Mark
                </StyledButton>
              )}
            </Box>
          </Fragment>
        ))}
    </>
  )
}
