import { useRedirects } from "@erinfo/consumer/src/hooks/use-redirects"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { useSelector } from "react-redux"

import useAuth from "../hooks/use-auth"
import Loader from "./Loader"
import { SettingsDialog } from "./SettingsDialog"
import { PurgeModal } from "./dashboard/PurgeModal"

const Protected = ({ children }) => {
  const { isLoading } = useAuth()
  useRedirects({ protectedRoute: true })
  const settingsOpen = useSelector(
    (state: RootState) => state.notifications.settingsOpen,
  )
  const { toggleSettings } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleSettings: dispatch.notifications.toggleSettings,
  }))

  if (isLoading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ height: `100vh` }}>
          <Loader>
            <Typography variant="h6">Loading...</Typography>
          </Loader>
        </Box>
      </Container>
    )
  }

  return (
    <>
      <PurgeModal />
      <SettingsDialog
        open={settingsOpen}
        handleClose={() => toggleSettings(false)}
      />
      {children}
    </>
  )
}

export default Protected
