import { useEffect } from "react"

export const filterValues = (type, formValues, options) => {
  const usedRecords = Object.entries(formValues)
    .filter(([k]) => k.startsWith(type))
    .map(([_, v]) => v)

  return options.filter((o) => !usedRecords.includes(o.title))
}

export const useHandleNoneSelection = ({
  formType,
  formFields,
  values,
  fieldCounts,
  removeField,
  setFieldValue,
}) => {
  const noneField = formFields[0]

  useEffect(() => {
    const noneIdx = Object.entries(values).reduce(
      (agg, [key, val]) =>
        key.startsWith(`${noneField}_`) && val === `None`
          ? Number(key.split(`_`)[1])
          : agg,
      null,
    )
    if (noneIdx !== null) {
      for (let i = 0; i < fieldCounts[formType]; i++) {
        if (i !== noneIdx) {
          removeField(formType, i)
        } else {
          formFields.slice(1).forEach((field) => {
            setFieldValue(`${field}_${i}`, ``)
          })
        }
      }
    }
  }, [values])
}
