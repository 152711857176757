import { PhotoFace } from "@erinfo/brand-ui/src/storybook/other/photo/face-v2"
import { Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { CameraType } from "expo-camera"

import { AddMemberWrapper } from "../components/AddMemberWrapper"
import useAddMember from "../use-add-member"

const AddMemberStep1 = ({ validationCallback }) => {
  const navigate = useNavigate()
  const { picture, setPicture, onDelete, user, returnUrl, proApp } =
    useAddMember()
  const nextStep = `/add-member/step-2`

  const onSubmit = () => navigate(nextStep)

  return (
    <AddMemberWrapper
      currStep={0}
      onSubmit={onSubmit}
      nextDisabled={!picture.created}
      showAvatar={false}
      user={user}
      returnUrl={returnUrl}
      proApp={proApp}
    >
      <Box
        sx={{ display: `flex`, flexDirection: `column`, alignItems: `center` }}
      >
        <Typography mb={2}>
          {!picture?.created && !picture.value
            ? `Add a photo for facial recognition below.`
            : `Select to retake.  NEXT to proceed.`}
        </Typography>
        <PhotoFace
          photo={picture}
          setPhoto={(pic) => setPicture(pic)}
          faceValidation={validationCallback}
          onDelete={onDelete}
          startingCameraType={CameraType.back}
        />
        <Typography sx={{ opacity: 0.38, marginTop: 2 }}>
          {proApp
            ? `*Only used by First Responders`
            : `*Edits and changes can be made later.`}
        </Typography>
      </Box>
    </AddMemberWrapper>
  )
}

export default AddMemberStep1
