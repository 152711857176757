import { Auth } from "@aws-amplify/auth"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { useRedirects } from "@erinfo/consumer/src/hooks/use-redirects"
import { Dispatch } from "@erinfo/consumer/src/store"
import { yupEmailSchema, yupPasswordSchema } from "@erinfo/data-schema"
import { authSignIn } from "@erinfo/react-utils/src/helpers/amplify"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import LoadingButton from "@mui/lab/LoadingButton"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { FC, useState } from "react"
import * as Yup from "yup"

const ForgotPassword = styled(TextInfo)({ marginBottom: `25px` })
const NotAMember = styled(TextInfo)({ marginBottom: `15px` })

const FormSchema = Yup.object().shape({
  email: yupEmailSchema.required(`Email is required`),
  password: yupPasswordSchema.required(`Password is required`),
})

const SignIn: FC = () => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const { handleSignout } = useAuth()
  const { setDialogMessage, fetchUserData } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      fetchUserData: dispatch.user.fetchUserData,
    }),
  )
  useRedirects({ protectedRoute: false })

  const handleSubmit = async (values) => {
    setLoading(true)
    try {
      const emailLowercase = values.email.toLowerCase()
      const cognitoUser = await authSignIn(emailLowercase, values.password)
      if (cognitoUser.response && cognitoUser.response.error) {
        throw cognitoUser
      }
      await fetchUserData({
        id: cognitoUser.attributes[`custom:compositeID`],
      })
    } catch (error) {
      setLoading(false)
      const { response } = error

      if (response) {
        const { status, message } = response
        if (status === `auth`) {
          if (message && message.includes(`validation error`)) {
            setDialogMessage({ msg: `The entered email is not valid` })
          } else if (message === `User does not exist.`) {
            setDialogMessage({
              title: `User Doesn't Exist`,
              msg: `Please check the email and try again.`,
            })
          } else if (message === `Incorrect username or password.`) {
            setDialogMessage({
              title: `Incorrect Username or Password`,
              msg: `Please check the email and/or password and try again.`,
            })
          } else {
            setDialogMessage({ msg: message })
          }
        } else if (status === 404) {
          setDialogMessage({ msg: `User doesn't exist` })
        }
      }
    }
  }

  const redirectIfValid = async (email) => {
    const validEmail = await yupEmailSchema
      .required(`Email is required`)
      .validate(email)
    if (validEmail.endsWith(`miami.edu`)) {
      console.log(`redirecting to federated sign in`, email)
      void Auth.federatedSignIn({
        customProvider: `university-of-miami`,
      })
    } else {
      setShowPassword(true)
    }
  }

  return (
    <BorderBox maxWidth={350}>
      <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
        Sign In
      </Typography>
      <Formik
        initialValues={{
          email: ``,
          password: ``,
        }}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ values, submitForm }) => (
          <Form>
            <Field
              component={TextInput}
              name="email"
              label="Email"
              type="email"
              onKeyPress={(e) => {
                if (e.key === `Enter`) {
                  void redirectIfValid(values.email)
                }
              }}
              onBlur={async (e) => {
                try {
                  await redirectIfValid(e.target.value.trim())
                  setShowPassword(true)
                } catch (error) {
                  setShowPassword(false)
                }
              }}
            />
            {showPassword && (
              <Field
                component={TextInput}
                name="password"
                label="Password"
                type="password"
                onKeyPress={(e) => e.key === `Enter` && submitForm()}
                sx={{ marginTop: 2 }}
              />
            )}
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              loadingPosition="end"
              loading={loading}
              sx={{ marginTop: 3 }}
            >
              Sign in
            </LoadingButton>
          </Form>
        )}
      </Formik>
      <Box
        sx={{
          marginTop: `15px`,
          display: `flex`,
          flexFlow: `column`,
          alignItems: `center`,
        }}
      >
        <ForgotPassword>
          <TextAnchor to={`/forgot-password/send-email`}>
            Forgot Password?
          </TextAnchor>
          {` `}
        </ForgotPassword>
        <NotAMember>
          Not a member yet?{` `}
          <TextAnchor
            to={`/sign-up/step-1`}
            onClick={async () => handleSignout()}
          >
            SIGN UP
          </TextAnchor>
        </NotAMember>
      </Box>
    </BorderBox>
  )
}

export default SignIn
