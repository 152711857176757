import axios from "axios"
import * as z from "zod"

const separator = `&` // it's best to use a separator that an Affiliate partner cannot put in the QS, as it guarantees not being used as part of the value, leading to an unsafe "Array.split"

export const impactQsKey = `irclickid` // from "API Solution" document
const impactAccountId = `IRAecTw4ek4u2819383tmKGRVpxxienBm1`
const impactApiUrl = `https://api.impact.com/Advertisers/${impactAccountId}/Conversions`
interface impactParams {
  [key: string]: string | number | undefined
  ClickId?: string
  ItemCategory1?: "Subscription" | "Trial"
}
export const impactSendEvent = async (user, params?: impactParams) => {
  return axios({
    url: impactApiUrl,
    auth: {
      username: impactAccountId,
      password: `ZQMgjbyHM.SmSY_ePPY9aPLQkjw4usEz`,
    },
    params: {
      CampaignId: 13712,
      EventDate: new Date().toISOString(),
      CustomerId: user.id,
      ...params,
    },
  })
}

export const shareASaleQsKey = `sscid` /** to find out how we got "sscid", press @see https://www.shareasale.com/r.cfm?b=1768451&u=178&m=110256&urllink=&afftrack= */

export const getShareASaleReferenceDate = (
  userCreateDt: string | number,
): string => {
  const formattedDt = new Date(userCreateDt).toISOString().slice(0, 10)
  return `${formattedDt.slice(5, 7)}/${formattedDt.slice(
    8,
  )}/${formattedDt.slice(0, 4)}`
}
interface shareASaleParams {
  [key: string]: string | number | undefined
  transtype: "sale" | "lead"
  sscid: string
  xtype: string
  action: "new" | "reference"
  date?: string
  ordernumber?: string
}
export const shareASaleSendEvent = async (user, params?: shareASaleParams) => {
  const crypto = require(`crypto`) // eslint-disable-line @typescript-eslint/no-var-requires
  const token = `QTt5kv2b6UMttf7r`
  const apiSecret = `VMz1hl7t8WZvuv1dQTt5kv2b6UMttf7r`
  const dateUtc = new Date().toUTCString()
  const action = (params && params.action) || `new`
  const headerAuthRaw = `${token}:${dateUtc}:${action}:${apiSecret}`
  const headerAuth = crypto
    .createHash(`sha256`)
    .update(headerAuthRaw)
    .digest(`hex`)

  console.log(
    JSON.stringify(
      {
        merchantId: 110256,
        token,
        version: 2.9,
        sscidmode: 6,
        ...params,
      },
      null,
      4,
    ),
  )

  axios.interceptors.request.use(
    (config) => {
      console.log(JSON.stringify(config, null, 4))
      return config
    },
    async (error) => {
      console.log(JSON.stringify(error, null, 4))
      return Promise.reject(error)
    },
  )

  return axios({
    url: `https://api.shareasale.com/w.cfm`,
    headers: {
      "x-ShareASale-Date": dateUtc,
      "x-ShareASale-Authentication": headerAuth,
    },
    params: {
      merchantId: 110256,
      token,
      version: 2.9,
      sscidmode: 6,
      ...params,
    },
  })
}

export const nameAffiliate = `affiliate`
export const affiliate = z.string()
export const affiliateOptional = affiliate.optional()

type qsKeyAffiliate = typeof impactQsKey | typeof shareASaleQsKey

export const affiliateGetValueFromRequest = (
  value: ReturnType<typeof setAffiliateValue>,
) => value.split(separator) as [qsKeyAffiliate, string]

const setAffiliateValue = (qsKeyAffiliate: qsKeyAffiliate, value: string) =>
  `${qsKeyAffiliate}${separator}${value}`

/**
 * Get affiliate value to persist & pass to the backend
 * @notes
 *   - used on the client-side (frontend) only
 *
 * @param qs query string which may or may not contain affiliate related key-value pair
 */
export const getAffiliateFromQS = (qs: string | URLSearchParams) => {
  const params = typeof qs === `string` ? new URLSearchParams(qs) : qs

  const impact = params.get(impactQsKey)
  if (impact) return setAffiliateValue(impactQsKey, impact)

  const shareASale = params.get(shareASaleQsKey)
  if (shareASale) return setAffiliateValue(shareASaleQsKey, shareASale)
}
