import { OverviewContainer } from "@erinfo/brand-ui/src/storybook/other/overview-container/_"
import { ProfileTabs as ProfileTabsBase } from "@erinfo/brand-ui/src/storybook/other/tabs/profile/_"
// import TopContainer from "@erinfo/provider/src/components/top-container"
import { styled } from "@mui/material/styles"

export const ProfileContainer = styled(`div`)(({ theme }) => ({
  gridColumn: `span 12`,
  marginBottom: 20,
  boxSizing: `border-box`,
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  maxWidth: `900px`,
  width: `100%`,
  margin: `0 auto`,
  background: theme.palette.background.paper,
  flexGrow: 1,
  position: `relative`,
  overflowY: `auto`,

  ".profile__alert-container": {
    position: `sticky`,
    top: `56px`,
    maxWidth: `370px`,
  },

  "@media (max-width: 480px)": {
    height: `100vh`,
    minHeight: `-webkit-fill-available`,
    marginBottom: 0,
  },
}))

export const ProfileTabs = styled(ProfileTabsBase)({
  position: `sticky`,
  zIndex: 10,
  background: `#fff`,

  "@media (max-width: 480px)": {
    position: `fixed`,
    top: `calc(100vh - 112px)`,
  },
})

export const SafariProfileTabs = styled(ProfileTabsBase)({
  width: `100%`,
  background: `#fff`,
  zIndex: 10,
  position: `relative`,
  top: `unset`,
})

// export const StyledTopContainer = styled(({ vPadding, ...other }) => (
//   <TopContainer {...other} />
// ))({
//   position: `sticky`,
//   paddingTop: (props) => props.vPadding,
//   paddingBottom: (props) => props.vPadding,

//   "@media (max-width: 480px)": {
//     top: `unset !important`,
//   },
// })

export const ProfileWrapper = styled(`div`)({
  flex: 1,
  overflowY: `auto`,
  overflowX: `hidden`,
  flexGrow: 1,
  flexShrink: 0,
  boxSizing: `border-box`,
  alignItems: `center`,
  width: `100%`,
  paddingBottom: `0 !important`,
})

export const ProfileSection = styled(`div`)({
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  width: `100%`,
  padding: `17px 0 20px`,
  margin: `0 auto`,
  fontFamily: `Roboto, sans-serif`,
  lineHeight: `25px`,
  color: `rgba(0, 0, 0, 0.6)`,
  maxWidth: 320,
})

export const StyledOverviewContainer = styled(OverviewContainer)({
  maxWidth: `340px`,
  width: `100%`,
  padding: `0`,
  alignItems: `flex-start`,
})

export const EditMedicalSection = styled(`div`)({
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  width: `100%`,
  margin: `0 auto`,
})

export const EditMedicalSectionWrapper = styled(`div`)({
  width: `100%`,
})

export const Overlay = styled(`div`)({
  opacity: 0.5,
  background: `#000`,
  width: `100%`,
  height: `100%`,
  zIndex: 10,
  top: `0`,
  left: `0`,
  position: `fixed`,
  textAlign: `center`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
})

export const AddMembersSection = styled(`div`)({
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  width: `100%`,
  padding: `17px 0 20px`,
  margin: `0 auto`,

  "&:nth-child(2n-1)": {
    background: `#f4f4f4`,
  },
  "& > div": {
    width: `350px`,
  },
})
