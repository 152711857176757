import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const BorderBox = styled(Box)((props) => ({
  padding: props.theme.spacing(2),
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  "@media (min-width: 480px)": {
    border: `1px solid #E0E0E0`,
    borderRadius: `4px`,
  },
}))
