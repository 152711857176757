import jdrfLogo from "@erinfo/consumer/src/assets/JDRF_logo.svg"
import piSeminole from "@erinfo/consumer/src/assets/piSeminole.png"
import piSeminoleAddress from "@erinfo/consumer/src/assets/piSeminoleAddress.png"
import { isMobileDevice } from "@erinfo/react-utils/src/helpers/check-browser"

import { JdrfInstructions } from "./jdrf"
export * from "./default"

export const partnerStyling = {
  jdrf: {
    logo: {
      source: jdrfLogo,
      style: {
        paddingTop: 3,
      },
    },
    instructions: JdrfInstructions,
  },
  piSeminole: {
    logo: {
      source: isMobileDevice ? piSeminole : piSeminoleAddress,
      style: !isMobileDevice && {
        maxWidth: `unset`,
        maxHeight: `85px`,
        paddingTop: 3,
      },
    },
  },
}
