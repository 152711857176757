import Card from "@erinfo/brand-ui/src/storybook/other/card/_"
import OnePersonIcon from "@erinfo/consumer/src/assets/family_1@3x.png"
import FourPeopleIcon from "@erinfo/consumer/src/assets/family_4@3x.png"
import TenPeopleIcon from "@erinfo/consumer/src/assets/family_10@3x.png"
import {
  checkStripeCoupon,
  createStripeCheckoutSession,
  getStripeSetup,
} from "@erinfo/consumer/src/data"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import * as schema from "@erinfo/data-schema"
import Loader from "@erinfo/provider/src/components/Loader"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import ArrowBack from "@mui/icons-material/ArrowBack"
import Button from "@mui/lab/LoadingButton"
import { Box, Typography } from "@mui/material"
import dayjs from "dayjs"
import { useStripe } from "@erinfo/react-utils/src/hooks"
import { get } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import * as Styled from "./styles"

declare global {
  interface Window {
    Stripe: (string) => any
  }
}

const MONTHS_YR = 12
const PCT = 100
const EXPIRED_COLOR = `#C77700`

const LapsedSubscription = () => {
  useStripe()
  const { user, handleSignout } = useAuth()
  const { search } = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(``)
  const [stripeData, setStripeData] = useState<DataSchema.stripe.pricing.get>()
  const [discount, setDiscount] = useState({
    type: ``,
    amount: ``,
  })
  const stripe = useRef()
  const { setDialogMessage } = useRematchDispatch((dispatch: Dispatch) => ({
    setDialogMessage: dispatch.notifications.setDialogMessage,
  }))
  const { registerCode: coupon } = useSelector(
    (state: RootState) => state.signUp,
  )
  const userData = useSelector((state: RootState) => state.user)
  const price1 = Number(get(stripeData, `price.individual.msrp`, 0)) / MONTHS_YR
  const price2 = Number(get(stripeData, `price.family.msrp`, 0)) / MONTHS_YR
  const price3 = Number(get(stripeData, `price.group.msrp`, 0)) / MONTHS_YR

  const mostRecentPlan = [
    ...(userData.paymentPlan.canceled ?? []),
    ...(userData.paymentPlan.expired ?? []),
  ].sort((a, b) => (a.ended_at > b.ended_at ? -1 : 1))[0]
  const expiredIndividual =
    mostRecentPlan?.name.toLowerCase() === `individual plan`
  const expiredFamily = mostRecentPlan?.name.toLowerCase() === `family plan`
  const expiredGroup =
    mostRecentPlan?.name.toLowerCase() === `extended family plan`
  const memberCount = userData.enrolledPatients?.length
  const userStripeCustomerId = userData?.idCustomer

  useEffect(() => {
    if (user) {
      getStripeSetup()
        .then((data) => {
          setStripeData(data)
          stripe.current = window.Stripe(data.keyPb)
        })
        .catch(console.log)
    }
  }, [user])

  useEffect(() => {
    if (coupon) {
      checkStripeCoupon(coupon)
        .then((data) => {
          if (data.offPct) {
            setDiscount({ type: `pct`, amount: data.offPct })
          }
        })
        .catch(console.log)
    }
  }, [coupon])

  useEffect(() => {
    if (search.includes(`cancel=1`)) {
      setDialogMessage({
        title: `Request Cancelled`,
        msg:
          `Your payment process was cancelled.  Please select your plan and complete ` +
          `your credit card submission to continue.`,
        altYesLabel: `OK`,
        onYes: () => setDialogMessage({ msg: `` }),
      })
    }
  }, [search])

  const getDiscountedPrice = (price) => {
    if (!price) return ``
    if (discount.type === `pct`) {
      return `$${(Number(price) * (1 - Number(discount.amount) / PCT)).toFixed(
        2,
      )}`
    }
    return `$${price.toFixed(2)}`
  }

  const onFormSubmit = async (priceId: string) => {
    console.log(`test`)
    if (!stripe.current) {
      return console.log(`Stripe is not initialized`)
    }
    setLoading(priceId)
    try {
      const sessionId = await createStripeCheckoutSession({
        priceId,
        idCustomer: userStripeCustomerId,
        pathname: `/dashboard`,
        ...(coupon && { coupon }),
      })
      stripe.current.redirectToCheckout({ sessionId })
    } catch (err) {
      console.log(err, err.response)
      setLoading(``)
      setDialogMessage({
        title: `Error`,
        msg: `We could not send you to the payment portal.`,
        altNoLabel: `Try Again`,
        altYesLabel: `Logout`,
        onNo: () => setDialogMessage({ msg: `` }),
        onYes: async () => {
          await handleSignout()
          navigate(`/`)
        },
      })
    }
  }

  return !stripeData ? (
    <Loader />
  ) : (
    <Styled.SignUpWrapper>
      <Box sx={{ width: `100%`, pt: 3, pl: 2 }}>
        <Box display="flex">
          <Typography
            paragraph={false}
            sx={{ fontWeight: `bold`, color: EXPIRED_COLOR, mr: 1 }}
          >
            Expired plan:
          </Typography>
          <Typography paragraph={false} sx={{ opacity: 0.6 }}>
            {mostRecentPlan?.name}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            paragraph={false}
            sx={{ fontWeight: `bold`, opacity: 0.6, mr: 1 }}
          >
            Date expired:
          </Typography>
          <Typography paragraph={false} sx={{ opacity: 0.6 }}>
            {mostRecentPlan?.ended_at
              ? dayjs(mostRecentPlan.ended_at * 1000).format(`MM/DD/YYYY`)
              : `N/A`}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            paragraph={false}
            sx={{ fontWeight: `bold`, opacity: 0.6, mr: 1 }}
          >
            Additional members on plan:
          </Typography>
          <Typography paragraph={false} sx={{ opacity: 0.6 }}>
            {memberCount}
          </Typography>
        </Box>
      </Box>
      <Styled.PlanWrapper>
        <div style={{ paddingTop: expiredIndividual ? `16px` : `43px` }}>
          <Card
            title="1 Person"
            className="card"
            topLabelStyle={{ backgroundColor: `#C77700` }}
            topLabel={expiredIndividual ? `Expired Plan` : undefined}
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.individual?.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={OnePersonIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.individual.id}
                disabled={loading === stripeData?.price?.individual.id}
                loadingPosition="end"
              >
                CHOOSE INDIVIDUAL
              </Button>
            </Styled.ButtonWrapper>
            <Box
              sx={{
                paddingLeft: `8px`,
                paddingRight: `8px`,
                textAlign: `center`,
                pt: 1,
              }}
            >
              {memberCount && memberCount > 0 ? (
                <Typography color="error" sx={{ fontSize: 14 }}>
                  Lose <strong>all</strong> of your additional members
                </Typography>
              ) : null}
            </Box>
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price1)}
                {getDiscountedPrice(price1) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
        <div style={{ paddingTop: expiredFamily ? `16px` : `43px` }}>
          <Card
            title="Up to 4 People"
            className="card"
            topLabelStyle={{ backgroundColor: `#C77700` }}
            topLabel={expiredFamily ? `Expired Plan` : undefined}
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.family.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={FourPeopleIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.family.id}
                disabled={loading === stripeData?.price?.family.id}
                loadingPosition="end"
              >
                CHOOSE FAMILY
              </Button>
            </Styled.ButtonWrapper>

            <Box
              sx={{
                paddingLeft: `8px`,
                paddingRight: `8px`,
                textAlign: `center`,
                pt: 1,
              }}
            >
              {memberCount && memberCount > 3 ? (
                <Typography color="error" sx={{ fontSize: 14 }}>
                  Lose <strong>{memberCount - 3}</strong> of your additional
                  members
                </Typography>
              ) : (
                <Typography sx={{ fontSize: 14 }}>
                  Lose <strong>none</strong> of your additional members
                </Typography>
              )}
            </Box>
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price2)}
                {getDiscountedPrice(price2) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
        <div style={{ paddingTop: expiredGroup ? `16px` : `43px` }}>
          <Card
            title="Up to 10 People"
            topLabelStyle={{ backgroundColor: `#C77700` }}
            topLabel={expiredGroup ? `Expired Plan` : undefined}
            className="card"
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.group.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={TenPeopleIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.group?.id}
                disabled={loading === stripeData?.price?.group?.id}
                loadingPosition="end"
              >
                CHOOSE EXTENDED FAMILY
              </Button>
            </Styled.ButtonWrapper>
            {!!memberCount && (
              <Box
                sx={{
                  paddingLeft: `8px`,
                  paddingRight: `8px`,
                  textAlign: `center`,
                  pt: 1,
                }}
              >
                <Typography sx={{ fontSize: 14 }}>
                  Lose <strong>none</strong> of your additional members
                </Typography>
              </Box>
            )}
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price3)}
                {getDiscountedPrice(price3) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
      </Styled.PlanWrapper>
      <Styled.BottomSection>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `flex-end`,
            width: `100%`,
          }}
        >
          {!!memberCount && (
            <Typography paragraph={false} sx={{ opacity: 0.6, mr: 1 }}>
              * You’ll be able to choose which members to keep after the
              purchase process.
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              width: "100%",
            }}
          >
            <Typography sx={{ float: "left" }}>
              <strong>Questions?</strong>{" "}
              <a href="https://erinfo.me/contact-us/" target="_blank">
                Contact us
              </a>
            </Typography>
            <Button
              onClick={async () => {
                await handleSignout()
                navigate(`/`)
              }}
              sx={{ textTransform: `unset`, mt: 2 }}
              startIcon={<ArrowBack />}
            >
              Back to Sign In
            </Button>
          </Box>
        </Box>
      </Styled.BottomSection>
    </Styled.SignUpWrapper>
  )
}

export default LapsedSubscription
