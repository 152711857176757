const ua = window.navigator.userAgent
const iOS = !!/iPad/i.exec(ua) || !!/iPhone/i.exec(ua)
const webkit = !!/WebKit/i.exec(ua)

export const isIosDevice = iOS && webkit // && !/CriOS/i.exec(ua)
export const isAndroid = ua.includes(`Android`)
export const isMobileDevice = isIosDevice || isAndroid

export const mobileBrowserLandscape = () =>
  window.orientation !== 0 &&
  window.innerHeight < 400 &&
  window.innerWidth < 900
