import { useFormikContext } from "formik"
import { useEffect } from "react"

export const FormikStateUpdate = ({ setIsValid, requireDirty = false }) => {
  const { dirty, isValid } = useFormikContext()

  useEffect(() => {
    if (!requireDirty || dirty) {
      setIsValid(isValid)
    }
  }, [isValid])

  return null
}
