import { ReactComponent as IPersonal } from "@erinfo/brand-ui/src/assets/svg/card.svg"
import { ReactComponent as IEmergencyContacts } from "@erinfo/brand-ui/src/assets/svg/emergency-contacts.svg"
import { ReactComponent as IMedical } from "@erinfo/brand-ui/src/assets/svg/emergency.svg"
import { ReactComponent as IID } from "@erinfo/brand-ui/src/assets/svg/eye.svg"
import { ReactComponent as IOverview } from "@erinfo/brand-ui/src/assets/svg/magnify.svg"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import useMediaQuery from "@mui/material/useMediaQuery"
import { styled } from "@mui/material/styles"
import { useState } from "react"
interface IProps {
  routes
  history
  className?: string
}

export const ProfileTabs = (props: IProps) => {
  const isMobile = useMediaQuery(`(max-width:480px)`)
  const idx = props.routes.findIndex((r) => r === props.history.match.path)
  const [value, setValue] = useState(idx)

  const handleChange = (event, newValue) => {
    setValue(newValue)
    console.log(props.routes[newValue])
    props.history.history.push(props.routes[newValue])
  }

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      className={props.className}
      indicatorColor="primary"
      textColor="primary"
      variant={isMobile ? `fullWidth` : `standard`}
    >
      {props.routes.length === 4 && (
        <StyledTab icon={<IOverview />} label="Overview" />
      )}
      <StyledTab icon={<IEmergencyContacts />} label="Contacts" />
      <StyledTab icon={<IID />} label="ID" />
      <StyledTab icon={<IMedical />} label="Medical" />
    </Tabs>
  )
}

// const StyledTabs = styled(Tabs)({})

const StyledTab = styled(Tab)(({ theme, selected, fullWidth }) => {
  return {
    minWidth: !fullWidth && `100px !important`,
    textTransform: `none !important`,
    // fill: selected && `${theme.palette.primary.main} !important`,
    "& .MuiTab-wrapper": {
      height: `48px !important`,
    },
  }
})
