import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { ArrowBack } from "@mui/icons-material"
import { Box, IconButton, Typography } from "@mui/material"

export const AddMemberHeader = ({ user, onBack, proApp = false }) => {
  const avatarFile = user?.pictures?.[0]?.src

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        paddingTop: 1,
        paddingBottom: 1,
        paddingRight: 2,
        paddingLeft: 2,
        width: `100%`,
        background: `#E0E0E0`,
      }}
    >
      <Box display="flex" sx={{ flex: 1, alignItems: `center` }}>
        {proApp ? (
          <StyledButton color="error" onClick={onBack}>
            Cancel
          </StyledButton>
        ) : (
          <>
            <IconButton
              color="primary"
              sx={{ marginRight: 1 }}
              onClick={onBack}
            >
              <ArrowBack sx={{ width: 32, height: 32 }} />
            </IconButton>
            <Avatar url={avatarFile} sx={{ width: 45, height: 45 }} />
          </>
        )}
      </Box>
      <Typography
        sx={{
          color: proApp ? `rgba(0,0,0,0.26)` : `success.main`,
          paddingRight: 2,
          fontWeight: 500,
        }}
      >
        {proApp ? `Provider Added Member` : `Adding Additional Member`}
      </Typography>
    </Box>
  )
}
