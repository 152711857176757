import TextField from "@mui/material/TextField"

export const TextfieldDefault = (props) => (
  <TextField
    sx={{
      marginBottom: `8px !important`,
      "& .MuiFormLabel-asterisk": {
        color: !props.hideAsterisk ? `error.main` : `transparent`,
      },
    }}
    variant="standard"
    fullWidth
    InputLabelProps={{ style: { padding: `0 15px` } }}
    InputProps={{ style: { padding: `0 15px` } }}
    {...props}
    id={props.name}
    value={props.value === undefined ? `` : props.value}
    label={props.label}
    className={props.className}
    type={props.type}
    disabled={props.disabled}
    required={props.required}
    onChange={props.onChange}
    onBlur={props.onBlur}
    error={!!props.errors}
    helperText={props.errors || props.helperText}
  />
)
