import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as z from "zod"

export const nameIdentifyingMarks = `identifyingMarks`

const regex: DataSchema.RegExDef[] = [
  {
    msg: `Invalid Entry`,
    exp: /^\s*(?:\S\s*){1,40}$/u,
  },
]

export const identifyingMark = z
  .object({
    type: z.string(),
    location: z.string(),
    desc: z.string().optional(),
    img: z.string().optional(),
  })
  .strict()

export const identifyingMarks = z.array(identifyingMark)
export type identifyingMarks = z.infer<typeof identifyingMarks>
export const identifyingMarksOptional = identifyingMarks.optional()

export const yupTypeOfMarkSchema = helpers.getYupSchema(regex)
export const yupLocationOfMarkSchema = helpers.getYupSchema(regex)
