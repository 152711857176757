export const dataUSStates = [
  { title: ``, value: `` },
  { title: `Alabama`, value: `alabama`, key: `alabama` },
  { title: `Alaska`, value: `alaska`, key: `alaska` },
  { title: `Arizona`, value: `arizona`, key: `arizona` },
  { title: `Arkansas`, value: `arkansas`, key: `arkansas` },
  { title: `California`, value: `california`, key: `california` },
  { title: `Colorado`, value: `colorado`, key: `colorado` },
  { title: `Connecticut`, value: `connecticut`, key: `connecticut` },
  { title: `Delaware`, value: `delaware`, key: `delaware` },
  { title: `Florida`, value: `florida`, key: `florida` },
  { title: `Georgia`, value: `georgia`, key: `georgia` },
  { title: `Hawaii`, value: `hawaii`, key: `hawaii` },
  { title: `Idaho`, value: `idaho`, key: `idaho` },
  { title: `Illinois`, value: `illinois`, key: `illinois` },
  { title: `Indiana`, value: `indiana`, key: `indiana` },
  { title: `Iowa`, value: `iowa`, key: `iowa` },
  { title: `Kansas`, value: `kansas`, key: `kansas` },
  { title: `Kentucky`, value: `kentucky`, key: `kentucky` },
  { title: `Louisiana`, value: `louisiana`, key: `louisiana` },
  { title: `Maine`, value: `maine`, key: `maine` },
  { title: `Maryland`, value: `maryland`, key: `maryland` },
  { title: `Massachusetts`, value: `massachusetts`, key: `massachusetts` },
  { title: `Michigan`, value: `michigan`, key: `michigan` },
  { title: `Minnesota`, value: `minnesota`, key: `minnesota` },
  { title: `Mississippi`, value: `mississippi`, key: `mississippi` },
  { title: `Missouri`, value: `missouri`, key: `missouri` },
  { title: `Montana`, value: `montana`, key: `montana` },
  { title: `Nebraska`, value: `nebraska`, key: `nebraska` },
  { title: `Nevada`, value: `nevada`, key: `nevada` },
  { title: `New Hampshire`, value: `new hampshire`, key: `new hampshire` },
  { title: `New Jersey`, value: `new jersey`, key: `new jersey` },
  { title: `New Mexico`, value: `new mexico`, key: `new mexico` },
  { title: `New York`, value: `new york`, key: `new york` },
  { title: `North Carolina`, value: `north carolina`, key: `north carolina` },
  { title: `North Dakota`, value: `north dakota`, key: `north dakota` },
  { title: `Ohio`, value: `ohio`, key: `ohio` },
  { title: `Oklahoma`, value: `oklahoma`, key: `oklahoma` },
  { title: `Oregon`, value: `oregon`, key: `oregon` },
  { title: `Pennsylvania`, value: `pennsylvania`, key: `pennsylvania` },
  { title: `Rhode Island`, value: `rhode island`, key: `rhode island` },
  { title: `South Carolina`, value: `south carolina`, key: `south carolina` },
  { title: `South Dakota`, value: `south dakota`, key: `south dakota` },
  { title: `Tennessee`, value: `tennessee`, key: `tennessee` },
  { title: `Texas`, value: `texas`, key: `texas` },
  { title: `Utah`, value: `utah`, key: `utah` },
  { title: `Vermont`, value: `vermont`, key: `vermont` },
  { title: `Virginia`, value: `virginia`, key: `virginia` },
  { title: `Washington`, value: `washington`, key: `washington` },
  { title: `West Virginia`, value: `west virginia`, key: `west virginia` },
  { title: `Wisconsin`, value: `wisconsin`, key: `wisconsin` },
  { title: `Wyoming`, value: `wyoming`, key: `wyoming` },
]
