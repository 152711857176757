export const markLocations = [
  { title: `Right arm`, value: `rightArm`, selected: false },
  { title: `Left arm`, value: `leftArm`, selected: false },
  { title: `Right leg`, value: `rightLeg`, selected: false },
  { title: `Left leg`, value: `leftLeg`, selected: false },
  { title: `Head`, value: `head`, selected: false },
  { title: `Back`, value: `back`, selected: false },
  { title: `Chest`, value: `chest`, selected: false },
  { title: `Stomach`, value: `stomach`, selected: false },
  { title: `Buttocks`, value: `buttocks`, selected: false },
]
