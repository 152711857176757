import * as helpers from "@erinfo/data-schema/src/_helpers"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as z from "zod"

export const nameMedicalConditions = `medicalConditions`

export const medicalCondition = z
  .object({
    [attr.nameTitle]: attr.titleOptional,
    [attr.nameOnset]: attr.onsetOptional,
  })
  .strict()
export type medicalCondition = z.infer<typeof medicalCondition>

export const medicalConditions = z.array(medicalCondition)
export const medicalConditionsOptional = medicalConditions.optional()

export const medicalConditionsPut = helpers.setPutItem(medicalCondition)
export const medicalConditionsPutOptional = medicalConditionsPut.optional()
