import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"
import {
  DatePicker as MuiDatePicker,
  DesktopDatePicker,
} from "@mui/x-date-pickers"
import { Dayjs } from "dayjs"

const isLinux = navigator.platform.startsWith(`Linux`)
const DatePicker = isLinux ? DesktopDatePicker : MuiDatePicker

interface IProps {
  inputId
  name: string
  label: string
  value: string
  classes: object
  errors: string[]
  className?: string
  wrapperClassName?: string
  datePickerParams?
  onChange: (value: any) => any
  onBlur?()
  hideAsterisk?: boolean
}

export const Datepicker = (props: IProps) => {
  const { classes, errors, onChange, label } = props

  return (
    <>
      <DatePicker
        {...props}
        value={
          props.value !== undefined && props.value !== `` ? props.value : null
        }
        label={label}
        onChange={(a: Dayjs) => {
          if (a?.format) {
            return onChange(a.format(`YYYY-MM-DD`))
          }
          onChange(``)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={props.name}
            fullWidth
            variant="standard"
            InputProps={{
              ...params.InputProps,
              style: { padding: `0 15px`, width: `auto`, marginBottom: 8 },
              classes,
            }}
            InputLabelProps={{
              shrink: true,
              style: { marginLeft: 15 },
            }}
            sx={{
              marginBottom: `8px !important`,
              "& .MuiFormLabel-asterisk": {
                color: !props.hideAsterisk ? `error.main` : `transparent`,
              },
              "& .MuiFormHelperText-root": {
                paddingLeft: `15px`,
              },
            }}
            onBlur={props.onBlur}
            error={!!errors?.length}
          />
        )}
      />
      <Errors>
        {errors &&
          errors.map((errorItem: string, key: number) => (
            <p key={key}>{errorItem}</p>
          ))}
      </Errors>
    </>
  )
}

const Errors = styled(`div`)(({ theme }) => ({
  padding: `0`,
  margin: `-10px 0px 10px 0px`,
  font: `400 0.9rem Roboto, sans-serif`,
  color: theme.palette.error.main,
}))
