export const dataRelationship = [
  { title: ``, value: `` },
  { title: `Mother`, value: `Mother`, key: `Mother` },
  { title: `Father`, value: `Father`, key: `Father` },
  { title: `Wife`, value: `Wife`, key: `Wife` },
  { title: `Husband`, value: `Husband`, key: `Husband` },
  { title: `Life Partner`, value: `Life Partner`, key: `Life Partner` },
  { title: `Daughter`, value: `Daughter`, key: `Daughter` },
  { title: `Son`, value: `Son`, key: `Son` },
  {
    title: `Doctor - Primary`,
    value: `Doctor - Primary`,
    key: `Doctor - Primary`,
  },
  {
    title: `Doctor - Specialist`,
    value: `Doctor - Specialist`,
    key: `Doctor - Specialist`,
  },
  { title: `Nurse`, value: `Nurse`, key: `Nurse` },
  {
    title: `Primary Care Facility`,
    value: `Primary Care Facility`,
    key: `Primary-care-facility`,
  },
  { title: `Brother`, value: `Brother`, key: `Brother` },
  { title: `Sister`, value: `Sister`, key: `Sister` },
  { title: `Caregiver`, value: `Caregiver`, key: `Caregiver` },
  { title: `Aunt`, value: `Aunt`, key: `Aunt` },
  { title: `Uncle`, value: `Uncle`, key: `Uncle` },
  { title: `Nephew`, value: `Nephew`, key: `Nephew` },
  { title: `Niece`, value: `Niece`, key: `Niece` },
  { title: `Guardian`, value: `Guardian`, key: `Guardian` },
  { title: `Grandfather`, value: `Grandfather`, key: `Grandfather` },
  { title: `Grandmother`, value: `Grandmother`, key: `Grandmother` },
  { title: `Granddaughter`, value: `Granddaughter`, key: `Granddaughter` },
  { title: `Grandson`, value: `Grandson`, key: `Grandson` },
  { title: `Cousin`, value: `Cousin`, key: `Cousin` },
  {
    title: `Daughter-in-law`,
    value: `Daughter-in-law`,
    key: `Daughter-in-law`,
  },
  { title: `Son-in-law`, value: `Son-in-law`, key: `Son-in-law` },
  { title: `Sister-in-law`, value: `Sister-in-law`, key: `Sister-in-law` },
  { title: `Brother-in-law`, value: `Brother-in-law`, key: `Brother-in-law` },
  { title: `Father-in-law`, value: `Father-in-law`, key: `Father-in-law` },
  { title: `Mother-in-law`, value: `Mother-in-law`, key: `Mother-in-law` },
  { title: `Friend`, value: `Friend`, key: `Friend` },
  { title: `Girlfriend`, value: `Girlfriend`, key: `Girlfriend` },
  { title: `Boyfriend`, value: `Boyfriend`, key: `Boyfriend` },
  { title: `Assistant`, value: `Assistant`, key: `Assistant` },
  { title: `Attorney`, value: `Attorney`, key: `Attorney` },
  { title: `Case Worker`, value: `Case Worker`, key: `Case Worker` },
  { title: `Conservator`, value: `Conservator`, key: `Conservator` },
  { title: `Employee`, value: `Employee`, key: `Employee` },
  { title: `Employer`, value: `Employer`, key: `Employer` },
  { title: `Fiance`, value: `Fiance`, key: `Fiance` },
  { title: `Fiancee`, value: `Fiancee`, key: `Fiancee` },
  { title: `Foster Mother`, value: `Foster Mother`, key: `Foster Mother` },
  { title: `Foster Parent`, value: `Foster Parent`, key: `Foster Parent` },
  { title: `Godchild`, value: `Godchild`, key: `Godchild` },
  { title: `Godparent`, value: `Godparent`, key: `Godparent` },
  { title: `Godparents`, value: `Godparents`, key: `Godparents` },
  { title: `Half Brother`, value: `Half Brother`, key: `Half Brother` },
  { title: `Half Sister`, value: `Half Sister`, key: `Half Sister` },
  { title: `Minister`, value: `Minister`, key: `Minister` },
  { title: `Neighbor`, value: `Neighbor`, key: `Neighbor` },
  {
    title: `POA for Healthcare`,
    value: `POA for Healthcare`,
    key: `POA for Healthcare`,
  },
  { title: `Pastor`, value: `Pastor`, key: `Pastor` },
  {
    title: `Power of Attorney`,
    value: `Power of Attorney`,
    key: `Power of Attorney`,
  },
  { title: `Priest`, value: `Priest`, key: `Priest` },
  { title: `Principal`, value: `Principal`, key: `Principal` },
  { title: `Investigator`, value: `Investigator`, key: `Investigator` },
  { title: `Rabbi`, value: `Rabbi`, key: `Rabbi` },
  { title: `Reverend`, value: `Reverend`, key: `Reverend` },
  { title: `Roommate`, value: `Roommate`, key: `Roommate` },
  { title: `Secretary`, value: `Secretary`, key: `Secretary` },
  { title: `Social Worker`, value: `Social Worker`, key: `Social Worker` },
  { title: `Stepbrother`, value: `Stepbrother`, key: `Stepbrother` },
  { title: `Stepdaughter`, value: `Stepdaughter`, key: `Stepdaughter` },
  { title: `Stepfather`, value: `Stepfather`, key: `Stepfather` },
  { title: `Stepmother`, value: `Stepmother`, key: `Stepmother` },
  { title: `Stepsister`, value: `Stepsister`, key: `Stepsister` },
  { title: `Stepson`, value: `Stepson`, key: `Stepson` },
]
