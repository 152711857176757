export const dataHeightFeet = [
  { title: ``, value: `` },
  { title: `2' `, value: `2' `, key: `2' ` },
  { title: `3' `, value: `3' `, key: `3' ` },
  { title: `4' `, value: `4' `, key: `4' ` },
  { title: `5' `, value: `5' `, key: `5' ` },
  { title: `6' `, value: `6' `, key: `6' ` },
  { title: `7' `, value: `7' `, key: `7' ` },
]

export const dataHeightInches = [
  { title: ``, value: `` },
  { title: `0"`, value: `0"`, key: `0"` },
  { title: `1"`, value: `1"`, key: `1"` },
  { title: `2"`, value: `2"`, key: `2"` },
  { title: `3"`, value: `3"`, key: `3"` },
  { title: `4"`, value: `4"`, key: `4"` },
  { title: `5"`, value: `5"`, key: `5"` },
  { title: `6"`, value: `6"`, key: `6"` },
  { title: `7"`, value: `7"`, key: `7"` },
  { title: `8"`, value: `8"`, key: `8"` },
  { title: `9"`, value: `9"`, key: `9"` },
  { title: `10"`, value: `10"`, key: `10"` },
  { title: `11"`, value: `11"`, key: `11"` },
]
