import { styled } from "@mui/material/styles"
import { Form } from "formik"

export const SignupContainer = styled(`div`)({
  gridColumn: `span 12`,
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  justifyContent: `center`,
  margin: `20px auto 52px`,
  width: `100%`,
  maxWidth: `900px`,
  flexShrink: 0,
  flexGrow: 1,
  marginTop: `-70px`,
  "@media (max-width: 470px)": {
    marginTop: 0,
  },
})

export const SignUpFormWrapper = styled(`div`)({
  display: `flex`,
  flexGrow: 1,
  width: `100%`,
  flexDirection: `column`,
  alignItems: `center`,
})

export const SignUpFormDiv = styled(`div`)({
  flexGrow: 1,
  boxSizing: `border-box`,
  maxWidth: `376px`,
  padding: `26px`,
  margin: `0 auto`,
})

export const SignUpForm = styled(Form)({
  flexGrow: 1,
  boxSizing: `border-box`,
  maxWidth: `376px`,
  padding: `26px`,
  margin: `0 auto`,
})
