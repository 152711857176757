export const allergies = [
  { title: `None`, value: `none`, selected: false },
  { title: `All caines`, value: `allCaines`, selected: false },
  { title: `Anesthetics`, value: `anesthetics`, selected: false },
  { title: `Animal`, value: `animal`, selected: false },
  { title: `Antabuse`, value: `antabuse`, selected: false },
  { title: `Antibiotics`, value: `antibiotics`, selected: false },
  { title: `Antihistamines`, value: `antihistamines`, selected: false },
  { title: `Aspirin`, value: `aspirin`, selected: false },
  { title: `Barbiturates`, value: `barbiturates`, selected: false },
  { title: `Bees`, value: `bees`, selected: false },
  { title: `Codeine`, value: `codeine`, selected: false },
  { title: `Cortisone`, value: `cortisone`, selected: false },
  { title: `Demerol`, value: `demerol`, selected: false },
  { title: `Fish`, value: `fish`, selected: false },
  { title: `Food`, value: `food`, selected: false },
  { title: `Horse serum`, value: `horseSerum`, selected: false },
  { title: `I.V.P. Dyes`, value: `iVPDyes`, selected: false },
  { title: `Lidocain`, value: `lidocain`, selected: false },
  { title: `Morphine`, value: `morphine`, selected: false },
  { title: `Narcotics`, value: `narcotics`, selected: false },
  { title: `Novacain`, value: `novacain`, selected: false },
  { title: `Peanuts`, value: `peanuts`, selected: false },
  { title: `Penicillin`, value: `penicillin`, selected: false },
  { title: `Salicylates`, value: `salicylates`, selected: false },
  { title: `Seafood`, value: `seafood`, selected: false },
  { title: `Shellfish`, value: `shellfish`, selected: false },
  { title: `Succinylcholine`, value: `succinylcholine`, selected: false },
  { title: `Sulfa`, value: `sulfa`, selected: false },
  { title: `Sulfonamides`, value: `sulfonamides`, selected: false },
  { title: `Tetracycline`, value: `tetracycline`, selected: false },
  { title: `X-Ray dye`, value: `xRayDye`, selected: false },
]
