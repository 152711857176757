import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { deleteUnverifiedUser } from "@erinfo/consumer/src/data/user"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { yupEmailSchema } from "@erinfo/data-schema"
import { forgotPassword } from "@erinfo/react-utils/src/helpers/amplify"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

const StyledTextInfo = styled(TextInfo)({
  gridColumn: `span 12`,
  textAlign: `center`,
  margin: `20px 0`,
})

const FormSchema = Yup.object().shape({
  email: yupEmailSchema.required(`Email is required`),
})

interface LocationState {
  mode?: "recover"
}

export const SendEmail = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { mode } = (location.state || {}) as LocationState
  const user = useSelector((state: RootState) => state.user)
  const { setDialogMessage, setSnackbarMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
    }),
  )

  const handleSubmit = async (values: Record<string, string>) => {
    try {
      const data = await forgotPassword(values.email)
      console.log(`TCL: handlerSubmit -> data`, data)
      setSnackbarMessage(`Verification code sent!`)
      navigate(`/forgot-password/reset`, {
        state: {
          mode: mode ?? `forgot`,
          email: values.email,
        },
      })
    } catch (error) {
      if (error.code === `InvalidParameterException`) {
        try {
          await deleteUnverifiedUser(values.email)
          return setDialogMessage({
            title: `This password cannot be recovered`,
            msg: `The account has been reset, please sign up again.`,
            onYes: () => navigate(`/sign-up/step-1`),
          })
        } catch (err) {
          return setDialogMessage({
            title: `Error`,
            msg: `The password could not be reset, please try again.`,
          })
        }
      }
      if (error.message) {
        console.log(`TCL: handlerSubmit -> (error.message`, error.message)
        if (error?.message === `Username/client id combination not found.`) {
          return setDialogMessage({
            title: `User Doesn't Exist`,
            msg: `Please check the email and try again.`,
          })
        }
        setDialogMessage({ msg: error.message })
      }
    }
  }

  return (
    <BorderBox maxWidth={350}>
      <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
        Forgot Password?
      </Typography>
      <Formik
        initialValues={{ email: `` }}
        validationSchema={FormSchema}
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field
              component={TextInput}
              name="email"
              label="Enter Email"
              type="email"
            />
            <StyledButton
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              loadingPosition="end"
              loading={isSubmitting}
              sx={{ marginTop: 3 }}
            >
              Send Code
            </StyledButton>
          </Form>
        )}
      </Formik>
      <StyledTextInfo>
        Back to{` `}
        <TextAnchor to={user?.id ? `/dashboard` : `/`}>
          {user?.id ? `DASHBOARD` : `SIGN IN`}
        </TextAnchor>
      </StyledTextInfo>
    </BorderBox>
  )
}
