import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { MemberListItem } from "@erinfo/brand-ui/src/storybook/other/memberlist-item/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { calculateProfileCompletion } from "@erinfo/react-utils/src/helpers/profile-completion"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import dayjs from "dayjs"

export const DeleteAccountConfirmation = ({
  open,
  handleClose,
  user,
  loading,
  onDelete,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      sx={{
        zIndex: 999,
        "& .MuiDialog-paper": {
          margin: 0,
          width: `calc(100% - 32px)`,
        },
      }}
    >
      <DialogTitle color="error">
        {open === "purge" ? "Purge" : "Delete"} Account
      </DialogTitle>
      <DialogContent>
        <Typography>
          All information{` `}
          {!!user.members?.length && `INCLUDING YOUR ADDITIONAL MEMBERS`} will
          be permanently deleted. Proceed?
        </Typography>
        {user?.members?.slice(0, 3)?.map((member) => (
          <MemberListItem
            key={member.id}
            avatar={member?.pictures?.[0]?.src || IPerson}
            updated={dayjs(member.updated).format(`MM/DD/YYYY`)}
            unclickable
            pctComplete={calculateProfileCompletion(member)?.score}
          >
            {member.profile.lastName ? `${member.profile.lastName}, ` : ``}
            {member.profile.firstName}
          </MemberListItem>
        ))}
      </DialogContent>
      <DialogActions>
        <StyledButton onClick={handleClose}>Cancel</StyledButton>
        <StyledButton color="error" onClick={onDelete} loading={loading}>
          {open === "purge" ? "Purge" : "Delete"} Account
        </StyledButton>
      </DialogActions>
    </Dialog>
  )
}
