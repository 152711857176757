import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import {
  AdvancedDirectivesForm,
  AllergiesForm,
  allergyFormFields,
  MedicalConditionsForm,
  medicalConditionsFormFields,
  MedicalDevicesForm,
  medicalDevicesFormFields,
  medicationFormFields,
  MedicationsForm,
  Notes,
  PreferredTreatmentForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import { StyledDivider } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { RootState } from "@erinfo/consumer/src/store" // @TODO: this has to go
import * as attr from "@erinfo/data-schema/src/attribute"
import {
  createMedicalDataStructure,
  createMedicalFormData,
} from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useDynamicFields } from "@erinfo/react-utils/src/hooks/use-dynamic-fields"
import { useUnsavedChanges } from "@erinfo/react-utils/src/hooks/use-unsaved-changes"
import { MedicalServices } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, useState } from "react"

const iconStyle = {
  color: `#E31B0C`,
  marginRight: 2,
}

interface IProps {
  open: boolean
  handleClose: () => void
  user: RootState["user"]
  handleSubmit: (data: DataSchema.user.id.put) => Promise<void>
}

export const MedicalInfoDialog: FC<IProps> = ({
  open,
  handleClose,
  user,
  handleSubmit,
}) => {
  const [loading, setLoading] = useState(false)
  const {
    fieldCounts,
    initialValues,
    addField,
    removeField,
    setFieldValueOrRemove,
  } = useDynamicFields(
    [`medicalConditions`, `allergies`, `medications`, `medicalDevices`],
    createMedicalFormData,
    user,
    open,
    `medicalData`,
    {
      medicalConditions: medicalConditionsFormFields,
      allergies: allergyFormFields,
      medications: medicationFormFields,
      medicalDevices: medicalDevicesFormFields,
    },
  )

  const { onClose, setDirty, dirty } = useUnsavedChanges(handleClose, open)

  const onSubmit = async (values) => {
    setLoading(true)
    const { notesMedical, ...formData } = values
    const data = {
      ...createMedicalDataStructure(formData),
      notesMedical,
    }
    await handleSubmit(data)
    handleClose()
    setLoading(false)
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      Icon={<MedicalServices sx={iconStyle} />}
      title="Medical Information"
      formId="medical"
      loading={loading}
    >
      <Formik
        initialValues={initialValues}
        // validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, setValues, dirty: formDirty }) => {
          if (formDirty && !dirty && open) {
            setDirty(true)
          }
          const formProps = {
            addField: addField(values, setValues),
            removeField: removeField(values, setValues),
            setFieldValue,
            setFieldValueOrRemove,
            values,
            fieldCounts,
          }
          return (
            <Form id="medical">
              <Box
                p={2}
                sx={{
                  backgroundColor: `#2196F320`,
                }}
              >
                <Typography variant="h5">Conditions</Typography>
                <MedicalConditionsForm {...formProps} />
              </Box>
              <Box p={2}>
                <Typography variant="h5">Allergies</Typography>
                <AllergiesForm {...formProps} />
              </Box>
              <Box
                p={2}
                sx={{
                  backgroundColor: `#2196F320`,
                }}
              >
                <Typography variant="h5">Medications</Typography>
                <MedicationsForm {...formProps} />
              </Box>
              <Box p={2}>
                <Typography variant="h5">Medical Devices & Implants</Typography>
                <MedicalDevicesForm {...formProps} />
              </Box>
              <Box
                p={2}
                sx={{
                  backgroundColor: `#2196F320`,
                }}
              >
                <Typography variant="h5" mb={2}>
                  Advanced Directives
                </Typography>
                <AdvancedDirectivesForm {...formProps} />
              </Box>
              <PreferredTreatmentForm />
              <StyledDivider />
              <Notes
                name={attr.nameNotesMedical}
                value={values?.[attr.nameNotesMedical]}
              />
            </Form>
          )
        }}
      </Formik>
    </BaseDialog>
  )
}
