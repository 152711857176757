import { HeaderWithLeftLogo } from "@erinfo/brand-ui/src/storybook/other/header/with-left-logo"
import { MainFooter } from "@erinfo/consumer/src/components/MainFooter"
import { MainMenu } from "@erinfo/consumer/src/components/MainMenu"
import Notifications from "@erinfo/consumer/src/components/Notifications"
import { CssBaseline, Hidden } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Outlet } from "react-router-dom"
import { UserState } from "@erinfo/consumer/src/store/models/user"

const Root = styled(`div`)({
  margin: `0 auto`,
  overflowY: `auto`,
  display: `grid`,
  gridTemplateRows: `auto 1fr`,
  minWidth: `100vw`,
  position: `relative`,
  "@media (min-width: 480px)": {
    height: `100vh`,
    footer: {
      position: `absolute`,
      bottom: `0`,
      padding: `8px 0`,
    },
  },
  "@media (max-width: 480px)": {
    justifyContent: `flex-start`,
    overflowY: `auto`,
    height: `100%`,
  },
})

const AppContent = styled(`div`)((props) => ({
  display: `flex`,
  flexDirection: `column`,
  background: `white`,
  width: `100%`,
  alignItems: `center`,
  "@media (min-width: 480px)": {
    justifyContent: `center`,
    overflowY: `auto`,
    position: `absolute`,
    top: 58,
  },
  flex: 1,
}))

interface IProps {
  user: UserState
  children?: React.ReactNode
}

const AppLayout = (props: IProps) => {
  const { children, user } = props
  const theme = useTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up(`lg`))

  return (
    <Root id="root">
      <CssBaseline />
      <HeaderWithLeftLogo renderRightSlot={MainMenu} user={user} />
      <AppContent>
        {children}
        <Outlet />
        <Hidden smDown>
          <MainFooter />
        </Hidden>
      </AppContent>
      <Notifications />
    </Root>
  )
}

export default AppLayout
