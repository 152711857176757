import React, { useState } from "react"

import "./_.scss"

interface IProps {
  min?: number
  max?: number
  value?: number | string
  step?: number
  className?: string
  onChange?: (event) => void
}

export const Slider = (props: IProps) => {
  const className = ((className = "slider") => {
    if (props.className) className += ` ${props.className}`

    return className
  })()

  return (
    <input
      type="range"
      max={props.max}
      min={props.min}
      step={props.step}
      value={props.value}
      className={className}
      onChange={props.onChange}
    />
  )
}
