import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { Box, Step, StepLabel, Stepper } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"

const StyledBox = styled(Box)({
  background: `white`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-between`,
  border: `1px solid rgba(0, 0, 0, 0.12)`,
  "@media (max-width: 480px)": {
    height: `100%`,
  },
})

interface IProps {
  activeStep: number
  steps: string[]
  subHeader?: React.ReactNode
  children: React.ReactNode
  nextLabel?: string
  backLabel?: string
  onNext?: () => void
  formId?: string
  nextDisabled?: boolean
  nextLoading?: boolean
  onBack?: () => void
  sx: SxProps
}

const StepperPanel = ({
  activeStep,
  steps,
  subHeader,
  children,
  nextLabel = `Next`,
  backLabel = `Back`,
  onNext,
  onBack,
  formId,
  nextDisabled = false,
  nextLoading = false,
  sx = {},
  ...rest
}: IProps) => {
  return (
    <StyledBox sx={sx} {...rest}>
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          flexDirection: `column`,
          paddingTop: 2,
          borderBottom: `1px solid #E0E0E0`,
          "@media(max-width: 480px)": {
            position: `sticky`,
            top: 0,
            background: `white`,
            zIndex: 5,
          },
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            width: `100%`,
            paddingBottom: 1,
          }}
        >
          {steps.map((label) => (
            <Step
              key={label}
              sx={{
                "&& .Mui-active": { color: `info.main` },
                "&& .Mui-completed": { color: `#4CAF50` },
              }}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {subHeader}
      </Box>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          alignItems: `center`,
          position: `relative`,
        }}
      >
        {children}
      </Box>
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          height: 58,
          backgroundColor: `#F5F5F5`,
          justifyContent: `space-between`,
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {onBack ? (
          <StyledButton
            variant="contained"
            sx={{ paddingLeft: 1 }}
            onClick={onBack || (() => {})}
            disabled={nextLoading}
          >
            <ArrowBackIcon sx={{ marginRight: 1 }} />
            {backLabel}
          </StyledButton>
        ) : (
          <div />
        )}
        <StyledButton
          variant="contained"
          sx={{ paddingRight: 1 }}
          onClick={onNext || (() => {})}
          disabled={nextDisabled}
          loading={nextLoading}
          type="submit"
          form={formId || activeStep.toString()}
        >
          {nextLabel}
          <ArrowForwardIcon sx={{ marginLeft: 1 }} />
        </StyledButton>
      </Box>
    </StyledBox>
  )
}

export default StepperPanel
