import {
  PhoneInput,
  TextfieldDefault,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextParagraph } from "@erinfo/brand-ui/src/storybook/typography/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { verifyAttribute } from "@erinfo/consumer/src/data"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { StatusCodes } from "http-status-codes"
import { useEffect, useState } from "react"
import { useTimer } from "react-timer-hook"

export type ModalState = undefined | "verify" | "resent" | "change" | "thanks"

const validateEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    email.toLowerCase(),
  )

interface IProps {
  open: boolean
  phone?: string
  email?: string
  onClose: () => void
  updateEmail: (email: string, code: number) => Promise<void>
  updatePhone: (phone: string, code: number) => Promise<void>
}

export const ChangePhoneEmailModal = (props: IProps) => {
  const [state, setState] = useState<ModalState>()
  const [code, setCode] = useState(``)
  const [email, setEmail] = useState(``)
  const [phone, setPhone] = useState(``)
  const [error, setError] = useState(``)
  const [submitting, setSubmitting] = useState(false)
  const { onClose, updateEmail, updatePhone } = props
  const { seconds, restart } = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: () => console.warn(`onExpire called`),
  })
  const mode = props.email ? `email` : `phone`

  const textProps = {
    fullWidth: true,
    inputId: `textfield`,
    disabled: false,
    maxLength: 12,
    style: { margin: `15px 0` },
    InputLabelProps: { style: { padding: `0 15px` } },
    InputProps: { style: { padding: `0 15px` } },
  }

  const startTimeout = () => {
    const time = new Date()
    time.setSeconds(time.getSeconds() + 30)
    restart(time, true)
  }

  useEffect(() => {
    setCode(``)
    setError(``)
    setState(`change`)
    if (props.email) {
      setEmail(props.email)
    } else if (props.phone) {
      setPhone(props.phone)
    }
  }, [props.open])

  const onSubmit = async () => {
    setError(``)
    try {
      if (mode === `phone`) {
        if (phone === props.phone) {
          return setError(`Please pick a different phone number.`)
        } else if (
          !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.exec(phone)
        ) {
          return setError(`Invalid phone number`)
        }
        await verifyAttribute({ phone })
      } else {
        if (email === props.email) {
          return setError(`Please pick a different email.`)
        }
        if (!validateEmail(email)) {
          return setError(`Invalid email format`)
        }
        await verifyAttribute({ email })
      }

      setState(`verify`)
    } catch (err) {
      try {
        if (err.json) {
          const error = await err.json()
          console.log(error.message)
          return setError(error.message)
        }
        console.log(err)
        setError(err.message)
      } catch (error) {
        setError(`Invalid ${mode === `phone` ? `phone number` : `email`}`)
      }
    }
  }

  return (
    <StyledDialog
      open={!!props.open}
      // onClose={remainingLogins <= 0 ? () => {} : onClose}
    >
      {state === `change` && (
        <>
          <DialogContent sx={{ minWidth: 350 }}>
            <StyledDialogTitle>
              Change {mode === `phone` ? `phone number` : `email address`}
              {` `}
            </StyledDialogTitle>
            <TextParagraph>
              Please enter a valid{` `}
              {mode === `email` ? `email address` : `phone number`} that you can
              access.
            </TextParagraph>
            {mode === `phone` ? (
              <PhoneInput
                {...textProps}
                fullWidth
                name="mobile"
                label=""
                type="tel"
                value={phone}
                form={{
                  errors: { mobile: error },
                  touched: { mobile: true },
                  setFieldValue: (_, v) => setPhone(v),
                  setFieldTouched: () => {},
                }}
                field={{ name: `mobile` }}
              />
            ) : (
              <TextfieldDefault
                {...textProps}
                variant="standard"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value.trim())}
                errors={error}
              />
            )}
          </DialogContent>
          <StyledDialogActions>
            <StyledButton onClick={onClose}>Cancel</StyledButton>
            <StyledButton onClick={onSubmit}>Submit</StyledButton>
          </StyledDialogActions>
        </>
      )}
      {[`verify`, `resent`].includes(state) && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>
              {state === `verify` && `Verification Code Sent`}
              {state === `resent` && `Code Re-Sent`}
            </StyledDialogTitle>
            <TextParagraph>
              Please enter the verification code that was just sent to:
              <span style={{ fontWeight: `bold`, marginLeft: 10 }}>
                {mode === `email` ? email : phone}
              </span>
            </TextParagraph>
            {state === `resent` && mode === `email` && (
              <Typography sx={{ color: `warning.main` }}>
                (email may be in spam)
              </Typography>
            )}
            <TextfieldDefault
              {...textProps}
              variant="standard"
              value={code}
              placeholder="Verification Code"
              onChange={(e) => setCode(e.target.value.trim())}
              errors={error}
              autoComplete="one-time-code"
            />
            <br />
          </DialogContent>
          <StyledDialogActions>
            <StyledButton onClick={onClose}>Cancel</StyledButton>
            <StyledButton
              onClick={() => {
                setError(``)
                setCode(``)
                setState(`change`)
              }}
            >
              Change {mode === `email` ? `Email` : `Phone Number`}
            </StyledButton>
            <StyledButton
              disabled={seconds > 1}
              onClick={async () => {
                setError(``)
                setCode(``)
                try {
                  mode === `email`
                    ? await verifyAttribute({ email })
                    : await verifyAttribute({ phone })
                  setState(`resent`)
                  startTimeout()
                } catch (err) {
                  console.log(err)
                  setError(err.message)
                }
              }}
            >
              {seconds > 1 ? `${seconds} sec` : `Resend Code`}
            </StyledButton>
            <StyledButton
              onClick={async () => {
                try {
                  setSubmitting(true)
                  if (mode === `email`) {
                    await updateEmail(email, Number(code))
                  } else {
                    await updatePhone(phone, Number(code))
                  }
                  setState(`thanks`)
                  setCode(``)
                } catch (err) {
                  if (
                    err.status === StatusCodes.FAILED_DEPENDENCY ||
                    err.status === StatusCodes.NOT_IMPLEMENTED
                  ) {
                    setError(`Invalid Code`)
                  } else if (err.status === StatusCodes.CONFLICT) {
                    setError(
                      `${
                        mode === `email` ? `Email address` : `Phone number`
                      } is already in use.`,
                    )
                  }
                } finally {
                  setSubmitting(false)
                }
              }}
              loading={submitting}
            >
              Submit
            </StyledButton>
          </StyledDialogActions>
        </>
      )}

      {state === `thanks` && (
        <>
          <DialogContent sx={{ minWidth: 300 }}>
            <StyledDialogTitle>
              {mode === `email` ? `Email` : `Phone`} Verified
            </StyledDialogTitle>
            <TextParagraph>
              Thank you!
              <br />
              The {mode === `email` ? `email` : `phone number`} was successfully
              verified
            </TextParagraph>
          </DialogContent>
          <StyledDialogActions sx={{ justifyContent: `flex-end` }}>
            <StyledButton onClick={onClose}>OK</StyledButton>
          </StyledDialogActions>
        </>
      )}
    </StyledDialog>
  )
}

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    maxWidth: 430,
  },
})

const StyledDialogTitle = styled(DialogTitle)({
  padding: `0 0 15px`,
})

const StyledDialogActions = styled(DialogActions)({
  padding: `0 !important`,
  display: `flex`,
  justfiyContent: `flex-end`,
  "& button": {
    marginLeft: `0 !important`,
  },
})
