import {
  IPhoto,
  PhotoFace,
} from "@erinfo/brand-ui/src/storybook/other/photo/face-v2"
import StepperPanel from "@erinfo/brand-ui/src/storybook/other/StepperPanel"
import { recoverUser } from "@erinfo/consumer/src/data"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { validationCallbackDuplicateOk } from "@erinfo/consumer/src/lib/imageUtils"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { gtagRecordPayment } from "@erinfo/react-utils/src/components/googleAnalytics"
import { getImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { CameraType } from "expo-camera"

import { stepperSteps } from "../constants"

const initialPicture = {
  value: ``,
  errors: [],
  dataBeforeEditing: ``,
  created: ``,
  imageId: ``,
}

const SignUpStep4 = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const confirmationShown = useRef(false)
  const { handleSignout } = useAuth()
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState<IPhoto>(initialPicture)
  const [retake, setRetake] = useState(false)
  const [error, setError] = useState(``)
  const user = useSelector((state: RootState) => state.user)
  const { storeUserFacePicture, setDialogMessage, deleteUserFacePicture } =
    useRematchDispatch((dispatch: Dispatch) => ({
      storeUserFacePicture: dispatch.user.storeUserFacePicture,
      setDialogMessage: dispatch.notifications.setDialogMessage,
      deleteUserFacePicture: dispatch.user.deleteUserFacePicture,
    }))
  const paymentPlan = user?.paymentPlan?.active
  const pictureInitialized = !!user?.pictures?.length && !retake
  const nextStep = `/sign-up/step-5`

  useEffect(() => {
    if (confirmationShown.current) return

    if (search.includes(`success=1`) && !user.fetching && paymentPlan) {
      setDialogMessage({
        title: `Congratulations`,
        msg: `Your payment has been processed.  You can now complete your enrollment.`,
        altYesLabel: `Proceed`,
        onYes: () => setDialogMessage({ msg: `` }),
      })
      gtagRecordPayment({
        transaction_id: `unknown`,
        value: 0,
        currency: `USD`,
        coupon: `unknown`,
        items: [
          {
            item_name: paymentPlan.name,
            discount: 0,
            currency: `USD`,
            price: 0,
          },
        ],
      })
      confirmationShown.current = true
    } else if (search.includes(`bypass=1`)) {
      setDialogMessage({
        title: `Congratulations`,
        msg: `Your ERinfo trial subscription has been enabled.
            Please complete your remaining registration.`,
        altYesLabel: `Continue Registration`,
        altNoLabel: `Logout`,
        onYes: () => setDialogMessage({}),
        onNo: async () => {
          await handleSignout()
          navigate(`/`)
        },
      })
      confirmationShown.current = true
    } else if (
      !search.includes(`success=1`) &&
      !search.includes(`?return=true`)
    ) {
      setDialogMessage({
        title: `Welcome Back!`,
        msg: `Your ERinfo account has already been created.
            Please complete your remaining registration.`,
        altYesLabel: `Continue Registration`,
        altNoLabel: `Logout`,
        onYes: () => setDialogMessage({}),
        onNo: async () => {
          await handleSignout()
          navigate(`/`)
        },
      })
      confirmationShown.current = true
    }
  }, [user])

  const onDelete = () => {
    setDialogMessage({
      title: `Delete Photo?`,
      msg: `Are you sure you want to delete this photo?`,
      altYesLabel: `Yes`,
      altNoLabel: `Cancel`,
      onYes: () => {
        setPicture(initialPicture)
        setError(``)
      },
      onNo: () => setDialogMessage({ msg: `` }),
    })
  }

  const duplicateImageErrorText = () => (
    <Box sx={{ lineHeight: `1rem` }}>
      <p>Possible duplicate photo associated with another account.</p>
      <br />
      <p style={{ color: `rgba(0, 0, 0, 0.6)` }}>
        If you would like to recover the account associated with this photo{` `}
        {` `}
        <Link to="#" onClick={recoverDuplicateAccount}>
          CLICK HERE
        </Link>
        <br />
        <br />
        If you feel like this is an error or {` `}
        need clarity regarding this issue please{` `}
        <a href="https://erinfo.me/contact" target="_blank">
          contact us
        </a>
        {` `}
        {` `}
      </p>
    </Box>
  )

  const recoverDuplicateAccount = async () => {
    if (!picture?.created) return
    const { data } = await recoverUser(picture?.created)
    await handleSignout()
    navigate(`/forgot-password/send-email`, { state: { mode: `recover` } })
    setDialogMessage({
      title: `Recover Password`,
      msg: () => (
        <>
          You have been logged out of the
          <br />
          current session.
          <br />
          <br />
          To recover your previous account enter
          <br />
          your full email: {data.email}
          <br />
          on the next screen and follow the
          <br />
          instructions to reset your password.
        </>
      ),
      altYesLabel: `OK`,
      onYes: () => setDialogMessage({}),
    })
  }

  const onSubmit = async () => {
    if (user.pictures?.length && !retake) {
      return navigate(nextStep)
    }
    setLoading(true)
    try {
      await storeUserFacePicture({ userId: user.id, picture })
      if (retake) {
        await deleteUserFacePicture({
          userId: user.id,
          faceId: user.pictures[0].faceId,
        })
      }
      navigate(nextStep)
    } catch (err) {
      console.log(err)
      if (err.error === `hasBeenAdded`) {
        setError(duplicateImageErrorText)
      } else if (err.message) {
        setError(err.message)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <StepperPanel
      activeStep={0}
      steps={stepperSteps}
      sx={{
        "@media(min-width: 480px)": {
          width: `calc(100vw - 50px)`,
          maxWidth: 960,
          minHeight: 650,
        },
        "@media(max-width: 480px)": {
          width: `100%`,
        },
      }}
      onNext={onSubmit}
      nextDisabled={!picture?.created && !pictureInitialized}
      nextLoading={loading}
    >
      <Typography mb={2}>
        {!pictureInitialized && !picture.value
          ? `Add a photo for facial recognition below.`
          : `Select to retake.  NEXT to proceed.`}
      </Typography>
      {pictureInitialized && user.pictures?.[0]?.created && (
        <img
          src={getImageUrl(
            user.id,
            picture.created || user.pictures?.[0]?.created,
          )}
          style={{
            width: 190,
            height: 250,
            objectFit: `contain`,
            cursor: `pointer`,
          }}
          onClick={() => setRetake(true)}
        />
      )}
      {!pictureInitialized && (
        <PhotoFace
          photo={picture}
          setPhoto={(pic) => setPicture(pic)}
          faceValidation={validationCallbackDuplicateOk}
          onDelete={onDelete}
          startingCameraType={CameraType.front}
        />
      )}
      {error ? (
        <ErrorWrapper>{error}</ErrorWrapper>
      ) : (
        <Typography sx={{ opacity: 0.38, marginTop: 2, fontSize: `0.9rem` }}>
          *Only used by first responders
        </Typography>
      )}
    </StepperPanel>
  )
}

const ErrorWrapper = styled(`div`)(({ theme }) => ({
  position: `relative`,
  fontFamily: `Roboto, arial`,
  fontSize: `12px`,
  maxWidth: `250px`,
  marginTop: `8px`,
  color: theme.palette.error.main,
  "& li": {
    marginLeft: 30,
  },
}))

export default SignUpStep4
