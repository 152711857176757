export const checkIsScriptTagCreated = (url: string) =>
  document.head.querySelector(`script[src="${url}"]`)
/**
 * We use it to load SDKs like Facebook, Google, etc. when we need them
 *   - for example, only when user opens Sign In page
 * @param url - what to download
 * @param callback - what to do after loading the file
 */
export const loadJsConditionally = (
  url: string,
  callback?: () => void,
  skipCheck?: boolean,
) => {
  const isCreatedBefore = skipCheck ? false : checkIsScriptTagCreated(url)
  if (!isCreatedBefore) {
    const scriptTag = document.createElement(`script`)

    if (callback) {
      scriptTag.onload = callback
    }
    scriptTag.src = url

    document.head.appendChild(scriptTag)
  }
}
