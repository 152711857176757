import { ReactComponent as IPerson } from "@erinfo/brand-ui/src/assets/svg/person-gray.svg"
import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import { MemberListItem } from "@erinfo/brand-ui/src/storybook/other/memberlist-item/_"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { getS3KeyPhotoFace } from "@erinfo/env"
import { FamilyRestroom } from "@mui/icons-material"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

export const MemberListDialog = ({ open, handleClose, user }) => {
  const navigate = useNavigate()
  const paymentPlan = user.paymentPlan?.active
  const addedMembers =
    user.enrolledPatients?.filter((i) => !i.status?.includes(`archived`)) || []

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      Icon={<FamilyRestroom sx={{ color: `plan.main`, marginRight: 2 }} />}
      title="Extended Family Members"
      renderActions={() => (
        <Box
          sx={{
            width: `100%`,
            display: `flex`,
            justifyContent: `flex-end`,
            alignItems: `center`,
          }}
        >
          <StyledButton onClick={handleClose}>Close</StyledButton>
        </Box>
      )}
    >
      <Box pl={2}>
        {user?.members?.map((member) => (
          <MemberListItem
            key={member.id}
            avatar={member.pictures?.[0] ? member.pictures[0].src : IPerson}
            added={dayjs(member.created).format(`MM/DD/YYYY`)}
            updated={dayjs(member.updated).format(`MM/DD/YYYY`)}
            onClick={() => navigate(`/dashboard/member/${member.id}`)}
          >
            {member.profile.lastName ? `${member.profile.lastName}, ` : ``}
            {member.profile.firstName}
          </MemberListItem>
        ))}
      </Box>
    </BaseDialog>
  )
}
