import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { Dispatch } from "@erinfo/consumer/src/store"
import { getUtmParams } from "@erinfo/react-utils/src/helpers/cookies"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Typography } from "@mui/material"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const PlanBypass = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { applyCoupon } = useRematchDispatch((dispatch: Dispatch) => ({
    applyCoupon: dispatch.user.applyCoupon,
  }))
  const currentParams = Object.fromEntries([...searchParams])
  const { rc } = currentParams || {}
  const { utm_rc } = getUtmParams()

  useEffect(() => {
    // call new endpoint to create plan with coupon
    console.log(`submitting`, rc || utm_rc)
    applyCoupon({ coupon: rc || utm_rc })
      .then(() => navigate(`/sign-up/step-4`))
      .catch((err) => {
        // coupon failed, redirect to plan selection page
        setTimeout(() => navigate(`/sign-up/step-3`), 5000)
      })
  }, [])

  return (
    <BorderBox sx={{ "@media (min-width: 470px)": { maxWidth: 350 } }}>
      <Typography
        variant="h6"
        sx={{ marginBottom: 2, alignSelf: `start`, whiteSpace: `nowrap` }}
      >
        {`Processing Coupon ${rc || utm_rc} ...`}
      </Typography>
    </BorderBox>
  )
}

export default PlanBypass
