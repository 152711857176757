import * as helpers from "@erinfo/data-schema/src/_helpers"

export const nameWeight = `weight`

const regex = [helpers.hasOnlyNumbersOrEmpty]

export const regexWeight = {
  regex,
}

export const weightValidation = {
  validation: (value: string | number) => {
    if (Number(value) < 0 || Number(value) > 999) {
      return `Weight should be between 0 and 999`
    }
  },
}

export const weight = helpers.setRegexes(regex, helpers.transformerTrim)
export const weightOptional = weight.optional()
