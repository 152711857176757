import { MuiTelInput } from "mui-tel-input"

export const PhoneInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <MuiTelInput
    inputId={field.name}
    onBlur={() => setFieldTouched(field.name)}
    variant="standard"
    defaultCountry="US"
    error={touched[field.name] && errors[field.name]}
    helperText={
      touched[field.name] && errors[field.name] ? errors[field.name] : ``
    }
    InputLabelProps={{ style: { padding: `0 15px` } }}
    InputProps={{ style: { padding: `0 15px` } }}
    {...field}
    {...props}
    inputProps={{ ...props.inputProps, maxLength: 16 }}
    sx={{
      marginBottom: `8px !important`,
      "& .MuiFormLabel-asterisk": {
        color: !props.hideAsterisk ? `error.main` : `transparent`,
      },
      ...props.sx,
    }}
    onChange={(newPhone) => setFieldValue(field.name, newPhone)}
  />
)
