import { InitialsGrouping } from "./InitialsGrouping"

export const InitialsGroupingInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { name } = field

  return (
    <InitialsGrouping
      errors={touched[field.name] && errors[name]}
      onBlur={() => setFieldTouched(field.name)}
      {...field}
      {...props}
    >
      {props.children}
    </InitialsGrouping>
  )
}
