import { WrapperView } from "@erinfo/brand-ui/src/storybook/other/wrapper-view/_"
import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"

export const SignUpWrapper = styled(WrapperView)({
  alignItems: `center`,
  position: `relative`,
  fontFamily: `Roboto, arial`,
  margin: `0px !important`,
  "@media (max-width: 480px)": {
    paddingBottom: `65px`,
    border: `none !important`,
  },
  "@media (min-width: 480px)": {
    border: `1px solid rgba(0, 0, 0, 0.12) !important`,
  },
})

export const PlanWrapper = styled(Box)({
  display: `flex`,
  flexWrap: `wrap`,
  width: `calc(100% - 20px)`,
  paddingLeft: `10px`,
  paddingRight: `10px`,
  "& > div": {
    flex: 1,
    padding: `16px 10px`,
    "& .card": {
      height: `100%`,
      minHeight: `278px`,
      minWidth: `260px`,
      cursor: `pointer`,
    },
  },
})

export const IconWrapper = styled(Box)({
  display: `flex`,
  justifyContent: `center`,
  flex: 1,
  alignItems: `center`,
  paddingTop: `15px`,
  minHeight: `124.5px`,
  "& > img": { objectFit: `contain`, maxWidth: `168px`, maxHeight: `85px` },
})

export const ButtonWrapper = styled(Box)({
  paddingLeft: `10px`,
  paddingRight: `10px`,
  flex: 1,
  alignItems: `center`,
  display: `flex`,
  "& > button": { width: `100% !important` },
})

export const PriceWrapper = styled(Box)({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  color: `#666666`,
  fontFamily: `Roboto, Arial`,
  padding: `10px`,
  "& > span": {
    fontWeight: `bold`,
    fontSize: `25px`,
    lineHeight: `20px`,
    letterSpacing: `0.32px`,
    "& > label": {
      fontWeight: `normal`,
      fontSize: `16px`,
      letterSpacing: `0.21px`,
    },
  },
  "& .subtitle": {
    fontSize: `14px`,
    letterSpacing: `0.18px`,
    color: `#888`,
    marginTop: `5px`,
  },
  "& .discount": {
    fontFamily: `sans-serif`,
    fontSize: `14px`,
    letterSpacing: `0.18px`,
    color: `#449309`,
    fontWeight: 200,
    marginTop: `5px`,
  },
})

export const BottomSection = styled(Box)(({ theme }) => ({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  "@media (max-width: 600px)": { padding: `30px 20px` },
  "@media (min-width: 600px)": { padding: `30px 80px 25px` },
  "& h2": {
    fontFamily: `Roboto`,
    fontSize: `20px`,
    fontWeight: `bold`,
    fontStretch: `normal`,
    fontStyle: `normal`,
    lineHeight: 1.2,
    letterSpacing: `0.26px`,
    textAlign: `left`,
    color: `#000000`,
    opacity: 0.6,
    marginTop: theme.spacing(2),
  },
  "& .descriptionWrapper": {
    display: `flex`,
    flexWrap: `wrap`,
    "& > div": {
      flex: 1,
      padding: `20px 20px 0 0`,
      minWidth: `266px`,
      "@media (max-width: 600px)": { paddingRight: `0` },
      "& .card-header": {
        background: `#e0e0e0`,
        color: `rgba(0, 0, 0, 0.6)`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        width: `100%`,
        padding: `0 !important`,
        "& > div": {
          display: `flex`,
          alignItems: `center`,
          justifyContent: `space-between`,
          width: `100%`,
          "& > div": {
            display: `flex`,
            alignItems: `center`,
            whiteSpace: `nowrap`,
          },
        },
        "& .icon": { width: `24px`, height: `24px`, margin: `0 5px` },
        "& .firstnet": { height: `20px`, marginRight: `5px` },
      },
      "& .card-body": {
        padding: `10px`,
        fontSize: `14px`,
        fontWeight: `normal`,
        fontStretch: `normal`,
        fontStyle: `normal`,
        lineHeight: 1.36,
        letterSpacing: `0.14px`,
        textAlign: `left`,
        color: `rgba(0, 0, 0, 0.6)`,
      },
    },
  },
}))
