import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { dataLanguage, dataUSStates } from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import { Box } from "@mui/material"
import { Field } from "formik"

interface IProps {
  setFieldValue: (field: string, val: string) => void
  setFieldTouched: (field: string) => void
  showPhone?: boolean
  disablePhone?: boolean
  userIsSubscriber?: boolean
}

export const PersonalDetailsForm = ({
  setFieldValue,
  setFieldTouched,
  showPhone,
  disablePhone,
  userIsSubscriber = false,
}: IProps) => {
  return (
    <>
      <Field
        name={attr.namePreferredName}
        label="Additional/Preferred Name"
        component={TextInput}
        inputProps={{ maxlength: `30` }}
      />
      <Field
        name={attr.nameLang}
        label="Language"
        component={AutocompleteInput}
        setFieldValue={setFieldValue}
        onBlur={setFieldTouched}
        options={dataLanguage}
      />
      {showPhone && (
        <Field
          component={PhoneInput}
          fullWidth
          name={attr.nameMobile}
          label="MOBILE Phone"
          type="tel"
          disabled={disablePhone}
          helperText={
            disablePhone &&
            userIsSubscriber &&
            ` To update, open Settings from main menu`
          }
        />
      )}
      <Field
        name={attr.nameStreet}
        label="Street Address"
        component={TextInput}
        inputProps={{ maxlength: `30`, autoComplete: `street-address` }}
      />
      <Field
        name={attr.nameCity}
        label="City"
        component={TextInput}
        inputProps={{ maxlength: `30`, autoComplete: `address-level2` }}
      />
      <Box display="flex" alignItems="center">
        <Field
          name={attr.nameState}
          label="State"
          component={AutocompleteInput}
          options={dataUSStates}
          setFieldValue={setFieldValue}
          inputProps={{ autoComplete: `address-level1` }}
        />
        <Field
          name={attr.nameZipCode}
          label="Zip/Postal Code"
          component={TextInput}
          inputProps={{ maxlength: `10`, autoComplete: `postal-code` }}
          style={{ marginLeft: 10 }}
        />
      </Box>
    </>
  )
}
