import { makeStyles, styled } from "@mui/styles"

interface IProps {
  title: string
  svg
}

const useStyles = makeStyles({
  icon: {
    height: `24px`,
    width: `24px`,
  },
})

export const OverviewHeader = (props: IProps) => {
  const classes = useStyles()
  const Icon = props.svg

  return (
    <Container>
      <Icon className={classes.icon} />
      <Title> {props.title}</Title>
    </Container>
  )
}

const Container = styled(`div`)({
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-start`,
  justifyContent: `center`,
})

const Title = styled(`p`)({
  margin: `0`,
  padding: `0`,
  marginLeft: `32px`,
  color: `rgba(0, 0, 0, 0.6)`,
  font: `20px/24px Roboto`,
  letterSpacing: `0.3px`,
  verticalAlign: `middle`,
})
