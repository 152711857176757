import { DatepickerInput } from "@erinfo/brand-ui/src/storybook/inputs/datepicker"
import { MedicalSelectInput } from "@erinfo/brand-ui/src/storybook/inputs/medical-select"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { medicalConditions } from "@erinfo/data-schema"
import {
  filterValues,
  useHandleNoneSelection,
} from "@erinfo/react-utils/src/helpers/form/formUtils"
import { Add, Delete } from "@mui/icons-material"
import { Box, Button, Divider } from "@mui/material"
import { Field } from "formik"
import { Fragment } from "react"

export const medicalConditionsFormFields = [`medicalCondition`, `onset`]

export const MedicalConditionsForm = ({
  addField,
  removeField,
  setFieldValue,
  setFieldValueOrRemove,
  values,
  fieldCounts,
}) => {
  const hideDelete = !values?.medicalCondition_0 && !values?.onset_0
  useHandleNoneSelection({
    formType: `medicalConditions`,
    formFields: medicalConditionsFormFields,
    values,
    fieldCounts,
    removeField,
    setFieldValue,
  })

  return (
    <>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values?.medicalCondition_0}
        onClick={() => addField(`medicalConditions`, `medicalCondition`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.medicalConditions)]
        .map((_, i) => i)
        .reverse()
        .map((i, idx) => (
          <Fragment key={`medicalCondition_${i}`}>
            {i < fieldCounts.medicalConditions - 1 && (
              <Divider sx={{ mt: 2, mb: 2 }} />
            )}
            <Field
              label="Medical Conditions"
              name={`medicalCondition_${i}`}
              component={MedicalSelectInput}
              setFieldValue={setFieldValueOrRemove(setFieldValue, () =>
                removeField(`medicalConditions`, i),
              )}
              dataForMultiSelect={filterValues(
                `medicalCondition`,
                values,
                medicalConditions,
              )}
              style={{ minWidth: 200 }}
            />
            <Field
              component={DatepickerInput}
              name={`onset_${i}`}
              label="Onset"
              InputLabelProps={{ shrink: true }}
              style={{ width: `100%` }}
              validate={(value) => {
                if (value && value.length !== 10) {
                  return `Invalid date`
                }
              }}
              disabled={
                !values.medicalCondition_0 ||
                values.medicalCondition_0 === `None`
              }
            />
            {(idx !== 0 || !hideDelete) && (
              <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
                <Button
                  onClick={() => removeField(`medicalConditions`, i)}
                  color="error"
                  sx={{ mt: 1 }}
                >
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Condition
                </Button>
              </Box>
            )}
          </Fragment>
        ))}
    </>
  )
}
