import Card from "@erinfo/brand-ui/src/storybook/other/card/_"
import OnePersonIcon from "@erinfo/consumer/src/assets/family_1@3x.png"
import FourPeopleIcon from "@erinfo/consumer/src/assets/family_4@3x.png"
import TenPeopleIcon from "@erinfo/consumer/src/assets/family_10@3x.png"
import FirstnetIcon from "@erinfo/consumer/src/assets/firstnet_logo@3x.png"
import DocumentIcon from "@erinfo/consumer/src/assets/icon_document@3x.png"
import EmergencyIcon from "@erinfo/consumer/src/assets/icon_emergency@3x.png"
import FaceIcon from "@erinfo/consumer/src/assets/icon_face_hair@3x.png"
import SmartphoneIcon from "@erinfo/consumer/src/assets/icon_smartphone@3x.png"
import {
  checkStripeCoupon,
  createStripeCheckoutSession,
  getStripeSetup,
  triggerAffiliateEvent,
} from "@erinfo/consumer/src/data"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import * as schema from "@erinfo/data-schema"
import Loader from "@erinfo/provider/src/components/Loader"
import { getCookie } from "@erinfo/react-utils/src/helpers/cookies"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useStripe } from "@erinfo/react-utils/src/hooks"
import Button from "@mui/lab/LoadingButton"
import { Box, Typography } from "@mui/material"
import { get } from "lodash"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import * as Styled from "./styles"

declare global {
  interface Window {
    Stripe: (string) => any
  }
}

const MONTHS_YR = 12
const PCT = 100

const SignUpStep3 = () => {
  useStripe()
  const { user, handleSignout } = useAuth()
  const { search } = useLocation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(``)
  const [stripeData, setStripeData] = useState<DataSchema.stripe.pricing.get>()
  const [discount, setDiscount] = useState({
    type: ``,
    amount: ``,
  })
  const stripe = useRef()
  const { setDialogMessage } = useRematchDispatch((dispatch: Dispatch) => ({
    setDialogMessage: dispatch.notifications.setDialogMessage,
  }))
  const { registerCode: coupon, trialPeriod } = useSelector(
    (state: RootState) => state.signUp,
  )
  const userData = useSelector((state: RootState) => state.user)
  const affiliate = getCookie(`affiliate`)
  const price1 = Number(get(stripeData, `price.individual.msrp`, 0)) / MONTHS_YR
  const price2 = Number(get(stripeData, `price.family.msrp`, 0)) / MONTHS_YR
  const price3 = Number(get(stripeData, `price.group.msrp`, 0)) / MONTHS_YR

  useEffect(() => {
    if (user) {
      getStripeSetup()
        .then((data) => {
          setStripeData(data)
          stripe.current = window.Stripe(data.keyPb)
        })
        .catch(console.log)
    }
  }, [user])

  useEffect(() => {
    if (coupon) {
      checkStripeCoupon(coupon)
        .then((data) => {
          if (data.offPct) {
            setDiscount({ type: `pct`, amount: data.offPct })
          }
        })
        .catch(console.log)
    }
  }, [coupon])

  useEffect(() => {
    if (user && !user?.[schema.nameIsInvited] && affiliate) {
      void triggerAffiliateEvent({ [schema.nameAffiliate]: affiliate })
    }
  }, [user?.[schema.nameIsInvited], affiliate])

  useEffect(() => {
    if (search.includes(`cancel=1`)) {
      setDialogMessage({
        title: `Request Cancelled`,
        msg:
          `Your payment process was cancelled.  Please select your plan and complete ` +
          `your credit card submission to continue.`,
        altYesLabel: `OK`,
        onYes: () => setDialogMessage({ msg: `` }),
      })
    } else if (!search.includes(`initialSignup=1`)) {
      setDialogMessage({
        title: `Welcome Back!`,
        msg: `Your ERinfo account has already been created.
            Please complete your remaining registration.`,
        altYesLabel: `Continue Registration`,
        altNoLabel: `Logout`,
        onYes: () => setDialogMessage({ msg: `` }),
        onNo: async () => {
          await handleSignout()
          navigate(`/`)
        },
      })
    }
  }, [search])

  const getDiscountedPrice = (price) => {
    if (!price) return ``
    if (discount.type === `pct`) {
      return `$${(Number(price) * (1 - Number(discount.amount) / PCT)).toFixed(
        2,
      )}`
    }
    return `$${price.toFixed(2)}`
  }

  const onFormSubmit = async (priceId: string) => {
    console.log(`test`)
    if (!stripe.current) {
      return console.log(`Stripe is not initialized`)
    }
    setLoading(priceId)
    try {
      const sessionId = await createStripeCheckoutSession({
        priceId,
        email: userData[schema.nameEmail],
        pathname: `/sign-up/step-4`,
        ...(coupon && { coupon }),
        ...(trialPeriod && { trial: Number(trialPeriod) }),
      })
      stripe.current.redirectToCheckout({ sessionId })
    } catch (err) {
      console.log(err, err.response)
      setLoading(``)
      setDialogMessage({
        title: `Error`,
        msg: `We could not send you to the payment portal.`,
        altNoLabel: `Try Again`,
        altYesLabel: `Logout`,
        onNo: () => setDialogMessage({ msg: `` }),
        onYes: async () => {
          await handleSignout()
          navigate(`/`)
        },
      })
    }
  }

  return !stripeData ? (
    <Loader />
  ) : (
    <Styled.SignUpWrapper>
      <Styled.PlanWrapper>
        <div style={{ paddingTop: 43 }}>
          <Card
            title="1 Person"
            className="card"
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.individual?.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={OnePersonIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.individual.id}
                disabled={loading === stripeData?.price?.individual.id}
                loadingPosition="end"
              >
                {trialPeriod
                  ? `TRY ${trialPeriod} DAYS FREE`
                  : `CHOOSE INDIVIDUAL`}
              </Button>
            </Styled.ButtonWrapper>
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price1)}
                {getDiscountedPrice(price1) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
        <div style={{ paddingTop: 43 }}>
          <Card
            title="Up to 4 People"
            className="card"
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.family.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={FourPeopleIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.family.id}
                disabled={loading === stripeData?.price?.family.id}
                loadingPosition="end"
              >
                {trialPeriod ? `TRY ${trialPeriod} DAYS FREE` : `CHOOSE FAMILY`}
              </Button>
            </Styled.ButtonWrapper>
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price2)}
                {getDiscountedPrice(price2) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
        <div>
          <Card
            title="Up to 10 People"
            topLabel="BEST VALUE"
            className="card"
            onClick={() => {
              if (stripeData) {
                void onFormSubmit(stripeData.price.group.id)
              }
            }}
          >
            <Styled.IconWrapper>
              <img src={TenPeopleIcon} alt="One Person" />
            </Styled.IconWrapper>
            <Styled.ButtonWrapper>
              <Button
                variant="contained"
                loading={loading === stripeData?.price?.group?.id}
                disabled={loading === stripeData?.price?.group?.id}
                loadingPosition="end"
              >
                {trialPeriod
                  ? `TRY ${trialPeriod} DAYS FREE`
                  : `CHOOSE EXTENDED FAMILY`}
              </Button>
            </Styled.ButtonWrapper>
            <Styled.PriceWrapper>
              <span>
                {getDiscountedPrice(price3)}
                {getDiscountedPrice(price3) && <label>/mo</label>}
              </span>
              <div className="subtitle">billed annually</div>
              {coupon && <div className="discount">Discount Applied</div>}
            </Styled.PriceWrapper>
          </Card>
        </div>
      </Styled.PlanWrapper>
      <Styled.BottomSection>
        <div className="descriptionWrapper">
          <div>
            <Card
              wrapperStyle={{
                height: `100%`,
                background: `white`,
                border: `1px solid #e0e0e0`,
              }}
              headerClass="card-header"
              title={() => (
                <div>
                  <div>
                    <img src={FaceIcon} className="icon" />
                    Nothing for You to Wear or Do
                  </div>
                  <img src={FirstnetIcon} className="firstnet" />
                </div>
              )}
            >
              <div className="card-body">
                Medical First Responders access ERinfo through the highly secure
                and nationally available First Responder Network Authority.
              </div>
            </Card>
          </div>
          <div style={{ paddingRight: 0 }}>
            <Card
              wrapperStyle={{
                height: `100%`,
                background: `white`,
                border: `1px solid #e0e0e0`,
              }}
              headerClass="card-header"
              title={() => (
                <>
                  <img src={SmartphoneIcon} className="icon" />
                  Emergency Records in the Right Hands
                </>
              )}
            >
              <div className="card-body">
                After a positive ID, your emergency records are immediately
                available for informed medical response.
              </div>
            </Card>
          </div>
        </div>
        <Box className="descriptionWrapper" mb={2}>
          <div>
            <Card
              wrapperStyle={{
                height: `100%`,
                background: `white`,
                border: `1px solid #e0e0e0`,
              }}
              headerClass="card-header"
              title={() => (
                <>
                  <img src={EmergencyIcon} className="icon" />
                  Notify Emergency Contacts
                </>
              )}
            >
              <div className="card-body">
                Medical Responders can quickly notify your emergency contacts,
                including family, caregivers and physicians.
              </div>
            </Card>
          </div>
          <div style={{ paddingRight: 0 }}>
            <Card
              wrapperStyle={{
                height: `100%`,
                background: `white`,
                border: `1px solid #e0e0e0`,
              }}
              headerClass="card-header"
              title={() => (
                <>
                  <img src={DocumentIcon} className="icon" />
                  Indicate Advance Directives
                </>
              )}
            >
              <div className="card-body">
                Highlight advance directives such as hospital preference, DNR,
                medical power of attorney and organ donor status.
              </div>
            </Card>
          </div>
        </Box>
        <Typography variant="h2" my={2} style={{ textAlign: `center` }}>
          All features are included for each person, regardless of plan
        </Typography>
      </Styled.BottomSection>
    </Styled.SignUpWrapper>
  )
}

export default SignUpStep3
