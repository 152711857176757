export const getS3KeyPhotoFace = (userID: string, created: number | string) =>
  `${userID}/face/${created}`

export const getS3KeyPhotoOther = (userID: string, created: number | string) =>
  `${userID}/other/${created}`

export const getS3KeyMatch = (userID: string, created: number | string) =>
  `${userID}/match/${created}`

export const getS3KeyTempFace = (userID: string, created: number | string) =>
  `${userID}/temp/face/${created}`

export const getS3KeyTempOther = (userID: string, created: number | string) =>
  `${userID}/temp/other/${created}`
