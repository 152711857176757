import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { yupPasswordSchema } from "@erinfo/data-schema"
import {
  changePwd,
  forgotPasswordSubmit,
  verifyUserAttribute,
  verifyUserAttributeWithCode,
} from "@erinfo/react-utils/src/helpers/amplify"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import LoadingButton from "@mui/lab/LoadingButton"
import { Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Field, Form, Formik } from "formik"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import * as Yup from "yup"

const StyledTextInfo = styled(TextInfo)({
  gridColumn: `span 12`,
  textAlign: `center`,
  margin: `20px 0`,
})

interface LocationState {
  mode: "forgot" | "change" | "recover"
  attribute: "phone_number" | "email"
  email: string
  userId?: string
}

export const ResetPassword = (props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [error, setError] = useState(``)
  const { mode, attribute, email, userId } = location.state as LocationState
  const user = useSelector((state: RootState) => state.user)

  const { setDialogMessage, setSnackbarMessage } = useRematchDispatch(
    (dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
    }),
  )

  const FormSchema = Yup.object().shape({
    code: Yup.string()
      .matches(/(\D*\d){6}/, `Code is not valid`)
      .required(`Code is required`),
    ...(mode === `change` && {
      oldPassword: yupPasswordSchema.required(`Old password is required`),
    }),
    password: yupPasswordSchema.required(`Password is required`),
    passwordConfirm: Yup.string()
      .required(`Password is required`)
      .test(`passwords-match`, `Passwords must match`, function (value) {
        return this.parent.password === value
      }),
  })

  const handleSubmit = async (values) => {
    setError(``)
    values.code = values.code.trim()
    try {
      if (mode === `forgot` || mode === `recover`) {
        const data = await forgotPasswordSubmit(
          email || values.email,
          values.code,
          values.passwordConfirm,
          mode === `recover` ? { recovery: `true` } : undefined,
        )
        console.log(`TCL: handlerSubmit -> data`, data)
      } else {
        const result = await verifyUserAttributeWithCode(attribute, values.code)
        if (result === `SUCCESS`) {
          await changePwd(values.oldPassword, values.password)
        } else {
          return setError(result.message)
        }
      }
      const message =
        userId || user.id
          ? `Password reset successful!`
          : `Password reset successful! Please login.`
      setSnackbarMessage(message)

      if (userId) {
        return navigate(`/dashboard`)
      }
      navigate(`/`)
    } catch (error) {
      if (error.message) {
        console.log(`TCL: handlerSubmit -> (error.message`, error.message)
        setError(
          `Invalid code or password, check ${
            attribute === `email` ? `email` : `SMS`
          } for updated code.`,
        )
        void verifyUserAttribute(attribute)
      }
    }
  }

  return (
    <BorderBox maxWidth={350}>
      <Typography variant="h6" sx={{ marginBottom: 3, alignSelf: `start` }}>
        Reset Password
      </Typography>
      <Typography sx={{ marginBottom: 2 }}>
        A code has been sent to your phone via SMS. Please enter it below and
        choose a new password.
      </Typography>
      <Formik
        initialValues={{
          code: ``,
          oldPassword: ``,
          password: ``,
          passwordConfirm: ``,
        }}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {mode === `forgot` && !email && (
              <Field
                component={TextInput}
                name="email"
                label="Enter Email"
                type="email"
              />
            )}
            <Field
              component={TextInput}
              name="code"
              label="Enter Code"
              autocomplete="off"
            />
            {mode === `change` && (
              <Field
                component={TextInput}
                name="oldPassword"
                label="Old Password"
                type="password"
              />
            )}
            <Field
              component={TextInput}
              name="password"
              label="New Password"
              type="password"
            />
            <Field
              component={TextInput}
              name="passwordConfirm"
              label="New Password (again)"
              type="password"
            />
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              sx={{ margin: `20px 0` }}
              loading={isSubmitting}
              disabled={isSubmitting}
              loadingPosition="end"
            >
              Submit
            </LoadingButton>
            {error && (
              <Typography sx={{ fontSize: `0.85rem` }} color="error">
                {error}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
      <StyledTextInfo>
        Back to{` `}
        <TextAnchor to={user?.id ? `/dashboard` : `/`}>
          {user?.id ? `DASHBOARD` : `SIGN IN`}
        </TextAnchor>
      </StyledTextInfo>
    </BorderBox>
  )
}
