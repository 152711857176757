import { Logo } from "@erinfo/brand-ui/src/storybook/other/logo/_"
import { partnerStyling } from "@erinfo/consumer/src/components/PartnerBranding"
import { Box, Hidden, Typography } from "@mui/material"

interface IProps {
  tagLine?: string
  sx?: any
  className?: any
  logo?: string
}

export const SignupBrand = ({ tagLine, sx = {}, className, logo }: IProps) => {
  return (
    <Box sx={{ maxWidth: 350, ...sx }} className={className}>
      <Box
        sx={{
          display: `flex`,
          justifyContent: logo ? `space-between` : `center`,
        }}
      >
        <Logo version="horizontal" />
        {logo && (
          <Box
            sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `flex-end`,
              ...partnerStyling?.[logo]?.logo.style,
            }}
          >
            <Typography
              sx={{
                fontSize: 10,
                opacity: 0.38,
                fontStyle: `italic`,
                marginBottom: 1,
              }}
            >
              in partnership with
            </Typography>
            <img
              src={partnerStyling?.[logo]?.logo?.source}
              style={{
                maxHeight: 100,
                maxWidth: 100,
              }}
            />
          </Box>
        )}
      </Box>
      <Typography variant="h5" sx={{ textAlign: `center`, fontSize: 24 }}>
        {tagLine || <>Most Innovative {!logo && <br />} Medical ID. Ever.</>}
      </Typography>
      {!logo && (
        <Hidden smDown>
          <Typography sx={{ textAlign: `center`, fontSize: 15, marginTop: 2 }}>
            Anytime, Anywhere Patient
            <br />
            Identification, Information & <br />
            Emergency Contact Notification
          </Typography>
        </Hidden>
      )}
    </Box>
  )
}
