import { TextareaInput } from "@erinfo/brand-ui/src/storybook/inputs/textarea"
import { Box, Typography } from "@mui/material"
import { Field } from "formik"

export const Notes = ({ name, value }) => (
  <Box p={2} sx={{ position: `relative` }}>
    <Field
      component={TextareaInput}
      name={name}
      label="Notes"
      maxLength={255}
    />
    <Typography
      sx={{
        fontSize: `0.85rem`,
        textAlign: `right`,
        opacity: 0.8,
      }}
    >
      {value?.length || 0} / 255
    </Typography>
    <Typography
      sx={{
        opacity: 0.5,
        fontSize: `0.85rem`,
        fontStyle: `italic`,
      }}
    >
      Use for advanced directives, special instructions, or anything else that
      may provide value to a First Responder in an emergency.
    </Typography>
  </Box>
)
