import { BorderBox } from "@erinfo/brand-ui/src/StyledComponents"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import {
  updateUserData,
  verifyUserAttribute,
  verifyUserAttributeWithCode,
} from "@erinfo/react-utils/src/helpers/amplify"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Typography } from "@mui/material"
import parsePhoneNumber from "libphonenumber-js"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { createSearchParams, useNavigate } from "react-router-dom"
import { typesConsumer } from "@erinfo/data-schema"
import { verifyAttribute } from "@erinfo/consumer/src/data"

import PhoneChange from "./PhoneChange"
import VerificationEntry from "./VerificationEntry"

const SignUpStep2 = () => {
  const navigate = useNavigate()
  const [isChangingPhone, setIsChangingPhone] = useState(false)
  const [code, setCode] = useState(``)
  const [phoneNumber, setPhoneNumber] = useState(``)
  const [phoneError, setPhoneError] = useState(``)
  const [verifyError, setVerifyError] = useState(``)
  const {
    user,
    handleUpdatePhone,
    handleSignout,
    handleSetPhoneVerified,
    isLoading,
  } = useAuth()
  const { setSnackbarMessage, confirmUserAttribute, updateUser } =
    useRematchDispatch((dispatch: Dispatch) => ({
      setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
      confirmUserAttribute: dispatch.user.confirmUserAttribute,
      updateUser: dispatch.user.updateUser,
    }))
  const userData = useSelector((state: RootState) => state.user)
  const isOrgMember = typesConsumer.userOrganizationMember === userData?.type

  let userNumber =
    user?.attributes?.phone_number ?? userData.profile.phoneNumbers.mobile ?? ``
  let phoneNumberDisplay = userNumber && parsePhoneNumber(userNumber)
  if (phoneNumberDisplay) {
    phoneNumberDisplay = phoneNumberDisplay.formatInternational()
  }
  userNumber = phoneNumberDisplay || userNumber

  useEffect(() => {
    if (!isLoading && user && !user.attributes?.phone_number_verified)
      setPhoneError(``)

    if (isOrgMember && userData.profile?.phoneNumbers?.mobile) {
      void verifyAttribute({ phone: userData.profile.phoneNumbers.mobile })
        .then(() => setSnackbarMessage(`Verification code sent!`))
        .catch((err) => setPhoneError(err.message))
    } else {
      void verifyUserAttribute(`phone_number`)
        .then(() => setSnackbarMessage(`Verification code sent!`))
        .catch((err) => setPhoneError(err.message))
    }
  }, [isLoading])

  const onCancel = async () => {
    await handleSignout()
    navigate(`/`)
  }

  const changePhoneNumber = async () => {
    const formattedNumber = phoneNumber.replace(/\s/g, ``)
    const isUsNumber =
      formattedNumber?.startsWith(`+1`) &&
      !!/^\+1\d{10}(?!\d)$/.exec(formattedNumber)?.[0]

    if (!formattedNumber || !isUsNumber) {
      return setPhoneError(`Please enter a valid phone number`)
    }

    try {
      setPhoneError(``)
      setVerifyError(``)
      if (isOrgMember) {
        void verifyAttribute({ phone: formattedNumber })
          .then(() => setSnackbarMessage(`Verification code sent!`))
          .catch((err) => setPhoneError(err.message))
      } else {
        await updateUserData({ phone_number: formattedNumber })
        void verifyUserAttribute(`phone_number`)
      }

      handleUpdatePhone(formattedNumber)
      setIsChangingPhone(false)
      setPhoneNumber(``)
    } catch (err) {
      console.log(err)
      setPhoneError(err.message)
    }
  }

  const onSubmit = async (code) => {
    if (isOrgMember) {
      try {
        await confirmUserAttribute({
          userId: userData.id,
          data: {
            phone: userNumber.replace(/\s/g, ``),
            code: Number(code),
          },
        })
        await updateUser({
          userId: userData.id,
          data: { termsAccepted: new Date().toISOString() },
        })
        return navigate({ pathname: `/dashboard` })
      } catch (err) {
        return setVerifyError(`Invalid Code`)
      }
    }
    const data = await verifyUserAttributeWithCode(`phone_number`, code)
    console.log(data)
    if (data === `SUCCESS`) {
      handleSetPhoneVerified()
      if (userData?.paymentPlan?.active) {
        return navigate({ pathname: `/sign-up/step-4` })
      }
      navigate({
        pathname: `/sign-up/step-3`,
        search: `?${createSearchParams({
          verified: `true`,
          initialSignup: `1`,
        })}`,
      })
    } else {
      setVerifyError(data.message || `Invalid Code`)
    }
  }

  return (
    <BorderBox maxWidth={350} sx={{ alignItems: `flex-start` }}>
      {isChangingPhone ? (
        <PhoneChange
          back={() => setIsChangingPhone(false)}
          prevPhoneNumber={userNumber}
          phoneNumber={phoneNumber}
          setPhoneNumber={(v) => setPhoneNumber(v)}
          sendCode={changePhoneNumber}
        />
      ) : (
        <VerificationEntry
          changePhoneNumber={() => setIsChangingPhone(true)}
          sendNewCode={() => {
            setPhoneError(``)
            void verifyUserAttribute(`phone_number`)
              .then(() => setSnackbarMessage(`New verification code sent!`))
              .catch((err) => setPhoneError(err.message))
          }}
          cancel={onCancel}
          phoneNumber={userNumber}
          code={code}
          setCode={(v) => setCode(v)}
          onSubmit={onSubmit}
          requireTerms={isOrgMember}
          error={verifyError}
        />
      )}
      {phoneError && (
        <Typography color="error" sx={{ fontSize: 12 }} mt={2}>
          {phoneError}
        </Typography>
      )}
    </BorderBox>
  )
}

export default SignUpStep2
