import * as schema from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import * as coll from "@erinfo/data-schema/src/collection"
import { nameAllergies } from "@erinfo/data-schema/src/collection/allergies"
import { nameMedicalConditions } from "@erinfo/data-schema/src/collection/medical-conditions"
import { nameMedicalDevices } from "@erinfo/data-schema/src/collection/medical-devices"
import { nameMedications } from "@erinfo/data-schema/src/collection/medications"
import { getPresignedImageUrl } from "@erinfo/react-utils/src/helpers/getImageUrl"
import dayjs from "dayjs"
import { camelCase, pick } from "lodash"

export interface FormData {
  [key: string]: string
}

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1)

const objToArray = (obj) => {
  const items = Object.keys(obj)
  const result = new Array(items.length)
  for (const idx of items) {
    result[idx] = obj[idx]
  }

  return result.filter((i) => Object.values(i).some((j) => j))
}

const removeEmpty = (obj) => {
  let finalObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === `object`) {
      const nestedObj = removeEmpty(obj[key])
      if (Object.keys(nestedObj).length) {
        finalObj[key] = nestedObj
      }
    } else if (obj[key] !== `` && obj[key] !== undefined && obj[key] !== null) {
      finalObj[key] = obj[key]
    }
  })
  return finalObj
}

const trimAll = (obj: FormData) =>
  Object.entries(obj).reduce((agg, [key, val]) => {
    agg[key] = val?.trim ? val.trim() : val
    return agg
  }, {})

export const createEmergencyContactsData = (
  formValues,
): schema.collection.emergencyContact[] => {
  const tempObj = Object.entries(formValues)
    .filter(([key, value]) => key.startsWith(`emergencyContact`))
    .reduce((agg, [key, value]) => {
      if (!value) return agg

      const [k, index] = key.split(`_`)
      let newKey = k.replace(`emergencyContact`, ``)
      newKey = newKey.charAt(0).toLowerCase() + newKey.slice(1)
      if (agg[index]) {
        agg[index][newKey] = value
      } else {
        agg[index] = { [newKey]: value }
      }
      return agg
    }, {})

  if (formValues.isPrimaryContact) {
    const idx = Number(formValues.isPrimaryContact)
    if (tempObj[idx]) {
      tempObj[idx].isPrimaryContact = true
    } else {
      tempObj[0].isPrimaryContact = true
    }
  }

  return Object.values(tempObj).map((obj: schema.collection.emergencyContact) =>
    trimAll(obj),
  )
}

export const createUserData = (values) => {
  const { email, mobile, password, initials, coupon, utmData, captchaToken } =
    values

  const data: any = {
    email: email.toLowerCase(),
    password,
    captchaToken,
    initials: initials ? `99` : ``,
    ...(coupon && { coupon }),
    ...(utmData && { utmData }),
    profile: {
      phoneNumbers: {
        mobile: mobile,
      },
    },
  }

  return data
}

export const createPersonalFormData = (
  user: DataSchema.user.post,
): FormData | undefined => {
  if (!user || !user.profile) return
  const emergencyContacts: schema.collection.emergencyContact[] = Object.values(
    user.emergencyContacts ?? {},
  )
  const emergencyContactForm = emergencyContacts.reduce(
    (agg: FormData, curr, idx) => {
      agg[`emergencyContactName_${idx}`] = curr.name || ``
      agg[`emergencyContactPhone_${idx}`] = curr.phone || ``
      agg[`emergencyContactEmail_${idx}`] = curr.email || ``
      agg[`emergencyContactRelationship_${idx}`] = curr.relationship || ``

      if (curr.isPrimaryContact) {
        agg[`isPrimaryContact`] = idx.toString()
      }
      return agg
    },
    {},
  )

  return {
    ...pick(user.profile, [
      attr.nameFirstName,
      attr.nameLastName,
      attr.namePreferredName,
      attr.nameLang,
      attr.nameGender,
    ]),
    [attr.nameBirthDate]: dayjs(user.profile[attr.nameBirthDate]).format(
      `YYYY-MM-DD`,
    ),
    [attr.nameEmail]: user[attr.nameEmail],
    [attr.nameMobile]: user.profile.phoneNumbers?.[attr.nameMobile],
    [attr.nameStreet]: user.profile.address?.[attr.nameStreet],
    [attr.nameCity]: user.profile.address?.[attr.nameCity],
    [attr.nameState]: user.profile.address?.[attr.nameState],
    [attr.nameZipCode]: user.profile.address?.[attr.nameZipCode],
    [attr.nameNotesContact]: user[attr.nameNotesContact],
    ...emergencyContactForm,
  }
}

export const createPersonalDataSubmission = (
  formValues,
): DataSchema.user.id.put => ({
  profile: {
    ...trimAll(
      pick(formValues, [
        attr.nameFirstName,
        attr.nameLastName,
        attr.namePreferredName,
        attr.nameLang,
        attr.nameGender,
        attr.nameBirthDate,
      ]),
    ),
    ...((formValues[attr.nameStreet] ||
      formValues[attr.nameCity] ||
      formValues[attr.nameState] ||
      formValues[attr.nameZipCode]) && {
      address: {
        ...trimAll(
          pick(formValues, [
            attr.nameStreet,
            attr.nameCity,
            attr.nameState,
            attr.nameZipCode,
          ]),
        ),
      },
    }),
    ...(formValues[attr.nameMobile]
      ? {
          phoneNumbers: {
            [attr.nameMobile]: formValues[attr.nameMobile],
          },
        }
      : formValues[attr.nameMobile] === ``
      ? {
          phoneNumbers: {
            [attr.nameMobile]: ``,
          },
        }
      : {}),
  },
  ...(formValues[attr.nameEmail] && { email: formValues[attr.nameEmail] }),
  emergencyContacts: createEmergencyContactsData(formValues),
  [attr.nameNotesContact]: formValues[attr.nameNotesContact],
})

export const createEmergencyContactData = (params) => {
  const tempObj: schema.collection.emergencyContact = {}
  const {
    emergencyContactName_0,
    emergencyContactPhone_0,
    emergencyContactEmail_0,
    emergencyContactRelationship_0,
  } = params
  if (emergencyContactName_0) tempObj.name = emergencyContactName_0
  if (emergencyContactPhone_0) tempObj.phone = emergencyContactPhone_0
  if (emergencyContactEmail_0) tempObj.email = emergencyContactEmail_0
  if (emergencyContactRelationship_0)
    tempObj.relationship = emergencyContactRelationship_0
  return tempObj
}

export const createIdentifyingFormData = (
  user: DataSchema.user.post,
): FormData | undefined => {
  if (!user || !user.identifyingInformation) return

  const [heightFeet, heightInches] =
    user.identifyingInformation[attr.nameHeight]
      ?.split(` `)
      .filter((s) => !!s) || []

  return {
    heightFeet,
    heightInches,
    ...pick(user.identifyingInformation, [
      attr.nameWeight,
      attr.nameRace,
      attr.nameHairColor,
      attr.nameEyeColor,
      attr.nameMilitaryVeteran,
    ]),
    ...user.identifyingMarks?.reduce((agg, curr, idx) => {
      agg[`identifyingMarkType_${idx}`] = curr.type
      agg[`identifyingMarkLocation_${idx}`] = curr.location || ``
      agg[`identifyingMarkDesc_${idx}`] = curr.desc || ``
      agg[`identifyingMarkImg_${idx}`] = curr.img
        ? getPresignedImageUrl(curr.img, user.otherPictures)
        : undefined
      return agg
    }, {}),
    [attr.nameNotes]: user[attr.nameNotes],
  }
}

export const createIdentifyingDataSubmission = (
  formValues,
): DataSchema.user.id.put => ({
  identifyingInformation: {
    [attr.nameHeight]:
      !formValues.heightFeet && !formValues.heightInches
        ? ``
        : `${formValues.heightFeet || ``} ${formValues.heightInches || ``}`,
    [attr.nameRace]: formValues[attr.nameRace] || ``,
    [attr.nameHairColor]: formValues[attr.nameHairColor] || ``,
    [attr.nameEyeColor]: formValues[attr.nameEyeColor] || ``,
    [attr.nameMilitaryVeteran]: formValues[attr.nameMilitaryVeteran],
    [attr.nameWeight]: formValues[attr.nameWeight]?.replace(`.`, ``) || ``,
  },
  [coll.nameIdentifyingMarks]: objToArray(
    Object.keys(formValues).reduce((agg, curr) => {
      const [field, idx] = curr.split(`_`)
      if (!field.includes(`identifyingMark`)) return agg
      const fieldName = field.replace(`identifyingMark`, ``).toLowerCase()

      const created = formValues[curr]?.split(`?`)[0]
      if (agg[idx]) {
        agg[idx][fieldName] = created || undefined
      } else {
        agg[idx] = { [fieldName]: created || undefined }
      }

      return agg
    }, {}),
  ),
  [attr.nameNotes]: formValues[attr.nameNotes],
})

export const createMedicalFormData = (user: DataSchema.user.post): FormData => {
  if (!user || !user.medicalData) return

  return {
    ...user.medicalData.allergies?.reduce((agg, curr, idx) => {
      agg[`allergy_${idx}`] = curr[attr.nameTitle]
      agg[`reactionDesc_${idx}`] = curr[attr.nameReactionDesc] || ``
      agg[`reaction_${idx}`] = curr[attr.nameReaction]
      return agg
    }, {}),
    ...user.medicalData.medicalConditions?.reduce((agg, curr, idx) => {
      agg[`medicalCondition_${idx}`] = curr[attr.nameTitle]
      agg[`onset_${idx}`] = curr[attr.nameOnset]
      return agg
    }, {}),
    ...user.medicalData.medications?.reduce((agg, curr, idx) => {
      agg[`medication_${idx}`] = curr[attr.nameTitle]
      agg[`dosage_${idx}`] = curr[attr.nameDosage] || ``
      agg[`frequency_${idx}`] = curr[attr.nameFrequency] || ``
      return agg
    }, {}),
    ...user.medicalData.medicalDevices?.reduce((agg, curr, idx) => {
      agg[`medicalDevice_${idx}`] = curr[attr.nameTitle]
      agg[`serialNumber_${idx}`] = curr[attr.nameSerialNumber] || ``
      agg[`implantedDate_${idx}`] = curr[attr.nameImplantedDate]
      return agg
    }, {}),
    ...Object.entries(user[coll.nameDirectives] || {}).reduce(
      (agg, [key, value]) => {
        agg[`advancedDirectives${capitalize(key)}`] = value
        return agg
      },
      {},
    ),
    ...pick(user.profile, [
      attr.namePreferredTreatmentHospital,
      attr.namePrimaryCarePhysician,
      attr.namePrimaryCarePhysicianPhone,
    ]),
    [attr.nameNotesMedical]: user[attr.nameNotesMedical],
  }
}

export const createMedicalDataStructure = (values: {
  [key: string]: string
}) => {
  const fieldMap = {
    medicalCondition: nameMedicalConditions,
    onset: nameMedicalConditions,
    allergy: nameAllergies,
    reactionDesc: nameAllergies,
    reaction: nameAllergies,
    medication: nameMedications,
    dosage: nameMedications,
    frequency: nameMedications,
    medicalDevice: nameMedicalDevices,
    serialNumber: nameMedicalDevices,
    implantedDate: nameMedicalDevices,
  }

  console.log(values)

  const result = Object.keys(values).reduce(
    (agg, curr) => {
      if (curr.includes(`_`)) {
        const [field, idx] = curr.split(`_`)
        const category = fieldMap[field]
        const fieldKey = [
          `medication`,
          `allergy`,
          `medicalCondition`,
          `medicalDevice`,
        ].includes(field)
          ? `title`
          : field
        if (!!values[curr]) {
          if (agg.medicalData[category][idx]) {
            agg.medicalData[category][idx][fieldKey] = values[curr] || undefined
          } else {
            agg.medicalData[category].push({
              [fieldKey]: values[curr] || undefined,
            })
          }
        }
      } else if (
        [
          attr.namePreferredTreatmentHospital,
          attr.namePrimaryCarePhysician,
          attr.namePrimaryCarePhysicianPhone,
        ].includes(curr)
      ) {
        agg.profile[curr] = values[curr]
      } else {
        const field = camelCase(curr.replace(`advancedDirectives`, ``))
        agg.directives[field] = values[curr]
      }

      return agg
    },
    {
      medicalData: {
        allergies: [],
        medicalConditions: [],
        medications: [],
        medicalDevices: [],
      },
      profile: {},
      directives: {},
    },
  )
  if (Object.keys(result.directives)?.length === 0) delete result.directives
  if (Object.keys(result.profile)?.length === 0) delete result.profile
  console.log(result)
  return result
}
