import { styled } from "@mui/material/styles"
import { ReactNode } from "react"

import { OverviewHeader } from "./overview-header/_"

interface IProps {
  title: string
  svg
  children?: ReactNode
  className?: string
}

export const OverviewContainer = (props: IProps) => {
  const className = ((className = ``) => {
    if (props.className) className += ` ${props.className}`
    return className
  })()

  return (
    <Container className={className}>
      <OverviewHeader title={props.title} svg={props.svg} />
      <Content>{props.children}</Content>
    </Container>
  )
}

const Container = styled(`div`)({
  display: `flex`,
  flexFlow: `column`,
  alignItems: `center`,
  paddingTop: `15px`,
  paddingBottom: `15px`,
})

const Content = styled(`div`)({
  padding: `0`,
  paddingTop: `7px`,
  marginLeft: `82px`,
})
