export const isIntlPhoneNumber: DataSchema.RegExDef = {
  msg: `Does not match international number`,
  exp: /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d+)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i,
}

export const formikOptionalPhoneValidation = (v) => {
  if (!v) return null
  const number = v?.replace(/\s/g, ``)
  if (
    number?.startsWith(`+1`)
      ? !/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
      : !isIntlPhoneNumber.exp.exec(number)?.[0] ||
        number?.length > 16 ||
        number?.length < 6
  ) {
    return `Phone number is not valid`
  }
}

export const formikRequiredPhoneValidation = (v) => {
  if (!v) return `Required`
  return formikOptionalPhoneValidation(v)
}
