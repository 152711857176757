import { AddMemberProvider } from "@erinfo/brand-ui/src/storybook/other/add-member-flow/add-member-context"
import AddMemberStep1 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-1"
import AddMemberStep2 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-2"
import AddMemberStep3 from "@erinfo/brand-ui/src/storybook/other/add-member-flow/pages/step-3"
import { validationCallback } from "@erinfo/consumer/src/lib/imageUtils"
import { Navigate } from "react-router-dom"

import Protected from "./components/Protected"
import AppLayout from "./layouts/App"
import AuthLayout from "./layouts/Auth"
import RegistrationLayout from "./layouts/Registration"
import Impersonate from "./pages/auth/Impersonate"
import {
  ChangePassword,
  ResetPassword,
  SendEmail,
} from "./pages/auth/reset-password"
import LapsedSubscription from "./pages/auth/sign-up/lapsed"
import PlanBypass from "./pages/auth/sign-up/plan-bypass"
import SignUpStep1 from "./pages/auth/sign-up/step-1"
import SignUpStep2 from "./pages/auth/sign-up/step-2"
import SignUpStep3 from "./pages/auth/sign-up/step-3"
import SignUpStep4 from "./pages/auth/sign-up/step-4"
import SignUpStep5 from "./pages/auth/sign-up/step-5"
import SignUpStep6 from "./pages/auth/sign-up/step-6"
import SignIn from "./pages/auth/SignIn"
import SsoRedirect from "./pages/auth/Sso"
import Dashboard from "./pages/Dashboard"
import MemberDashboard from "./pages/MemberDashboard"

const routes = ({ user, setDialogMessage, createNewMember, signUp }) => [
  {
    path: `/`,
    element: <AuthLayout variant="login" />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
      {
        path: `sso`,
        element: <SsoRedirect />,
      },
      {
        path: `change-password`,
        element: <ChangePassword />,
      },
      {
        path: `impersonate`,
        element: <Impersonate />,
      },
    ],
  },
  {
    path: `/forgot-password`,
    element: <AuthLayout variant="login" />,
    children: [
      {
        path: `send-email`,
        element: <SendEmail />,
      },
      {
        path: `reset`,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: `/sign-up/step-1`,
    element: (
      <AuthLayout variant="signup" signUp={signUp}>
        <SignUpStep1 />
      </AuthLayout>
    ),
  },
  {
    path: `/sign-up/step-2`,
    element: (
      <Protected>
        <AuthLayout variant="signup">
          <SignUpStep2 />
        </AuthLayout>
      </Protected>
    ),
  },
  {
    path: `/sign-up/step-3`,
    element: (
      <Protected>
        <AuthLayout
          variant="plan"
          headerText="Choose a plan for you and your family"
        >
          <SignUpStep3 />
        </AuthLayout>
      </Protected>
    ),
  },
  {
    path: `/sign-up`,
    element: (
      <Protected>
        <RegistrationLayout />
      </Protected>
    ),
    children: [
      {
        path: `plan-bypass`,
        element: <PlanBypass />,
      },
      {
        path: `step-4`,
        element: <SignUpStep4 />,
      },
      {
        path: `step-5`,
        element: <SignUpStep5 />,
      },
      {
        path: `step-6`,
        element: <SignUpStep6 />,
      },
    ],
  },
  {
    path: `/sign-up/lapsed-subscription`,
    element: (
      <Protected>
        <AuthLayout variant="plan" headerText="Re-choose a plan">
          <LapsedSubscription />
        </AuthLayout>
      </Protected>
    ),
  },
  {
    path: `/dashboard`,
    element: (
      <Protected>
        <AppLayout user={user} />
      </Protected>
    ),
    children: [
      {
        path: ``,
        element: <Dashboard />,
      },
      {
        path: `member/:memberId`,
        element: <MemberDashboard />,
      },
    ],
  },
  {
    path: `/add-member`,
    element: (
      <Protected>
        <AddMemberProvider
          user={user}
          setDialogMessage={setDialogMessage}
          createNewMember={createNewMember}
        >
          <RegistrationLayout />
        </AddMemberProvider>
      </Protected>
    ),
    children: [
      {
        path: `step-1`,
        element: <AddMemberStep1 validationCallback={validationCallback} />,
      },
      {
        path: `step-2`,
        element: <AddMemberStep2 />,
      },
      {
        path: `step-3`,
        element: <AddMemberStep3 />,
      },
    ],
  },

  {
    path: `*`,
    element: <Navigate to="/dashboard" />,
  },
]

export default routes
