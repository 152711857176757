import MuiCheckbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormHelperText from "@mui/material/FormHelperText"
import { SxProps } from "@mui/system"

interface IProps {
  label: string
  value: boolean
  inputId: string
  classes
  errors?: [string]
  onChange?: (v: any) => void
  sx?: SxProps
  disabled?: boolean
}

export const Checkbox = (props: IProps) => {
  const {
    disabled,
    label,
    onChange,
    value,
    inputId,
    classes,
    errors,
    sx,
  } = props

  return (
    <>
      <FormControlLabel
        classes={classes}
        disabled={disabled}
        sx={sx}
        control={
          <MuiCheckbox
            checked={!!value}
            onChange={(e) => onChange({ target: { value: e.target.checked } })}
            name={inputId}
            color="primary"
          />
        }
        label={label}
      />
      {errors?.length && <FormHelperText>{errors.join(`, `)}</FormHelperText>}
    </>
  )
}
