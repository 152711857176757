import React from "react"
import "./_.scss"

interface IProps {
  icon
  children?: string
  className?: string
  onClick?
}

export const ButtonWithIcon = (props: IProps) => {
  const Icon = props.icon
  const className = ((className = "button-with-icon") => {
    if (props.className) className += ` ${props.className}`

    return className
  })()
  return (
    <button onClick={props.onClick} className={className}>
      <Icon className={"button-with-icon__icon"} />
      {props.children}
    </button>
  )
}
