import { Avatar } from "@erinfo/brand-ui/src/storybook/other/Avatar"
import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import {
  AllergiesForm,
  allergyFormFields,
  MedicalConditionsForm,
  medicalConditionsFormFields,
  medicationFormFields,
  MedicationsForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import StepperPanel from "@erinfo/brand-ui/src/storybook/other/StepperPanel"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { useDynamicFields } from "@erinfo/consumer/src/hooks/use-dynamic-fields"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { getCompositeId } from "@erinfo/data-schema"
import { envProviderUrl } from "@erinfo/env"
import { createMedicalDataStructure } from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Coronavirus, MedicalServices, Medication } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { stepperSteps } from "../constants"

const StyledFormCard = styled(FormCard)({
  width: `30vw`,
  maxWidth: 295,
  "@media(max-width: 480px)": {
    width: `100%`,
    maxWidth: "unset",
  },
})

const iconStyle = {
  color: `#E31B0C;`,
}

const SignUpStep6 = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const user = useSelector((state: RootState) => state.user)
  const { logoutAfterSignup, providerApp } = useSelector(
    (state: RootState) => state.signUp,
  )
  const isSamlUser = auth.isSamlUser()
  const { setDialogMessage, addMedicalData, updateUser, setSignupInfo } =
    useRematchDispatch((dispatch: Dispatch) => ({
      setDialogMessage: dispatch.notifications.setDialogMessage,
      addMedicalData: dispatch.user.addMedicalData,
      updateUser: dispatch.user.updateUser,
      setSignupInfo: dispatch.signUp.setSignupInfo,
    }))
  const avatarFile = user?.pictures?.[0]?.src

  const {
    fieldCounts,
    addField,
    removeField,
    initialValues,
    setFieldValueOrRemove,
  } = useDynamicFields(
    [`medicalConditions`, `allergies`, `medications`],
    () => ({
      medicalCondition_0: ``,
      onset_0: ``,
      allergy_0: ``,
      reactionDesc_0: ``,
      reaction_0: false,
      medication_0: ``,
      dosage_0: ``,
      frequency_0: ``,
    }),
    user,
    true,
    `medicalData`,
    {
      medicalConditions: medicalConditionsFormFields,
      allergies: allergyFormFields,
      medications: medicationFormFields,
    },
  )

  const handleSubmit = async (values) => {
    setLoading(true)
    const userId = isSamlUser
      ? getCompositeId(
          auth.user.identities[0].providerName,
          auth.user.identities[0].userId,
        )
      : auth.user.attributes[`custom:compositeID`]

    const email = isSamlUser ? auth.user.email : auth.user.attributes.email

    await updateUser({
      userId,
      data: { isFirstTimeLogin: false, noNotification: true },
      email,
    })

    const hasChanges =
      values.medicalCondition_0 || values.medication_0 || values.allergy_0

    if (!hasChanges) {
      if (logoutAfterSignup) {
        return logout()
      } else {
        return setDialogMessage({
          title: `Congratulations`,
          msg:
            `You didn't fill out any medical information though.\n\n ` +
            `The next page is your dashboard where your can further add and edit information.`,
          onYes: () => navigate(`/dashboard`),
        })
      }
    }

    const userData = createMedicalDataStructure(values)
    await addMedicalData({ userId, userData })
    setLoading(false)

    if (logoutAfterSignup) {
      return logout()
    }
    setDialogMessage({
      title: `Congratulations`,
      msg: `The next page is your dashboard where your can further add and edit information.`,
      onYes: () => navigate(`/dashboard`),
    })
  }

  const logout = () => {
    setDialogMessage({
      title: `Signup Completed`,
      msg: `The new member signup is complete.  You will be logged out to process another signup.`,
      onYes: async () => {
        setSignupInfo({ registerCode: ``, logoutAfterSignup: false })
        await auth.handleSignout()
        navigate(`/`)
        setTimeout(() => providerApp.postMessage(`close`, envProviderUrl), 1000)
      },
    })
  }

  return (
    <StepperPanel
      activeStep={2}
      steps={stepperSteps}
      sx={{
        "@media(min-width: 480px)": {
          width: `calc(100vw - 50px)`,
          maxWidth: 960,
          minHeight: 620,
          marginBottom: 2,
        },
        "@media(max-width: 480px)": {
          width: `100%`,
        },
      }}
      formId="step-6"
      nextLoading={loading}
      nextLabel="Finish"
    >
      <Avatar url={avatarFile} sx={{ marginTop: 1 }} />
      <Formik
        initialValues={{
          medicalCondition_0: ``,
          onset_0: ``,
          allergy_0: ``,
          reactionDesc_0: ``,
          reaction_0: false,
          medication_0: ``,
          dosage_0: ``,
          frequency_0: ``,
        }}
        validateOnBlur
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, setValues }) => {
          const formProps = {
            addField,
            removeField: removeField(values, setValues),
            setFieldValue,
            setFieldValueOrRemove,
            values,
            fieldCounts,
          }
          return (
            <Form
              id="step-6"
              style={{
                width: `100%`,
                display: `flex`,
                justifyContent: `center`,
              }}
            >
              <Box
                sx={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  marginTop: 2,
                  marginBottom: 2,
                  justifyContent: `space-between`,
                  alignItems: `baseline`,
                  "@media(min-width: 480px)": {
                    maxWidth: 930,
                    width: `100%`,
                  },
                }}
              >
                <StyledFormCard
                  Icon={<Medication sx={iconStyle} />}
                  headerText="Medical Conditions"
                  sx={{ minHeight: 326 }}
                >
                  <MedicalConditionsForm {...formProps} />
                </StyledFormCard>
                <StyledFormCard
                  Icon={<Coronavirus sx={iconStyle} />}
                  headerText="Allergies"
                  sx={{ minHeight: 326 }}
                >
                  <AllergiesForm {...formProps} />
                </StyledFormCard>
                <StyledFormCard
                  Icon={<MedicalServices sx={iconStyle} />}
                  headerText="Medications"
                  sx={{ minHeight: 326 }}
                >
                  <MedicationsForm {...formProps} />
                </StyledFormCard>
              </Box>
            </Form>
          )
        }}
      </Formik>
      <Typography sx={{ opacity: 0.38 }}>
        *Edits and changes can be made later.
      </Typography>
    </StepperPanel>
  )
}

export default SignUpStep6
