import { ReactComponent as IMarkHorizontal } from "@erinfo/brand-ui/src/assets/svg/mark-horizontal-white.svg"
import { Dispatch } from "@erinfo/consumer/src/store"
import { version } from "@erinfo/provider/package.json"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

const StyledBox = styled(Box)({
  backgroundColor: `rgba(0,0,0,0.5)`,
  display: `grid`,
  gridTemplateColumns: `repeat(3, 1fr)`,
  minHeight: 150,
  width: `100%`,
  paddingTop: 10,
})

const Logo = styled(IMarkHorizontal)({
  width: `180px`,
  height: `80px`,
})

const StyledLink = styled(Link)({
  color: `white`,
  marginBottom: 20,
  fontSize: `0.85rem`,
  cursor: `pointer`,
})

export const MainFooter = () => {
  const { toggleSettings } = useRematchDispatch((dispatch: Dispatch) => ({
    toggleSettings: dispatch.notifications.toggleSettings,
  }))
  return (
    <StyledBox>
      <Box pl={4}>
        <Logo />
      </Box>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-start`,
          paddingTop: 1,
        }}
      >
        <StyledLink>
          <b>About ERinfo</b>
        </StyledLink>
        <StyledLink href="https://erinfo.me/" target="_blank">
          About
        </StyledLink>
        <StyledLink href="https://erinfo.me/privacy-policy/" target="_blank">
          Privacy
        </StyledLink>
        <StyledLink href="https://erinfo.me/terms-conditions/" target="_blank">
          Terms & Conditions
        </StyledLink>
        <StyledLink href="https://erinfo.me/patents/" target="_blank">
          Patent Information
        </StyledLink>
      </Box>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `flex-start`,
          paddingTop: 1,
        }}
      >
        <StyledLink>
          <b>Help</b>
        </StyledLink>
        <StyledLink href="https://erinfo.me/faqs/" target="_blank">
          FAQ
        </StyledLink>
        <StyledLink href="https://erinfo.me/faqs/" target="_blank">
          Using ERinfo
        </StyledLink>
        <StyledLink href="https://erinfo.me/contact" target="_blank">
          Send Feedback
        </StyledLink>
        <StyledLink onClick={() => toggleSettings(true)}>Settings</StyledLink>
      </Box>
      <Box sx={{ position: `absolute`, bottom: 15, right: 30 }}>
        <Typography sx={{ color: `white`, fontSize: `0.8rem` }}>
          v {version}
        </Typography>
      </Box>
    </StyledBox>
  )
}
