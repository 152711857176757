import * as Dialogs from "@erinfo/brand-ui/src/storybook/other/dialogs"
import { AvatarList } from "@erinfo/consumer/src/components/AvatarList"
import * as Dash from "@erinfo/consumer/src/components/dashboard/index"
import { MobileMainMenu } from "@erinfo/consumer/src/components/MobileMainMenu"
import * as api from "@erinfo/consumer/src/data"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { Box, Hidden } from "@mui/material"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CameraType } from "expo-camera"

const Dashboard = () => {
  const navigate = useNavigate()
  const [medInfoDialogOpen, setMedInfoDialogOpen] = useState(false)
  const [identifyingDialogOpen, setIdentifyingDialogOpen] = useState(false)
  const [documentsDialogOpen, setDocumentsDialogOpen] = useState(false)
  const [personalDialogOpen, setPersonalDialogOpen] = useState(false)
  const [facePhotoDialogOpen, setFacePhotoDialogOpen] = useState(false)
  const [memberListDialogOpen, setMemberListDialogOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const user = useSelector((state: RootState) => state.user)
  const {
    updateUser,
    storeUserFacePicture,
    deleteUserFacePicture,
    storeDocuments,
    setSnackbarMessage,
  } = useRematchDispatch((dispatch: Dispatch) => ({
    updateUser: dispatch.user.updateUser,
    storeUserFacePicture: dispatch.user.storeUserFacePicture,
    deleteUserFacePicture: dispatch.user.deleteUserFacePicture,
    storeDocuments: dispatch.user.storeDocuments,
    setSnackbarMessage: dispatch.notifications.setSnackbarMessage,
  }))

  const handleSubmit = async (data) =>
    updateUser({ userId: user.id, email: user.email, data }).then(() =>
      setSnackbarMessage(`Changes saved`),
    )

  const handleDocuments = async (data) =>
    storeDocuments({ userId: user.id, data })

  const onCloseMenu = () => setMobileMenuOpen(false)

  return (
    <Box
      sx={{
        "@media (min-width: 480px)": {
          padding: 2,
        },
        "@media (max-width: 480px)": {
          paddingBottom: 3,
        },
        paddingTop: 3,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <AvatarList user={user} onClick={() => setFacePhotoDialogOpen(true)} />
      <Box
        sx={{
          display: `flex`,
          flexWrap: `wrap`,
          marginTop: 2,
          marginBottom: 2,
          alignItems: `flex-start`,
          justifyContent: `space-between`,
          "@media (max-width: 869px)": { justifyContent: `center` },
          "@media (min-width: 480px)": {
            width: `calc(100vw - 50px)`,
          },
          "@media (max-width: 1279px)": {
            justifyContent: `space-around`,
          },
          maxWidth: 1280,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            "@media (min-width: 1024px)": { marginBottom: 3 },
            "@media (max-width: 480px)": { width: `100%` },
          }}
        >
          <Dash.PersonalPanel
            user={user}
            onAdd={() => setPersonalDialogOpen(true)}
            sx={{ "@media (min-width: 480px)": { mb: 3 } }}
          />
          <Hidden lgDown>
            <Dash.PlanPanel
              user={user}
              setMemberListDialogOpen={setMemberListDialogOpen}
            />
          </Hidden>
        </Box>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            marginBottom: 3,
            "@media (max-width: 480px)": { width: `100%` },
          }}
        >
          <Dash.IdentifyingPanel
            user={user}
            onAdd={() => setIdentifyingDialogOpen(true)}
            sx={{ "@media (min-width: 480px)": { mb: 3 } }}
          />
          <Dash.DocumentsPanel
            user={user}
            onAdd={() => setDocumentsDialogOpen(true)}
          />
        </Box>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            "@media (max-width: 480px)": { width: `100%` },
          }}
        >
          <Dash.MedicalPanel
            user={user}
            onAdd={() => setMedInfoDialogOpen(true)}
            sx={{ "@media (min-width: 480px)": { mb: 3 } }}
          />

          <Hidden lgUp>
            <Dash.PlanPanel
              user={user}
              setMemberListDialogOpen={setMemberListDialogOpen}
            />
          </Hidden>
        </Box>
      </Box>

      <Dialogs.PersonalDialog
        open={personalDialogOpen}
        handleClose={() => setPersonalDialogOpen(false)}
        handleSubmit={handleSubmit}
        user={user}
        appType="consumer"
      />
      <Dialogs.IdentifyingDialog
        open={identifyingDialogOpen}
        handleClose={() => setIdentifyingDialogOpen(false)}
        addOtherPicture={api.addOtherPicture}
        handleSubmit={handleSubmit}
        user={user}
      />
      <Dialogs.DocumentsDialog
        open={documentsDialogOpen}
        handleClose={() => setDocumentsDialogOpen(false)}
        user={user}
        handleSubmit={handleDocuments}
      />
      <Dialogs.MedicalInfoDialog
        open={medInfoDialogOpen}
        handleClose={() => setMedInfoDialogOpen(false)}
        handleSubmit={handleSubmit}
        user={user}
      />
      <Dialogs.FacePhotosDialog
        open={facePhotoDialogOpen}
        handleClose={() => setFacePhotoDialogOpen(false)}
        storeUserFacePicture={storeUserFacePicture}
        deleteUserFacePicture={deleteUserFacePicture}
        user={user}
        startingCameraType={CameraType.front}
      />
      <Hidden smUp>
        <MobileMainMenu open={mobileMenuOpen} closeMenu={onCloseMenu} />
        <Dash.MobileBottomMenu
          onAddMember={() => navigate(`/add-member/step-1`)}
          openMenu={() => setMobileMenuOpen(true)}
        />
      </Hidden>
      <Dialogs.MemberListDialog
        open={memberListDialogOpen}
        handleClose={() => setMemberListDialogOpen(false)}
        user={user}
      />
    </Box>
  )
}

export default Dashboard
