export const phoneNumberMask = {
  removeMaskedChar: /(\D+|^0)/g,
  mask: [
    `(`,
    /[2-9]/,
    /\d/,
    /\d/,
    `)`,
    ` `,
    /\d/,
    /\d/,
    /\d/,
    `-`,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
}
