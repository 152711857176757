import type * as TF from "type-fest"

const dataURLtoFile = (dataurl: string, filename = `a`) => {
  const arr = dataurl.split(`,`)
  const mime = /:(.*?);/.exec(arr[0])![1] // eslint-disable-line @typescript-eslint/no-non-null-assertion
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) u8arr[n] = bstr.charCodeAt(n)

  return new File([u8arr], filename, { type: mime })
}

const formAppend = (form: FormData, condition: object) => {
  for (const key of Object.keys(condition)) {
    form.append(key, condition[key])
  }
}

interface common {
  req: [string, string]
}
interface withFile {
  file: File
}
interface withDataUrl {
  dataURL: string
}
type uploadFile = common & TF.MergeExclusive<withFile, withDataUrl>

export const uploadFile = async (props: uploadFile): Promise<Response> => {
  const [policy, signature] = props.req
  const policyParsed = JSON.parse(atob(policy))
  const form = new FormData()

  let bucketName!: string
  for (const condition of policyParsed.conditions) {
    if (condition[`content-type`]) continue
    if (Array.isArray(condition)) continue
    if (condition.bucket) bucketName = condition.bucket

    formAppend(form, condition)
  }

  const file = props.file || dataURLtoFile(props.dataURL)

  form.append(`content-type`, file.type) // ex: "image/png"
  form.append(`Policy`, policy)
  form.append(`X-Amz-Signature`, signature)
  /**
   * @IMPORTANT FILE HAS TO BE APPENDED LAST
   */
  form.append(`file`, file)

  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Uploading_a_file
   */
  return fetch(`https://${bucketName}.s3-accelerate.amazonaws.com`, {
    method: `POST`,
    body: form,
    /**
     * @IMPORTANT fetch does not need to set "multipart/form-data"
     */
    // headers: { "Content-Type": `multipart/form-data` },
  })
}
