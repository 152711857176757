export const medicalDevices = [
  { title: `None`, value: `none`, selected: false },
  { title: `Pacemaker`, value: `pacemaker`, selected: false },
  { title: `Artificial Hip`, value: `artificialHip`, selected: false },
  { title: `Breast Implants`, value: `breast`, selected: false },
  {
    title: `Spine Screws, Rods, Artificial Discs`,
    value: `spineScrews`,
    selected: false,
  },
  { title: `Intra-Uterine Devices (IUDs)`, value: `iud`, selected: false },
  {
    title: `Metal Screws, Pins, Plates, and Rods`,
    value: `metalScrews`,
    selected: false,
  },
  { title: `Artificial Knees`, value: `knees`, selected: false },
  { title: `Coronary Stent`, value: `stent`, selected: false },
  { title: `Ear Tubes`, value: `earTubes`, selected: false },
  { title: `Artificial Eye Lenses`, value: `eyeLenses`, selected: false },
]
