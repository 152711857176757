import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import {
  AllergiesForm,
  MedicalConditionsForm,
  MedicationsForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import { confirm } from "@erinfo/react-utils/src/components/Confirmation"
import { Coronavirus, MedicalServices, Medication } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Form, Formik } from "formik"
import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"

import { AddMemberWrapper } from "../components/AddMemberWrapper"
import useAddMember from "../use-add-member"

const StyledFormCard = styled(FormCard)({
  width: 295,
  "@media(max-width: 480px)": {
    width: `100%`,
  },
})

const iconStyle = {
  color: `#E31B0C;`,
}

const AddMemberStep3 = () => {
  const navigate = useNavigate()
  const valueRef = useRef()
  const {
    proApp,
    fieldCounts,
    addField,
    removeField,
    setFieldValueOrRemove,
    initialValues,
    setMedicalData,
    medical,
    addMember,
    user,
    picture,
  } = useAddMember()

  useEffect(() => {
    if (!picture?.created) {
      navigate(`/add-member/step-1`)
    }
  }, [picture])

  const onSubmit = async (values) => {
    if (
      Object.values(values).every((i) => !i) &&
      !(await confirm(``, {
        title: `No Medical Information?`,
        description: `Even though medical information isn't required, it helps in emergencies. Are you sure want to continue?`,
        noAction: `Close`,
        yesAction: `Continue`,
      }))
    ) {
      return
    }
    const newMemberId = await addMember(values)
    if (proApp) {
      navigate(`/member-detail/${newMemberId}`)
    } else {
      navigate(`/dashboard`)
    }
  }

  const onBack = () => {
    setMedicalData(valueRef.current)
    navigate(`/add-member/step-2`, { state: `RETURN_FROM_STEP_3` })
  }

  return (
    <AddMemberWrapper
      currStep={2}
      formId="step-2"
      nextLabel="Finish"
      onBack={onBack}
      user={user}
      proApp={proApp}
    >
      <Formik
        initialValues={{
          ...initialValues,
          ...medical,
        }}
        // validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, setValues }) => {
          valueRef.current = values
          const formProps = {
            addField,
            removeField: removeField(values, setValues),
            setFieldValue,
            setFieldValueOrRemove,
            values,
            fieldCounts,
          }
          return (
            <Form id="step-2">
              <Box
                sx={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  marginTop: 2,
                  marginBottom: 2,
                  justifyContent: `space-between`,
                  alignItems: `baseline`,
                  "@media(min-width: 480px)": {
                    width: 930,
                  },
                }}
              >
                <StyledFormCard
                  Icon={<Medication sx={iconStyle} />}
                  headerText="Medical Conditions"
                  sx={{ minHeight: 326 }}
                >
                  <MedicalConditionsForm {...formProps} />
                </StyledFormCard>
                <StyledFormCard
                  Icon={<Coronavirus sx={iconStyle} />}
                  headerText="Allergies"
                  sx={{ minHeight: 326 }}
                >
                  <AllergiesForm {...formProps} />
                </StyledFormCard>
                <StyledFormCard
                  Icon={<MedicalServices sx={iconStyle} />}
                  headerText="Medications"
                  sx={{ minHeight: 326 }}
                >
                  <MedicationsForm {...formProps} />
                </StyledFormCard>
              </Box>
            </Form>
          )
        }}
      </Formik>
      <Typography sx={{ opacity: 0.38, mb: 1 }}>
        *Edits and changes can be made later.
      </Typography>
    </AddMemberWrapper>
  )
}

export default AddMemberStep3
