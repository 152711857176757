import { UserState } from "@erinfo/consumer/src/store/models/user"
import { bucketFilesRaw } from "@erinfo/env"
import { getPresignedImageUrl} from "@erinfo/react-utils/src/helpers/getImageUrl"
import { getS3KeyPhotoOther } from "@erinfo/env/src/files"
import { Visibility } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import { SxProps } from "@mui/system"
import { FC } from "react"

import {
  AddEditAction,
  ProfileValueDisplay,
  StyledDivider,
  StyledFormCard,
} from "./common"

export const IdentifyingPanel: FC<{
  user: UserState
  onAdd: () => void
  sx: SxProps
}> = ({ user, onAdd, sx }) => {
  const { height, weight, race, hairColor, eyeColor, militaryVeteran } =
    user?.identifyingInformation || {}
  const { identifyingMarks } = user || {}

  const dataPresent =
    height ||
    weight ||
    race ||
    hairColor ||
    eyeColor ||
    militaryVeteran !== undefined ||
    identifyingMarks?.length

  return (
    <StyledFormCard
      id="identifyingPanel"
      Icon={<Visibility sx={{ color: `identify.main` }} />}
      headerText="Identifying"
      sx={sx}
      Action={() => <AddEditAction edit={dataPresent} onClick={onAdd} />}
    >
      <ProfileValueDisplay label="Height" value={height} />
      <ProfileValueDisplay label="Weight" value={weight} />
      <ProfileValueDisplay label="Race" value={race} />
      <ProfileValueDisplay label="Hair Color" value={hairColor} />
      <ProfileValueDisplay label="Eye Color" value={eyeColor} />
      <ProfileValueDisplay
        label="Military Veteran"
        value={militaryVeteran ? `Yes` : `No`}
      />
      <Typography>Identifying Marks</Typography>
      {!identifyingMarks?.length && (
        <Typography sx={{ opacity: 0.25 }}>Not yet entered</Typography>
      )}
      <Box pl={2}>
        {identifyingMarks?.map((mark) => (
          <Box
            sx={{ "& img": { minHeight: 65, maxHeight: 100 } }}
            key={`${mark.type}-${mark.location}`}
          >
            <Typography sx={{ opacity: 0.5, mb: 1 }}>
              {mark.type}, {mark.location}
              <br />
              {mark.desc}
            </Typography>
            {!!mark.img && (
              <img src={getPresignedImageUrl(mark.img, user.otherPictures)}/>
            )}
          </Box>
        ))}
      </Box>

      <StyledDivider />

      <ProfileValueDisplay
        label="Notes"
        value={user?.notes}
        sx={{ whiteSpace: `normal` }}
      />
    </StyledFormCard>
  )
}
