import { Footer } from "@erinfo/brand-ui/src/storybook/other/footer"
import { HeaderWithLeftLogo } from "@erinfo/brand-ui/src/storybook/other/header/with-left-logo"
import { Logo } from "@erinfo/brand-ui/src/storybook/other/logo/_"
import Notifications from "@erinfo/consumer/src/components/Notifications"
import {
  DefaultInstructions,
  partnerStyling,
} from "@erinfo/consumer/src/components/PartnerBranding"
import { SignupBrand } from "@erinfo/consumer/src/components/SignupBrand"
import { SignUpState } from "@erinfo/consumer/src/store/models/signUp"
import { Box, CssBaseline, Hidden } from "@mui/material"
import { styled } from "@mui/material/styles"
import { FC } from "react"
import { Outlet } from "react-router-dom"

const Root = styled(`div`)({
  margin: `0 auto`,
  justifyContent: `center`,
  alignItems: `center`,
  display: `flex`,
  minWidth: `100vw`,
  flexDirection: `column`,
  position: `relative`,
  "@media (min-width: 480px)": {
    minHeight: `100vh`,
    footer: {
      position: `absolute`,
      bottom: `0`,
      padding: `8px 0`,
    },
  },
  "@media (max-width: 480px)": {
    justifyContent: `flex-start`,
    overflowY: `auto`,
    height: `100%`,
  },
})

const Wrapper = styled(`div`)((props) => ({
  background: `white`,
  padding: props.theme.spacing(3),
  display: `flex`,
  "@media (min-width: 480px)": {
    alignItems: `center`,
    justifyContent: `center`,
    marginBottom: 120,
  },
  flex: 1,
}))

const WrapperSignup = styled(Wrapper)({
  flexWrap: `wrap`,
  flexDirection: `row`,
})

const WrapperSignin = styled(Wrapper)({
  flexDirection: `column`,
})

const StyledSignupBrand = styled(SignupBrand)({
  "@media (min-width: 470px)": { marginRight: 40, width: 350 },
  "@media (max-width: 470px)": { marginRight: 40, width: `100%` },
})

type componentVariant = "login" | "signup" | "plan"

interface IProps {
  variant: componentVariant
  headerText?: string
  signUp?: SignUpState
  children?: React.ReactNode
}

const Auth = ({ children, variant, headerText, signUp }: IProps) => {
  const { logo } = signUp ?? {}

  const instructions = () =>
    logo &&
    partnerStyling[logo]?.instructions && (
      <Box
        sx={{
          borderRadius: `4px`,
          background: `#eee`,
          p: 2,
          mt: 2,
          maxWidth: 350,
        }}
      >
        {partnerStyling[logo].instructions()}
      </Box>
    )

  return (
    <Root sx={{ bgcolor: variant === `plan` ? `background.default` : `white` }}>
      <CssBaseline />
      {variant === `login` && (
        <WrapperSignin>
          <Logo version="horizontal" />
          {children}
          <Outlet />
        </WrapperSignin>
      )}
      {variant === `signup` && (
        <WrapperSignup>
          <Box sx={{ "@media (max-width: 470px)": { width: `100%` } }}>
            <StyledSignupBrand logo={logo} />
            <Hidden smDown>{instructions() ?? <></>}</Hidden>
          </Box>
          {children}
          <Outlet />
          <Hidden smUp>{instructions() ?? <></>}</Hidden>
        </WrapperSignup>
      )}
      {variant === `plan` && (
        <>
          <HeaderWithLeftLogo
            titleText={headerText}
            showHeaderInMobile={true}
          />
          <WrapperSignup
            style={{
              marginBottom: 0,
              padding: 0,
              flex: 1,
              background: `#e2e2e2`,
            }}
          >
            {children}
            <Outlet />
          </WrapperSignup>
        </>
      )}
      <Footer />
      <Notifications />
    </Root>
  )
}

export default Auth
