import { useContext } from "react"

import { AddMemberContext } from "./add-member-context"

const useAddMember = () => {
  const context = useContext(AddMemberContext)

  if (!context)
    throw new Error(`AddMemberContext must be placed within AddMemberProvider`)

  return context
}

export default useAddMember
