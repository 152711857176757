import { Auth } from "@aws-amplify/auth"
import useAuth from "@erinfo/consumer/src/hooks/use-auth"
import { Dispatch, RootState } from "@erinfo/consumer/src/store"
import { UserState } from "@erinfo/consumer/src/store/models/user"
import { getUtmParams } from "@erinfo/react-utils/src/helpers/cookies"
import { useRematchDispatch } from "@erinfo/react-utils/src/hooks"
import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import * as schema from "@erinfo/data-schema"

interface Props {
  protectedRoute: boolean
}

export const useRedirects = ({ protectedRoute }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { user, isLoading, isSamlUser } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname, search } = location
  const storedUser = useSelector((state: RootState): UserState => state.user)
  const { registerCode } = useSelector((state: RootState) => state.signUp)
  const { clearUser } = useRematchDispatch((dispatch: Dispatch) => ({
    clearUser: dispatch.user.clear,
  }))
  const currentParams = Object.fromEntries([...searchParams])
  const redirectHandled = useRef<boolean>()
  const { sso } = currentParams || {}
  const isSaml = isSamlUser()

  console.log(`>> `, pathname)
  console.log(`user`, user)

  if (pathname === `/` && sso) {
    void Auth.federatedSignIn({ customProvider: sso })
  }

  const redirect = (path) => {
    if (pathname !== path) {
      console.log(`**************`, pathname, path)
      navigate(path)
    }
  }

  const evaluateRedirect = () => {
    const { utm_rc } = getUtmParams()
    const { rc } = currentParams || {}
    const couponCode = registerCode || rc || utm_rc
    const stripeRedirect = !!search && pathname.includes(`step-3`)

    const phoneVerificationNeeded =
      (user && !isSaml && !user.attributes?.phone_number_verified) ||
      (storedUser &&
        storedUser.type === schema.typesConsumer.userOrganizationMember &&
        storedUser.termsAccepted === undefined)

    if (phoneVerificationNeeded) {
      return redirect(`/sign-up/step-2`)
    }

    // const paymentPlanExpired = user?.paymentPlan && !user?.paymentPlan?.active
    // props.history.replace(`/?expired=true`)

    // const cancelledPlan = paymentPlanExpired && search.includes(`cancel`)
    // props.history.replace(`/sign-up/step-3?cancel=1`)

    const needPaymentPlan =
      storedUser && (!storedUser?.paymentPlan?.active || stripeRedirect)
    if (needPaymentPlan) {
      if (couponCode) {
        return redirect(`/sign-up/plan-bypass?rc=${couponCode}`)
      }
      if (
        storedUser?.paymentPlan?.canceled ||
        storedUser?.paymentPlan?.expired
      ) {
        return redirect(`/sign-up/lapsed-subscription`)
      }
      return redirect(`/sign-up/step-3`)
    }

    const needUserPicture = storedUser && !storedUser.pictures?.length
    if (needUserPicture) {
      return redirect(`/sign-up/step-4`)
    }

    const needEmergencyContacts =
      storedUser &&
      (!storedUser.emergencyContacts ||
        !Object.keys(storedUser.emergencyContacts).length)
    if (needEmergencyContacts) {
      if (pathname.includes(`step-4`)) return
      return redirect(`/sign-up/step-5`)
    }

    const firstLogin = storedUser?.isFirstTimeLogin === true
    if (firstLogin && !isSaml) {
      if (pathname.includes(`step-4`) || pathname.includes(`step-5`)) return
      return redirect(`/sign-up/step-6`)
    }

    redirect(`/dashboard`)
  }

  useEffect(() => {
    if (isLoading || storedUser.fetching) return
    if (!user) {
      if (storedUser.id) {
        clearUser()
      }
      if (!protectedRoute) return
      if (
        protectedRoute &&
        !pathname.includes(`reset-password`) &&
        pathname !== `/`
      ) {
        return navigate(`/`)
      }
    } else {
      if (redirectHandled.current) return
      evaluateRedirect()
      redirectHandled.current = true
    }
  }, [user, storedUser, isLoading, pathname])

  return evaluateRedirect
}
