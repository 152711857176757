import { CheckboxInput } from "@erinfo/brand-ui/src/storybook/inputs/checkbox"
import { MedicalSelectInput } from "@erinfo/brand-ui/src/storybook/inputs/medical-select"
import { TextInput } from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2"
import { allergies } from "@erinfo/data-schema"
import {
  filterValues,
  useHandleNoneSelection,
} from "@erinfo/react-utils/src/helpers/form/formUtils"
import { Add, Delete } from "@mui/icons-material"
import { Box, Button, Divider } from "@mui/material"
import { Field } from "formik"
import { Fragment } from "react"

export const allergyFormFields = [`allergy`, `reaction`, `reactionDesc`]
const MAX_LENGTH = 40

export const AllergiesForm = ({
  addField,
  removeField,
  setFieldValue,
  setFieldValueOrRemove,
  values,
  fieldCounts,
}) => {
  const hideDelete =
    !values?.allergy_0 && !values?.reactionDesc_0 && !values?.reaction_0

  useHandleNoneSelection({
    formType: `allergies`,
    formFields: allergyFormFields,
    values,
    fieldCounts,
    removeField,
    setFieldValue,
  })

  return (
    <>
      <StyledButton
        sx={{ float: `right`, marginBottom: 1 }}
        disabled={!values?.allergy_0}
        onClick={() => addField(`allergies`, `allergy`)}
      >
        <Add /> Add New
      </StyledButton>
      {[...Array(fieldCounts.allergies)]
        .map((_, i) => i)
        .reverse()
        .map((i, idx) => (
          <Fragment key={`allergy_${i}`}>
            {i < fieldCounts.allergies - 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
            <Field
              label="Allergies"
              name={`allergy_${i}`}
              component={MedicalSelectInput}
              setFieldValue={setFieldValueOrRemove(setFieldValue, () =>
                removeField(`allergies`, i),
              )}
              dataForMultiSelect={filterValues(`allergy`, values, allergies)}
              style={{ minWidth: 200 }}
            />
            <Field
              name={`reactionDesc_${i}`}
              label="Types or Description"
              component={TextInput}
              disabled={!values.allergy_0 || values.allergy_0 === `None`}
              inputProps={{ maxLength: MAX_LENGTH + 1 }}
            />
            <Field
              label="Anaphylaxis Reaction"
              name={`reaction_${i}`}
              component={CheckboxInput}
              sx={{ marginTop: 2 }}
              disabled={!values.allergy_0 || values.allergy_0 === `None`}
            />
            {(idx !== 0 || !hideDelete) && (
              <Box sx={{ display: `flex`, justifyContent: `flex-end` }}>
                <Button
                  onClick={() => removeField(`allergies`, i)}
                  color="error"
                  sx={{ mt: 1 }}
                >
                  <Delete sx={{ marginRight: 2 }} />
                  Delete Allergy
                </Button>
              </Box>
            )}
          </Fragment>
        ))}
    </>
  )
}
