import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-input": {
    padding: 10,
  },
})

export const Textarea = (props) => {
  const className = ((className = ``) => {
    if (props.className) className += ` ${props.className}`

    return className
  })()

  return (
    <StyledTextField
      multiline
      fullWidth
      rows={props.rows || 4}
      name={props.inputId}
      value={props.value}
      label={props.label}
      className={className}
      type={props.type}
      disabled={props.disabled}
      required={props.required}
      placeholder={props.placeholder}
      inputProps={{ ...(props.maxLength && { maxLength: props.maxLength }) }}
      autoComplete={props.autoComplete}
      onChange={props.onChange}
      onBlur={props.onBlur}
      error={!!props.errors}
      helperText={props.errors}
    />
  )
}
