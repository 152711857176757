import { Box, Card, CardContent, CardHeader } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"

const IconWrapper = styled(`div`)({
  marginRight: 15,
  display: `flex`,
  alignItems: `center`,
})

interface IProps {
  Icon?: React.ReactNode
  headerText: string
  Action?: React.ReactNode | JSX.Element
  className?: string
  sx?: SxProps
  contentSx?: SxProps
  headerSx?: SxProps
}

export const FormCard: React.FC<IProps> = ({
  Icon,
  headerText,
  Action,
  children,
  sx,
  className,
  contentSx,
  headerSx,
  ...props
}) => (
  <Card
    sx={{
      "@media(min-width: 480px)": {
        boxShadow: `0px 0px 0px 1px #E0E0E0`,
        borderRadius: `5px`,
      },
      "@media(max-width: 480px)": {
        borderRadius: 0,
        boxShadow: `none`,
      },
      ...sx,
    }}
    className={className}
    {...props}
  >
    <CardHeader
      subheader={
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
          }}
        >
          <Box
            sx={{
              fontSize: `1rem`,
              display: `flex`,
              alignItems: `center`,
              padding: 0.5,
            }}
          >
            <IconWrapper>{Icon}</IconWrapper> {headerText}
          </Box>
          {!!Action && typeof Action === `function` && <Action />}
        </Box>
      }
      sx={{
        backgroundColor: `#F5F5F5`,
        ...headerSx,
      }}
    />
    <CardContent sx={contentSx}>{children}</CardContent>
  </Card>
)
