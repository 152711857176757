import { StyledButton } from "@erinfo/brand-ui/src/StyledComponents/v2/Button"
import Dialog from "@mui/material//Dialog"
import DialogActions from "@mui/material//DialogActions"
import DialogContent from "@mui/material//DialogContent"
import DialogContentText from "@mui/material//DialogContentText"
import DialogTitle from "@mui/material//DialogTitle"
import { confirmable, createConfirmation } from "react-confirm"

const DEFAULT_OPTIONS = {
  description: `Are you sure you want to remove this`,
  noAction: `No`,
  title: `Confirm Delete`,
  yesAction: `Yes, delete`,
  onNo: null,
}

const getOption = (options, optionKey) =>
  ({ ...DEFAULT_OPTIONS, ...options }[optionKey])

const ConfirmModal = ({ cancel, confirmation, options, proceed, show }) => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open={show}
    onClose={cancel}
    ref={options?.ref}
    // maxWidth="xs"
  >
    <DialogTitle id="alert-dialog-title">
      {getOption(options, `title`)}
    </DialogTitle>
    <DialogContent>
      <DialogContentText
        id="alert-dialog-description"
        sx={{ color: `rgba(0, 0, 0, 0.87)` }}
      >
        {typeof getOption(options, `description`) === `function`
          ? getOption(options, `description`)()
          : `${getOption(options, `description`)} ${confirmation} ${
              !!confirmation ? `?` : ``
            }`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {!!getOption(options, `noAction`) && (
        <StyledButton
          color={options.noColor || `error`}
          onClick={() => {
            const onNo = getOption(options, `onNo`)
            onNo && onNo()
            cancel()
          }}
        >
          {getOption(options, `noAction`)}
        </StyledButton>
      )}
      <StyledButton
        autoFocus
        color={options.yesColor || `primary`}
        onClick={proceed}
      >
        {getOption(options, `yesAction`)} {confirmation}
      </StyledButton>
    </DialogActions>
  </Dialog>
)

const ConfirmDialog = confirmable(ConfirmModal)
const triggerConfirmation = createConfirmation(ConfirmDialog)

export const confirm = (value, options = {}) =>
  triggerConfirmation({ confirmation: value, options })

export default ConfirmDialog
