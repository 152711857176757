import { Datepicker } from "./Datepicker"

export const DatepickerInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldTouched, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => (
  <Datepicker
    errors={touched[field.name] && errors[field.name] && [errors[field.name]]}
    onBlur={() => setFieldTouched(field.name)}
    {...field}
    {...props}
    onChange={(value) => setFieldValue(field.name, value)}
  />
)
