import { makeStyles } from "@mui/styles"
import { Link } from "react-router-dom"

const useStyles = makeStyles({
  link: {
    font: `400 14px / 16px Roboto`,
    letterSpacing: `0.24px`,
    textDecoration: `none`,
  },
})

interface IProps {
  children
  to?
  className?: string
  onClick?: () => void
}

export const TextAnchor = (props: IProps) => {
  const classes = useStyles()

  if (/^https?:\/\//.test(props.to)) {
    return (
      <a
        href={props.to}
        className={`${classes.link} ${props.className}`}
        onClick={props.onClick}
        target="_blank"
      >
        {props.children}
      </a>
    )
  }

  return (
    <Link
      to={props.to}
      className={`${classes.link} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </Link>
  )
}
