import { TextInfo } from "@erinfo/brand-ui/src/storybook/typography/_"
import { TextAnchor } from "@erinfo/brand-ui/src/storybook/typography/text-anchor/_"
import Box from "@mui/material/Box"
import Checkbox from "@mui/material/Checkbox"
import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import { styled } from "@mui/material/styles"

interface IProps {
  maxLength?: number
  to?
  children?
  inputId?: string
  value?: string | boolean
  errors: string[]
  onChange?(value)
  onBlur?()
  externalLink?: boolean
  showCheckbox?: boolean
  inline?: boolean
}

export const InitialsGrouping = (props: IProps) => {
  const { value, errors, children, to, onChange, maxLength, inline } = props
  return (
    <Container>
      {props.showCheckbox ? (
        <CheckboxContainer>
          <Checkbox
            {...props}
            checked={!!value}
            color="primary"
            onChange={onChange}
          />
          {errors && <FormHelperText error>{errors}</FormHelperText>}
        </CheckboxContainer>
      ) : (
        <StyledTextField
          {...props}
          variant="outlined"
          label="Initials"
          type="text"
          defaultValue=" "
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          onBlur={props.onBlur}
          inputProps={{
            min: 0,
            style: { textAlign: `center`, minHeight: 40 },
          }}
          error={!!errors}
          helperText={errors}
        />
      )}
      {inline ? (
        <TextContainer style={{ marginLeft: 10 }}>
          <TextInfo>
            {children} <TextAnchor to={to}>terms & conditions</TextAnchor>
          </TextInfo>
        </TextContainer>
      ) : (
        <TextContainer>
          <TextInfo>{children}</TextInfo>
          <InitialsLink>
            <TextAnchor to={to}>terms & conditions</TextAnchor>
            <TextInfo>*</TextInfo>
          </InitialsLink>
        </TextContainer>
      )}
    </Container>
  )
}

const Container = styled(`div`)({
  display: `flex`,
  alignItems: `center`,
  position: `relative`,
  justifyContent: `center`,
  "&.error-messages": {
    position: `absolute`,
    left: `10px`,
    top: `100%`,
    display: `flex`,
    justifyContent: `center`,
    p: {
      padding: `0`,
      textAlign: `center`,
      margin: `3px 17px`,
      fontWidth: `400`,
      fontSize: 12,
      lineHeight: 14,
      color: `#b00020`,
    },
  },
})

const TextContainer = styled(Box)({
  display: `flex`,
  flexFlow: `column`,
  marginLeft: `40px`,
})

const CheckboxContainer = styled(`div`)({
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
})

const InitialsLink = styled(`div`)({
  display: `inline-flex`,
  color: `#00f`,
  font: `400 14px/16px Roboto,sans-serif`,
  letterSpacing: `.24px`,
  textDecoration: `none`,
})

const StyledTextField = styled(TextField)({
  maxWidth: 80,
  "& .Mui-error": {
    margin: 0,
  },
})
