import { FormCard } from "@erinfo/brand-ui/src/storybook/other/card-v2"
import { AddBox, Edit } from "@mui/icons-material"
import { Box, Divider, IconButton, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { SxProps } from "@mui/system"
import { ReactNode } from "react"

export const StyledFormCard = styled(FormCard)({
  width: 410,
  minHeight: 400,
  "@media(max-width: 480px)": {
    width: `100%`,
  },
})

export const StyledDivider = styled(Divider)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

export const AddEditAction = ({ edit = false, onClick }) => (
  <IconButton color="primary" onClick={onClick}>
    {edit ? <Edit /> : <AddBox />}
  </IconButton>
)

export const ProfileValueDisplay = ({
  label,
  value,
  stacked = true,
  sx,
}: {
  label: string
  value?: string | ReactNode
  stacked?: boolean
  sx?: SxProps
}) => {
  const val = value || `Not yet entered`

  return (
    <Box
      display="flex"
      flexDirection={stacked ? `column` : `row`}
      alignItems={!stacked ? `center` : `flex-start`}
      sx={sx}
    >
      <Typography>{label}</Typography>
      <Typography
        sx={{
          opacity: value ? 0.6 : 0.25,
          mb: stacked ? 1 : 0,
          ml: !stacked ? 1 : 0,
        }}
      >
        {typeof val === `function` ? val() : val}
      </Typography>
    </Box>
  )
}

export const MedicalDataValue = ({ label, value }) =>
  !!value ? (
    <Box>
      <Typography sx={{ opacity: 0.26, mr: 1 }} component="span">
        {label}
      </Typography>
      <Typography sx={{ opacity: 0.54 }} component="span">
        {value}
      </Typography>
      <br />
    </Box>
  ) : (
    <></>
  )
