import { AutocompleteInput } from "@erinfo/brand-ui/src/storybook/inputs/autocomplete"
import {
  PhoneInput,
  TextInput,
} from "@erinfo/brand-ui/src/storybook/inputs/textfield"
import { dataRelationship } from "@erinfo/data-schema"
import { Field } from "formik"

export const SingleEmergencyContactForm = ({ setFieldValue }) => {
  return (
    <>
      <Field
        component={TextInput}
        name={`emergencyContactName`}
        label="Contact Name"
        inputProps={{ maxlength: `50` }}
        required
        hideAsterisk
      />
      <Field
        component={PhoneInput}
        fullWidth
        required
        hideAsterisk
        name={`emergencyContactPhone`}
        label="Contact MOBILE Phone"
        type="tel"
      />
      <Field
        component={TextInput}
        name={`emergencyContactEmail`}
        label="Contact Email"
        hideAsterisk
      />
      <Field
        component={AutocompleteInput}
        label="Relationship"
        name={`emergencyContactRelationship`}
        options={dataRelationship}
        setFieldValue={setFieldValue}
        required
        hideAsterisk
      />
    </>
  )
}
