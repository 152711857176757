import { TextParagraph } from "@erinfo/brand-ui/src/storybook/typography/_"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import { styled } from "@mui/material/styles"

interface IProps {
  title: string
  onClickYesEvent: () => void
  msg?: string | (() => void)
  alternativeYesLabel?: string
  alternativeNoLabel?: string
  onClickNoEvent?: () => void | null
  disableClose?: boolean
  onClose: () => void
  open: boolean
  altNoColor?: string
  altYesColor?: string
}

export const DialogStandard = (props: IProps) => {
  const onClickYesEvent = () => {
    props.onClickYesEvent()
    props.onClose()
  }

  const onClickNoEvent = () => {
    if (props.onClickNoEvent) props.onClickNoEvent()
    props.onClose()
  }

  return (
    <Dialog
      open={props.open}
      onClose={!props.disableClose ? props.onClose : () => {}}
    >
      {props.title && <DialogTitle>{props.title}</DialogTitle>}

      <DialogContent>
        <DialogContentText>
          {typeof props.msg === `string` && (
            <TextParagraph className="unsaved-modal__text">
              {props.msg}
            </TextParagraph>
          )}
          {typeof props.msg === `function` && props.msg()}
        </DialogContentText>
      </DialogContent>
      <StyledDialogActions>
        {props.onClickNoEvent && (
          <>
            <Button
              color={props.altNoColor ?? `error`}
              onClick={onClickNoEvent}
              sx={{ textTransform: `none`, marginRight: 2 }}
            >
              {props.alternativeNoLabel || `NO`}
            </Button>
          </>
        )}
        <Button
          onClick={onClickYesEvent}
          sx={{ textTransform: `none` }}
          color={props.altYesColor ?? `primary`}
        >
          {props.alternativeYesLabel || (props.onClickNoEvent ? `YES` : `OK`)}
        </Button>
      </StyledDialogActions>
    </Dialog>
  )
}

const StyledDialogActions = styled(DialogActions)({
  padding: `0 !important`,
  display: `flex`,
  justifyContent: `flex-end`,
  "& button": {
    marginLeft: `0 !important`,
  },
})
