import * as schema from "@erinfo/data-schema"
import deepmerge from "deepmerge"

import { setIfExists } from "./set-if-exists"

export const setUserData = (
  raw: DataSchema.user.post,
  isNewRecord?: boolean,
): DataSchema.user.post => {
  const result: DataSchema.user.post = {}
  for (const key of Object.keys(raw)) setIfExists(key, raw, result)
  return result
}
