import { BaseDialog } from "@erinfo/brand-ui/src/storybook/other/dialogs/BaseDialog"
import {
  CorePersonalForm,
  EmergencyContactsForm,
  emergencyContactsFormFields,
  Notes,
  PersonalDetailsForm,
} from "@erinfo/brand-ui/src/storybook/other/forms"
import {
  helpers,
  yupFirstNameSchema,
  yupLastNameSchema,
} from "@erinfo/data-schema"
import * as attr from "@erinfo/data-schema/src/attribute"
import {
  createPersonalDataSubmission,
  createPersonalFormData,
} from "@erinfo/react-utils/src/helpers/form/formDataFormatters"
import { useDynamicFields } from "@erinfo/react-utils/src/hooks/use-dynamic-fields"
import { useUnsavedChanges } from "@erinfo/react-utils/src/hooks/use-unsaved-changes"
import { AccountCircle } from "@mui/icons-material"
import { Box } from "@mui/material"
import dayjs from "dayjs"
import { Form, Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"

const { isIntlPhoneNumber } = helpers

const FormSchema = Yup.object().shape({
  mobile: Yup.string()
    .test(`intl phone`, `Phone number is not valid`, (val) => {
      if (!val) return true
      const number = val?.replace(/\s/g, ``)
      return number?.startsWith(`+1`)
        ? !!/^\+1\d{10}(?!\d)$/.exec(number)?.[0]
        : !!isIntlPhoneNumber.exp.exec(number)?.[0]
    })
    .max(16, `Too many digits for a phone number`),
  firstName: yupFirstNameSchema.required(`First name is required`),
  lastName: yupLastNameSchema.required(`Last name is required`),
  birthDate: Yup.string().test({
    name: `dob`,
    exclusive: false,
    message: `Must be a valid date of birth`,
    test: (value) =>
      value && dayjs(value).isValid() && dayjs(value).isBefore(dayjs()),
  }),
})

const iconStyle = {
  color: `#F44336`,
  filter: `brightness(0.7)`,
  marginRight: 2,
}

interface IProps {
  open: boolean
  user: any
  appType?: "provider" | "consumer"
  handleClose: () => void
  handleSubmit: (data: any) => Promise<void>
}

export const PersonalDialog = ({
  open,
  user,
  appType,
  handleClose,
  handleSubmit,
}: IProps) => {
  const [loading, setLoading] = useState(false)
  const { fieldCounts, initialValues, addField, removeField } =
    useDynamicFields(
      [`emergencyContacts`],
      createPersonalFormData,
      user,
      open,
      null,
      { emergencyContacts: emergencyContactsFormFields },
    )
  const { onClose, setDirty, dirty } = useUnsavedChanges(handleClose, open)

  const onSubmit = async (values) => {
    setLoading(true)
    console.log(values)
    const data = createPersonalDataSubmission(values)
    console.log(data)
    if (user.type !== `u/member`) {
      delete data.email
      delete data.profile.phoneNumbers
    }
    await handleSubmit(data)
    handleClose()
    setLoading(false)
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      Icon={<AccountCircle sx={iconStyle} />}
      title="Personal Information"
      formId="personal"
      loading={loading}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        validateOnBlur
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({
          setFieldValue,
          setFieldTouched,
          values,
          setValues,
          dirty: formDirty,
        }) => {
          if (formDirty && !dirty && open) {
            setDirty(true)
          }
          const formProps = {
            addField: addField(values, setValues),
            removeField: removeField(values, setValues),
            setFieldValue,
            values,
            fieldCounts,
          }
          return (
            <Form id="personal">
              <Box p={2}>
                <CorePersonalForm
                  showEmail
                  userIsSubscriber
                  setFieldValue={setFieldValue}
                  disableEmail={["u/orgMember", `u/subscriber`].includes(
                    user?.type,
                  )}
                />
                <PersonalDetailsForm
                  showPhone
                  userIsSubscriber
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  disablePhone={["u/orgMember", `u/subscriber`].includes(
                    user?.type,
                  )}
                />
              </Box>
              <Box
                p={2}
                sx={{
                  backgroundColor: `#2196F320`,
                }}
              >
                <EmergencyContactsForm {...formProps} />
              </Box>
              <Notes
                name={attr.nameNotesContact}
                value={values?.[attr.nameNotesContact]}
              />
            </Form>
          )
        }}
      </Formik>
    </BaseDialog>
  )
}
