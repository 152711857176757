import Box from "@mui/material/Box"
import { styled } from "@mui/material/styles"
import dayjs from "dayjs"
import { ReactNode } from "react"

interface IProps {
  className?: string
  helpEmail?: string
  showAbout?: boolean
  renderRightContent?: ReactNode
  renderLeftContent?: ReactNode
}

export const Footer = (props: IProps) => {
  const {
    showAbout = true,
    helpEmail = `help@erinfo.me`,
    renderRightContent,
    renderLeftContent,
  } = props
  const footerContent = [
    showAbout && [`About ERinfo`, `https://erinfo.me/about-us/`],
    [`Help`, `mailto:${helpEmail}`],
  ]

  return (
    <FooterWrapper className={props.className}>
      {renderLeftContent && (
        <Box
          sx={{
            position: `absolute`,
            left: 0,
            display: `flex`,
            alignItems: `center`,
            marginLeft: 1,
          }}
        >
          {renderLeftContent}
        </Box>
      )}
      <FooterContent className="footer-content">
        © 2018 - {dayjs().year()} ERinfo Inc
      </FooterContent>
      {renderRightContent && (
        <Box
          sx={{
            position: `absolute`,
            right: 0,
            display: `flex`,
            alignItems: `center`,
            marginRight: 1,
          }}
        >
          {renderRightContent}
        </Box>
      )}
    </FooterWrapper>
  )
}

const FooterWrapper = styled(`footer`)(({ theme }) => ({
  boxSizing: `border-box`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
  width: `100%`,
  borderTop: `solid 1px ${theme.palette?.border?.main}`,
  backgroundColor: theme.palette?.lightBackground?.main,
  font: `bold 14px / 16px Roboto`,
  textAlign: `center`,
  background: `white`,
}))

const FooterContent = styled(`div`)({
  display: `inline-block`,
})
